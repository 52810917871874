import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dimmer, Dropdown, DropdownProps, Loader,
} from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import Header from '../../components/Header';
import styles from './SalesRepReassign.module.scss';
import { Club } from '../../models/Club';
import {
  FetchClubRequestAction,
  FetchSalesRepOrgsRequestAction,
} from '../../models/actionTypes';
import { FilterSalesOrganizationsRequest, SalesOrganizationsByHash } from '../../models/Organizations';
import { OrganizationType } from '../../models/OrganizationType';
import { ReassignSalesRepToClubRequestAction } from '../../models/actionTypes/reassignActions';
import { CLUBS_ROUTE } from '../../constants/routes';

export interface SalesRepReassignProps extends RouteComponentProps<{ clubId: string }> {
  club: Club;
  organizationsByHash: SalesOrganizationsByHash;
  organizationsById: number[];
  loading: boolean;
  match: any;
  fetchClub: (id: number) => FetchClubRequestAction;
  fetchSalesRepOrgs: (request: FilterSalesOrganizationsRequest) => FetchSalesRepOrgsRequestAction;
  reassignSalesReptoClub: (salesRepOrganizationId: number, clubId: number)
  => ReassignSalesRepToClubRequestAction;
}
const SalesRepReassign = ({
  club: { name, salesOrganization },
  match: { params: { clubId } },
  fetchClub,
  loading,
  fetchSalesRepOrgs,
  organizationsByHash,
  organizationsById,
  reassignSalesReptoClub,
}: SalesRepReassignProps) => {
  const [translate] = useTranslation();
  const [selectedSalesRepOrg, setSelectedSalesRepOrg] = useState<number | null>(
    salesOrganization ? salesOrganization.id : null,
  );

  useEffect(() => {
    fetchClub(+clubId);
  }, [clubId, fetchClub]);

  const handleSalesRepSelection = (
    event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps,
  ) => {
    setSelectedSalesRepOrg(previousValue => (data.value ? +data.value : previousValue));
  };

  const organizationOptions = useMemo(
    () => organizationsById.map(id => organizationsByHash[id]).map(organization => ({
      value: organization.id,
      text: organization.name,
    })), [organizationsById, organizationsByHash],
  );

  useEffect(() => {
    fetchSalesRepOrgs({ type: OrganizationType.SALES });
  }, [fetchSalesRepOrgs]);

  const onSubmit = () => {
    if (selectedSalesRepOrg) {
      reassignSalesReptoClub(selectedSalesRepOrg as number, clubId);
    }
  };

  const renderActionButtons = () => (
    <Button
      content={translate('ASSIGN')}
      key="save_button"
      onClick={onSubmit}
      disabled={!selectedSalesRepOrg}
      primary
    />
  );

  const clubStamp = (
    <div className={styles.clubNameWrapper}>
      <label className={styles.clubNameLabel}>{translate('CLUB_NAME')}</label>
      <div className={styles.clubNameBadge}>
        <span>{name}</span>
      </div>
    </div>
  );

  const salesRepSelectionDropDown = (
    <div className={styles.assignSalesRepOrgWrapper}>
      <label className={styles.assignOrgLabel}>{translate('RESPONSIBLE_SALES_REP')}</label>
      <Dropdown
        name="sales-rep-org"
        options={organizationOptions || []}
        selection
        fluid
        placeholder={translate('RESPONSIBLE_SALES_REP')}
        value={selectedSalesRepOrg || ''}
        onChange={handleSalesRepSelection}
      />
    </div>
  );

  return (
    <>
      {loading && <Dimmer active inverted><Loader /></Dimmer>}
      <div className={styles.wrapper}>
        <div className={[styles.topGroup, styles.fixHeight].join(' ')}>
          <Header backLinkTo={CLUBS_ROUTE} backLabel={translate('BACK')} border title={translate('ASSIGN_CLUB_TO_SALES_REP')} renderButton={renderActionButtons} />
          <div className={styles['content-wrapper']}>
            {clubStamp}
            {salesRepSelectionDropDown}
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesRepReassign;
