import { TeamBasketOrderActions } from '../models/actionTypes';
import {
  FETCH_TEAM_BASKET_ORDERS_REQUEST,
  FETCH_TEAM_BASKET_ORDERS_SUCCESS,
  FETCH_TEAM_BASKET_ORDERS_ERROR,
} from '../constants/actionTypes';
import { TeamBasketOrder, TeamBasketOrderByHash, TeamBasketOrdersReducer } from '../models/TeamBasketOrder';

export const initialState: TeamBasketOrdersReducer = {
  byHash: {},
  byId: [],
  count: 0,
  loading: false,
  error: false,
  ordersDeleted: false,
};

export default (state = initialState, action: TeamBasketOrderActions) => {
  switch (action.type) {
    case FETCH_TEAM_BASKET_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TEAM_BASKET_ORDERS_SUCCESS: {
      const { orders, count } = action.payload;

      return {
        ...state,
        byHash: orders
          .reduce((byHash: TeamBasketOrderByHash, order: TeamBasketOrder) => ({
            ...byHash,
            [order.id]: order,
          }), state.byHash),
        byId: orders.map(({ id }: TeamBasketOrder) => id),
        loading: false,
        count,
      };
    }
    case FETCH_TEAM_BASKET_ORDERS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
