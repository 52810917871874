import { AjaxError } from 'rxjs/ajax';

import { Catalog } from '../../models/Catalog';
import { FETCH_CATALOG_REQUEST, FETCH_CATALOG_SUCCESS, FETCH_CATALOG_ERROR } from '../../constants/actionTypes';
import {
  FetchCatalogErrorAction,
  FetchCatalogRequestAction,
  FetchCatalogSuccessAction,
} from '../../models/actionTypes';

export const fetchCatalogRequest = (clubId: number): FetchCatalogRequestAction => ({
  type: FETCH_CATALOG_REQUEST,
  payload: {
    clubId,
  },
});

export const fetchCatalogSuccess = (catalog: Catalog): FetchCatalogSuccessAction => ({
  type: FETCH_CATALOG_SUCCESS,
  payload: {
    catalog,
  },
});

export const fetchCatalogError = (error: AjaxError): FetchCatalogErrorAction => ({
  type: FETCH_CATALOG_ERROR,
  payload: {
    error,
  },
});
