import pickBy from 'lodash/pickBy';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';
import { CLUBS_PATH } from '../../constants/paths';
import {
  CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_ERROR,
  CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_REQUEST,
} from '../../constants/actionTypes';
import {
  CreateClubAndApproveHobbyTeamPromotionRequestAction,
} from '../../models/actionTypes';
import { State } from '../../models/State';
import { createClubError, createClubSuccess } from '../../actions/clubs/createClub';
import { handleError } from '../../actions/errors/handleError';
import { sendInvitationRequest } from '../../actions/invitations';
import { approvePromotionRequest } from '../../actions/teamRequests/ApprovePromotionRequest';

export default (
  action$: ActionsObservable<CreateClubAndApproveHobbyTeamPromotionRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      name, location, salesRepOrganizationId,
      createInvitation, invitationPersonalData,
      teamId, promotionRequestId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: CLUBS_PATH,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(pickBy({
      name, salesRepOrganizationId, address: { displayName: location },
    })),
  }).pipe(
    mergeMap(({ response: createdClub }) => {
      const createInvitationWithClubId = { ...createInvitation, clubId: createdClub.id };
      const invitationPersonalDataWithClubName = {
        ...invitationPersonalData, clubName: createdClub.name,
      };

      return [
        createClubSuccess(createdClub),
        sendInvitationRequest(createInvitationWithClubId, invitationPersonalDataWithClubName),
        approvePromotionRequest(
          teamId,
          promotionRequestId,
          {
            officialClubId: createdClub.id,
          },
        ),
      ].filter(Boolean);
    }),
    catchError((error: AjaxError) => ([
      handleError(error, CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_ERROR),
      createClubError(error),
    ])),
  )),
);
