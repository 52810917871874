import { Column } from '../../components/Table/Column';
import SeasonFilter from '../../components/SeasonFilter';

const columns = (translate: any): Column[] => [{
  width: '10%',
  align: 'left',
  header: `# ${translate('ID')}`,
  key: 'id',
  sortable: true,
}, {
  width: '20%',
  header: 'NAME',
  align: 'left',
  key: 'name',
  sortable: true,
}, {
  width: '20%',
  header: 'SEASON',
  align: 'left',
  key: 'seasons',
  sortable: false,
  filter: SeasonFilter,
}, {
  width: '10%',
  header: 'NUMBER_OF_TEAM_LEADS',
  align: 'center',
  key: 'teamLeads',
  sortable: false,
}, {
  width: '10%',
  header: 'PENDING',
  align: 'center',
  key: 'pendingTeamLeads',
  sortable: false,
}, {
  width: '10%',
  header: 'NUMBER_OF_PLAYERS',
  align: 'center',
  key: 'players',
  sortable: false,
}, {
  width: '15%',
  header: 'SEASON_START',
  align: 'left',
  key: 'seasonStartDate',
  sortable: false,
}, {
  width: '15%',
  header: 'SEASON_END',
  align: 'left',
  key: 'seasonEndDate',
  sortable: false,
}, {
  width: '10%',
  header: 'STATUS',
  align: 'left',
  key: 'status',
  sortable: false,
}, {
  width: '5%',
  header: '',
  align: 'left',
  key: 'actions',
  sortable: false,
}];

export default columns;
