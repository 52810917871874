import React, { FC, useRef, useState } from 'react';
import {
  Modal, Loader, Input, Button, Label,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import { TeamInvitationPopupProps } from './TeamInvitationPopupProps';
import styles from './TeamInvitationPopup.module.scss';
import { UrlParams } from '../../../../constants/urlParams';
import config from '../../../../config';

const TeamInvitationPopup: FC<TeamInvitationPopupProps> = ({
  inviteToken,
  loadingInviteToken,
  tokenDeactivated,
  isOpen,
  closePopup,
}) => {
  const [translate] = useTranslation();
  const [isTokenCopied, setIsTokenCopied] = useState(false);
  const params = queryString
    .stringify({ [UrlParams.INVITATION_TOKEN]: inviteToken });
  const inputRef = useRef<Input>(null);

  const copyToClipboard = () => {
    if (inputRef && inputRef.current && document) {
      inputRef.current.select();
      try {
        document.execCommand('copy');
        setIsTokenCopied(true);
        return;
      } catch (e) {
        setIsTokenCopied(false);
      }
    }
  };

  const renderPopupContent = () => (tokenDeactivated ? <span>{translate('TOKEN_DEACTIVATED')}</span> : (
    <div>
      { isTokenCopied && (
      <Label
        className={[styles['copied-label'], styles.absolute, styles.message].join(' ')}
        pointing="below"
      >
        {translate('COPIED_TO_CLIPBOARD')}
      </Label>
      )}
      <Input
        type="text"
        action
        ref={inputRef}
        className={styles['invite-link-input']}
        defaultValue={`${config.CLUB_WEB_URL}/?${params}`}
      >
        <input />
        <Button basic icon="copy" onClick={copyToClipboard} />
      </Input>
      <span className={styles['team-invite-message']}>{translate('TEAM_INVITE_WARNING_MESSAGE')}</span>
    </div>
  ));

  return (
    <Modal
      open={isOpen}
      onClose={closePopup}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header content={translate('INVITE_PLAYERS')} />
      <Modal.Content>
        { loadingInviteToken && !inviteToken ? <Loader active /> : renderPopupContent()}
      </Modal.Content>
    </Modal>
  );
};

export default TeamInvitationPopup;
