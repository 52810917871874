import { RemoveProductAction } from '../../models/actionTypes';
import { REMOVE_PRODUCT } from '../../constants/actionTypes';

export const removeProduct = (clubId: number, productId: string): RemoveProductAction => ({
  type: REMOVE_PRODUCT,
  payload: {
    clubId,
    productId,
  },
});
