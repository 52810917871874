import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import InputPassword from '../InputPassword';
import styles from './FormFields.module.scss';
import { FormFieldProps } from './FormFieldsProps';

const FormFields = ({
  schema, values, handleChange, errors, inline,
  disabled: formDisabled = false,
}: FormFieldProps) => {
  const [translate] = useTranslation();

  return (
    <>
      {Object.keys(schema).map(fieldName => {
        const {
          type, label, options: selectOptions, required, placeholder, disabled, clearable = true,
        } = schema[fieldName];
        const value = get(values, fieldName);

        return (
          <Form.Field
            className={classNames(styles.formField, inline && styles.formFieldInline)}
            key={fieldName}
            required={required}
            disabled={formDisabled}
          >
            <label>
              {translate(label || fieldName.toUpperCase())}
            </label>

            <div className={styles.inputWithLabel}>
              {type === 'text' && (
                <Form.Input
                  error={errors[fieldName]}
                  name={fieldName}
                  onChange={handleChange}
                  disabled={disabled}
                  placeholder={placeholder && translate(placeholder)}
                  value={value || ''}
                />
              )}

              {type === 'password' && (
                <InputPassword
                  error={errors[fieldName]}
                  name={fieldName}
                  disabled={disabled}
                  onChange={handleChange}
                  value={value || ''}
                />
              )}

              {type === 'number' && (
                <Form.Input
                  error={errors[fieldName]}
                  min={0}
                  name={fieldName}
                  onChange={handleChange}
                  disabled={disabled}
                  type="number"
                  value={value || ''}
                />
              )}

              {type === 'select' && (
                <Form.Dropdown
                  clearable={clearable}
                  error={errors[fieldName]}
                  lazyLoad
                  disabled={disabled}
                  name={fieldName}
                  onChange={handleChange}
                  options={selectOptions ? selectOptions(translate) : []}
                  selection
                  value={value || ''}
                  placeholder={translate(placeholder || '')}
                />
              )}
            </div>
          </Form.Field>
        );
      })}
    </>
  );
};

export default FormFields;
