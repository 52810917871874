import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { HOME_ROUTE } from '../../../../constants/routes';
import RouteProps from '../../interfaces/RouteProps';

const UnprotectedRoute = ({ isAuthenticated, ...props }: RouteProps) => (
  isAuthenticated
    ? <Redirect to={HOME_ROUTE} />
    : <Route {...props} />
);

export default UnprotectedRoute;
