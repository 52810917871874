import { AjaxError } from 'rxjs/ajax';

import {
  CONFIRM_INDIVIDUAL_ORDER_REQUEST,
  CONFIRM_INDIVIDUAL_ORDER_SUCCESS,
  CONFIRM_INDIVIDUAL_ORDER_ERROR,
} from '../../constants/actionTypes';
import {
  ConfirmIndividualOrderRequest,
  ConfirmIndividualOrderSuccess,
  ConfirmIndividualOrderError,
} from '../../models/actionTypes';

export const confirmIndividualOrderRequest = (
  orderId: number, finalPrice: number,
): ConfirmIndividualOrderRequest => ({
  type: CONFIRM_INDIVIDUAL_ORDER_REQUEST,
  payload: {
    orderId,
    finalPrice,
  },
});

export const confirmIndividualOrderSuccess = (
  orderId: number,
): ConfirmIndividualOrderSuccess => ({
  type: CONFIRM_INDIVIDUAL_ORDER_SUCCESS,
  payload: {
    orderId,
  },
});

export const confirmIndividualOrderError = (error: AjaxError): ConfirmIndividualOrderError => ({
  type: CONFIRM_INDIVIDUAL_ORDER_ERROR,
  payload: {
    error,
  },
});
