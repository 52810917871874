import { EntityTypesEnum } from './Entity';

export enum NtsRewardsLevel {
  WOOD = 'WOOD',
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PLATINIUM = 'PLATINIUM',
}

export interface EntityScoreDto {
  name: string;
  level: NtsRewardsLevel;
  points: number;
  totalPointsEverCollected: number;
}

export interface RewardsListDataActionPayload {
  entityId: number;
  entityType: EntityTypesEnum,
  dateTime?: number;
  direction?: FiltersDirectionsEnum;
  limit?: number;
  tags?: string[];
}

export enum FiltersDirectionsEnum {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}
