import {
  CLUBS_ROUTE,
  CLUBS_CATALOGS_ROUTE,
  TEAM_REQUESTS_ROUTE,
  HOBBY_CLUBS_ROUTE,
  HOBBY_CATALOG_ROUTE,
  INDIVIDUAL_ORDERS_ROUTE,
  TEAM_BASKET_ORDERS_ROUTE,
} from '../../constants/routes';
import { MenuItem } from '../../components/Layout/components/Sidebar/MenuItem';
import { Roles } from '../../constants/roles';
import { OrganizationType } from '../../models/OrganizationType';

export default (userRoles: Roles[], organizationType?: OrganizationType) => [
  {
    name: 'MY_CLUBS',
    route: CLUBS_ROUTE,
    icon: 'shield',
  },
  {
    name: 'CLUB_CATALOGS',
    route: CLUBS_CATALOGS_ROUTE,
    icon: 'shield alternate',
  },
  userRoles.includes(Roles.SALES_REPRESENTATIVE)
    && organizationType === OrganizationType.MASTER && {
    name: 'HOBBY_CATALOG',
    route: HOBBY_CATALOG_ROUTE,
    icon: 'shield alternate',
  },
  userRoles.includes(Roles.SALES_REPRESENTATIVE)
  && organizationType === OrganizationType.MASTER && {
    name: 'TEAM_BASKET_ORDERS.TITLE',
    route: TEAM_BASKET_ORDERS_ROUTE,
    icon: 'shopping bag',
  },
  userRoles.includes(Roles.SALES_REPRESENTATIVE)
    && organizationType === OrganizationType.MASTER && {
    name: 'INDIVIDUAL_ORDERS',
    route: INDIVIDUAL_ORDERS_ROUTE,
    icon: 'shopping basket',
  },
  userRoles.includes(Roles.SALES_REPRESENTATIVE)
    && organizationType === OrganizationType.MASTER && {
    name: 'HOBBY_TEAM_REQUESTS',
    route: TEAM_REQUESTS_ROUTE,
    icon: 'plus',
  },
  {
    name: 'HOBBY_TEAMS',
    route: HOBBY_CLUBS_ROUTE,
    icon: 'plus square',
  },
].filter(Boolean) as MenuItem[];
