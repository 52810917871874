import { AjaxError } from 'rxjs/ajax';
import { SAVE_EXTERNAL_REFERENCE_ERROR, SAVE_EXTERNAL_REFERENCE_REQUEST, SAVE_EXTERNAL_REFERENCE_SUCCESS } from '../../constants/actionTypes';
import { SaveExternalReferenceErrorAction, SaveExternalReferenceRequestAction, SaveExternalReferenceSuccessAction } from '../../models/actionTypes/ExternalSystemsActions';
import { ExternalSystemReference } from '../../models/ExternalSystems';

export const saveExternalReference = (
  externalSystemReference: ExternalSystemReference,
  clubId?: number,
  currentPage?: number,
): SaveExternalReferenceRequestAction => ({
  type: SAVE_EXTERNAL_REFERENCE_REQUEST,
  payload: {
    reference: externalSystemReference,
    clubId,
    currentPage,
  },
});

export const saveExternalReferenceSuccess = (
): SaveExternalReferenceSuccessAction => ({
  type: SAVE_EXTERNAL_REFERENCE_SUCCESS,
  payload: {},
});

export const saveExternalReferenceError = (error: AjaxError): SaveExternalReferenceErrorAction => ({
  type: SAVE_EXTERNAL_REFERENCE_ERROR,
  payload: {
    error,
  },
});
