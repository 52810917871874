import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { withRouter } from 'react-router';
import { State } from '../../models/State';
import { Club } from '../../models/Club';
import { fetchClubRequest } from '../../actions/clubs/fetchClub';
import { ClubTeamBasketOrdersProps } from './ClubTeamBasketOrdersProps';
import { fetchTeamBasketOrdersRequest } from '../../actions/teamBasketOrders/fetchTeamBasketOrders';
import ClubTeamBasketOrders from './ClubTeamBasketOrders';

const mapStateToProps = ({
  clubs: {
    byHash: clubsByHash,
    byId: clubsById,
  },
  teamBasketOrders: {
    byHash: ordersByHash,
    byId: ordersById,
    count,
    loading,
  },
}: State, { match: { params: { clubId } } }: ClubTeamBasketOrdersProps) => ({
  clubs: clubsById.map(id => clubsByHash[id]),
  teamBasketOrders: ordersById.map(id => ordersByHash[id]),
  loading,
  count,
  club: clubId && clubsByHash[clubId]
    ? clubsByHash[clubId]
    : {
      address: {},
    } as Club,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTeamBasketOrders: bindActionCreators(fetchTeamBasketOrdersRequest, dispatch),
  fetchClub: bindActionCreators(fetchClubRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClubTeamBasketOrders));
