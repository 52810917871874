import { serializeProduct } from './serializeProduct';
import {
  HobbyCatalog, CatalogResponse, EmbellishmentFixedPriceDiscountType,
} from '../../models/Catalog';

const mapGroupDiscounts = (fixedPrice: EmbellishmentFixedPriceDiscountType) => ({
  ...fixedPrice,
  key: `${fixedPrice.embellishment}${fixedPrice.groupId}`,
});

const serializeHobbyCatalog = ({
  club,
  products,
  productDiscountPercent,
  embellishmentsDiscountPercent,
  embellishmentFixedPriceDiscounts = [],
  ...candidate
}: CatalogResponse): HobbyCatalog => ({
  ...candidate,
  products: Array.isArray(products) ? products.map(serializeProduct) : [],
  productDiscountPercent: productDiscountPercent || 0,
  embellishmentsDiscountPercent: embellishmentsDiscountPercent || 0,
  embellishmentFixedPriceDiscounts: embellishmentFixedPriceDiscounts.map(mapGroupDiscounts),
});

export default serializeHobbyCatalog;
