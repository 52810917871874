import { Column } from '../../components/Table/Column';

const columns = (): Column[] => [{
  width: '30%',
  header: 'NAME',
  align: 'left',
  key: 'name',
  sortable: false,
}, {
  width: '30%',
  header: 'EMAIL',
  align: 'left',
  key: 'email',
  sortable: false,
}, {
  width: '40%',
  header: '',
  align: 'right',
  key: 'actions',
  sortable: false,
}];

export default columns;
