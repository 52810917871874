import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import { formatPrice } from '../../utils/PricingUtil';
import Row from '../../components/Table/components/Row';
import Text from '../../components/Text';
import { formatDate } from '../../utils/DateUtils';
import TableContainer from '../../components/Layout/components/TableContainer';
import styles from '../Settings/Settings.module.scss';
import ClubDetailsHeader from '../../components/ClubDetailsHeader';
import filteredColumns from './filteredColumns';
import { ClubTeamBasketOrdersProps } from './ClubTeamBasketOrdersProps';
import { TeamBasketOrder } from '../../models/TeamBasketOrder';

const ClubTeamBasketOrders = ({
  fetchTeamBasketOrders,
  fetchClub,
  loading,
  count,
  teamBasketOrders,
  match,
  history,
  club: { name },
} : ClubTeamBasketOrdersProps) => {
  const [translate] = useTranslation();

  const { clubId } = match.params;

  useEffect(() => {
    if (!name) {
      fetchClub(clubId);
    }
  }, [clubId, fetchClub, name]);

  const renderRows = (
    {
      id,
      finalPrice,
      price,
      orderDate,
      articleCount,
      status,
      checkoutType,
      shopwareOrderNumber,
    }: TeamBasketOrder,
  ) => (
    <Row
      key={id}
    >
      <Text bold>{shopwareOrderNumber || `#${id}`}</Text>
      <Text>{formatDate(orderDate)}</Text>
      <Text>{status ? translate(`TEAM_BASKET_ORDERS.STATUS.${status}`) : ''}</Text>
      <Text>{articleCount || ''}</Text>
      <Text>{price ? formatPrice(price.amount, price.currency) : ''}</Text>
      <Text>{finalPrice ? formatPrice(finalPrice.amount, finalPrice.currency) : ''}</Text>
      <Text>{checkoutType ? translate(`TEAM_BASKET_ORDERS.CHECKOUT_TYPE.${checkoutType}`) : ''}</Text>
    </Row>
  );
  return (
    <>
      {loading && <Dimmer active inverted><Loader /></Dimmer>}
      <ClubDetailsHeader clubName={name} clubId={clubId} />
      <div className={styles.wrapper}>
        <TableContainer
          extraFetchParameters={{ clubId }}
          fetch={fetchTeamBasketOrders}
          loading={loading}
          count={count}
          history={history}
          columns={filteredColumns(translate)}
          renderItems={teamBasketOrders.map(renderRows)}
          disableSearch
          invertedHeader
          emptyTableText="NO_ORDERS_AVAILABLE"
        />
      </div>
    </>
  );
};

export default ClubTeamBasketOrders;
