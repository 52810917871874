import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { State } from '../../models/State';
import { fetchClubTeamsRequest } from '../../actions/clubTeams/fetchClubTeams';
import { AddTeamLeadRoleToClubAdminProps } from './AddTeamLeadRoleToClubAdminProps';
import AddTeamLeadRoleToClubAdmin from './AddTeamLeadRoleToClubAdmin';
import { assignMemberToTeamRequest } from '../../actions/clubTeams/assignMemberToTeam';

const mapStateToProps = ({
  clubs: {
    byHash: clubsByHash,
  },
  clubTeams: {
    byHash: teamsByHash,
    byId: teamsById,
  },
}: State, { match: { params: { clubId } } }: AddTeamLeadRoleToClubAdminProps) => ({
  clubTeams: teamsById.map(id => teamsByHash[id]),
  clubAdmins: clubId && clubsByHash[clubId]
    && clubsByHash[clubId].clubAdmins
    ? clubsByHash[clubId].clubAdmins
    : [],
  clubId,
  clubName: clubId && clubsByHash[clubId] ? clubsByHash[clubId].name : '',
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchClubTeams: bindActionCreators(fetchClubTeamsRequest, dispatch),
  assignMember: bindActionCreators(assignMemberToTeamRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTeamLeadRoleToClubAdmin);
