import { Column } from '../../../components/Table/Column';

const columns: Column[] = [{
  width: '50%',
  header: 'REWARDS.REASON',
  align: 'left',
  key: 'reason',
  sortable: false,
}, {
  width: '25%',
  header: 'REWARDS.CHANGE_OF_BALLER',
  align: 'left',
  key: 'change',
  sortable: false,
}, {
  width: '25%',
  header: 'REWARDS.DATE',
  align: 'left',
  key: 'date',
  sortable: false,
}];

export default columns;
