import { Column } from '../../components/Table/Column';

const columns = (translate: any): Column[] => [{
  width: '15%',
  align: 'left',
  header: `# ${translate('ORDER')}`,
  key: 'orderId',
  sortable: true,
}, {
  width: '20%',
  header: 'ORDER_DATE',
  align: 'left',
  key: 'createdAt',
  sortable: true,
}, {
  width: '25%',
  header: 'PLAYER',
  align: 'left',
  key: 'createdBy',
  sortable: true,
}, {
  width: '15%',
  header: 'TOTAL',
  align: 'right',
  key: 'itemsSummedPrice',
  sortable: true,
}];

export default columns;
