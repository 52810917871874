import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';
import {
  AssignMemberToTeamRequestAction,
  NotificationType,
} from '../../models/actionTypes';
import { State } from '../../models/State';
import {
  ADD_ROLE_TO_MEMBER_REQUEST,
  ADD_ROLE_TO_MEMBER_ERROR,
  ADD_ROLE_TO_MEMBER_SUCCESS,
} from '../../constants/actionTypes';
import { ASSIGN_MEMBERS_PATH } from '../../constants/paths';
import { addNotification } from '../../actions/notifications/addNotification';
import { handleError } from '../../actions/errors/handleError';
import {
  assignMemberToTeamError,
  assignMemberToTeamSuccess,
} from '../../actions/clubTeams/assignMemberToTeam';

export default (
  action$: ActionsObservable<AssignMemberToTeamRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(ADD_ROLE_TO_MEMBER_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      role,
      personId,
      clubId,
      teamId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: ASSIGN_MEMBERS_PATH,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      role,
      personId,
      clubId,
      teamId,
    }),
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, ADD_ROLE_TO_MEMBER_SUCCESS),
      assignMemberToTeamSuccess(),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, ADD_ROLE_TO_MEMBER_ERROR),
      assignMemberToTeamError(error),
    ]),
  )),
);
