import { AjaxError } from 'rxjs/ajax';
import {
  FETCH_EMBELLISHMENTS_ERROR,
  FETCH_EMBELLISHMENTS_REQUEST,
  FETCH_EMBELLISHMENTS_SUCCESS,
} from '../../constants/actionTypes';
import { Embellishment } from '../../models/Embellishment';
import {
  FetchEmbellishmentsErrorAction,
  FetchEmbellishmentsRequestAction,
  FetchEmbellishmentsSuccessAction,
} from '../../models/actionTypes';

export const fetchEmbellishmentsRequest = (): FetchEmbellishmentsRequestAction => ({
  type: FETCH_EMBELLISHMENTS_REQUEST,
});

export const fetchEmbellishmentsSuccess = (
  embellishments: Embellishment[],
): FetchEmbellishmentsSuccessAction => ({
  type: FETCH_EMBELLISHMENTS_SUCCESS,
  payload: { embellishments },
});

export const fetchEmbellishmentsError = (error: AjaxError): FetchEmbellishmentsErrorAction => ({
  type: FETCH_EMBELLISHMENTS_ERROR,
  payload: {
    error,
  },
});
