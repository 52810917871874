import { FETCH_SALES_REP_ORGS_REQUEST, FETCH_SALES_REP_ORGS_SUCCESS, FETCH_SALES_REP_ORGS_ERROR } from '../constants/actionTypes';

import { SalesOrganization, SalesOrganizationsByHash, OrganizationsReducer } from '../models/Organizations';
import { SIGN_OUT_SUCCESS, SIGN_OUT_ERROR } from '../packages/authentication';
import { Actions } from '../models/actionTypes';

export const initialState: OrganizationsReducer = {
  loading: false,
  byHash: {},
  byId: [],
  error: false,
};

export default (state = initialState, action : Actions) => {
  switch (action.type) {
    case FETCH_SALES_REP_ORGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SALES_REP_ORGS_SUCCESS: {
      const { organizations } = action.payload;

      return {
        ...state,
        byHash: organizations
          .reduce((byHash: SalesOrganizationsByHash, request: SalesOrganization) => ({
            ...byHash,
            [request.id]: request,
          }), state.byHash),
        byId: organizations.map(({ id }: SalesOrganization) => id),
        loading: false,
      };
    }
    case FETCH_SALES_REP_ORGS_ERROR: {
      const { error: { message } } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: message,
      };
    }
    case SIGN_OUT_SUCCESS:
    case SIGN_OUT_ERROR:
      return initialState;
    default:
      return state;
  }
};
