import { AjaxError } from 'rxjs/ajax';
import {
  FetchTeamInviteErrorAction,
  FetchTeamInviteRequestAction,
  FetchTeamInviteSuccessAction,
} from '../../models/actionTypes';
import {
  FETCH_TEAM_INVITE_TOKEN_ERROR,
  FETCH_TEAM_INVITE_TOKEN_REQUEST,
  FETCH_TEAM_INVITE_TOKEN_SUCCESS,
} from '../../constants/actionTypes';

export const fetchTeamInviteRequest = (
  teamId: number, clubId: number,
): FetchTeamInviteRequestAction => ({
  type: FETCH_TEAM_INVITE_TOKEN_REQUEST,
  payload: {
    teamId,
    clubId,
  },
});

export const fetchTeamInviteSuccess = (token: string): FetchTeamInviteSuccessAction => ({
  type: FETCH_TEAM_INVITE_TOKEN_SUCCESS,
  payload: {
    token,
  },
});

export const fetchTeamInviteError = (error: AjaxError): FetchTeamInviteErrorAction => ({
  type: FETCH_TEAM_INVITE_TOKEN_ERROR,
  payload: {
    error,
  },
});
