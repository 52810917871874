import { Price } from './Price';

export enum EmbellishmentTypes {
  BACK_NUMBER = 'BACK_NUMBER',
  EMBLEM = 'EMBLEM',
  INITIALS = 'INITIALS',
  LETTERING = 'LETTERING',
  PLAYER_NAME = 'PLAYER_NAME',
  SLEEVE = 'SLEEVE',
  SMALL_NUMBER = 'SMALL_NUMBER',
  SMALL_PLAYER_NAME = 'SMALL_PLAYER_NAME',
  SMALL_SPONSOR = 'SMALL_SPONSOR',
  SMALL_TEAM_NAME = 'SMALL_TEAM_NAME',
  SPONSOR_BACK = 'SPONSOR_BACK',
  SPONSOR_CHEST = 'SPONSOR_CHEST',
  SPONSOR_SLEEVE = 'SPONSOR_SLEEVE',
  TEAM_NAME = 'TEAM_NAME',
  TEMPLATE = 'TEMPLATE',
  CLUB_NAME_XL = 'CLUB_NAME_XL',
  CLUB_NAME_S = 'CLUB_NAME_S',
  SMALL_NUMBERS = 'SMALL_NUMBERS',
  LARGE_NUMBERS = 'LARGE_NUMBERS',
  INITIALS_NUMBERS = 'INITIALS_NUMBERS',
  PLAYER_NAME_XL = 'PLAYER_NAME_XL',
  PLAYER_NAME_S = 'PLAYER_NAME_S',
  SPECIAL_EMBLEM = 'SPECIAL_EMBLEM',
  ADVERTISING_PRINT_100_MONOCHROMATIC = 'ADVERTISING_PRINT_100_MONOCHROMATIC',
  ADVERTISING_PRINT_100_POLYCHROMATIC = 'ADVERTISING_PRINT_100_POLYCHROMATIC',
  ADVERTISING_PRINT_200_MONOCHROMATIC = 'ADVERTISING_PRINT_200_MONOCHROMATIC',
  ADVERTISING_PRINT_200_POLYCHROMATIC = 'ADVERTISING_PRINT_200_POLYCHROMATIC',
  ADVERTISING_PRINT_400_MONOCHROMATIC = 'ADVERTISING_PRINT_400_MONOCHROMATIC',
  ADVERTISING_PRINT_400_POLYCHROMATIC = 'ADVERTISING_PRINT_400_POLYCHROMATIC',
  ADVERTISING_PRINT_600_MONOCHROMATIC = 'ADVERTISING_PRINT_600_MONOCHROMATIC',
  ADVERTISING_PRINT_600_POLYCHROMATIC = 'ADVERTISING_PRINT_600_POLYCHROMATIC',
  ADVERTISING_PRINT_800_MONOCHROMATIC = 'ADVERTISING_PRINT_800_MONOCHROMATIC',
  ADVERTISING_PRINT_800_POLYCHROMATIC = 'ADVERTISING_PRINT_800_POLYCHROMATIC',
  SUBLIMATION = 'SUBLIMATION',
  TEAMSPORTS_11_LOGO_LARGE = 'TEAMSPORTS_11_LOGO_LARGE',
  TEAMSPORTS_11_LOGO_SMALL = 'TEAMSPORTS_11_LOGO_SMALL',
  TEAMSPORTS_11_SLEEVE_PATCH = 'TEAMSPORTS_11_SLEEVE_PATCH',
  CLUB_EMBLEM_POLYCHROMATIC = 'CLUB_EMBLEM_POLYCHROMATIC',
  CLUB_EMBLEM_MONOCHROMATIC = 'CLUB_EMBLEM_MONOCHROMATIC',
  EMBOSSING = 'EMBOSSING',
  STRIPES = 'STRIPES',
}

export interface Embellishment {
  type: EmbellishmentTypes,
  name: string;
  price: Price;
  priceWithSmall11TSLogo?: Price;
  priceWithBig11TSLogo?: Price;
  valueType?: string;
}

export interface EmbellishmentsByHash {
  [key: string]: Embellishment;
}

export interface EmbellishmentsReducer {
  embellishmentsByHash: EmbellishmentsByHash;
  embellishmentsById: EmbellishmentTypes[];
  embellishmentValues: EmbellishmentValue[];
  loadingEmbellishmentValues: boolean;
  loadingEmbellishments: boolean;
  fetchEmbellishmentValuesError: boolean;
}

export interface EmbellishmentValue {
  id?: number;
  embellishment: string,
  selectOption: string;
}
