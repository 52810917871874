import { pickBy } from 'lodash';
import { useState } from 'react';

import { TABLE_FILTER_URL_PARAM } from '../../constants/customizations';
import params from '../../utils/params';

const useFilters = (history: any) => {
  const [filter, setFilter] = useState<Record<string, string | number | undefined | null>>({});
  const { setParams } = params(history);

  const onFilterChange = (value: Record<string, string | number | undefined | null>) => {
    setParams({
      [TABLE_FILTER_URL_PARAM]: Object.keys(pickBy(value)).length ? JSON.stringify(pickBy(value)) : '',
    });
    setFilter(oldValue => pickBy({
      ...oldValue,
      ...value,
    }));
  };

  return {
    filter, setFilter, onFilterChange,
  };
};

export default useFilters;
