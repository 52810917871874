import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { NotificationType, UploadFileRequestAction } from '../../models/actionTypes';
import { UPLOAD_FILE_PATH } from '../../constants/paths';
import { UPLOAD_FILE_ERROR, UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS } from '../../constants/actionTypes';
import { UploadFile } from '../../models/File';
import { addNotification } from '../../actions/notifications/addNotification';
import { handleError } from '../../actions/errors/handleError';
import { uploadFileError, uploadFileSuccess } from '../../actions/files/uploadFile';

const uploadFile = (
  action$: ActionsObservable<UploadFileRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(UPLOAD_FILE_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { file, replaceId } }, { authentication: { authToken } }]) => {
    const form = new FormData();
    form.append('file', file);

    return ajax.post(
      UPLOAD_FILE_PATH,
      form,
      { authToken },
    ).pipe(
      mergeMap(({ response: { id, originalFilename, extension } }: Response<UploadFile>) => [
        uploadFileSuccess(id, originalFilename, extension, replaceId),
        addNotification(NotificationType.SUCCESS, UPLOAD_FILE_SUCCESS),
      ]),
      catchError(error => ([
        handleError(error, UPLOAD_FILE_ERROR),
        uploadFileError(error),
      ])),
    );
  }),
);

export default uploadFile;
