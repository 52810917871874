import { AjaxError } from 'rxjs/ajax';
import { CreateInvitation } from '../../models/InvitationData';
import {
  GenerateInviteTokenErrorAction,
  GenerateInviteTokenRequestAction, GenerateInviteTokenSuccessAction,
} from '../../models/actionTypes';
import {
  GENERATE_INVITE_TOKEN_ERROR,
  GENERATE_INVITE_TOKEN_REQUEST, GENERATE_INVITE_TOKEN_SUCCESS,
} from '../../constants/actionTypes';

export const generateInviteTokenRequest = (
  createInvitation: CreateInvitation,
): GenerateInviteTokenRequestAction => ({
  type: GENERATE_INVITE_TOKEN_REQUEST,
  payload: {
    createInvitation,
  },
});
export const generateInviteTokenError = (error: AjaxError): GenerateInviteTokenErrorAction => ({
  type: GENERATE_INVITE_TOKEN_ERROR,
  payload: {
    error,
  },
});

export const generateInviteTokenSuccess = (token: string): GenerateInviteTokenSuccessAction => ({
  type: GENERATE_INVITE_TOKEN_SUCCESS,
  payload: {
    token,
  },
});
