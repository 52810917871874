import { AjaxError } from 'rxjs/ajax';
import { ClubTeam } from '../../models/ClubTeam';
import {
  CreateTeamErrorAction,
  CreateTeamRequestAction,
  CreateTeamSuccessAction,
} from '../../models/actionTypes';
import {
  CREATE_TEAM_ERROR,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
} from '../../constants/actionTypes';

export const createTeamRequest = (
  clubId: number,
  name: string,
): CreateTeamRequestAction => ({
  type: CREATE_TEAM_REQUEST,
  payload: {
    clubId,
    name,
  },
});

export const createTeamSuccess = (team: ClubTeam): CreateTeamSuccessAction => ({
  type: CREATE_TEAM_SUCCESS,
  payload: {
    team,
  },
});

export const createTeamError = (error: AjaxError): CreateTeamErrorAction => ({
  type: CREATE_TEAM_ERROR,
  payload: {
    error,
  },
});
