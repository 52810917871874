import { Column } from '../../components/Table/Column';
import SalesRepFilter from '../../components/SalesRep/SalesRepFilter';

const columns: Column[] = [{
  header: 'CLUB_NAME',
  align: 'left',
  width: '20%',
  sortable: true,
  key: 'club.name',
  ellipsis: true,
}, {
  header: 'CREATED',
  width: '15%',
  align: 'left',
  sortable: true,
  key: 'created',
}, {
  header: 'SALES_REPS',
  align: 'left',
  width: '25%',
  sortable: false,
  key: 'salesRepIds',
  filter: SalesRepFilter,
}, {
  header: 'NUMBER_OF_ITEMS',
  width: '15%',
  align: 'center',
  sortable: false,
  key: 'number_of_items',
}, {
  header: 'DRAFT',
  width: '15%',
  align: 'left',
  key: 'draft',
}, {
  header: 'STATUS',
  width: '15%',
  align: 'left',
  key: 'status',
}, {
  width: '5%',
  header: '',
  align: 'right',
  sortable: false,
  key: 'actions',
}];

export default columns;
