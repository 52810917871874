import React from 'react';

import styles from './Avatar.module.scss';
import { AvatarProps } from './AvatarProps';

const Avatar = ({ initials }: AvatarProps) => (
  <div className={styles.avatar}>
    <span>{initials}</span>
  </div>
);

export default Avatar;
