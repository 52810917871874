import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import EmbellishmentsModal from '../EmbellishmentsModal';
import Header, { HeaderTypes } from '../../../../components/Header';
import PriceDiscountsPopup from '../PriceDiscountsPopup';
import styles from './ProductEmbellishments.module.scss';
import { ProductEmbellishmentsProps } from './ProductEmbellishmentsProps';
import { ProductEmbellishment } from '../../../../models/Product';
import HobbyProductPrice from '../HobbyProductPrice';

const ProductEmbellishments: FC<ProductEmbellishmentsProps> = ({
  embellishments,
  setEmbellishments,
  embellishmentsByHash,
  clubId,
  isHobbyCatalog,
  isUserSalesRep,
}) => {
  const [translate] = useTranslation();

  const renderButton = () => (isUserSalesRep ? (
    <EmbellishmentsModal
      isHobbyCatalog={isHobbyCatalog}
      clubId={clubId}
      embellishments={embellishments}
      setEmbellishments={setEmbellishments}
    />
  ) : null);

  return (
    <div className={styles.wrapper}>
      <Header
        title={translate('EMBELLISHMENTS')}
        type={HeaderTypes.H3}
        // @ts-ignore
        renderButton={renderButton}
      />

      {embellishments.length ? (
        <Table striped basic="very" className={styles.table}>
          <Table.Body>
            {embellishments.map((embellishment: ProductEmbellishment) => (
              <Table.Row key={embellishment.embellishment}>
                <Table.Cell>
                  {translate(embellishment.embellishment)}
                </Table.Cell>
                <Table.Cell>
                  {translate(embellishment.mandatory ? 'MANDATORY' : 'OPTIONAL')}
                </Table.Cell>
                <Table.Cell collapsing textAlign="right">
                  {isHobbyCatalog ? (
                    <HobbyProductPrice
                      embellishments={
                        [embellishmentsByHash[embellishment.embellishment]].filter(Boolean)
                      }
                      productEmbellishments={[embellishment]}
                    />
                  ) : (
                    <PriceDiscountsPopup
                      productEmbellishments={[embellishment]}
                      embellishments={
                        [embellishmentsByHash[embellishment.embellishment]].filter(Boolean)
                      }
                      clubId={clubId!}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <p>
          {translate('NO_EMBELLISHMENTS')}
        </p>
      )}
    </div>
  );
};

export default ProductEmbellishments;
