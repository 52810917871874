import { AjaxError } from 'rxjs/ajax';
import {
  FETCH_TEAM_REQUESTS, FETCH_TEAM_REQUESTS_SUCCESS, FETCH_TEAM_REQUESTS_ERROR,
} from '../../constants/actionTypes';
import {
  FetchTeamRequestsAction,
  FetchTeamRequestsSuccessAction,
  FetchTeamRequestsErrorAction,
} from '../../models/actionTypes';
import { TeamRequest } from '../../models/TeamRequests';

const fetchTeamRequestsDefaultPayload = {
  page: 1,
};

export const fetchTeamRequestsAction = (
  payload = fetchTeamRequestsDefaultPayload,
): FetchTeamRequestsAction => ({
  type: FETCH_TEAM_REQUESTS,
  payload,
});

export const fetchTeamRequestsSuccess = (
  requests: TeamRequest[], count: number,
): FetchTeamRequestsSuccessAction => ({
  type: FETCH_TEAM_REQUESTS_SUCCESS,
  payload: {
    requests,
    count,
  },
});

export const fetchTeamRequestsError = (error: AjaxError): FetchTeamRequestsErrorAction => ({
  type: FETCH_TEAM_REQUESTS_ERROR,
  payload: {
    error,
  },
});
