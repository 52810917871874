import application from './application';
import catalogs from './catalogs';
import clubs from './clubs';
import files from './files';
import invitations from './invitations';
import notifications from './notifications';
import ordersList from './ordersList';
import individualOrders from './individualOrders';
import teamRequests from './teamRequests';
import organizations from './organizations';
import hobbyClubs from './hobbyClubs';
import externalSystems from './externalSystems';
import activityRewards from './activityRewards';
import teamBasketOrders from './teamBasketOrders';
import clubOrders from './clubOrdersList';
import clubTeams from './clubTeams';
import players from './players';
import members from './members';
import staff from './staff';
import embellishments from './embellishments';

export default {
  application,
  catalogs,
  clubs,
  files,
  invitations,
  notifications,
  individualOrders,
  ordersList,
  teamRequests,
  organizations,
  hobbyClubs,
  externalSystems,
  activityRewards,
  teamBasketOrders,
  clubOrders,
  clubTeams,
  players,
  members,
  staff,
  embellishments,
};
