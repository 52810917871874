import { AjaxError } from 'rxjs/ajax';
import { FETCH_EXTERNAL_CLUBS_ERROR, FETCH_EXTERNAL_CLUBS_REQUEST, FETCH_EXTERNAL_CLUBS_SUCCESS } from '../../constants/actionTypes';
import { FetchExternalClubsErrorAction, FetchExternalClubsRequestAction, FetchExternalClubsSuccessAction } from '../../models/actionTypes/ExternalSystemsActions';
import { Tor24ClubDto } from '../../models/ExternalSystems';

export const fetchExternalClubsRequest = (
  search?: string,
): FetchExternalClubsRequestAction => ({
  type: FETCH_EXTERNAL_CLUBS_REQUEST,
  payload: {
    search,
  },
});

export const fetchExternalClubsSuccess = (
  clubs: Tor24ClubDto[],
): FetchExternalClubsSuccessAction => ({
  type: FETCH_EXTERNAL_CLUBS_SUCCESS,
  payload: {
    clubs,
  },
});

export const fetchExternalClubsError = (error: AjaxError): FetchExternalClubsErrorAction => ({
  type: FETCH_EXTERNAL_CLUBS_ERROR,
  payload: {
    error,
  },
});
