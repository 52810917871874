import {
  hasCapitalLetter,
  hasMinLength,
  hasNumber,
  hasSpecialCharacter,
  isEqualAs,
  isRequired,
} from '../../../../utils/formValidators';
import { FormSchema } from '../../../../models/Form';

export const updatePasswordSchema: FormSchema = ({
  password: {
    type: 'password',
    label: 'PASSWORD_TEXT',
  },
  confirmPassword: {
    type: 'password',
    label: 'CONFIRMPASSWORD',
  },
});

export const validationSchema = {
  password: [isRequired, hasCapitalLetter, hasNumber, hasSpecialCharacter, hasMinLength(8)],
  confirmPassword: [isRequired, isEqualAs('password')],
};
