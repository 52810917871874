import { AjaxError } from 'rxjs/ajax';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom, map,
} from 'rxjs/operators';

import { GENERATE_INVITE_TOKEN_ERROR, GENERATE_INVITE_TOKEN_REQUEST } from '../../constants/actionTypes';
import { INVITATIONS_PATH } from '../../constants/paths';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { TeamInvitationToken } from '../../models/InvitationData';
import { GenerateInviteTokenRequestAction } from '../../models/actionTypes';
import { handleError } from '../../actions/errors/handleError';
import {
  generateInviteTokenError,
  generateInviteTokenSuccess,
} from '../../actions/clubTeams/generateInviteTokenRequest';

export default (
  action$: ActionsObservable<GenerateInviteTokenRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(GENERATE_INVITE_TOKEN_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      createInvitation,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: INVITATIONS_PATH,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      createInvitation,
    }),
  }).pipe(
    map(({
      response: { token },
    }: Response<TeamInvitationToken>) => generateInviteTokenSuccess(token)),
    catchError((error: AjaxError) => [
      handleError(error, GENERATE_INVITE_TOKEN_ERROR),
      generateInviteTokenError(error),
    ]),
  )),
);
