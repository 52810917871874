import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import HobbyClubs from './HobbyClubs';
import { State } from '../../models/State';
import { fetchHobbyClubsRequest } from '../../actions/hobbyClubs/fetchHobbyClubs';

const mapStateToProps = ({
  hobbyClubs: {
    byId,
    byHash,
    loading,
    count,
  },
}: State) => ({
  hobbyClubs: byId.map(id => byHash[id]),
  loading,
  count,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchHobbyClubs: bindActionCreators(fetchHobbyClubsRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HobbyClubs);
