import calculateEmbellishmentPrice from './calculateEmbellishmentPrice';
import { Catalog, HobbyCatalog } from '../../models/Catalog';
import { Embellishment } from '../../models/Embellishment';
import { ProductEmbellishment } from '../../models/Product';
import { sumPrices } from '../PricingUtil';

const calculateEmbellishmentsPrice = (
  clubId: number | null,
  teamId: number | null,
  embellishments: Embellishment[],
  catalog?: Catalog | HobbyCatalog,
  productEmbellishments?: ProductEmbellishment[],
) => embellishments.reduce((acc, embellishment) => {
  if (embellishment) {
    const embellishmentPrice = calculateEmbellishmentPrice(
      clubId,
      teamId,
      embellishment,
      catalog,
      productEmbellishments
        ? productEmbellishments.find(pe => pe.embellishment === embellishment.type)
        : null,
    );
    return sumPrices([acc, embellishmentPrice]);
  }
  return acc;
}, 0);

export default calculateEmbellishmentsPrice;
