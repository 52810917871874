import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button, Dimmer, DropdownItemProps, DropdownProps, Form, Image, Input, Loader, Menu,
} from 'semantic-ui-react';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Club, ClubEditRequest } from '../../models/Club';
import { CLUB_DETAILS_ROUTE } from '../../constants/routes';
import HeaderComponent from '../../components/Header';
import styles from './SettingsEdit.module.scss';
import LabelInputField from '../../components/LabelInputField';
import ColorSelector from './components/ColorSelector';
import serialize from './methods/serialize';
import {
  FetchClubRequestAction,
  FetchExternalClubsRequestAction,
  UpdateClubRequestAction,
  UploadClubLogoRequestAction,
} from '../../models/actionTypes';
import FileUploadModal from '../../components/FileUploadModal';
import { ExternalReferencedEntityType, Tor24ClubDto } from '../../models/ExternalSystems';
import { FileUploadTypes } from '../../components/FileUploadModal/FileUploadModalProps';
import {
  clubSalesIdFormat,
  hasMaxLength,
  hasMinLength,
  hasOnlyNumbers,
} from '../../utils/formValidators';
import isEmptyOrOtherValidators from '../../utils/formValidators/isEmptyOrOtherValidators';

export interface SettingsEditProps extends RouteComponentProps {
  club: Club;
  match: any;
  loadingUpload: boolean;
  clubLogoUploaded: boolean;
  loading: boolean;
  externalClubs: Tor24ClubDto[];
  loadingExternalClubs: boolean;
  uploadClubLogo: (clubId: number, image: File) => UploadClubLogoRequestAction;
  updateClub: (updatedClub: ClubEditRequest, club: Club, externalClubId?: string)
  => UpdateClubRequestAction;
  fetchClub: (id: number) => FetchClubRequestAction;
  fetchExternalClubs: (search?: string) => FetchExternalClubsRequestAction,
}

const SettingsEdit = ({
  club,
  uploadClubLogo,
  loadingUpload,
  updateClub,
  fetchClub,
  match,
  loading,
  loadingExternalClubs,
  externalClubs,
  clubLogoUploaded,
  fetchExternalClubs,
}: SettingsEditProps) => {
  const requiredFields = useMemo(() => ['address.street', 'address.city', 'address.number', 'address.postCode', 'address.displayName', 'name', 'email'], []);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [translate] = useTranslation();
  const [editedClub, setEditedClub] = useState<Club>(club);
  const [location$] = useState(() => new Subject<string>());
  const [locationChanged, setLocationChanged] = useState(false);
  const [location, setLocation] = useState('');

  const [externalClubId, setExternalClubId] = useState<string | null>(null);
  const [externalClubsOptions, setExternalClubsOptions] = useState<DropdownItemProps[]>([]);

  const [addedClubId, setAddedClubId] = useState<string | null>(null);
  const [addedClubsOptions, setAddedClubsOptions] = useState<DropdownItemProps[]>([]);

  const { clubId } = match.params;

  useEffect(() => {
    setExternalClubsOptions(externalClubs.map(({ id, name }) => ({ value: id, text: name })));
  }, [externalClubs]);

  useEffect(() => {
    const searchListener = location$
      .pipe(
        distinctUntilChanged(),
        tap(() => { setLocationChanged(true); }),
        debounceTime(500),
      ).subscribe((searchLocation: string) => {
        if (searchLocation) {
          fetchExternalClubs(searchLocation);
        } else {
          setExternalClubsOptions([]);
          setExternalClubId(null);
        }
        setLocationChanged(false);
      });

    return () => {
      searchListener.unsubscribe();
    };
  }, [fetchExternalClubs, location$]);

  const onLocationChange = useCallback((
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLocation(value);
    setEditedClub(_editedClub => (
      { ..._editedClub, address: { ..._editedClub.address, displayName: value } }
    ));
    location$.next(value);
  }, [location$]);

  const handleExternalClubChange = (e: any, { value }: DropdownProps) => {
    const externalClubSelected = externalClubsOptions.find(o => o.value === value);
    setExternalClubId(externalClubSelected ? value as string : null);
    setAddedClubId(!externalClubSelected ? value as string : null);
    setEditedClub(_editedClub => (
      {
        ..._editedClub,
        name: externalClubSelected ? externalClubSelected.text as string : value as string,
      }
    ));
  };

  useEffect(() => {
    fetchClub(clubId);
  }, [clubId, fetchClub]);

  const uploadImage = useCallback(
    image => {
      if (clubId) {
        uploadClubLogo(clubId, image);
      }
    },
    [uploadClubLogo, clubId],
  );

  useEffect(() => {
    if (club) {
      setEditedClub(club);
      setLocation(club.address.displayName || '');
      location$.next(club.address.displayName || '');
      const externalClubRef = club.externalSystemReferences
        ? club.externalSystemReferences.find(
          ref => ref.entityType === ExternalReferencedEntityType.CLUB,
        )
        : null;
      if (externalClubRef) {
        setExternalClubId(externalClubRef.externalSystemEntityId);
      } else {
        if (!addedClubsOptions.find(opt => opt.value === club.name)) {
          setAddedClubsOptions(options => [{ text: club.name, value: club.name as string },
            ...options]);
        }
        setEditedClub(_editedClub => (
          {
            ..._editedClub,
            name: club.name,
          }
        ));
        setAddedClubId(club.name);
      }
    }
  }, [addedClubsOptions, club, location$]);

  useEffect(() => {
    setIsFormValid(!requiredFields.filter(item => !get(editedClub, item)).length && isEmptyOrOtherValidators([hasMaxLength(7).rule, hasMinLength(7).rule, hasOnlyNumbers.rule]).rule(get(editedClub, 'customerId')));
  }, [editedClub, requiredFields, editedClub.customerId]);

  const onSubmit = () => {
    setIsSubmitted(true);
    if (isFormValid) {
      if (addedClubId) {
        updateClub(serialize(editedClub), club);
      }
      if (externalClubId) {
        updateClub(serialize(editedClub), club, externalClubId);
      }
    }
  };

  const renderButton = () => [
    <Button
      as={Link}
      content={translate('CANCEL')}
      key="cancel"
      primary
      to={CLUB_DETAILS_ROUTE.replace(':clubId', clubId)}
    />,
    <Button
      content={translate('SAVE')}
      key="save"
      onClick={onSubmit}
      primary
    />,
  ];

  const handleClubAddition = (
    e: any, { value }: DropdownProps,
  ) => {
    setAddedClubsOptions(options => [{ text: value, value: value as string },
      ...options]);
    editedClub.name = value as string;
  };

  const setColor = (primaryColor: string) => {
    setEditedClub({
      ...editedClub,
      primaryColor,
    });
  };

  const renderInput = (
    property: string,
    validators: ((value: any) => boolean)[] = [],
    icon?: string,
    required?: boolean,
    placeholder?: string,
    extraErrorMessage?: string,
  ) => () => (
    <div className={styles.fieldWrapper}>
      <Input
        className={styles.input}
        placeholder={placeholder || ''}
        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          setEditedClub({
            ...set(editedClub, property, value),
          });
        }}
        value={get(editedClub, property) || ''}
        icon={icon || undefined}
        error={isSubmitted
            && ((required && !get(editedClub, property))
                || (!!validators.length
                    && !validators.every(validator => validator(get(editedClub, property))))
            )}
      />
      {isSubmitted && required && !get(editedClub, property) && (
        <span className={styles.validation}>
          {`*${translate('ERROR_IS_REQUIRED')}`}
        </span>
      )}
      {isSubmitted
          && !(required && !get(editedClub, property))
          && !!validators.length
          && !validators.every(validator => validator(get(editedClub, property)))
          && !!extraErrorMessage
          && (
          <span className={styles.validation}>
            {`${translate(extraErrorMessage)}`}
          </span>
          )}
    </div>
  );

  return (
    <>
      {loading && <Dimmer active inverted><Loader /></Dimmer>}
      <HeaderComponent title={translate('SETTINGS')} renderButton={renderButton} />
      <div className={styles.wrapper}>
        <Menu className="filter" pointing secondary>
          <Menu.Item
            name={translate('CLUB_INFO')}
            key="club-info"
            active
          />
        </Menu>
        <h3 className={styles.header}>{translate('CLUB_NAME')}</h3>
        <div className={styles.layout}>
          <div className={styles.left}>
            <Form.Field
              className={styles.formField}
              key="club-location-id"
              required
            >
              <label>{translate('LOCATION')}</label>
              <div className={styles.inputWithLabel}>
                <Form.Input
                  name="club-location"
                  onChange={onLocationChange}
                  className={styles.fullWidth}
                  placeholder={translate('ENTER_LOCATION')}
                  value={location}
                />
              </div>
            </Form.Field>
            <Form.Field
              className={styles.formField}
              key="club-name-id"
              required
            >
              <label>{translate('CLUB_NAME')}</label>
              <div className={styles.inputWithLabel}>
                <Form.Dropdown
                  name="club-name"
                  placeholder={`${translate('ENTER_CLUB_NAME_OR_CHOOSE_EXISTING_CLUB')} (${externalClubs.length || 0})`}
                  onChange={handleExternalClubChange}
                  options={[...externalClubsOptions, ...addedClubsOptions]}
                  disabled={!location || loadingExternalClubs || locationChanged}
                  loading={loadingExternalClubs || locationChanged}
                  selection
                  search
                  className={styles.fullWidth}
                  allowAdditions
                  additionLabel={`${translate('CLUB_CREATION.CREATE_CLUB_PLACEHOLDER')} `}
                  value={externalClubId || addedClubId || ''}
                  onAddItem={handleClubAddition}
                  noResultsMessage={translate('CLUB_CREATION.NO_CLUBS_FOUND_FOR_PROVIDED_LOCATION')}
                />
              </div>
            </Form.Field>
          </div>
          <div className={styles.right} />
        </div>
        <h3 className={styles.header}>{translate('BASIC_DATA')}</h3>
        <div className={styles.layout}>
          <div className={styles.left}>
            <LabelInputField label={translate('CLUB_SALES_ID')} input={renderInput('clubSalesId', [clubSalesIdFormat().rule, hasMaxLength(5).rule], '', true, translate('CLUB_SALES_ID_FORMAT'), clubSalesIdFormat().errorMessage)} required />
            <LabelInputField label={translate('CUSTOMER_ID')} input={renderInput('customerId', [isEmptyOrOtherValidators([hasMaxLength(7).rule, hasMinLength(7).rule, hasOnlyNumbers.rule]).rule], '', false, translate('CUSTOMER_ID_FORMAT'), 'ERROR_CUSTOMER_ID_FORMAT')} />
            <LabelInputField label={translate('STREET')} input={renderInput('address.street', [], '', true)} required />
            <LabelInputField label={translate('NUMBER')} input={renderInput('address.number', [], '', true)} required />
            <LabelInputField label={translate('CITY')} input={renderInput('address.city', [], '', true)} required />
            <LabelInputField label={translate('ZIP_CODE')} input={renderInput('address.postCode', [], '', true)} required />
            <LabelInputField label={translate('PHONE_NUMBER')} input={renderInput('phoneNumber', [])} />
            <LabelInputField label={translate('EMAIL')} input={renderInput('email', [], '', true)} required />
          </div>
          <div className={styles.right}>
            <div className={styles.logoSection}>
              <span className={styles.label}>{translate('LOGO')}</span>
              <div className={styles.valueSection}>
                {club.logoUrl && (
                  <Image src={club.logoUrl} size="small" alt="" className={styles.image} />
                )}
                <FileUploadModal
                  uploadFile={uploadImage}
                  loading={loadingUpload}
                  fileUploaded={clubLogoUploaded}
                  type={FileUploadTypes.IMAGE}
                />
              </div>
            </div>
            <LabelInputField label={translate('FAX_NUMBER')} input={renderInput('faxNumber')} />
            <LabelInputField label={translate('WEBSITE')} input={renderInput('websiteAddress')} />
          </div>
        </div>
        <div className={styles.field}>
          <span className={styles.label}>{translate('PRIMARY_COLOR')}</span>
          <ColorSelector value={editedClub.primaryColor} onChange={setColor} />
        </div>
        <div className={styles.field}>
          <span className={styles.label}>{translate('SOCIAL_MEDIA')}</span>
          <div className={styles.media}>
            {renderInput('socialMedia.facebookUrl', [], 'facebook f')()}
            {renderInput('socialMedia.instagramUrl', [], 'instagram')()}
            {renderInput('socialMedia.snapchatUrl', [], 'twitter')()}
          </div>
        </div>
        <h3 className={styles.header}>{translate('CURRENT')}</h3>
        <div className={styles.layout}>
          <div className={styles.left}>
            <LabelInputField label={translate('WEBSITE_EVENTS_ADDRESS')} input={renderInput('websiteEventsAddress')} />
            <LabelInputField label={translate('WEBSITE_NEWS_ADDRESS')} input={renderInput('websiteNewsAddress')} />
          </div>
          <div className={styles.right} />
        </div>
      </div>
    </>
  );
};

export default SettingsEdit;
