import React, { ChangeEvent, FC, SyntheticEvent } from 'react';
import classNames from 'classnames';
import findIndex from 'lodash/findIndex';
import set from 'lodash/set';
import { Button, Form, Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import InputWithSymbol from '../../../../components/InputWithSymbol';
import styles from '../../CatalogDiscounts.module.scss';
import { TeamDiscountRowsProps } from './TeamDiscountRowsProps';
import {
  defaultAddButtonProps,
  defaultInputSymbolProps,
  emptyTeamDiscount, maxLimitPercentageValidator,
} from '../../customizations';

const TeamDiscountRows: FC<TeamDiscountRowsProps> = ({
  teamDiscounts, setTeamDisctounts, teamsOptions, leftLabel = 'TEAM', rightLabel = 'DISCOUNT',
}) => {
  const [translate] = useTranslation();
  const usedTeamIds = teamDiscounts.map(({ teamId }) => teamId);

  const getFilteredOptions = (teamId?: number) => teamsOptions.filter(
    ({ value }) => !usedTeamIds.includes(Number(value)) || value === teamId,
  );

  const onAddRow = () => {
    setTeamDisctounts([...teamDiscounts, emptyTeamDiscount()]);
  };

  const onRemoveRow = (key: string) => () => {
    const filteredTeamDiscounts = teamDiscounts.filter(row => row.key !== key);
    setTeamDisctounts(filteredTeamDiscounts);
  };

  const onChange = (key: string, name: string) => (
    event: ChangeEvent<HTMLElement> | SyntheticEvent<HTMLElement> | null,
    { value }: any,
  ) => {
    const index = findIndex(teamDiscounts, discount => discount.key === key);
    const newTeamDiscounts = [...teamDiscounts];
    set(newTeamDiscounts[index], name, value);
    setTeamDisctounts(newTeamDiscounts);
  };

  return (
    <div>
      {teamDiscounts.map(({ key, teamId, discountPercent }) => (
        <Form.Group widths="equal" key={key}>
          <Form.Field>
            <label>{translate(leftLabel)}</label>
            <Dropdown
              onChange={onChange(key, 'teamId')}
              options={getFilteredOptions(teamId)}
              placeholder={translate(teamsOptions.length ? 'SELECT_TEAM' : 'NO_TEAMS')}
              selection
              value={teamId}
            />
          </Form.Field>

          <Form.Field>
            <label>{translate(rightLabel)}</label>
            <InputWithSymbol
              {...defaultInputSymbolProps}
              validators={[maxLimitPercentageValidator(50, 'FIFTYPERCENT_AND_MORE_DISCOUNT_WARNING')]}
              onChange={onChange(key, 'discountPercent')}
              value={discountPercent}
            />
          </Form.Field>

          <Button
            {...defaultAddButtonProps}
            basic
            className={classNames(styles.buttonRemove, teamDiscounts.length === 1 && styles.hidden, 'simple')}
            icon="remove"
            onClick={onRemoveRow(key)}
          />
        </Form.Group>
      ))}

      <Button
        {...defaultAddButtonProps}
        className={styles.buttonAdd}
        label={translate(teamDiscounts.length ? 'ADD_ADDITIONAL_TEAM' : 'ADD_TEAM_DISCOUNT')}
        onClick={onAddRow}
      />
    </div>
  );
};

export default TeamDiscountRows;
