import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import IndividualOrdersRow from './IndividualOrdersRow';
import { fetchFileRequest } from '../../../actions/files/fetchFile';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFile: bindActionCreators(fetchFileRequest, dispatch),
});

export default connect(null, mapDispatchToProps)(IndividualOrdersRow);
