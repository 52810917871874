import { AjaxError } from 'rxjs/ajax';

import {
  SEND_INVITATION,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_ERROR,
  RESEND_INVITATION_REQUEST,
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_ERROR,
} from '../constants/actionTypes';
import { CreateInvitation, InvitationPersonalData } from '../models/InvitationData';
import {
  SendInvitationAction,
  SendInvitationSuccessAction,
  SendInvitationErrorAction,
  ResendInvitationSuccessAction,
  ResendInvitationRequestAction,
  ResendInvitationErrorAction,
} from '../models/actionTypes';

export const sendInvitationRequest = (
  createInvitation: CreateInvitation,
  invitationPersonalData: InvitationPersonalData,
): SendInvitationAction => ({
  type: SEND_INVITATION,
  payload: {
    createInvitation,
    invitationPersonalData,
  },
});

export const sendInvitationSuccess = (): SendInvitationSuccessAction => ({
  type: SEND_INVITATION_SUCCESS,
});

export const sendInvitationError = (error: AjaxError): SendInvitationErrorAction => ({
  type: SEND_INVITATION_ERROR,
  payload: {
    error,
  },
});

export const resendInvitationRequest = (token: string): ResendInvitationRequestAction => ({
  type: RESEND_INVITATION_REQUEST,
  payload: {
    token,
  },
});

export const resendInvitationSuccess = (): ResendInvitationSuccessAction => ({
  type: RESEND_INVITATION_SUCCESS,
});

export const resendInvitationError = (error: AjaxError): ResendInvitationErrorAction => ({
  type: RESEND_INVITATION_ERROR,
  payload: {
    error,
  },
});
