import { Column } from '../../components/Table/Column';
import SalesRepFilter from '../../components/SalesRep/SalesRepFilter';

const columns: Column[] = [{
  width: '10%',
  header: 'ORDER_ID',
  align: 'left',
  key: 'orderId',
  sortable: true,
}, {
  width: '13%',
  header: 'ORDER_DATE',
  align: 'left',
  key: 'createdAt',
  sortable: true,
}, {
  width: '20%',
  header: 'PLAYER',
  align: 'left',
  key: 'createdBy',
  sortable: true,
}, {
  width: '20%',
  header: 'CLUB',
  align: 'left',
  key: 'clubName',
  sortable: true,
}, {
  header: 'SALES_REPS',
  align: 'left',
  width: '15%',
  sortable: false,
  key: 'salesRepIds',
  filter: SalesRepFilter,
}, {
  width: '12%',
  header: 'TOTAL',
  align: 'right',
  key: 'itemsSummedPrice',
  sortable: true,
}, {
  width: '10%',
  header: '',
  align: 'right',
  sortable: false,
  key: 'actions',
}];

export default columns;
