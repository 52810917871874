export const HOME_ROUTE = '/';
export const SIGN_IN_ROUTE = '/sign-in';
export const RESET_PASSWORD = '/password-reset';

export const CLUBS_ROUTE = '/clubs';
export const TEAM_REQUESTS_ROUTE = '/team-requests';
export const CLUB_DETAILS_ROUTE = `${CLUBS_ROUTE}/:clubId/details`;
export const CLUB_INDIVIDUAL_ORDERS_ROUTE = `${CLUBS_ROUTE}/:clubId/individual-orders`;
export const CLUB_TEAM_BASKET_ORDERS_ROUTE = `${CLUBS_ROUTE}/:clubId/team-basket-orders`;
export const CLUB_EDIT_ROUTE = `${CLUB_DETAILS_ROUTE}/edit`;
export const CLUBS_CATALOGS_ROUTE = `${CLUBS_ROUTE}/catalogs`;
export const CATALOG_CREATOR_ROUTE = `${CLUBS_ROUTE}/:clubId/catalog`;
export const EMBELLISHMENT_VALUE_SELECTION_ROUTE = `${CLUBS_ROUTE}/:clubId/selectEmbellishments`;
export const ATTACHMENTS_ROUTE = `${CLUBS_ROUTE}/:clubId/catalog/attachments`;
export const DISCOUNTS_ROUTE = `${CLUBS_ROUTE}/:clubId/catalog/discounts`;
export const CATALOG_PRODUCT_ROUTE = `${CATALOG_CREATOR_ROUTE}/product/:id`;
export const TEAM_BASKET_ORDERS_ROUTE = '/team-orders';
export const NEW_CLUB_ROUTE = `${CLUBS_ROUTE}/new`;
export const CLUB_INVITATION_ROUTE = `${CLUBS_ROUTE}/:clubId/invitation`;
export const REWARDS_ROUTE = `${CLUBS_ROUTE}/:clubId/rewards`;
export const REWARDS_TEAMS_ROUTE = `${REWARDS_ROUTE}/teams`;
export const REWARDS_TEAM_ROUTE = `${REWARDS_TEAMS_ROUTE}/:teamId`;
export const ASSIGN_SALES_REP_ROUTE = `${CLUBS_ROUTE}/:clubId/assign`;

export const PLAYERS_ROUTE = '/players';
export const HOBBY_CLUBS_ROUTE = '/hobby-clubs';
export const INDIVIDUAL_ORDERS_ROUTE = '/individual-orders';
export const HOBBY_CATALOG_ROUTE = `${HOBBY_CLUBS_ROUTE}/catalog`;
export const HOBBY_CATALOG_PRODUCT_ROUTE = `${HOBBY_CATALOG_ROUTE}/product/:id`;
export const HOBBY_CATALOG_DISCOUNTS_ROUTE = `${HOBBY_CATALOG_ROUTE}/discounts`;
export const HOBBY_CATALOG_ATTACHMENTS_ROUTE = `${HOBBY_CATALOG_ROUTE}/attachments`;

export const PERSONAL_ORDERS_ROUTE = '/personal/orders';

export const PARAM_CATALOGS = 'catalogs';

export const CLUB_TEAMS_ROUTE = `${CLUBS_ROUTE}/:clubId/teams/list`;
export const CREATE_TEAM_ROUTE = `${CLUBS_ROUTE}/:clubId/teams/create`;
export const CLUB_TEAMS_CREATE_MEMBER_ROUTE = `${CLUBS_ROUTE}/:clubId/teams/:teamId/add-team-lead`;
export const CLUB_TEAMS_UPDATE_CLUB_ADMIN_TO_TEAM_LEAD_ROUTE = `${CLUBS_ROUTE}/:clubId/teams/add-team-lead-to-club-admin`;
export const CLUB_TEAMS_PENDING_INVITATIONS_ROUTE = `${CLUBS_ROUTE}/:clubId/teams/:teamId/pending-invitations`;
