import { PaginationProps } from 'semantic-ui-react';
import params from '../../utils/params';
import { DEFAULT_LIST_LIMIT, TABLE_PAGE_URL_PARAM } from '../../constants/customizations';

export default (history: any, count: number, loadingList: boolean, limit = DEFAULT_LIST_LIMIT) => {
  const { setParam } = params(history);

  const onPageChange = (event: any, { activePage }: PaginationProps) => {
    setParam(TABLE_PAGE_URL_PARAM, activePage);
  };

  const totalPages = Math.ceil(count / limit);
  const showPagination = limit < count && !loadingList;

  return [
    onPageChange as (event: any, data: PaginationProps) => void,
    totalPages as number,
    showPagination as boolean,
  ];
};
