import { AjaxError } from 'rxjs/ajax';
import { REJECT_PROMOTION_ERROR, REJECT_PROMOTION_REQUEST, REJECT_PROMOTION_SUCCESS } from '../../constants/actionTypes';
import { RejectPromotionRequestAction, RejectPromotionRequestErrorAction, RejectPromotionRequestSuccessAction } from '../../models/actionTypes';

export const rejectPromotionRequest = (
  teamId: number, promotionRequestId: number,
): RejectPromotionRequestAction => ({
  type: REJECT_PROMOTION_REQUEST,
  payload: {
    teamId,
    promotionRequestId,
  },
});

export const rejectPromotionSuccess = (
  promotionRequestId: number,
): RejectPromotionRequestSuccessAction => ({
  type: REJECT_PROMOTION_SUCCESS,
  payload: {
    promotionRequestId,
  },
});

export const rejectPromotionError = (error: AjaxError): RejectPromotionRequestErrorAction => ({
  type: REJECT_PROMOTION_ERROR,
  payload: {
    error,
  },
});
