import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import ResetPassword from './ResetPassword';
import { resetPasswordCleanUp, resetPasswordTokenRequest, resetPasswordRequest } from '../../packages/authentication';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetPasswordCleanUp,
  resetPasswordTokenRequest,
  resetPasswordRequest,
}, dispatch);

export default connect(null, mapDispatchToProps)(ResetPassword);
