import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Button, Menu,
} from 'semantic-ui-react';
import Header from '../../../../components/Header';
import { Roles } from '../../../../constants/roles';
import {
  CATALOG_CREATOR_ROUTE,
  CLUBS_ROUTE,
  DISCOUNTS_ROUTE, EMBELLISHMENT_VALUE_SELECTION_ROUTE,
} from '../../../../constants/routes';
import styles from './EmbellishmentValueSelectionHeader.module.scss';
import { EmbellishmentValueSelectionHeaderProps } from './EmbellishmentValueSelectionHeaderProps';

const EmbellishmentValueSelectionHeader: FC<EmbellishmentValueSelectionHeaderProps> = ({
  clubId,
  club,
  history: { location: { search } },
  memberships,
  currentlyUsedOrganizationId,
  showEmbellishmentValuesModal,
}) => {
  const [translate] = useTranslation();
  const linkWithParams = (value: string) => value.replace(':clubId', String(clubId));
  const productsLink = linkWithParams(CATALOG_CREATOR_ROUTE);
  const discountsLink = linkWithParams(DISCOUNTS_ROUTE);
  const embellishmentsTypesRoute = linkWithParams(EMBELLISHMENT_VALUE_SELECTION_ROUTE);
  const backLinkTo = CLUBS_ROUTE;
  const [userRoles, setUserRoles] = useState<Roles[]>([]);
  const isUserSalesRepresentative = useMemo(
    () => userRoles.includes(Roles.SALES_REPRESENTATIVE), [userRoles],
  );

  useEffect(() => {
    if (currentlyUsedOrganizationId) {
      setUserRoles(memberships[currentlyUsedOrganizationId] || []);
    }
  }, [memberships, currentlyUsedOrganizationId]);

  const renderButtons = () => (
    <>
      <Button content={translate('ADD_EMBELLISHMENT_VALUE')} basic onClick={showEmbellishmentValuesModal} />
    </>
  );

  return (
    <div>
      <Header
        backLinkTo={backLinkTo}
        backLabel="BACK"
        // @ts-ignore
        renderButton={renderButtons}
        title={`${translate('EMBELLISHMENT_VALUES_FOR')} ${club ? club.name : ''}`}
      />
      <div className={styles.container}>
        <Menu className="filter" pointing secondary>
          <Menu.Item
            as={Link}
            content={translate('PRODUCTS')}
            to={`${productsLink}${search}`}
          />
          { isUserSalesRepresentative && (
            <Menu.Item
              as={Link}
              content={translate('DISCOUNTS')}
              to={`${discountsLink}${search}`}
            />
          )}
          <Menu.Item
            active
            as={Link}
            content={translate('PREDEFINED_EMBELLISHMENT_VALUES')}
            to={`${embellishmentsTypesRoute}`}
          />
        </Menu>
      </div>
    </div>
  );
};

export default EmbellishmentValueSelectionHeader;
