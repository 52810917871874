import { ReactChild, ReactNode } from 'react';

export enum HeaderTypes {
  H2 = 'h2',
  H3 = 'h3',
}

export interface HeaderProps {
  backLinkTo?: string | null,
  backLabel?: string,
  border?: boolean,
  renderButton?: () => ReactChild | ReactChild[],
  title: string | ReactNode,
  type?: HeaderTypes,
}
