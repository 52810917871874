export const orderTypeFilterParser = (filter: string | undefined) => {
  let filters;
  if (filter) {
    filters = JSON.parse(filter!);
  }
  if (filters && filters.orderType && (filters.orderType === 'FRANCHISE' || filters.orderType === 'FORCE')) {
    filters.clubSalesId = 'V0000';
    filters.excludeByClubSalesId = filters.orderType === 'FORCE';
  }
  if (filters && filters.orderType) {
    delete filters.orderType;
  }
  return filters;
};
