import React, { FC, FormEvent } from 'react';
import { Radio, CheckboxProps } from 'semantic-ui-react';

import { Club } from '../../../../models/Club';
import Row from '../../../../components/Table/components/Row';
import styles from './TeamRequestApprovalModalRow.module.scss';
import Text from '../../../../components/Text';

export interface TeamRequestApprovalModalRowProps {
  id: number;
  club: Club;
  selectedClubId: number | null;
  setSelectedClubId: (id: number) => void;
}

const TeamRequestApprovalModalRow: FC<TeamRequestApprovalModalRowProps> = ({
  id,
  club,
  selectedClubId,
  setSelectedClubId,
}) => {
  const handleRadioClick = (e: FormEvent<HTMLInputElement>, { checked }: CheckboxProps) => {
    if (checked) {
      setSelectedClubId(id);
    }
  };

  return (
    <Row key={id} classNames={styles.wrapper}>
      <div className={styles.radioWrapper}>
        <Radio
          name="radio"
          checked={id === selectedClubId}
          onChange={handleRadioClick}
        />
        <Text>{club.name || ''}</Text>
      </div>
      <Text>{(club.address && club.address.city) || ''}</Text>
      <Text>{club.teamsCount || ''}</Text>
      <Text>{club.playerCount || ''}</Text>
    </Row>
  );
};

export default TeamRequestApprovalModalRow;
