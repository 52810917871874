import { withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import CatalogHeader from './CatalogHeader';
import { State } from '../../../../models/State';
import { createCatalogRequest } from '../../../../actions/catalogs/createCatalog';
import { fetchCatalogRequest } from '../../../../actions/catalogs/fetchCatalog';
import { fetchHobbyCatalogRequest } from '../../../../actions/catalogs/fetchHobbyCatalog';
import { createHobbyCatalogRequest } from '../../../../actions/catalogs/createHobbyCatalog';
import { fetchClubsRequest } from '../../../../actions/clubs/fetchClubs';
import { copyCatalogRequest } from '../../../../actions/catalogs/copyCatalog';

const mapStateToProps = (
  {
    application: {
      memberships,
      clubId: currentlyUsedOrganizationId,
    },
    clubs: {
      loadingList: loading,
      byHash: clubsByHash,
      byId: clubsById,
    },
  }: State,
) => ({
  memberships,
  currentlyUsedOrganizationId,
  clubsByHash,
  loading,
  clubsById,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createCatalog: bindActionCreators(createCatalogRequest, dispatch),
  createHobbyCatalog: bindActionCreators(createHobbyCatalogRequest, dispatch),
  fetchCatalog: bindActionCreators(fetchCatalogRequest, dispatch),
  fetchHobbyCatalog: bindActionCreators(fetchHobbyCatalogRequest, dispatch),
  copyCatalog: bindActionCreators(copyCatalogRequest, dispatch),
  fetchClubs: bindActionCreators(fetchClubsRequest, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogHeader));
