import { AjaxError } from 'rxjs/ajax';

import {
  ASSIGN_MEMBER_ERROR,
  ASSIGN_MEMBER_REQUEST,
  FETCH_MEMBERS_ERROR,
  FETCH_MEMBERS_REQUEST,
  FETCH_MEMBERS_SUCCESS,
} from '../constants/actionTypes';
import { ListFilters } from '../models/ListFilters';
import {
  AssignMemberErrorAction,
  AssignMemberRequestAction,
  FetchMembersErrorAction,
  FetchMembersRequestAction,
  FetchMembersSuccessAction,
} from '../models/actionTypes/Members';
import { Member } from '../models/Member';

export const fetchMembersRequest = (
  clubId: number,
  filters: ListFilters,
  search?: string,
): FetchMembersRequestAction => ({
  type: FETCH_MEMBERS_REQUEST,
  payload: {
    filters,
    search,
    clubId,
  },
});

export const fetchMembersSuccess = (
  members: Member[], count: number,
): FetchMembersSuccessAction => ({
  type: FETCH_MEMBERS_SUCCESS,
  payload: {
    members,
    count,
  },
});

export const fetchMembersError = (error: AjaxError): FetchMembersErrorAction => ({
  type: FETCH_MEMBERS_ERROR,
  payload: {
    error,
  },
});

export const assignMemberRequest = (
  teamId: number,
  role: string,
  personId: number,
): AssignMemberRequestAction => ({
  type: ASSIGN_MEMBER_REQUEST,
  payload: {
    teamId,
    role,
    personId,
  },
});

export const assignMemberError = (error: AjaxError): AssignMemberErrorAction => ({
  type: ASSIGN_MEMBER_ERROR,
  payload: {
    error,
  },
});
