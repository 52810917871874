import React, { FC, useState, useEffect } from 'react';
import {
  Modal, Button, Form, Header, Message,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useForm from '../../../../hooks/useForm';
import styles from './EditTeamRequestModal.module.scss';
import FormFields from '../../../../components/FormFields';
import { promotionSchema, promotionManagerSchema } from '../TeamRequestRow/schema';
import { HeaderTypes } from '../../../../components/Header';
import { TeamRequest, EditTeamRequest } from '../../../../models/TeamRequests';
import { EditTeamRequestAction } from '../../../../models/actionTypes';
import { Roles } from '../../../../constants/roles';

interface EditTeamRequestModalProps {
  request: TeamRequest;
  error: boolean;
  editSuccessful: boolean;
  loading: boolean;
  setEditModalOpened: (open: boolean) => void;
  editPromotionRequest: (id: number, promotionRequest: EditTeamRequest) => EditTeamRequestAction;
}

const EditTeamRequestModal: FC<EditTeamRequestModalProps> = ({
  request,
  editPromotionRequest,
  error,
  loading,
  editSuccessful,
  setEditModalOpened,
}) => {
  const [translate] = useTranslation();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [teamName] = useState((request && request.team && request.team.name) || '');
  const {
    values,
    errors,
    handleChange,
  } = useForm({}, JSON.parse(JSON.stringify(request)));

  const handleApproveClick = () => {
    setFormSubmitted(true);
    const {
      actualRoleInClub, clubAdminContactDetails, comment,
    } = values;
    editPromotionRequest(request.id, {
      actualRoleInClub,
      ...(actualRoleInClub !== Roles.CLUB_ADMIN && ({ clubAdminContactDetails })),
      comment,
    });
  };

  useEffect(() => {
    if (editSuccessful && formSubmitted) {
      setEditModalOpened(false);
    }
  }, [editSuccessful, formSubmitted, setEditModalOpened]);

  return (
    <Modal
      onClose={() => setEditModalOpened(false)}
      closeIcon
      size="small"
      open
    >
      <div className={styles.modalWrapper}>
        <div className={styles.headerWrapper}>
          <span className={styles.teamName}>{teamName}</span>
          <Header className={styles.title} as={HeaderTypes.H2} content={translate('EDIT_INFORMATION')} />
        </div>
        <div className={styles.editInformationContentWrapper}>
          <Form loading={loading} className={styles.form} id="edit-form">
            {error && (
              <Message error={error} header={translate('ERROR')} content={translate('GENERAL_ERROR')} />
            )}

            <FormFields
              schema={promotionSchema}
              errors={errors}
              inline
              values={values}
              handleChange={handleChange}
            />
            <p>{translate('CONTACT_DETAILS_TO_CLUB_MANAGER')}</p>
            <FormFields
              schema={promotionManagerSchema}
              errors={errors}
              values={values}
              disabled={values.actualRoleInClub === Roles.CLUB_ADMIN}
              inline
              handleChange={handleChange}
            />
            <Form.Field
              className={classNames(styles.formField, styles.formFieldInline)}
              key="comment"
            >
              <label>{translate('COMMENT')}</label>
              <div className={styles.inputWithLabel}>
                <Form.Input
                  name="comment"
                  onChange={handleChange}
                  value={values.comment || ''}
                />
              </div>
            </Form.Field>
          </Form>
        </div>
        <div className={styles.footerWrapper}>
          <div className={styles.actionsButtons}>
            <Button
              basic
              circular
              content={translate('CANCEL')}
              onClick={() => setEditModalOpened(false)}
              primary
              fluid
            />
            <Button
              circular
              content={translate('SAVE')}
              onClick={handleApproveClick}
              primary
              fluid
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditTeamRequestModal;
