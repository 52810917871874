import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { ClubImageResponse } from '../../models/Club';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import {
  UPLOAD_CLUB_LOGO_ERROR,
  UPLOAD_CLUB_LOGO_REQUEST,
  UPLOAD_CLUB_LOGO_SUCCESS,
} from '../../constants/actionTypes';
import { UPLOAD_LOGO_PATH } from '../../constants/paths';
import { NotificationType, UploadClubLogoRequestAction } from '../../models/actionTypes';
import { uploadClubLogoError, uploadClubLogoSuccess } from '../../actions/clubs/uploadClubLogo';
import { handleError } from '../../actions/errors/handleError';
import { addNotification } from '../../actions/notifications/addNotification';

export default (
  action$: ActionsObservable<UploadClubLogoRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(UPLOAD_CLUB_LOGO_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { clubId, image } },
    { authentication: { authToken } },
  ]) => {
    const form = new FormData();
    form.append('image', image);

    return ajax.post(
      UPLOAD_LOGO_PATH.replace('$clubId', String(clubId)),
      form,
      { authToken },
    ).pipe(
      mergeMap(({ response: { publicUrl } }: Response<ClubImageResponse>) => [
        addNotification(NotificationType.SUCCESS, UPLOAD_CLUB_LOGO_SUCCESS),
        uploadClubLogoSuccess(clubId as number, publicUrl),
      ]),
      catchError(error => [
        handleError(error, UPLOAD_CLUB_LOGO_ERROR),
        uploadClubLogoError(error),
      ]),
    );
  }),
);
