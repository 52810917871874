import { AjaxError } from 'rxjs/ajax';
import {
  APPROVE_PROMOTION_REQUEST,
  APPROVE_PROMOTION_SUCCESS,
  APPROVE_PROMOTION_ERROR,
} from '../../constants/actionTypes';
import {
  ApprovePromotionRequestAction,
  ApprovePromotionRequestSuccessAction,
  ApprovePromotionRequestErrorAction,
} from '../../models/actionTypes';
import { AcceptPromotionRequest } from '../../models/TeamRequests';

export const approvePromotionRequest = (
  teamId: number, promotionRequestId: number, approveRequest: AcceptPromotionRequest,
): ApprovePromotionRequestAction => ({
  type: APPROVE_PROMOTION_REQUEST,
  payload: {
    teamId,
    approveRequest,
    promotionRequestId,
  },
});

export const approvePromotionSuccess = (
  promotionRequestId: number,
  newClubCreated: boolean,
  teamApproved: boolean,
): ApprovePromotionRequestSuccessAction => ({
  type: APPROVE_PROMOTION_SUCCESS,
  payload: {
    promotionRequestId,
    newClubCreated,
    teamApproved,
  },
});

export const approvePromotionError = (error: AjaxError): ApprovePromotionRequestErrorAction => ({
  type: APPROVE_PROMOTION_ERROR,
  payload: {
    error,
  },
});
