import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { push } from 'connected-react-router';
import { handleError } from '../../actions/errors/handleError';
import {
  FETCH_SALES_REP_ORGS_ERROR,
  REASSIGN_SALES_REP_REQUEST, REASSIGN_SALES_REP_SUCCESS,
} from '../../constants/actionTypes';
import { REASSIGN_SALES_REP_PATH } from '../../constants/paths';
import { State } from '../../models/State';
import { ReassignSalesRepToClubRequestAction } from '../../models/actionTypes/reassignActions';
import {
  reassignSalesRepToClubError,
  reassignSalesRepToClubSuccess,
} from '../../actions/organizations/ReassignSalesRepToClub';
import { addNotification } from '../../actions/notifications/addNotification';
import { NotificationType } from '../../models/actionTypes';
import { CLUBS_ROUTE } from '../../constants/routes';

const reassignSalesRepToClub = (
  action$: ActionsObservable<ReassignSalesRepToClubRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(REASSIGN_SALES_REP_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { salesRepOrganizationId, clubId } },
    {
      authentication: { authToken },
    },
  ]) => ajax({
    url: REASSIGN_SALES_REP_PATH.replace('$clubId', clubId.toString()),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({ salesRepOrganizationId }),
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, REASSIGN_SALES_REP_SUCCESS),
      reassignSalesRepToClubSuccess(),
      push(CLUBS_ROUTE)]),
    catchError(error => ([
      handleError(error, FETCH_SALES_REP_ORGS_ERROR),
      reassignSalesRepToClubError(error),
    ])),
  )),
);

export default reassignSalesRepToClub;
