import { FormSchema } from '../../../../models/Form';
import { isRequired, isValidEmail } from '../../../../utils/formValidators';

export const clubAdminSchema: FormSchema = ({
  email: {
    type: 'text',
    label: 'EMAIL',
    placeholder: 'ENTER_EMAIL',
  },
  firstName: {
    type: 'text',
    label: 'FIRST_NAME',
    placeholder: 'ENTER_FIRST_NAME',
  },
  lastName: {
    type: 'text',
    label: 'LAST_NAME',
    placeholder: 'ENTER_LAST_NAME',
  },
});

export const validationSchema = {
  email: [isRequired, isValidEmail],
  firstName: [isRequired],
  lastName: [isRequired],
};
