import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import styles from './TeamRewardsBallers.module.scss';
import { EntityTypesEnum } from '../../../models/Entity';
import { TeamInClubRewards } from '../../../models/Team';
import RewardsPointsEdition from '../../Rewards/TeamRewardsBallersCreation';

export interface TeamRewardsBallersProps {
  team: TeamInClubRewards;
  clubId: number;
}

const TeamRewardsBallers: FC<TeamRewardsBallersProps> = ({
  team, clubId,
}) => {
  const [translate] = useTranslation();
  const [createMode, setCreateMode] = useState<boolean>(false);

  const toggleCreateMode = () => {
    setCreateMode(isCreateMode => !isCreateMode);
  };

  return (
    <div className={styles.clubOverviewWrapper}>
      <h3 className={styles.clubOverviewHeader}>
        {translate('SELECTED_TEAM_OVERVIEW', { teamName: team.name })}
      </h3>
      <span>
        {`${(team && team.teamScore && team.teamScore.points) || 0}`}
      </span>
      <strong>{translate('REWARDS.BALLERS.BALLERS')}</strong>
      <span className={styles.buttonContainer}>
        <Button circular icon className="simple" basic onClick={toggleCreateMode}>
          <Icon name="plus" circular />
        </Button>
      </span>
      {team && team.teamScore && team.teamScore.level && [
        <span key="levelScore">{translate(`REWARDS.LEVELS.${team.teamScore.level}`)}</span>,
        <strong key="levelTranslation">{translate('LEVEL')}</strong>,
      ]}
      <div hidden={!createMode}>
        <RewardsPointsEdition clubId={clubId} entityId={team.id} entityType={EntityTypesEnum.TEAM} action="ADD" />
      </div>
    </div>
  );
};

export default TeamRewardsBallers;
