import { FilesActions } from '../models/actionTypes';
import { FilesReducer } from '../models/File';
import {
  FETCH_FILE_SUCCESS,
  REMOVE_DRAFT_FILE,
  RESET_FILE_UPLOADED,
  SET_DRAFT_FILES,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
} from '../constants/actionTypes';

export const initialState: FilesReducer = {
  byHash: {},
  byId: [],
  draftFiles: [],
  loadingUpload: false,
  fileUploaded: false,
};

export default (state = initialState, action: FilesActions) => {
  switch (action.type) {
    case FETCH_FILE_SUCCESS: {
      const { id, file } = action.payload;

      return {
        ...state,
        byHash: {
          ...state.byHash,
          [id]: file,
        },
        byId: [
          id,
          ...state.byId.filter(fileId => fileId !== id),
        ],
      };
    }
    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        loadingUpload: true,
        fileUploaded: false,
      };
    case RESET_FILE_UPLOADED:
      return {
        ...state,
        fileUploaded: false,
      };
    case UPLOAD_FILE_SUCCESS: {
      const {
        id: fileId,
        originalFilename,
        extension,
        replaceId,
      } = action.payload;

      return {
        ...state,
        loadingUpload: false,
        fileUploaded: true,
        draftFiles: [
          { id: fileId, originalFilename, extension },
          ...state.draftFiles.filter(({ id }) => id !== replaceId && id !== fileId),
        ],
      };
    }
    case UPLOAD_FILE_ERROR:
      return {
        ...state,
        loadingUpload: false,
        fileUploaded: false,
      };
    case SET_DRAFT_FILES: {
      return {
        ...state,
        draftFiles: [
          ...action.payload.files,
        ],
      };
    }
    case REMOVE_DRAFT_FILE: {
      return {
        ...state,
        draftFiles: state.draftFiles.filter(({ id }) => id !== action.payload.id),
      };
    }
    default:
      return state;
  }
};
