import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import RewardsActivitiesList from './RewardsActivitiesList';
import { fetchEntityRewardsRequest } from '../../../actions/rewards/fetchRewards';
import { State } from '../../../models/State';

const mapStateToProps = ({
  activityRewards: {
    byHash: activityRewardsByHash,
    byId: activityRewardsById,
    loading,
    lastDateTime,
    count,
    tags,
  },
}: State) => ({
  activityRewardsByHash,
  activityRewardsById,
  loading,
  lastDateTime,
  count,
  tags,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRewards: bindActionCreators(fetchEntityRewardsRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RewardsActivitiesList);
