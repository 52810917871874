import { connect } from 'react-redux';

import ProductDetailsPrice from './ProductDetailsPrice';
import { State } from '../../../../models/State';

const mapStateToProps = ({
  embellishments: { embellishmentsByHash },
}: State) => ({
  embellishmentsByHash,
});

export default connect(mapStateToProps)(ProductDetailsPrice);
