import React, { ChangeEvent, FC, SyntheticEvent } from 'react';
import classNames from 'classnames';
import findIndex from 'lodash/findIndex';
import set from 'lodash/set';
import { Button, Form, Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import InputWithSymbol from '../../../../components/InputWithSymbol';
import styles from '../../CatalogDiscounts.module.scss';
import { GroupFixedPriceRowsProps } from './GroupFixedPriceRowsProps';
import { defaultAddButtonProps, defaultInputSymbolProps, emptyGroupFixedPrice } from '../../customizations';

const GroupFixedPriceRows: FC<GroupFixedPriceRowsProps> = ({
  groupFixedPrice, setGroupFixedPrice, teamsOptions, embellishemntOptions,
}) => {
  const [translate] = useTranslation();

  const onAddRow = () => {
    setGroupFixedPrice([...groupFixedPrice, emptyGroupFixedPrice()]);
  };

  const onRemoveRow = (key: string) => () => {
    const filteredTeamDiscounts = groupFixedPrice.filter(row => row.key !== key);
    setGroupFixedPrice(filteredTeamDiscounts);
  };

  const onChange = (key: string, name: string) => (
    event: ChangeEvent<HTMLElement> | SyntheticEvent<HTMLElement> | null,
    { value }: any,
  ) => {
    const index = findIndex(groupFixedPrice, discount => discount.key === key);
    const newTeamDiscounts = [...groupFixedPrice];
    set(newTeamDiscounts[index], name, value);
    setGroupFixedPrice(newTeamDiscounts);
  };

  return (
    <div>
      {groupFixedPrice.map(({
        key, embellishment, groupId, price: { amount },
      }) => (
        <Form.Group widths="equal" key={key}>
          <Form.Field>
            <label>{translate('EMBELLISHMENT')}</label>
            <Dropdown
              onChange={onChange(key, 'embellishment')}
              options={embellishemntOptions}
              placeholder={translate('SELECT_EMBELLISHMENT')}
              selection
              value={embellishment}
            />
          </Form.Field>

          <Form.Field>
            <label>{translate('TEAM')}</label>
            <Dropdown
              onChange={onChange(key, 'groupId')}
              options={teamsOptions}
              placeholder={translate('SELECT_TEAM')}
              selection
              value={groupId}
            />
          </Form.Field>

          <Form.Field>
            <label>{translate('FIX_PRICE')}</label>
            <InputWithSymbol
              {...defaultInputSymbolProps}
              decimalScale={2}
              max={undefined}
              onChange={onChange(key, 'price.amount')}
              step={0.01}
              symbol="€"
              value={amount}
            />
          </Form.Field>

          <Button
            {...defaultAddButtonProps}
            basic
            className={classNames(styles.buttonRemove, groupFixedPrice.length === 1 && styles.hidden, 'simple')}
            icon="remove"
            onClick={onRemoveRow(key)}
          />
        </Form.Group>
      ))}

      <Button
        {...defaultAddButtonProps}
        className={styles.buttonAdd}
        label={translate(groupFixedPrice.length ? 'ADD_ADDITIONAL_EMBELLISHMENT' : 'ADD_EMBELLISHMENT_DISCOUNT')}
        onClick={onAddRow}
      />
    </div>
  );
};

export default GroupFixedPriceRows;
