import { AjaxError } from 'rxjs/ajax';
import {
  DeactivateInviteTokenErrorAction,
  DeactivateInviteTokenRequestAction,
  DeactivateInviteTokenSuccessAction,
} from '../../models/actionTypes';
import {
  DEACTIVATE_INVITE_TOKEN_ERROR,
  DEACTIVATE_INVITE_TOKEN_REQUEST,
  DEACTIVATE_INVITE_TOKEN_SUCCESS,
} from '../../constants/actionTypes';

export const deactivateInviteTokenRequest = (
  token: string,
): DeactivateInviteTokenRequestAction => ({
  type: DEACTIVATE_INVITE_TOKEN_REQUEST,
  payload: {
    token,
  },
});

export const deactivateInviteTokenSuccess = (
  payload = {},
): DeactivateInviteTokenSuccessAction => ({
  type: DEACTIVATE_INVITE_TOKEN_SUCCESS,
  payload,
});

export const deactivateInviteTokenError = (
  error: AjaxError,
): DeactivateInviteTokenErrorAction => ({
  type: DEACTIVATE_INVITE_TOKEN_ERROR,
  payload: {
    error,
  },
});
