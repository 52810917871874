import React, {
  ChangeEvent, FC, SyntheticEvent, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'semantic-ui-react';
import { HeaderTypes } from '../../../components/Header';
import InputWithSymbol from '../../../components/InputWithSymbol';
import { ConfirmIndividualOrderRequest } from '../../../models/actionTypes';
import { IndividualOrderListItem } from '../../../models/IndividualOrder';

import styles from './ConfirmOrderModal.module.scss';

export const defaultInputSymbolProps = {
  decimalScale: 1,
  focused: true,
  max: 100,
  min: 0,
  step: 0.1,
  symbol: '%',
};

export interface ConfirmOrderModalProps {
  order: IndividualOrderListItem;
  loadingConfirmation: boolean;
  confirmIndividualOrder: (orderId: number, finalPrice: number) => ConfirmIndividualOrderRequest;
  setModalActiveOrder: (order: number | null) => void;
}

const ConfirmOrderModal: FC<ConfirmOrderModalProps> = ({
  order,
  loadingConfirmation,
  confirmIndividualOrder,
  setModalActiveOrder,
}) => {
  const [translate] = useTranslation();
  const [orderId] = useState((order && order.orderId) || null);
  const [itemsSummedPrice] = useState(
    (order && order.itemsSummedPrice && order.itemsSummedPrice.amount) || 0,
  );
  const [price, setPrice] = useState(
    (order && order.itemsSummedPrice && order.itemsSummedPrice.amount) || 0,
  );
  const CANCELATION_ORDER_PRICE = 0;

  const handleFinishOrderClick = () => {
    confirmIndividualOrder(orderId!, price!);
  };

  const handleCancelClick = () => {
    confirmIndividualOrder(orderId!, CANCELATION_ORDER_PRICE);
  };

  const handleModalClose = () => {
    setModalActiveOrder(null);
  };

  const handleChange = (
    event: ChangeEvent<HTMLElement> | SyntheticEvent<HTMLElement> | null,
    { value }: any,
  ) => {
    setPrice(value);
  };

  return (
    <Modal
      closeIcon
      size="small"
      onClose={handleModalClose}
      open
    >
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <Modal.Header className={styles.title} as={HeaderTypes.H2} content={translate('FINISH_INDIVIDUAL_ORDER', { orderId: order.shopwareOrderNumber })} />
        </div>
        <div className={styles.pricesWrapper}>
          <Form.Field className={styles.oldPriceFormField}>
            <label>{translate('ORIGINAL_PRICE')}</label>
            <InputWithSymbol
              {...defaultInputSymbolProps}
              decimalScale={2}
              max={undefined}
              disabled
              step={0.01}
              symbol=" €"
              value={itemsSummedPrice!}
            />
          </Form.Field>

          <Form.Field className={styles.newPriceFormField}>
            <label>{translate('FINAL_PRICE')}</label>
            <InputWithSymbol
              {...defaultInputSymbolProps}
              decimalScale={2}
              max={undefined}
              onChange={handleChange}
              step={0.01}
              disabled={loadingConfirmation}
              symbol=" €"
              value={price!}
            />
          </Form.Field>
        </div>
        <div className={styles.actionsContainer}>
          <Button
            basic
            circular
            disabled={loadingConfirmation}
            content={translate('CANCEL_ORDER')}
            onClick={handleCancelClick}
            primary
          />
          <Button
            circular
            disabled={loadingConfirmation}
            content={translate('CHANGE_BILLING_AND_FINISH_ORDER')}
            onClick={handleFinishOrderClick}
            primary
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmOrderModal;
