import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import ProfileAvatar from './ProfileAvatar';
import { State } from '../../../models/State';
import { fetchFileRequest } from '../../../actions/files/fetchFile';

const mapStatesToProps = ({ files: { byHash } }: State) => ({
  filesByHash: byHash,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFile: bindActionCreators(fetchFileRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(ProfileAvatar);
