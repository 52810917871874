import {
  FETCH_MEMBERS_ERROR, FETCH_MEMBERS_REQUEST, FETCH_MEMBERS_SUCCESS,
} from '../constants/actionTypes';
import { Member, MembersByHash, MembersReducer } from '../models/Member';
import { MembersActions } from '../models/actionTypes/Members';

export const initialState: MembersReducer = {
  byHash: {},
  byId: [],
  count: 0,
  loading: false,
  error: false,
  errorMessage: '',
};

export default (state = initialState, action: MembersActions) => {
  switch (action.type) {
    case FETCH_MEMBERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MEMBERS_SUCCESS: {
      const { members, count } = action.payload;

      return {
        ...state,
        byHash: members
          .reduce((byHash: MembersByHash, member: Member) => ({
            ...byHash,
            [member.personId]: member,
          }), state.byHash),
        byId: members.map(({ personId }: Member) => personId),
        loading: false,
        count,
      };
    }
    case FETCH_MEMBERS_ERROR:
    default:
      return state;
  }
};
