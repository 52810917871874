import { connect } from 'react-redux';

import HobbyProductPrice from './HobbyProductPrice';
import { State } from '../../../../models/State';

const mapStateToProps = (
  {
    catalogs: { hobbyCatalog },
  }: State,
) => ({
  catalog: hobbyCatalog!,
});

export default connect(mapStateToProps)(HobbyProductPrice);
