import React, { FC, useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { EmbellishmentValueRowProps } from './EmbellishmentValueRowProps';
import Row from '../../../../components/Table/components/Row';
import styles from '../../../../components/OrdersRow/OrdersRow.module.scss';
import Text from '../../../../components/Text';

const EmbellishmentValueRow: FC<EmbellishmentValueRowProps> = ({
  embellishmentValue: {
    id,
    embellishment,
    selectOption,
  },
  embellishments,
  isSelected,
  onSelect,
  onRowClick,
}) => {
  const [embellishmentName, setEmbellishmentName] = useState<string>();

  useEffect(() => {
    if (embellishments.length) {
      const found = embellishments.find(emb => emb.type === embellishment);
      if (found) {
        setEmbellishmentName(found.name);
      }
    }
  }, [embellishment, embellishments]);
  const handleCheckboxChange = (event: React.FormEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onSelect(id || 0);
  };

  const handleRowClick = () => {
    if (id) {
      onRowClick(id);
    }
  };

  return (
    <Row key={`${embellishment}-${selectOption}`} classNames={styles.wrapper} itemOnClick={handleRowClick}>
      <div className={styles['order-id']}>
        <Checkbox checked={isSelected} onChange={handleCheckboxChange} />
      </div>
      <Text>{embellishmentName}</Text>
      <Text>{selectOption}</Text>
    </Row>
  );
};

export default EmbellishmentValueRow;
