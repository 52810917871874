import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { handleError } from '../../actions/errors/handleError';
import { fetchExternalClubsError, fetchExternalClubsSuccess } from '../../actions/externalSystems/fetchExternalClubs';
import { FETCH_EXTERNAL_CLUBS_ERROR, FETCH_EXTERNAL_CLUBS_REQUEST } from '../../constants/actionTypes';
import { EXTERNAL_CLUBS_TOR_24 } from '../../constants/paths';
import { FetchExternalClubsRequestAction } from '../../models/actionTypes';
import { Tor24ClubDto } from '../../models/ExternalSystems';
import { Response } from '../../models/Response';
import { State } from '../../models/State';

const fetchExternalClubsOrgs = (
  action$: ActionsObservable<FetchExternalClubsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_EXTERNAL_CLUBS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { search } },
    {
      authentication: { authToken },
    },
  ]) => ajax({
    url: `${EXTERNAL_CLUBS_TOR_24}${search ? `?search=${search}` : ''}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: clubs }: Response<Tor24ClubDto[]>) => (
      fetchExternalClubsSuccess(clubs)
    )),
    catchError(error => ([
      handleError(error, FETCH_EXTERNAL_CLUBS_ERROR),
      fetchExternalClubsError(error),
    ])),
  )),
);

export default fetchExternalClubsOrgs;
