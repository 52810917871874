import { push } from 'connected-react-router';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { copyCatalogError, copyCatalogSuccess } from '../../actions/catalogs/copyCatalog';
import { handleError } from '../../actions/errors/handleError';
import { addNotification } from '../../actions/notifications/addNotification';
import { COPY_CATALOG_ERROR, COPY_CATALOG_REQUEST, COPY_CATALOG_SUCCESS } from '../../constants/actionTypes';
import { COPY_CATALOG } from '../../constants/paths';
import { CLUBS_CATALOGS_ROUTE } from '../../constants/routes';
import { CopyCatalogRequestAction, NotificationType } from '../../models/actionTypes';
import { State } from '../../models/State';

const copyCatalog = (
  action$: ActionsObservable<CopyCatalogRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(COPY_CATALOG_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { originalClubId, targetClubId } },
    {
      authentication: { authToken },
    },
  ]) => ajax({
    url: COPY_CATALOG.replace('$originalClubId', `${originalClubId}`).replace('$targetClubId', `${targetClubId}`),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, COPY_CATALOG_SUCCESS),
      copyCatalogSuccess(targetClubId),
      push(CLUBS_CATALOGS_ROUTE),
    ]),
    catchError(error => [
      handleError(error, COPY_CATALOG_ERROR),
      copyCatalogError(error),
    ]),
  )),
);

export default copyCatalog;
