import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { handleError } from '../../actions/errors/handleError';
import { saveExternalReferenceError, saveExternalReferenceSuccess } from '../../actions/externalSystems/saveExternalReference';
import { addNotification } from '../../actions/notifications/addNotification';
import { fetchClubTeamsRequest } from '../../actions/clubTeams/fetchClubTeams';
import { SAVE_EXTERNAL_REFERENCE_ERROR, SAVE_EXTERNAL_REFERENCE_REQUEST, SAVE_EXTERNAL_REFERENCE_SUCCESS } from '../../constants/actionTypes';
import { EXTERNAL_REFERENCES } from '../../constants/paths';
import { NotificationType, SaveExternalReferenceRequestAction } from '../../models/actionTypes';
import { State } from '../../models/State';
import {
  ExternalSystemReference,
  UpdateExternalSystemReferenceRequest,
} from '../../models/ExternalSystems';

const prepareExternalReference = (
  ref: ExternalSystemReference,
): UpdateExternalSystemReferenceRequest => ({
  references: [ref],
});

export default (
  action$: ActionsObservable<SaveExternalReferenceRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(SAVE_EXTERNAL_REFERENCE_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload,
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: EXTERNAL_REFERENCES,
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(prepareExternalReference(payload.reference)),
  }).pipe(
    mergeMap(() => [
      saveExternalReferenceSuccess(),
      addNotification(NotificationType.SUCCESS, SAVE_EXTERNAL_REFERENCE_SUCCESS),
      fetchClubTeamsRequest({
        clubId: payload.clubId,
        page: payload.currentPage || 1,
      }),
    ]),
    catchError((error: AjaxError) => ([
      handleError(error, SAVE_EXTERNAL_REFERENCE_ERROR),
      saveExternalReferenceError(error),
    ])),
  )),
);
