import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { State } from '../../models/State';
import { Club } from '../../models/Club';
import { fetchClubRequest } from '../../actions/clubs/fetchClub';
import PendingInvitations from './PendingInvitations';
import { PendingInvitationsProps } from './PendingInvitationsProps';
import { fetchTeamRequest } from '../../actions/clubTeams/fetchClubTeam';
import { fetchStaffRequest } from '../../actions/staff/staff';
import { resendInvitationRequest } from '../../actions/invitations';
import { addNotification } from '../../actions/notifications/addNotification';

const mapStateToProps = ({
  clubs: {
    byHash: clubsByHash,
    byId: clubsById,
  },
  clubTeams: {
    byHash: teamsByHash,
    byId: teamsById,
    count,
    loading,
  },
  staff: {
    pendingStaffMembersByHash,
    pendingStaffMembersById,
  },
}: State, { match: { params: { clubId } } }: PendingInvitationsProps) => ({
  clubs: clubsById.map(id => clubsByHash[id]),
  clubTeams: teamsById.map(id => teamsByHash[id]),
  pendingStaffMembers: pendingStaffMembersById.map(id => pendingStaffMembersByHash[id]),
  loading,
  count,
  club: clubId && clubsByHash[clubId]
    ? clubsByHash[clubId]
    : {
      address: {},
    } as Club,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTeam: bindActionCreators(fetchTeamRequest, dispatch),
  fetchClub: bindActionCreators(fetchClubRequest, dispatch),
  fetchStaff: bindActionCreators(fetchStaffRequest, dispatch),
  resendInvitation: bindActionCreators(resendInvitationRequest, dispatch),
  addNotification: bindActionCreators(addNotification, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingInvitations);
