import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { editTeamRequest } from '../../../../actions/teamRequests/EditTeamRequest';
import EditTeamRequestModal from './EditTeamRequestModal';
import { State } from '../../../../models/State';

const mapStateToProps = ({ teamRequests: { error, loading, editSuccessful } }: State) => ({
  error,
  loading,
  editSuccessful,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  editPromotionRequest: bindActionCreators(editTeamRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTeamRequestModal);
