import get from 'lodash/get';
import xor from 'lodash/xor';
import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox, Dropdown, DropdownProps, Input, Label, Table,
} from 'semantic-ui-react';
import {
  Embellishment,
  EmbellishmentsByHash,
  EmbellishmentTypes,
} from '../../../../../../models/Embellishment';
import { ProductEmbellishment } from '../../../../../../models/Product';
import { formatPrice } from '../../../../../../utils/PricingUtil';
import HobbyProductPrice from '../../../HobbyProductPrice';
import PriceDiscountsPopup from '../../../PriceDiscountsPopup';
import styles from './EmbellishmentModalRow.module.scss';
import embellishmentsPriceOverride from '../../../../../../constants/embellishmentsPriceOverride';

interface EmbellishmentModalRowProps {
  clubId?: number;
  isHobbyCatalog: boolean;
  productEmbellishment?: ProductEmbellishment;
  embellishment: Embellishment;
  embellishmentsByHash: EmbellishmentsByHash;
  embellishmentsById: EmbellishmentTypes[];
  enabled: EmbellishmentTypes[];
  setEnabled: (enabledEmbellishments: EmbellishmentTypes[]) => void;
  setEnabledGlobal: (enabled: boolean) => void;
  updateProductEmbellishment: (embellishment: ProductEmbellishment) => void;
}

const EmbellishmentModalRow: FC<EmbellishmentModalRowProps> = ({
  setEnabled,
  setEnabledGlobal,
  updateProductEmbellishment,
  productEmbellishment,
  enabled,
  isHobbyCatalog,
  embellishmentsById,
  embellishmentsByHash,
  clubId,
  embellishment: {
    type,
    price,
  },
}) => {
  const [translate] = useTranslation();
  const resolveFixedPrice = useCallback(() => ((get(productEmbellishment, 'fixedPricePerUnit.amount')
  || get(productEmbellishment, 'fixedPricePerUnit.amount') === 0)
    ? get(productEmbellishment, 'fixedPricePerUnit.amount') : ''), [productEmbellishment]);

  const [embellishmentFixedPrice, setEmbellishmentFixedPrice] = useState(resolveFixedPrice());
  const [embellishmentQuantity, setEmbellishmentQuantity] = useState(
    (productEmbellishment && productEmbellishment.quantity) || 1,
  );
  const [embellishmentMandatory, setEmbellishmentMandatory] = useState(
    (productEmbellishment && productEmbellishment.mandatory) || false,
  );

  useEffect(() => {
    updateProductEmbellishment({
      embellishment: type,
      mandatory: embellishmentMandatory,
      ...(!!(embellishmentFixedPrice || embellishmentFixedPrice === 0)
        && ({ fixedPricePerUnit: { amount: +embellishmentFixedPrice, currency: 'EUR' } })),
      ...(embellishmentQuantity && ({ quantity: embellishmentQuantity })),
    });
    // eslint-disable-next-line
  }, [embellishmentFixedPrice, embellishmentQuantity, type, embellishmentMandatory]);

  useEffect(() => {
    if (productEmbellishment) {
      setEmbellishmentQuantity(productEmbellishment.quantity || 1);
      setEmbellishmentMandatory(productEmbellishment.mandatory);
      setEmbellishmentFixedPrice(resolveFixedPrice());
    }
  }, [productEmbellishment, resolveFixedPrice]);

  useEffect(() => {
    const embellishmentOverride = embellishmentsPriceOverride.find(e => e.type === type);
    if (enabled.includes(EmbellishmentTypes.TEAMSPORTS_11_LOGO_SMALL)) {
      if (embellishmentOverride && embellishmentOverride.priceWithSmall11TSLogo) {
        setEmbellishmentFixedPrice(embellishmentOverride.priceWithSmall11TSLogo);
      }
    } else if (enabled.includes(EmbellishmentTypes.TEAMSPORTS_11_LOGO_LARGE)) {
      if (embellishmentOverride && embellishmentOverride.priceWithBig11TSLogo) {
        setEmbellishmentFixedPrice(embellishmentOverride.priceWithBig11TSLogo);
      }
    } else if (enabled.includes(EmbellishmentTypes.TEAMSPORTS_11_SLEEVE_PATCH)) {
      if (embellishmentOverride && embellishmentOverride.priceWithSmall11TSLogo) {
        setEmbellishmentFixedPrice(embellishmentOverride.priceWithSmall11TSLogo);
      }
    } else {
      setEmbellishmentFixedPrice('');
    }
  }, [embellishmentsByHash, enabled, enabled.length, type]);

  const validateFixedPrice = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const fixedPrice = value.replace(',', '.');
    const valueSplitted = fixedPrice.split('.');

    if (valueSplitted && valueSplitted[1] && valueSplitted[1].length > 2) {
      return;
    }
    setEmbellishmentFixedPrice(fixedPrice);
  };

  const availableQuantity = useMemo(
    () => [...Array(21).keys()].map(number => ({
      value: number,
      text: number,
    })).slice(1), [],
  );

  const handleQuantityChange = (
    event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps,
  ) => {
    if (data.value) {
      setEmbellishmentQuantity(+data.value);
    }
  };

  const onToggleMandatory = useCallback(() => {
    setEmbellishmentMandatory(isEmbellishmentMandatory => !isEmbellishmentMandatory);
  }, [setEmbellishmentMandatory]);

  const onToggleEnable = useCallback(() => {
    let nextEnabledState = enabled;
    if (type === EmbellishmentTypes.TEAMSPORTS_11_LOGO_LARGE
        && enabled.includes(EmbellishmentTypes.TEAMSPORTS_11_LOGO_SMALL)) {
      nextEnabledState = xor(enabled, [EmbellishmentTypes.TEAMSPORTS_11_LOGO_SMALL]);
    } else if (type === EmbellishmentTypes.TEAMSPORTS_11_LOGO_SMALL
        && enabled.includes(EmbellishmentTypes.TEAMSPORTS_11_LOGO_LARGE)) {
      nextEnabledState = xor(enabled, [EmbellishmentTypes.TEAMSPORTS_11_LOGO_LARGE]);
    }
    nextEnabledState = xor(nextEnabledState, [type]);
    setEnabled(nextEnabledState);
    setEmbellishmentMandatory(nextEnabledState.includes(type));
    setEnabledGlobal(nextEnabledState.length === embellishmentsById.length);
  }, [enabled, embellishmentsById, type, setEnabledGlobal, setEnabled]);

  return (
    <Table.Body key={type}>
      <Table.Row>
        <Table.Cell>
          <Checkbox
            checked={enabled.includes(type)}
            onClick={onToggleEnable}
          />
        </Table.Cell>
        <Table.Cell>
          <Dropdown
            options={availableQuantity}
            selection
            disabled={!enabled.includes(type)}
            onChange={handleQuantityChange}
            value={embellishmentQuantity}
            fluid
          />
        </Table.Cell>
        <Table.Cell>
          {translate(type)}
        </Table.Cell>
        <Table.Cell>
          {formatPrice(
            price.amount,
            price.currency,
          )}
        </Table.Cell>
        <Table.Cell className={styles.priceRangeCell}>
          {isHobbyCatalog ? (
            <HobbyProductPrice
              embellishments={[embellishmentsByHash[type]].filter(Boolean)}
              productEmbellishments={[]}
            />
          ) : (
            <PriceDiscountsPopup
              productEmbellishments={[]}
              embellishments={[embellishmentsByHash[type]].filter(Boolean)}
              clubId={clubId!}
            />
          )}
        </Table.Cell>
        <Table.Cell>
          <Input
            type="number"
            min="0.00"
            step="0.01"
            labelPosition="right"
            onChange={validateFixedPrice}
            disabled={!enabled.includes(type)}
            value={embellishmentFixedPrice}
            className={[styles.fixedPriceInputWrapper, !enabled.includes(type) ? styles.disabled : ''].join(' ')}
          >
            <input />
            <Label basic>€</Label>
          </Input>
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Checkbox
            checked={embellishmentMandatory}
            disabled={!enabled.includes(type)}
            onClick={onToggleMandatory}
            toggle
          />
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  );
};

export default EmbellishmentModalRow;
