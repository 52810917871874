import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import {
  FETCH_CLUB_ORDERS_ERROR,
  FETCH_CLUB_ORDERS_REQUEST,
} from '../../constants/actionTypes';
import { ListResponse, Response } from '../../models/Response';
import { FetchClubOrdersRequest } from '../../models/actionTypes/ClubOrdersActions';
import { State } from '../../models/State';
import { CLUB_ORDERS_LIST_LIMIT } from '../../constants/customizations';
import { fetchClubOrdersError, fetchClubOrdersSuccess } from '../../actions/clubs/fetchClubOrders';
import { OrderList } from '../../models/Order';
import { handleError } from '../../actions/errors/handleError';
import { ORDER_LIST_BY_CLUB } from '../../constants/paths';

const fetchClubOrders = (
  action$: ActionsObservable<FetchClubOrdersRequest>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_CLUB_ORDERS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    {
      payload: {
        page, field, order, search, clubId,
      },
    },
    {
      authentication: { authToken },
    },
  ]) => {
    const query = queryString.stringify(pickBy({
      offset: ((page || 1) - 1) * CLUB_ORDERS_LIST_LIMIT,
      limit: CLUB_ORDERS_LIST_LIMIT,
      ...(search && ({ search })),
    }));

    return ajax({
      url: `${ORDER_LIST_BY_CLUB.replace('$clubId', clubId)}?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify(((field || order) ? { sort: { field, order } } : {})),
    }).pipe(
      map(({ response: { data: orders, count } }: Response<ListResponse<OrderList>>) => (
        fetchClubOrdersSuccess(orders, count))),
      catchError(error => ([
        handleError(error, FETCH_CLUB_ORDERS_ERROR),
        fetchClubOrdersError(error),
      ])),
    );
  }),
);

export default fetchClubOrders;
