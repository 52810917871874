import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import SetDfbDivisionPopup from './SetDfbDivisionPopup';
import { saveExternalReference } from '../../../../actions/externalSystems/saveExternalReference';
import { DispatchProps } from './SetDfbDivisionPopupProps';

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  saveExternalReference: bindActionCreators(saveExternalReference, dispatch),
});

export default connect(null, mapDispatchToProps)(SetDfbDivisionPopup);
