import { AjaxError } from 'rxjs/ajax';

import { Catalog } from '../../models/Catalog';
import { FETCH_CATALOGS_REQUEST, FETCH_CATALOGS_SUCCESS, FETCH_CATALOGS_ERROR } from '../../constants/actionTypes';
import {
  FetchCatalogsErrorAction,
  FetchCatalogsRequestAction,
  FetchCatalogsSuccessAction,
} from '../../models/actionTypes';

const fetchCatalogsDefaultPayload = {
  page: 1,
  search: '',
};

export const fetchCatalogsRequest = (
  payload = fetchCatalogsDefaultPayload,
): FetchCatalogsRequestAction => ({
  type: FETCH_CATALOGS_REQUEST,
  payload: {
    ...fetchCatalogsDefaultPayload,
    ...payload,
  },
});

export const fetchCatalogsSuccess = (
  payload: { catalogs: Catalog[], count: number },
): FetchCatalogsSuccessAction => ({
  type: FETCH_CATALOGS_SUCCESS,
  payload,
});

export const fetchCatalogsError = (error: AjaxError): FetchCatalogsErrorAction => ({
  type: FETCH_CATALOGS_ERROR,
  payload: {
    error,
  },
});
