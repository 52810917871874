import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import TeamRequest from './TeamRequests';
import { State } from '../../models/State';
import { fetchTeamRequestsAction } from '../../actions/teamRequests/FetchTeamRequests';
import { rejectPromotionRequest } from '../../actions/teamRequests/RejectPromotionRequest';
import { resetApproveModalAction } from '../../actions/teamRequests/ResetApproveRequestModal';

const mapStateToProps = ({
  teamRequests: {
    byHash,
    byId,
    loading,
    count,
    newClubCreated,
    teamApproved,
    teamRejected,
  },
}: State) => ({
  loading,
  count,
  newClubCreated,
  teamApproved,
  teamRejected,
  requests: byId.map(id => byHash[id]),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRequests: bindActionCreators(fetchTeamRequestsAction, dispatch),
  rejectRequest: bindActionCreators(rejectPromotionRequest, dispatch),
  resetApproveModal: bindActionCreators(resetApproveModalAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamRequest);
