import NumberFormat from 'react-number-format';
import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Button } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';
import SortArrow from '../../icons/SortArrow';
import styles from './InputWithSymbol.module.scss';
import { InputWithSymbolProps } from './InputWithSymbolProps';
import { InputValidatorProps } from './InputWarningProps';

const InputWithSymbol: FC<InputWithSymbolProps> = ({
  decimalScale = 0,
  defaultValue,
  disabled = false,
  focused,
  max,
  min,
  name,
  onChange,
  onFocus,
  size = 'medium',
  step = 1,
  symbol,
  value,
  validators,
}) => {
  const [translate] = useTranslation();
  const isAllowed = useCallback((floatValue: number) => {
    if (typeof max === 'number' && floatValue > max) {
      return false;
    }
    if (typeof min === 'number' && floatValue < min) {
      return false;
    }
    return floatValue !== value;
  }, [min, max, value]);

  const onValueChange = useCallback(values => {
    if (onChange && focused && isAllowed(values.floatValue)) {
      onChange(null, { value: values.floatValue, name });
    }
  }, [onChange, focused, name, isAllowed]);

  const inputWarnings = useMemo(() => validators
      && validators.map((validator: InputValidatorProps) => {
        if (!validator.isValid(value)) {
          return (
            <div key={uuidv4()} className={styles.warningContainer}>
              <span className={styles.warning}>{translate(validator.content)}</span>
            </div>
          );
        }
        return null;
      }), [validators, value, translate]);

  const modify = useCallback((modifier: number) => () => {
    const modifiedValue = Number(value) + modifier;

    if (onFocus) {
      onFocus();
    }

    if (onChange && isAllowed(modifiedValue)) {
      onChange(null, { value: modifiedValue, name });
    }
  }, [onChange, onFocus, value, name, isAllowed]);

  return (
    <div>
      <div className={classNames(styles.inputContainer, disabled && styles.disabled)}>
        <div className={`ui input ${styles[`${size}Input`]}`}>
          <NumberFormat
            className={styles.inputNumber}
            decimalScale={decimalScale}
            decimalSeparator=","
            defaultValue={defaultValue}
            disabled={disabled}
            fixedDecimalScale
            isAllowed={({ floatValue }) => isAllowed(floatValue as number)}
            name={name}
            onFocus={onFocus}
            onValueChange={onValueChange}
            suffix={symbol}
            thousandSeparator="."
            value={value}
          />
        </div>
        {!disabled && ([
          <Button
            basic
            as="a"
            className={`${styles.buttonIncrease} ${focused && styles.focused} simple`}
            key="increase"
            onClick={modify(step)}
          >
            <SortArrow fill="#B41B31" />
          </Button>,
          <Button
            basic
            as="a"
            className={`${styles.buttonDecrease} ${focused && styles.focused} simple`}
            key="decrease"
            onClick={modify(-step)}
          >
            <SortArrow fill="#B41B31" />
          </Button>,
        ])}
      </div>
      {inputWarnings}
    </div>
  );
};

export default InputWithSymbol;
