import { AjaxError } from 'rxjs/ajax';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import {
  DELETE_EXTERNAL_REF_ERROR,
  DELETE_EXTERNAL_REF_REQUEST,
} from '../../constants/actionTypes';
import { EXTERNAL_REF_DELETION_PATH } from '../../constants/paths';
import { State } from '../../models/State';
import { DeleteExternalReferenceRequestAction } from '../../models/actionTypes';
import { ExternalSystemReference } from '../../models/ExternalSystems';
import { handleError } from '../../actions/errors/handleError';
import {
  deleteExternalReferencesSuccess,
} from '../../actions/externalSystems/deleteExternalSystemReference';

export default (
  action$: ActionsObservable<DeleteExternalReferenceRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(DELETE_EXTERNAL_REF_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      referenceName,
      clubId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: EXTERNAL_REF_DELETION_PATH.replace('$clubId', clubId).replace('$externalRefName', referenceName),
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap((externalRefs: { response: ExternalSystemReference[] }) => [
      deleteExternalReferencesSuccess(externalRefs),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, DELETE_EXTERNAL_REF_ERROR),
    ]),
  )),
);
