import upperCase from 'lodash/upperCase';

interface GetInitialsProps {
  firstName: string;
  lastName: string;
}

const getInitials = ({ firstName, lastName }: GetInitialsProps) => [firstName, lastName]
  .map(upperCase)
  .map(text => text.charAt(0))
  .join('');

export default getInitials;
