import * as types from '../../constants/actionTypes';
import { SetMenuExpandedAction } from '../../models/actionTypes';

const setMenuExpanded = (value: boolean): SetMenuExpandedAction => ({
  type: types.SET_MENU_EXPANDED,
  payload: {
    value,
  },
});

export default setMenuExpanded;
