import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import { formatPrice } from '../../utils/PricingUtil';
import Row from '../../components/Table/components/Row';
import Text from '../../components/Text';
import { formatDate } from '../../utils/DateUtils';
import TableContainer from '../../components/Layout/components/TableContainer';
import styles from '../Settings/Settings.module.scss';
import ClubDetailsHeader from '../../components/ClubDetailsHeader';
import { ClubIndividualOrdersProps } from './ClubIndividualOrdersProps';
import { IndividualOrderListItem } from '../../models/IndividualOrder';
import { getFullNameWithNullGuards } from '../../utils/getFullName';
import filteredColumns from './filteredColumns';

const ClubIndividualOrders = ({
  fetchIndividualOrders,
  fetchClub,
  loading,
  count,
  individualOrders,
  match,
  history,
  club: { name },
} : ClubIndividualOrdersProps) => {
  const [translate] = useTranslation();
  const { clubId } = match.params;
  useEffect(() => {
    fetchClub(clubId);
  }, [clubId, fetchClub]);

  const renderRows = (
    {
      orderId,
      shopwareOrderNumber,
      createdAt,
      createdBy,
      itemsSummedPrice,
    }: IndividualOrderListItem,
  ) => (
    <Row
      key={shopwareOrderNumber}
    >
      <Text>{(shopwareOrderNumber && `#${shopwareOrderNumber}`) || `#${orderId}`}</Text>
      <Text>{formatDate(createdAt)}</Text>
      <Text>{createdBy ? getFullNameWithNullGuards(createdBy.firstName, createdBy.lastName) : ''}</Text>
      <Text>{itemsSummedPrice ? formatPrice(itemsSummedPrice.amount, itemsSummedPrice.currency) : ''}</Text>
    </Row>
  );
  return (
    <>
      {loading && <Dimmer active inverted><Loader /></Dimmer>}
      <ClubDetailsHeader clubName={name} clubId={clubId} />
      <div className={styles.wrapper}>
        <TableContainer
          extraFetchParameters={{ clubId }}
          fetch={fetchIndividualOrders}
          loading={loading}
          count={count}
          history={history}
          columns={filteredColumns(translate)}
          renderItems={individualOrders.map(renderRows)}
          disableSearch
          invertedHeader
          emptyTableText="NO_ORDERS_AVAILABLE"
        />
      </div>
    </>
  );
};

export default ClubIndividualOrders;
