import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Button, DropdownItemProps, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { AddTeamLeadRoleToClubAdminProps } from './AddTeamLeadRoleToClubAdminProps';
import styles from './AddTeamLeadRoleToClubAdmin.module.scss';
import { ClubAdmin } from '../../models/Club';
import { ClubTeam } from '../../models/ClubTeam';
import { Roles } from '../../constants/roles';
import HeaderComponent from '../../components/Header';

const AddTeamLeadRoleToClubAdmin: FC<AddTeamLeadRoleToClubAdminProps> = ({
  clubAdmins,
  clubTeams,
  clubId,
  clubName,
  fetchClubTeams,
  assignMember,
  location,
} : AddTeamLeadRoleToClubAdminProps) => {
  const [translate] = useTranslation();
  const [clubAdminOptions, setClubAdminOptions] = useState<DropdownItemProps[]>([]);
  const [selectedClubAdmin, setSelectedClubAdmin] = useState<ClubAdmin | null>(null);

  const [activeTeamsOptions, setActiveTeamsOptions] = useState<DropdownItemProps[]>([]);
  const [selectedActiveTeam, setSelectedActiveTeam] = useState<ClubTeam | null>(null);
  const [selectedRoles, setSelectedRoles] = useState<Roles[]>([Roles.TEAM_LEAD]);

  const history = useHistory();

  const { teamId } = queryString.parse(location.search);
  const roleOptions: DropdownItemProps[] = [{
    value: Roles.TEAM_LEAD,
    text: translate(Roles.TEAM_LEAD),
    content: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{translate(Roles.TEAM_LEAD)}</span>
      </div>
    ),
    key: Roles.TEAM_LEAD,
  }, {
    value: Roles.PLAYER,
    text: translate(Roles.PLAYER),
    content: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{translate(Roles.PLAYER)}</span>
      </div>
    ),
    key: Roles.PLAYER,
  }];

  const setSelectedTeam = useCallback(
    (id?: number) => {
      const found = clubTeams.find((team: ClubTeam) => team.id === id);
      if (found) {
        setSelectedActiveTeam(found);
      }
    },
    [setSelectedActiveTeam, clubTeams],
  );

  const selectClubAdmin = (personId?: number) => {
    if (clubAdmins) {
      const found = clubAdmins.find((clubAdmin: ClubAdmin) => clubAdmin.personId === personId);
      if (found) {
        setSelectedClubAdmin(found);
      }
    }
  };

  useEffect(() => { fetchClubTeams({ clubId, pagination: false }); }, [clubId, fetchClubTeams]);
  useEffect(() => {
    if (teamId) {
      setSelectedTeam(+teamId);
    }
  }, [clubId, fetchClubTeams, teamId, setSelectedTeam]);

  useEffect(() => {
    if (clubAdmins && clubAdmins.length) {
      setClubAdminOptions(clubAdmins.map((clubAdmin: ClubAdmin) => ({
        value: clubAdmin.personId,
        text: `${clubAdmin.firstName} ${clubAdmin.lastName}`,
        content: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{`${clubAdmin.firstName} ${clubAdmin.lastName}`}</span>
          </div>
        ),
        key: clubAdmin.personId,
      })));
    }
  }, [clubAdmins]);
  useEffect(() => {
    if (clubTeams && clubTeams.length) {
      const activeTeams = clubTeams.filter((team: ClubTeam) => team.status === 'ACTIVE');
      if (activeTeams && activeTeams.length) {
        setActiveTeamsOptions(activeTeams.map((team: ClubTeam) => ({
          value: team.id,
          text: team.name,
          content: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{team.name}</span>
            </div>
          ),
          key: team.id,
        })));
        return;
      }
    }
    setActiveTeamsOptions([]);
  }, [clubTeams]);

  const submit = () => {
    if (selectedRoles
        && selectedRoles.length) {
      selectedRoles.forEach(role => {
        if (selectedClubAdmin
            && selectedClubAdmin.personId && selectedActiveTeam) {
          assignMember({
            clubId,
            teamId: selectedActiveTeam.id,
            personId: +selectedClubAdmin.personId,
            role,
          });
        }
      });
    }
  };
  const cancel = () => {
    history.goBack();
  };

  const renderButton = () => (
    <>
      <Button
        primary
        onClick={() => cancel()}
      >
        {translate('CANCEL')}
      </Button>
      <Button
        primary
        onClick={() => submit()}
        disabled={
                !selectedRoles
                || !selectedRoles.length
                || !selectedClubAdmin
                || !selectedActiveTeam
            }
      >
        {translate('SAVE')}
      </Button>
    </>

  );

  return (
    <div>
      <HeaderComponent title={clubName} renderButton={renderButton} />
      <Form id="create-form" className={styles.formWrapper}>
        <Form.Field
          className={styles.formField}
          key="club-admin-form-field"
        >
          <label>{translate('CLUB_ADMIN')}</label>
          <div className={styles.inputWithLabel}>
            <Form.Dropdown
              name="club-admin-select"
              onChange={(event, data) => selectClubAdmin(data.value as number)}
              placeholder={translate('CLUB_ADMIN')}
              options={clubAdminOptions}
              selection
            />
          </div>
        </Form.Field>
        <Form.Field
          className={styles.formField}
          key="team-select-form-field"
        >
          <label>{translate('TEAM')}</label>
          <div className={styles.inputWithLabel}>
            <Form.Dropdown
              name="team-select"
              onChange={(event, data) => setSelectedTeam(data.value as number)}
              placeholder={translate('TEAM')}
              options={activeTeamsOptions}
              value={selectedActiveTeam ? selectedActiveTeam.id : undefined}
              selection
            />
          </div>
        </Form.Field>
        <div className={styles.secondPartWrapper}>
          <h3>{translate('SEARCH_RESULT')}</h3>
          <table className={styles.recapTable}>
            <tbody>
              <tr>
                <td><span>{translate('FULL_NAME')}</span></td>
                <td><span>{selectedClubAdmin ? `${selectedClubAdmin.firstName} ${selectedClubAdmin.lastName}` : ''}</span></td>
              </tr>
              <tr>
                <td><span>{translate('TEAM_NAME')}</span></td>
                <td><span>{selectedActiveTeam ? selectedActiveTeam.name : ''}</span></td>
              </tr>
              <tr>
                <td>
                  <label>{translate('ROLE')}</label>
                </td>
                <td>
                  <Form.Field
                    key="team-select-form-field"
                  >
                    <div>
                      <Form.Dropdown
                        name="role-select"
                        style={{ padding: '8px 2.1em 8px  0.36em' }}
                        onChange={(event, data) => setSelectedRoles(data.value as Roles[])}
                        placeholder={translate('ROLE')}
                        multiple
                        options={roleOptions}
                        value={selectedRoles}
                        selection
                      />
                    </div>
                  </Form.Field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Form>
    </div>
  );
};

export default AddTeamLeadRoleToClubAdmin;
