import React, { FC, useEffect, useState } from 'react';
import get from 'lodash/get';
import {
  Dropdown, Icon, Popup,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ClubAdminCell from '../ClubAdminCell';
import Confirm from '../../../../components/Confirm';
import Row from '../../../../components/Table/components/Row';
import Text from '../../../../components/Text';
import styles from './ClubsRow.module.scss';
import {
  ASSIGN_SALES_REP_ROUTE,
  CATALOG_CREATOR_ROUTE,
  CLUB_DETAILS_ROUTE,
  CLUB_INVITATION_ROUTE, CLUB_TEAMS_UPDATE_CLUB_ADMIN_TO_TEAM_LEAD_ROUTE,
  REWARDS_ROUTE,
} from '../../../../constants/routes';
import { Club } from '../../../../models/Club';
import { DeleteClubRequestAction } from '../../../../models/actionTypes';
import { Roles } from '../../../../constants/roles';
import { OrganizationType } from '../../../../models/OrganizationType';
import { Team } from '../../../../models/Team';
import { history } from '../../../../configureStore';
import ClubSalesRepsCell from '../../../../components/SalesRep/ClubSalesRepsCell';

interface ClubsRowProps {
  club: Club;
  deleteClub: (clubId: number) => DeleteClubRequestAction;
  userRoles: Roles[];
  organizationType?: OrganizationType
}

const ClubsRow: FC<ClubsRowProps> = ({
  club, deleteClub, userRoles = [], organizationType,
}) => {
  const {
    id,
    clubAdmins = [],
    name,
    hasCatalog,
    pendingClubAdmins = [],
    playerCount = 0,
    teamsCount = 0,
    address,
    salesOrganization,
  } = club;
  const [translate] = useTranslation();
  const onDeleteClub = (clubId: number) => () => deleteClub(clubId);
  const isUserSalesRep = userRoles.includes(Roles.SALES_REPRESENTATIVE);
  const isUserSalesLead = organizationType === OrganizationType.MASTER;
  const [clubTeams, setClubTeams] = useState<Team[]>([]);
  useEffect(() => {
    if (club && club.teams) {
      setClubTeams(club.teams);
    }
  }, [club]);
  const renderTeamRow = (team: Team) => (
    <li key={team.id}>{team.name}</li>
  );

  const renderTeamList = clubTeams.map(renderTeamRow);

  const handleRowClick = () => {
    history.push(CLUB_DETAILS_ROUTE.replace(':clubId', `${id}`));
  };

  return (
    <Row key={id} itemOnClick={handleRowClick}>
      <Text bold ellipsis>{name}</Text>
      <Text>{get(address, 'displayName', '')}</Text>
      <ClubSalesRepsCell
        clubSalesReps={salesOrganization ? salesOrganization.users : []}
      />
      <Popup
        className={styles.teamsPopup}
        disabled={clubTeams.length === 0}
        on="hover"
        hoverable
        trigger={(
          <div>
            <Text>
              {`${teamsCount}`}
            </Text>
          </div>
        )}
      >
        <div>
          <Text bold>
            {translate('CLUBS_TEAMS_OVERVIEW')}
            :
          </Text>
          <ul className={styles.teamList}>
            {renderTeamList}
          </ul>
        </div>
      </Popup>
      <Text>{`${playerCount}`}</Text>
      <ClubAdminCell clubAdmins={clubAdmins} pendingClubAdmins={pendingClubAdmins} />
      <div>{translate(hasCatalog ? 'SENT_TO_CLUB' : 'NOT_SENT_TO_CLUB')}</div>
      <div className={styles['options-wrapper']}>
        <Dropdown icon={<Icon name="ellipsis vertical" color="red" size="large" />}>
          <Dropdown.Menu direction="left">
            {
                  isUserSalesLead && (<Dropdown.Item text={translate('ASSIGN_NEW_SALES_REP')} as={Link} to={`${ASSIGN_SALES_REP_ROUTE.replace(':clubId', `${id}`)}`} disabled={!isUserSalesLead} />
                  )
              }
            <Dropdown.Item text={translate('INVITE_CLUB_ADMIN')} as={Link} to={`${CLUB_INVITATION_ROUTE.replace(':clubId', `${id}`)}`} disabled={!isUserSalesRep} />
            <Dropdown.Item
              text={translate('ADD_TEAM_LEAD_ROLE_TO_CLUB_ADMIN')}
              as={Link}
              to={`${CLUB_TEAMS_UPDATE_CLUB_ADMIN_TO_TEAM_LEAD_ROUTE.replace(':clubId', `${id}`)}`}
              disabled={!isUserSalesRep}
            />
            <Dropdown.Item text={translate('CLUB_CATALOG')} as={Link} to={CATALOG_CREATOR_ROUTE.replace(':clubId', `${id}`)} />
            <Dropdown.Item text={translate('CLUB_DETAILS')} as={Link} to={CLUB_DETAILS_ROUTE.replace(':clubId', `${id}`)} disabled={!isUserSalesRep} />
            <Dropdown.Item text={translate('REWARDS.SECTION')} as={Link} to={REWARDS_ROUTE.replace(':clubId', `${id}`)} disabled={!isUserSalesRep} />
            <Confirm
              as={Dropdown.Item}
              className={[styles.menuOptionDelete, 'item'].join(' ')}
              confirmText={translate('ARE_YOU_SURE_TO_DELETE_CLUB')}
              onConfirm={onDeleteClub(id)}
              triggerText={translate('DELETE_CLUB')}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Row>
  );
};

export default ClubsRow;
