import { Column } from '../../components/Table/Column';
import SalesRepFilter from '../../components/SalesRep/SalesRepFilter';

const columns: Column[] = [{
  header: 'CLUB_NAME',
  align: 'left',
  width: '20%',
  sortable: true,
  key: 'name',
  ellipsis: true,
}, {
  header: 'LOCATION',
  align: 'left',
  width: '15%',
  sortable: true,
  key: 'addressDisplayName',
},
{
  header: 'SALES_REPS',
  align: 'left',
  width: '15%',
  sortable: false,
  key: 'salesRepIds',
  filter: SalesRepFilter,
},
{
  header: 'TEAMS',
  align: 'center',
  width: '10%',
  sortable: true,
  key: 'teamsCount',
}, {
  header: 'PLAYERS',
  align: 'center',
  width: '10%',
  sortable: true,
  key: 'playerCount',
}, {
  header: 'ADMIN',
  align: 'left',
  width: '15%',
  sortable: false,
  key: 'admin',
},
{
  header: 'CATALOG.TITLE',
  align: 'left',
  width: '15%',
  sortable: true,
  key: 'hasCatalog',
}, {
  width: '5%',
  header: '',
  align: 'right',
  sortable: false,
  key: 'options',
}];

export default columns;
