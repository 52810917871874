import { Product, ProductEmbellishment } from './Product';
import { TagsByHash } from './Tag';
import { EmbellishmentTypes } from './Embellishment';
import { Price } from './Price';
import { SalesOrganization } from './Organizations';

export interface CatalogBase {
  productsCount: number;
  status: 'ACTIVE' | 'INACTIVE';
  items?: Product[];
  createdAt: number;
  isDraft?: boolean;
  productDiscountPercent?: number;
  embellishmentsDiscountPercent?: number;
  teamProductDiscounts?: TeamDiscountsType[],
  teamEmbellishmentsDiscounts?: TeamDiscountsType[],
  embellishmentFixedPriceDiscounts?: EmbellishmentFixedPriceDiscountType[],
  salesOrganization: SalesOrganization
}

export interface Catalog extends CatalogBase {
  id: number;
  clubId: number;
  clubName: string;
  products?: Product[];
  isIncomplete?: boolean;
  isDraft?: boolean;
}

export interface HobbyCatalog extends CatalogBase {
  products?: Product[];
  isIncomplete?: boolean;
  isDraft?: boolean;
}

export interface CatalogResponse extends CatalogBase {
  club: {
    id: number;
    name: string;
  }
  createdAt: number;
  products: Product[];
}

export enum GroupType {
  CLUB = 'CLUB',
  TEAM = 'TEAM',
}

export interface EmbellishmentFixedPriceDiscountType {
  key: string;
  embellishment?: EmbellishmentTypes;
  groupType?: GroupType;
  groupId?: number;
  price: Price;
}

export interface TeamDiscountsType {
  key: string;
  teamId?: number;
  discountPercent: number;
}

export interface CatalogUpdate {
  productDiscountPercent?: number;
  embellishmentsDiscountPercent?: number;
  teamProductDiscounts?: TeamDiscountsType[],
  teamEmbellishmentsDiscounts?: TeamDiscountsType[],
  embellishmentFixedPriceDiscounts?: EmbellishmentFixedPriceDiscountType[],
}

export interface CatalogsByHash {
  [key: string]: Catalog;
}

export interface CatalogReducer {
  byHash: CatalogsByHash;
  byId: number[];
  count: number;
  hobbyCatalog: HobbyCatalog | null;
  error: boolean;
  errorMessage: string;
  errorTags: boolean;
  loading: boolean;
  loadingList: boolean;
  loadingProduct: string | null;
  loadingTags: boolean;
  tagsByHash: TagsByHash;
  tagsById: [];
  previousEmbellishmentSelection: ProductEmbellishment[];
  selectedEmbellishmentsDraft: ProductEmbellishment[];
  showReleaseCatalogModal: boolean;
}
