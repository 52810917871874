import { AjaxError } from 'rxjs/ajax';
import {
  CheckEmailAvailabilityErrorAction,
  CheckEmailAvailabilityRequestAction,
  CheckEmailAvailabilitySuccessAction,
} from '../../models/actionTypes/CheckEmailAvailability';
import EmailAvailabilityResponse from '../../models/EmailAvailabilityResponse';
import {
  CHECK_EMAIL_AVAILABILITY_ERROR, CHECK_EMAIL_AVAILABILITY_REQUEST,
  CHECK_EMAIL_AVAILABILITY_SUCCESS,
} from '../../constants/actionTypes';

export const checkEmailAvailabilityRequest = (
  email: string,
  clubId: number,
): CheckEmailAvailabilityRequestAction => ({
  type: CHECK_EMAIL_AVAILABILITY_REQUEST,
  payload: {
    email,
    clubId,
  },
});

export const checkEmailAvailabilitySuccess = (
  emailAvailability: EmailAvailabilityResponse,
): CheckEmailAvailabilitySuccessAction => ({
  type: CHECK_EMAIL_AVAILABILITY_SUCCESS,
  payload: {
    emailAvailability,
  },
});

export const checkEmailAvailabilityError = (
  error: AjaxError,
): CheckEmailAvailabilityErrorAction => ({
  type: CHECK_EMAIL_AVAILABILITY_ERROR,
  payload: {
    error,
  },
});
