import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderComponent from '../../components/Header/Header';
import TableContainer from '../../components/Layout/components/TableContainer';
import { history } from '../../configureStore';
import { HobbyClub } from '../../models/HobbyClub';
import columns from './columns';
import HobbyClubRow from './HobbyClubRow';
import { HobbyClubsProps } from './HobbyClubsProps';
import styles from './HobbyClubs.module.scss';

const HobbyClubs: FC<HobbyClubsProps> = ({
  fetchHobbyClubs,
  loading,
  count,
  hobbyClubs,
}) => {
  const [translate] = useTranslation();

  const renderRows = (club: HobbyClub) => (
    <HobbyClubRow
      key={club.id}
      hobbyClub={club}
    />
  );

  return (
    <div className={styles.wrapper}>
      <HeaderComponent border title={translate('HOBBY_TEAMS_OVERVIEW')} />
      <div className={styles['content-wrapper']}>
        <TableContainer
          fetch={fetchHobbyClubs}
          loading={loading}
          count={count}
          history={history}
          columns={columns}
          renderItems={hobbyClubs.map(renderRows)}
          invertedHeader
          searchPlaceholder="SEARCH_FOR_HOBBY_TEAMS"
          emptyTableText="NO_HOBBY_CLUBS_AVAILABLE"
          notFoundTableText="NO_HOBBY_CLUBS_FOUND"
        />
      </div>
    </div>
  );
};

export default HobbyClubs;
