import React, {
  useState, FC, useCallback, useEffect, useMemo,
} from 'react';
import {
  Button, Dimmer, Loader, Divider, Popup,
} from 'semantic-ui-react';
import get from 'lodash/get';
import partition from 'lodash/partition';
import xor from 'lodash/xor';
import { useTranslation } from 'react-i18next';

import Confirm from '../../components/Confirm';
import Header from '../../components/Header';
import ProductDescription from './components/ProductDescription';
import ProductDetailsPrice from './components/ProductDetailsPrice';
import ProductEmbellishments from './components/ProductEmbellishments';
import ProductsUploadImages from './components/ProductUploadImages';
import TitleEditModal from './components/TitleEditModal';
import styles from './ProductDetails.module.scss';
import { CATALOG_CREATOR_ROUTE, HOBBY_CATALOG_ROUTE } from '../../constants/routes';
import { IMAGES_EXTENSIONS } from '../../constants/customizations';
import { Product, ProductEmbellishment } from '../../models/Product';
import { ProductDetailsProps } from './ProductDetailsProps';
import { Roles } from '../../constants/roles';
import ManufacturerArticleNumber from './components/ManufacturerNumber';
import ChipComponent from '../../components/ChipComponent';
import { ClubTeam } from '../../models/ClubTeam';
import ProductCategory from './components/ProductCategory';

const ProductDetails: FC<ProductDetailsProps> = ({
  club,
  clubId,
  draftFiles,
  allTags,
  allClubTeams,
  loadingTags,
  embellishmentsById,
  fetchCatalog,
  fetchHobbyCatalog,
  fetchClub,
  updateHobbyProduct,
  isHobbyCatalog,
  fetchEmbellishments,
  history,
  loading,
  product,
  clearCatalogData,
  persistSelection,
  removeProduct,
  removeHobbyProduct,
  setDraftFiles,
  fetchTags,
  updateProduct,
  memberships,
  currentlyUsedOrganizationId,
  fetchClubTeams,
}) => {
  const [translate] = useTranslation();
  const productDiscountPercent = get(product, 'discountPercent', null);
  const [userRoles, setUserRoles] = useState<Roles[]>([]);
  const originalPrice = (product && product.originalPrice) || ({ amount: 0, currency: 'EUR' });

  const [tagIds, setTagIds] = useState((product && product.tagIds) || []);
  const [teamIds, setTeamIds] = useState((product && product.exclusiveToTeams) || []);
  const [discountPercent, setDiscountPercent] = useState<number | null>(productDiscountPercent);
  const [description, setDescription] = useState<string>(product ? product.description : '');
  const [customCategory, setCustomCategory] = useState<string | undefined>(
    product ? product.customCategory : undefined,
  );
  const [title, setTitle] = useState<string>(product ? product.name : '');
  const [embellishments, setEmbellishments] = useState<ProductEmbellishment[]>(
    (product && product.embellishments) || [],
  );
  const [images] = useMemo(() => partition(
    draftFiles, ({ extension }) => !!IMAGES_EXTENSIONS.find(i => i.toLowerCase() === `${extension}`.toLowerCase()),
  ), [draftFiles]);
  const backLink = isHobbyCatalog ? HOBBY_CATALOG_ROUTE : `${CATALOG_CREATOR_ROUTE.replace(':clubId', String(clubId))}`;

  const {
    id: productId = null,
    files: productFiles = [],
    originalImages = [],
    manufacturerArticleNumber = '',
  } = product || {};

  useEffect(() => { fetchTags(); }, [fetchTags]);
  useEffect(() => { fetchClubTeams({ clubId, pagination: false }); }, [clubId, fetchClubTeams]);

  useEffect(() => {
    if (!product) {
      if (isHobbyCatalog) {
        fetchHobbyCatalog();
      } else if (clubId) {
        fetchCatalog(clubId);
      }
    }
  }, [isHobbyCatalog, fetchCatalog, fetchHobbyCatalog, product, clubId]);

  const isUserSalesRep = useMemo(
    () => userRoles.includes(Roles.SALES_REPRESENTATIVE), [userRoles],
  );

  useEffect(() => () => {
    clearCatalogData();
  }, [clearCatalogData]);

  useEffect(() => {
    if (!isHobbyCatalog && isUserSalesRep && (!club || club.isIncomplete)) {
      fetchClub(clubId!);
    }
  }, [fetchClub, isHobbyCatalog, club, clubId, userRoles, isUserSalesRep]);

  useEffect(() => {
    setDraftFiles(productFiles);
  }, [productId, setDraftFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!embellishmentsById.length) {
      fetchEmbellishments();
    }
  }, [fetchEmbellishments, embellishmentsById]);

  const handleTagClick = (tagId: number) => setTagIds(xor(tagIds, [tagId]));

  const handleTeamClick = (teamId: number) => setTeamIds(xor(teamIds, [teamId]));

  useEffect(() => {
    if (currentlyUsedOrganizationId) {
      setUserRoles(memberships[currentlyUsedOrganizationId] || []);
    }
  }, [memberships, currentlyUsedOrganizationId]);

  const handleSaveDraft = useCallback(() => {
    persistSelection();
    if (product) {
      const newProduct: Product = {
        ...product,
        name: title,
        tagIds,
        files: draftFiles,
        embellishments,
        description,
        discountPercent,
        exclusiveToTeams: teamIds,
        customCategory,
      };
      if (isHobbyCatalog) {
        updateHobbyProduct(newProduct);
      } else {
        updateProduct(clubId!, newProduct);
      }
    }
  }, [
    clubId, description, draftFiles, embellishments, discountPercent, persistSelection,
    product, tagIds, updateProduct, title, teamIds, isHobbyCatalog, updateHobbyProduct,
    customCategory,
  ]);

  const getActiveTeams = (): ClubTeam[] => {
    if (allClubTeams && allClubTeams.length) {
      const activeTeams = allClubTeams.filter(team => team.status === 'ACTIVE');
      if (activeTeams.length) {
        return activeTeams;
      }
    }
    return [];
  };

  const handleRemoveItem = useCallback(() => {
    if (product) {
      if (isHobbyCatalog) {
        removeHobbyProduct(product.id);
      } else {
        removeProduct(clubId!, product.id);
      }
    }
    history.push(backLink);
  }, [removeProduct, removeHobbyProduct, clubId, product, history, isHobbyCatalog, backLink]);

  const renderActionButtons = useCallback(() => [
    isUserSalesRep && (
    <Popup
      key="product_not_removable"
      content={translate('PRODUCT_NOT_REMOVABLE')}
      disabled={!(product && product.productUsedInActiveOrder)}
      trigger={(
        <div>
          <Confirm
            confirmText={translate('ARE_YOU_SURE_TO_REMOVE_PRODUCT')}
            content={translate('REMOVE_ITEM')}
            renderPopup
            popupOnDisabled
            popupText="PRODUCT_REMOVAL_USED_IN_ORDER_ERROR"
            inverted
            key="remove_button"
            onConfirm={handleRemoveItem}
            disabled={product && product.productUsedInActiveOrder}
            primary
          />
        </div>
      )}
    />
    ),
    <Button
      content={translate('SAVE_IN_DRAFT')}
      key="save_button"
      onClick={handleSaveDraft}
      primary
    />,
  ].filter(Boolean),
  [handleSaveDraft, handleRemoveItem, product, translate, isUserSalesRep]);

  const renderTitle = (
    <div>
      {title}
      {isUserSalesRep && (
        <TitleEditModal title={title} setTitle={setTitle} />
      )}
    </div>
  );

  return product ? (
    <div className={styles.wrapper}>
      <Header
        title={renderTitle}
        // @ts-ignore
        renderButton={renderActionButtons}
        backLinkTo={backLink}
      />
      <div className={styles.contentWrapper}>
        <div>
          <ProductsUploadImages
            images={images}
            originalImages={originalImages}
          />

          {manufacturerArticleNumber && (
            <ManufacturerArticleNumber
              articleNumber={manufacturerArticleNumber}
            />
          )}

          {!isHobbyCatalog && (
          <ProductCategory
            category={product.category}
            customCategory={customCategory}
            setCustomCategory={setCustomCategory}
          />
          )}

          <ProductDescription
            description={description}
            setDescription={setDescription}
            isUserSalesRep={isUserSalesRep}
          />

          <ChipComponent
            header="PROPERTIES"
            entities={allTags}
            selectedEntities={tagIds}
            clickable={isUserSalesRep}
            onClick={handleTagClick}
            loading={loadingTags}
            noEntriesText="NO_TAGS"
          />

          {!isHobbyCatalog && (
            <ChipComponent
              header="TEAMS"
              entities={getActiveTeams()}
              selectedEntities={teamIds}
              clickable={isUserSalesRep}
              onClick={handleTeamClick}
              noEntriesText="NO_TEAMS"
            />
          )}

          <Divider />

          {embellishmentsById.length && (
            <ProductEmbellishments
              clubId={clubId}
              isHobbyCatalog={isHobbyCatalog}
              embellishments={embellishments}
              setEmbellishments={setEmbellishments}
              isUserSalesRep={isUserSalesRep}
            />
          )}
        </div>
        <div>
          {isUserSalesRep && embellishmentsById.length && (
            <ProductDetailsPrice
              isHobbyCatalog={isHobbyCatalog}
              discountPercent={discountPercent}
              embellishments={embellishments}
              onDiscountPercentChange={setDiscountPercent}
              originalPrice={originalPrice}
              clubId={clubId}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.wrapper}>
      {loading
        ? <Dimmer active inverted><Loader /></Dimmer>
        : <Header title={translate('PRODUCT.NOT_FOUND')} backLinkTo={backLink} />
      }
    </div>
  );
};

export default ProductDetails;
