import { AjaxError } from 'rxjs/ajax';
import {
  FetchClubTeamsErrorAction,
  FetchClubTeamsRequestAction,
  FetchClubTeamsRequestPayload,
  FetchClubTeamsSuccessAction,
} from '../../models/actionTypes';
import {
  FETCH_CLUB_TEAMS_ERROR,
  FETCH_CLUB_TEAMS_REQUEST,
  FETCH_CLUB_TEAMS_SUCCESS,
} from '../../constants/actionTypes';
import { ClubTeam } from '../../models/ClubTeam';

export const fetchClubTeamsRequest = (
  payload: FetchClubTeamsRequestPayload,
): FetchClubTeamsRequestAction => ({
  type: FETCH_CLUB_TEAMS_REQUEST,
  payload,
});

export const fetchClubTeamsSuccess = (
  count: number, teams: ClubTeam[], hasInactiveTeams: boolean,
): FetchClubTeamsSuccessAction => ({
  type: FETCH_CLUB_TEAMS_SUCCESS,
  payload: {
    count,
    teams,
    hasInactiveTeams,
  },
});

export const fetchClubTeamsError = (error: AjaxError): FetchClubTeamsErrorAction => ({
  type: FETCH_CLUB_TEAMS_ERROR,
  payload: {
    error,
  },
});
