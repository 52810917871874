import React, { FC } from 'react';
import classNames from 'classnames';

import { v4 as uuidv4 } from 'uuid';
import AvatarWithPopup from '../AvatarWithPopup';
import getFullName from '../../../utils/getFullName';
import styles from './ClubSalesRepsCell.module.scss';
import { ClubAdmin } from '../../../models/Club';
import { ClubSalesRepsCellProps } from './ClubSalesRepsCellProps';

const ClubSalesRepsCell: FC<ClubSalesRepsCellProps> = ({ clubSalesReps }) => {
  const hasOneAdmin = clubSalesReps && clubSalesReps.length === 1;

  return (
    <div className={classNames(styles.avatarsContainer, hasOneAdmin && styles.noWrap)}>
      {clubSalesReps && clubSalesReps.length ? clubSalesReps.map((user: ClubAdmin) => (
        <AvatarWithPopup user={user} key={user.personId || uuidv4()} />
      )) : null}
      {hasOneAdmin && (<span>{getFullName(clubSalesReps[0])}</span>)}
    </div>
  );
};

export default ClubSalesRepsCell;
