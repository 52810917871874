import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import ClubsRow from './ClubsRow';
import { deleteClubRequest } from '../../../../actions/clubs/deleteClub';
import { State } from '../../../../models/State';

const mapStateToProps = ({
  application: {
    memberships, clubId, organizationType,
  },
}: State) => ({
  userRoles: clubId ? memberships[clubId] : [],
  organizationType,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteClub: bindActionCreators(deleteClubRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClubsRow);
