import { Actions } from '../models/actionTypes';
import {
  CREATE_TEAM_ERROR,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS, DEACTIVATE_INVITE_TOKEN_ERROR,
  DEACTIVATE_INVITE_TOKEN_REQUEST, DEACTIVATE_INVITE_TOKEN_SUCCESS,
  FETCH_CLUB_TEAMS_ERROR,
  FETCH_CLUB_TEAMS_REQUEST,
  FETCH_CLUB_TEAMS_SUCCESS,
  FETCH_TEAM_INVITE_TOKEN_REQUEST,
  FETCH_TEAM_INVITE_TOKEN_SUCCESS, GENERATE_INVITE_TOKEN_ERROR,
  GENERATE_INVITE_TOKEN_REQUEST, GENERATE_INVITE_TOKEN_SUCCESS,
} from '../constants/actionTypes';
import { ClubTeam, ClubTeamReducer, ClubTeamsByHash } from '../models/ClubTeam';

export const initialState: ClubTeamReducer = {
  byHash: {},
  byId: [],
  activeTeams: [],
  count: 0,
  loading: false,
  loadingTeams: false,
  bulkAssignLoading: false,
  error: false,
  errorMessage: '',
  inviteToken: null,
  inviteTokenError: false,
  loadingInviteToken: false,
  tokenDeactivated: false,
  hasInactiveTeams: false,
  editionSuccessful: false,
};

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case CREATE_TEAM_REQUEST:
    case FETCH_CLUB_TEAMS_REQUEST:
      return {
        ...state,
        loadingTeams: true,
      };
    case FETCH_CLUB_TEAMS_SUCCESS: {
      const { count, teams, hasInactiveTeams } = action.payload;

      return {
        ...state,
        byHash: teams
          .reduce((byHash: ClubTeamsByHash, team: ClubTeam) => ({
            ...byHash,
            [team.id]: team,
          }), state.byHash),
        byId: teams.map(({ id }: ClubTeam) => id),
        hasInactiveTeams,
        loadingTeams: false,
        count,
      };
    }
    case CREATE_TEAM_ERROR:
    case FETCH_CLUB_TEAMS_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
        loadingTeams: false,
      };
    }
    case CREATE_TEAM_SUCCESS: {
      const { team } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        byId: [
          ...state.byId.filter(id => id !== team.id),
          team.id,
        ],
        byHash: { ...state.byHash, [team.id]: team },
      };
    }
    case FETCH_TEAM_INVITE_TOKEN_REQUEST:
    case GENERATE_INVITE_TOKEN_REQUEST:
    case DEACTIVATE_INVITE_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        inviteToken: null,
        loadingInviteToken: true,
        tokenDeactivated: false,
      };
    case FETCH_TEAM_INVITE_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        inviteToken: action.payload.token,
        loadingInviteToken: false,
        inviteTokenError: false,
        tokenDeactivated: false,
      };
    case GENERATE_INVITE_TOKEN_ERROR:
    case DEACTIVATE_INVITE_TOKEN_ERROR:
      return {
        ...state,
        inviteToken: null,
        loadingInviteToken: false,
        inviteTokenError: true,
        tokenDeactivated: false,
      };
    case GENERATE_INVITE_TOKEN_SUCCESS:
      return {
        ...state,
        inviteToken: action.payload.token,
        loadingInviteToken: false,
        inviteTokenError: false,
        tokenDeactivated: false,
        loading: false,
      };
    case DEACTIVATE_INVITE_TOKEN_SUCCESS:
      return {
        ...state,
        inviteToken: null,
        loadingInviteToken: false,
        inviteTokenError: false,
        tokenDeactivated: true,
      };
    default:
      return state;
  }
};
