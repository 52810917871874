import { Team } from './Team';
import { EntityScoreDto } from './Rewards';
import { Address } from './Address';
import { SalesOrganization } from './Organizations';
import { ExternalSystemReference } from './ExternalSystems';

export interface ClubAdmin {
  userId?: number;
  personId?: number;
  firstName: string;
  lastName: string;
}

export interface ClubLightweight {
  id: number;
  name: string;
}

export interface ClubBase extends ClubLightweight {
  address: Address;
  note?: string;
  phoneNumber?: string;
  faxNumber?: string;
  email?: string;
  websiteAddress?: string;
  websiteEventsAddress?: string;
  websiteNewsAddress?: string;
  primaryColor?: string;
  socialMedia: {
    facebookUrl?: string;
    instagramUrl?: string;
    snapchatUrl?: string;
  },
  externalId?: string;
}

export interface Club extends ClubBase {
  organizationId?: string;
  customerId?: string;
  teamsCount?: number;
  playerCount?: number;
  hasCatalog?: boolean;
  clubAdmins?: ClubAdmin[];
  pendingClubAdmins?: ClubAdmin[];
  teams?: Team[];
  logoUrl?: string;
  totalPlayersNo?: number;
  isIncomplete?: boolean;
  clubScore: EntityScoreDto;
  salesOrganization?: SalesOrganization;
  externalSystemReferences?: ExternalSystemReference[];
  clubSalesId?: string;
}

export interface ClubDataResponse extends ClubBase {
  organizationId: string;
  teamsCount: number;
  playerCount: number;
  hasCatalog: boolean;
  clubAdmins: ClubAdmin[];
  clubScore: EntityScoreDto;
}

export interface ClubDetailsResponse extends ClubBase {
  logoUrl: string;
  teams: Team[];
  totalPlayersNo: number;
  clubScore: EntityScoreDto;
}

export interface ClubsByHash {
  [key: string]: Club;
}

export interface ClubReducer {
  byHash: ClubsByHash;
  byId: number[];
  count: number;
  loading: boolean;
  loadingList: boolean;
  error: boolean;
  errorMessage: string;
  loadingUpload: boolean;
  clubLogoUploaded: boolean;
}

export interface ClubEditRequest {
  name?: string;
  note?: string;
  address?: Address;
  displayName?: string;
  phoneNumber?: string;
  faxNumber?: string;
  email?: string;
  websiteAddress?: string;
  websiteEventsAddress?: string;
  websiteNewsAddress?: string;
  primaryColor?: string;
  customerId?: string;
  clubSalesId?: string;
  socialMedia?: {
    facebookUrl?: string;
    instagramUrl?: string;
    snapchatUrl?: string;
  };
}

export enum ClubType {
  OFFICIAL = 'OFFICIAL',
  HOBBY = 'HOBBY',
}

export interface ClubImageResponse {
  clubId: number;
  publicUrl: string;
}
