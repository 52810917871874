import React, { useState, FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Header, Form, Button, Message,
} from 'semantic-ui-react';

import AuthenticationFormsWrapper from '../../components/AuthenticationFormsWrapper';
import FormFields from '../../components/FormFields';
import styles from './Login.module.scss';
import useFocusFirstInput from '../../hooks/useFocusFirstInput';
import useForm from '../../hooks/useForm';
import { LoginProps } from './LoginProps';
import { REQUIRED_ROLES } from '../../constants/customizations';
import { RESET_PASSWORD } from '../../constants/routes';
import { signInSchema, validationSchema } from './schema';

const Login: FC<LoginProps> = ({ signIn, loading, error }) => {
  const [translate] = useTranslation();
  const [formPristine, setFormPristine] = useState(true);
  const {
    values,
    errors,
    handleChange,
    validate,
  } = useForm(validationSchema);

  useFocusFirstInput();

  const handleSubmit = () => {
    if (validate()) {
      const { email, password } = values;
      signIn(email, password, REQUIRED_ROLES);
      setFormPristine(false);
    }
  };

  return (
    <AuthenticationFormsWrapper>
      <Header as="h2" content={translate('LOGIN')} />
      <Form onSubmit={handleSubmit} loading={loading} className={styles.formWrapper}>
        <FormFields
          schema={signInSchema}
          errors={errors}
          values={values}
          handleChange={handleChange}
        />

        {!formPristine && error && <Message size="mini" header={translate('INVALID_CREDENTIALS')} negative />}

        <Button
          primary
          type="submit"
          content={translate('LOGIN')}
          fluid
        />

        <Button
          as={Link}
          basic
          className="simple"
          content={translate('FORGOT_PASSWORD')}
          to={RESET_PASSWORD}
        />
      </Form>
    </AuthenticationFormsWrapper>
  );
};

export default Login;
