import i18next from 'i18next';
import { FormSchema } from '../../../../models/Form';
import { Roles } from '../../../../constants/roles';

const getOptions = (values: string[], translate: i18next.TFunction) => values.map(value => ({
  key: value as string,
  text: translate(`${value}`) as string,
  value: value as string,
}));

export const promotionSchema: FormSchema = ({
  'club.name': {
    type: 'text',
    label: 'CLUB_NAME',
    disabled: true,
  },
  'team.name': {
    type: 'text',
    label: 'TEAM_NAME',
    disabled: true,
  },
  actualRoleInClub: {
    type: 'select',
    label: 'REQUESTOR_ROLE',
    clearable: false,
    options: (translate: i18next.TFunction) => getOptions(
      [Roles.CLUB_ADMIN, Roles.PLAYER, Roles.TEAM_LEAD],
      translate,
    ),
  },
});

export const promotionManagerSchema: FormSchema = ({
  'clubAdminContactDetails.firstName': {
    type: 'text',
    label: 'FIRST_NAME',
  },
  'clubAdminContactDetails.lastName': {
    type: 'text',
    label: 'LAST_NAME',
  },
  'clubAdminContactDetails.email': {
    type: 'text',
    label: 'EMAIL',
  },
  'clubAdminContactDetails.phoneNumber': {
    type: 'text',
    label: 'PHONE_NUMBER',
  },
});
