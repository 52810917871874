import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Form, Modal, TextArea,
} from 'semantic-ui-react';

import styles from './DescriptionEditModal.module.scss';
import { DescriptionEditModalProps } from './DescriptionEditModalProps';

const FileUploadModal: FC<DescriptionEditModalProps> = ({ description, setDescription }) => {
  const [translate] = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState<string>(description);

  const onChange = useCallback(
    (event, data) => setValue(String(data.value)),
    [],
  );

  const onOpen = useCallback(() => (
    setModalOpen(true)
  ), []);

  const onClose = useCallback(() => {
    setValue(description);
    setModalOpen(false);
  }, [setValue, description]);

  const onSubmit = useCallback(() => {
    setDescription(value);
    setModalOpen(false);
  }, [setDescription, value]);

  return (
    <Modal
      closeIcon
      onClose={onClose}
      open={modalOpen}
      trigger={(
        <Button
          basic
          className="simple"
          content={translate('EDIT_DESCRIPTION')}
          onClick={onOpen}
        />
      )}
    >
      <Modal.Header content={translate('EDIT_DESCRIPTION')} />
      <Modal.Content scrolling>
        <Form>
          <Form.Field>
            <label>{translate('PRODUCT.DESCRIPTION')}</label>
            <TextArea
              onChange={onChange}
              rows={10}
              value={value}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions className={styles.actionsContainer}>
        <Button
          basic
          circular
          content={translate('CANCEL')}
          onClick={onClose}
          primary
        />
        <Button
          circular
          content={translate('SAVE')}
          onClick={onSubmit}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
};

export default FileUploadModal;
