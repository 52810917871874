import { Roles } from './roles';

export const CATALOGS_LIST_LIMIT = 10;
export const CLUBS_LIST_LIMIT = 10;
export const TEAM_ORDERS_LIST_LIMIT = 10;
export const INDIVIDUAL_ORDERS_LIST_LIMIT = 10;
export const TEAM_BASKET_ORDERS_LIST_LIMIT = 10;
export const CLUB_ORDERS_LIST_LIMIT = 10;
export const TEAM_REQUESTS_LIST_LIMIT = 10;
export const TEAMS_LIST_LIMIT = 10;
export const PLAYERS_LIST_LIMIT = 10;
export const MAX_TEAMS_LIST_LIMIT = 50;

export const USER_PASSWORD_MIN_LENGTH = 8;
export const TRANSITION_DURATION = 500;
export const DEFAULT_LIST_LIMIT = 10;
export const APP_NAME = 'ntsCrm';
export const IMAGES_EXTENSIONS = ['jpg', 'png', 'svg', 'jpeg'];
export const FILES_EXTENSIONS = ['pdf', 'doc', 'docx'];
export const EXCEL_EXTENSIONS = ['xls', 'xlsx'];
export const LANG = 'de-DE';
export const DEFAULT_IMAGE_ASPECT = 0.8333;

export const ERROR_NOTIFICATION_DISPLAY_TIME = 5000;

export const REQUIRED_ROLES = [Roles.SALES_REPRESENTATIVE, Roles.GRAPHIC];

export const TABLE_PAGE_URL_PARAM = 'page';
export const TABLE_SEARCH_URL_PARAM = 'search';
export const TABLE_SORT_FIELD_URL_PARAM = 'field';
export const TABLE_SORT_ORDER_URL_PARAM = 'order';
export const TABLE_FILTER_URL_PARAM = 'filter';
