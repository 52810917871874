import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { handleError } from '../../actions/errors/handleError';
import { addNotification } from '../../actions/notifications/addNotification';
import { rejectPromotionError, rejectPromotionSuccess } from '../../actions/teamRequests/RejectPromotionRequest';
import { REJECT_PROMOTION_ERROR, REJECT_PROMOTION_REQUEST, REJECT_PROMOTION_SUCCESS } from '../../constants/actionTypes';
import { REJECT_PROMOTION_PATH } from '../../constants/paths';
import { NotificationType, RejectPromotionRequestAction } from '../../models/actionTypes';
import { State } from '../../models/State';

const rejectPromotionRequest = (
  action$: ActionsObservable<RejectPromotionRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(REJECT_PROMOTION_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      teamId,
      promotionRequestId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: REJECT_PROMOTION_PATH.replace('$teamId', `${teamId}`),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, REJECT_PROMOTION_SUCCESS),
      rejectPromotionSuccess(promotionRequestId),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, REJECT_PROMOTION_ERROR),
      rejectPromotionError(error),
    ]),
  )),
);

export default rejectPromotionRequest;
