import React from 'react';
import { Action } from 'redux';
import { Button, Form, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import FormFields from '../../../../components/FormFields';
import styles from '../RequestTokenForm/RequestTokenForm.module.scss';
import useFocusFirstInput from '../../../../hooks/useFocusFirstInput';
import useForm from '../../../../hooks/useForm';
import { updatePasswordSchema, validationSchema } from './schema';

type Props = {
  errorMessage: string;
  loading: boolean;
  resetPasswordRequest: (username: string, password: string, token: string) => Action,
  success: boolean;
  token: string;
  username: string;
};

const UpdatePasswordForm: React.FC<Props> = ({
  errorMessage, loading, resetPasswordRequest, success, token, username,
}) => {
  const [translate] = useTranslation();
  const {
    values,
    errors,
    handleChange,
    validate,
  } = useForm(validationSchema);

  useFocusFirstInput();

  const handleSubmit = () => {
    if (validate()) {
      resetPasswordRequest(username, values.password, token);
    }
  };

  return success ? (
    <Message
      positive
      header={translate('SUCCESS')}
      content={translate('PASSWORD_HAS_BEEN_CHANGED')}
    />
  ) : (
    <Form onSubmit={handleSubmit} loading={loading}>
      <div className={styles.formWrapper}>
        <FormFields
          errors={errors}
          handleChange={handleChange}
          schema={updatePasswordSchema}
          values={values}
        />
      </div>

      {errorMessage && (
        <Message size="mini" negative header={translate('INVALID_DATA_PROVIDED')} />
      )}

      <Button primary fluid content={translate('RESET_PASSWORD')} />
    </Form>
  );
};

export default UpdatePasswordForm;
