import { OrderActions } from '../models/actionTypes';
import { OrderList, OrdersByHash, OrdersListReducer } from '../models/Order';
import {
  FETCH_TEAM_ORDERS_REQUEST,
  FETCH_TEAM_ORDERS_SUCCESS,
  FETCH_TEAM_ORDERS_ERROR,
  DELETE_ORDERS_ERROR,
  DELETE_ORDERS_REQUEST,
  DELETE_ORDERS_SUCCESS,
} from '../constants/actionTypes';

export const initialState: OrdersListReducer = {
  byHash: {},
  byId: [],
  count: 0,
  loading: false,
  error: false,
  ordersDeleted: false,
};

export default (state = initialState, action: OrderActions) => {
  switch (action.type) {
    case FETCH_TEAM_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
        ordersDeleted: false,
      };
    case DELETE_ORDERS_SUCCESS: {
      const updatedById = state.byId.filter(
        (id: number) => !action.payload.orderIds.includes(id),
      );
      const updatedByHash = updatedById.reduce((byHash: any, itemId: number) => ({
        ...byHash,
        [itemId]: state.byHash[itemId],
      }), {});

      return ({
        ...state,
        loading: false,
        byId: updatedById,
        byHash: updatedByHash,
        ordersDeleted: true,
      });
    }
    case FETCH_TEAM_ORDERS_SUCCESS: {
      const { orders, count } = action.payload;

      return {
        ...state,
        byHash: orders
          .reduce((byHash: OrdersByHash, order: OrderList) => ({
            ...byHash,
            [order.id]: order,
          }), state.byHash),
        byId: orders.map(({ id }: OrderList) => id),
        loading: false,
        count,
      };
    }
    case DELETE_ORDERS_ERROR:
    case FETCH_TEAM_ORDERS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
