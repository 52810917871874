import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { push } from 'connected-react-router';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';

import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { UPDATE_CLUB_ERROR, UPDATE_CLUB_REQUEST, UPDATE_CLUB_SUCCESS } from '../../constants/actionTypes';
import { CLUBS_PATH } from '../../constants/paths';
import { Club } from '../../models/Club';
import { CLUB_DETAILS_ROUTE } from '../../constants/routes';
import { NotificationType, UpdateClubRequestAction } from '../../models/actionTypes';
import { updateClubError, updateClubSuccess } from '../../actions/clubs/updateClub';
import { addNotification } from '../../actions/notifications/addNotification';
import { handleError } from '../../actions/errors/handleError';
import { saveExternalReference } from '../../actions/externalSystems/saveExternalReference';
import { ExternalReferencedEntityType, ExternalSystemName } from '../../models/ExternalSystems';
import { deleteExternalReferencesRequest } from '../../actions/externalSystems/deleteExternalSystemReference';

const shouldAttachSocialMedia = (obj?: Object) => {
  const pickedBy = pickBy(obj);

  return !isEmpty(pickedBy);
};

const processExternalRef = (club: Club, externalSystemRefId?: string) => {
  const externalClubRef = club.externalSystemReferences
    ? club.externalSystemReferences.find(
      ref => ref.entityType === ExternalReferencedEntityType.CLUB,
    )
    : undefined;
  if ((!externalClubRef && externalSystemRefId)
      || (
        (externalClubRef && externalSystemRefId)
          && (externalClubRef.externalSystemEntityId !== externalSystemRefId)
      )) {
    return saveExternalReference({
      entityId: club.id,
      externalSystemEntityId: externalSystemRefId,
      externalSystemName: ExternalSystemName.TOR_24,
      entityType: ExternalReferencedEntityType.CLUB,
    });
  }
  if (externalClubRef && !externalSystemRefId) {
    return deleteExternalReferencesRequest(externalClubRef.externalSystemName, club.id.toString());
  }
  return false;
};

const updateClub = (
  action$: ActionsObservable<UpdateClubRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(UPDATE_CLUB_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      updatedClub, club, externalClubId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: `${CLUBS_PATH}/${club.id}`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(pickBy({
      ...updatedClub,
      socialMedia: shouldAttachSocialMedia(updatedClub.socialMedia)
          && pickBy(updatedClub.socialMedia),
    })),
  }).pipe(
    mergeMap(({ response }: Response<Club>) => [
      addNotification(NotificationType.SUCCESS, UPDATE_CLUB_SUCCESS),
      updateClubSuccess(response),
      processExternalRef(club, externalClubId),
      push(CLUB_DETAILS_ROUTE.replace(':clubId', String(club.id))),
    ].filter(Boolean)),
    catchError((error: AjaxError) => [
      handleError(error, UPDATE_CLUB_ERROR),
      updateClubError(error),
    ]),
  )),
);

export default updateClub;
