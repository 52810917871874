import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import serializeTeam from '../../utils/serializers/serializeTeam';
import { FETCH_TEAM_ERROR, FETCH_TEAM_REQUEST } from '../../constants/actionTypes';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { FetchTeamRequestAction } from '../../models/actionTypes';
import { fetchTeamsError, fetchTeamSuccess } from '../../actions/clubTeams/fetchClubTeam';
import { handleError } from '../../actions/errors/handleError';
import { ClubTeam } from '../../models/ClubTeam';
import { TEAM_PATH } from '../../constants/paths';

export default (
  action$: ActionsObservable<FetchTeamRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_TEAM_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { id, clubId } },
    {
      authentication: { authToken },
    },
  ]) => ajax({
    url: `${TEAM_PATH
      .replace('$clubId', `${clubId}`)}`
      .replace('$teamId', `${id}`),
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: team }: Response<ClubTeam>) => serializeTeam(team)),
    map(fetchTeamSuccess),
    catchError(error => [
      handleError(error, FETCH_TEAM_ERROR),
      fetchTeamsError(error),
    ]),
  )),
);
