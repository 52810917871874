import React, { FC } from 'react';

type SortArrowProps = {
  fill: string;
};

const SortArrow: FC<SortArrowProps> = ({ fill }) => (
  <svg width="7px" height="5px" viewBox="0 0 7 5" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Vereine" transform="translate(-385.000000, -294.000000)" fill={fill} fillRule="nonzero">
        <path d="M391.411469,298.615275 L385.588531,298.615275 C385.065377,298.615275 384.803201,297.900015 385.17312,297.48278 L388.086983,294.195154 C388.187606,294.071838 388.340301,294 388.501796,294 C388.663291,294 388.815986,294.071838 388.916608,294.195154 L391.82688,297.48278 C392.196799,297.900015 391.934623,298.615275 391.411469,298.615275 Z" id="sort-asc-icon" />
      </g>
    </g>
  </svg>
);

export default SortArrow;
