import pickBy from 'lodash/pickBy';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { handleError } from '../../actions/errors/handleError';
import { approvePromotionError, approvePromotionSuccess } from '../../actions/teamRequests/ApprovePromotionRequest';
import { convertToOfficial } from '../../actions/teamRequests/ConvertToOfficial';
import { APPROVE_PROMOTION_ERROR, APPROVE_PROMOTION_REQUEST } from '../../constants/actionTypes';
import { APPROVE_PROMOTION_PATH } from '../../constants/paths';
import { ApprovePromotionRequestAction } from '../../models/actionTypes';
import { State } from '../../models/State';

const approvePromotionRequest = (
  action$: ActionsObservable<ApprovePromotionRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(APPROVE_PROMOTION_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      teamId,
      approveRequest,
      promotionRequestId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: APPROVE_PROMOTION_PATH.replace('$teamId', `${teamId}`),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(pickBy({
      ...approveRequest,
    })),
  }).pipe(
    mergeMap(() => [
      approvePromotionSuccess(
        promotionRequestId,
        !!approveRequest.salesRepOrganizationId,
        !!approveRequest.officialClubId,
      ),
    ]),
    catchError((error: AjaxError) => {
      // Special handling => we don't really know why this error is triggering sometimes
      // In case it occurs, we just convert the hobby team to a real club
      if (error.status === 404
          && error.message === 'invitation.user_with_email_already_exists_in_club') {
        return [
          convertToOfficial(
            teamId,
            approveRequest.salesRepOrganizationId as number,
            approveRequest.officialClubId as number,
          ),
        ];
      }
      return [
        handleError(error, APPROVE_PROMOTION_ERROR),
        approvePromotionError(error),
      ];
    }),
  )),
);

export default approvePromotionRequest;
