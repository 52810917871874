import { useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import params from '../../utils/params';
import { TABLE_SEARCH_URL_PARAM } from '../../constants/customizations';

export default (history: any) => {
  const { setParam } = params(history);
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const toggleFilter = () => setFilterPanelVisible(!filterPanelVisible);

  const debouncedSetSearch = useMemo(() => debounce(value => {
    setParam(TABLE_SEARCH_URL_PARAM, value);
  }, 500), [setParam]);

  const onChange = (value: string) => {
    debouncedSetSearch(value);
  };

  return [
    toggleFilter as () => {},
    onChange as (value: string) => void,
    filterPanelVisible as boolean,
  ];
};
