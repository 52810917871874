import get from 'lodash/get';

import {
  Catalog, EmbellishmentFixedPriceDiscountType, HobbyCatalog, TeamDiscountsType,
} from '../../models/Catalog';
import { Embellishment } from '../../models/Embellishment';
import { Price } from '../../models/Price';
import { ProductEmbellishment } from '../../models/Product';
import { calculatePriceWithQuantity } from '../PricingUtil';

const getFixedPrice = (
  groupId: number | null,
  embellishment: Embellishment | null,
  embellishmentFixedPriceDiscounts: EmbellishmentFixedPriceDiscountType[],
) => {
  const discount = embellishmentFixedPriceDiscounts
    .find(fixedPriceDiscount => (
      fixedPriceDiscount.groupId === groupId
          && fixedPriceDiscount.embellishment === (embellishment && embellishment.type)
    ));
  return get(discount, 'price.amount', null);
};

// returns quantity aware embellishment discounted price
// if global or team embellishment discount for specific item was defined
const calculatePriceForNonFixedPriceDiscounts = (
  teamId: number | null,
  teamEmbellishmentsDiscounts: TeamDiscountsType[],
  embellishmentsDiscountPercent: number,
  embellishmentPrice: Price | null,
  embellishmentQuantity: number,
) => {
  const teamDiscount = teamEmbellishmentsDiscounts.find(
    ({ teamId: id }) => teamId === id,
  );
  const discountPercent = get(teamDiscount, 'discountPercent', embellishmentsDiscountPercent);
  const { amount = 0 } = embellishmentPrice || {};

  return calculatePriceWithQuantity(amount, embellishmentQuantity, discountPercent);
};

// returns quantity aware embellishment fixed price if such discount is defined
const calculatePriceForFixedPriceDiscounts = (
  clubId: number | null,
  teamId: number | null,
  embellishment: Embellishment | null,
  embellishmentFixedPriceDiscounts: EmbellishmentFixedPriceDiscountType[],
  productEmbellishment?: ProductEmbellishment | null,
) => {
  const embellishmentQuantity = get(productEmbellishment, 'quantity', 1);
  let fixedPrice = 0;
  const fixedPriceDefined = get(productEmbellishment, 'fixedPricePerUnit.amount', null);

  if (fixedPriceDefined || fixedPriceDefined === 0) {
    fixedPrice = fixedPriceDefined;
  } else {
    fixedPrice = getFixedPrice(teamId, embellishment, embellishmentFixedPriceDiscounts)
      || getFixedPrice(clubId, embellishment, embellishmentFixedPriceDiscounts);
  }

  return (fixedPrice || fixedPrice === 0)
    ? calculatePriceWithQuantity(fixedPrice, embellishmentQuantity) : null;
};

const calculateEmbellishmentPrice = (
  clubId: number | null,
  teamId: number | null,
  embellishment: Embellishment | null,
  catalog?: Catalog | HobbyCatalog,
  productEmbellishment?: ProductEmbellishment | null,
) => {
  const embellishmentQuantity = get(productEmbellishment, 'quantity', 1);
  const { price = null } = embellishment || {};
  const {
    embellishmentsDiscountPercent = 0,
    teamEmbellishmentsDiscounts = [],
    embellishmentFixedPriceDiscounts = [],
  } = catalog || {};

  const fixedPriceDiscount = calculatePriceForFixedPriceDiscounts(
    clubId,
    teamId,
    embellishment,
    embellishmentFixedPriceDiscounts,
    productEmbellishment,
  );

  if (fixedPriceDiscount || fixedPriceDiscount === 0) {
    return fixedPriceDiscount;
  }

  return calculatePriceForNonFixedPriceDiscounts(
    teamId,
    teamEmbellishmentsDiscounts,
    embellishmentsDiscountPercent,
    price,
    embellishmentQuantity,
  );
};

export default calculateEmbellishmentPrice;
