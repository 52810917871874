import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { FETCH_INDIVIDUAL_ORDERS_ERROR, FETCH_INDIVIDUAL_ORDERS_REQUEST } from '../../constants/actionTypes';
import { FetchIndividualOrdersRequest } from '../../models/actionTypes';
import { ListResponse, Response } from '../../models/Response';
import { INDIVIDUAL_ORDERS_LIST } from '../../constants/paths';
import { State } from '../../models/State';
import { INDIVIDUAL_ORDERS_LIST_LIMIT } from '../../constants/customizations';
import { handleError } from '../../actions/errors/handleError';
import { fetchIndividualOrdersError, fetchIndividualOrdersSuccess } from '../../actions/individualOrders/fetchIndividualOrders';
import { IndividualOrderListItem } from '../../models/IndividualOrder';
import { orderTypeFilterParser } from '../../utils/filterParsers/orderTypeFilterParser';

const fetchOrders = (
  action$: ActionsObservable<FetchIndividualOrdersRequest>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_INDIVIDUAL_ORDERS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    {
      payload:
                      {
                        page, field, order, status, search, clubId, filter,
                      },
    },
    {
      authentication: { authToken },
    },
  ]) => {
    const query = queryString.stringify(pickBy({
      offset: ((page || 1) - 1) * INDIVIDUAL_ORDERS_LIST_LIMIT,
      limit: INDIVIDUAL_ORDERS_LIST_LIMIT,
    }));
    const filters = orderTypeFilterParser(filter);
    return ajax({
      url: `${INDIVIDUAL_ORDERS_LIST}?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify({
        ...((field || order) && ({ sort: { field, order } })),
        ...(status && ({ status })),
        ...(search && ({ search })),
        ...(clubId && ({ clubId })),
        ...(filters || {}),
      }),
    }).pipe(
      map(({ response: { data, count } }: Response<ListResponse<IndividualOrderListItem>>) => (
        fetchIndividualOrdersSuccess({ orders: data, count })
      )),
      catchError(error => ([
        handleError(error, FETCH_INDIVIDUAL_ORDERS_ERROR),
        fetchIndividualOrdersError(error),
      ])),
    );
  }),
);

export default fetchOrders;
