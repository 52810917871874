import { AjaxError } from 'rxjs/ajax';

import { Club } from '../../models/Club';
import { FETCH_CLUBS_ERROR, FETCH_CLUBS_REQUEST, FETCH_CLUBS_SUCCESS } from '../../constants/actionTypes';
import { FetchClubsRequestAction, FetchClubsSuccessAction, FetchClubsErrorAction } from '../../models/actionTypes';

const fetchClubsDefaultPayload = {
  page: 1,
  search: '',
};

export const fetchClubsRequest = (payload = {}): FetchClubsRequestAction => ({
  type: FETCH_CLUBS_REQUEST,
  payload: {
    ...fetchClubsDefaultPayload,
    ...payload,
  },
});

export const fetchClubsSuccess = (
  payload: { clubs: Club[], count: number },
): FetchClubsSuccessAction => ({
  type: FETCH_CLUBS_SUCCESS,
  payload,
});

export const fetchClubsError = (error: AjaxError): FetchClubsErrorAction => ({
  type: FETCH_CLUBS_ERROR,
  payload: {
    error,
  },
});
