import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { FETCH_TEAM_REQUESTS, FETCH_TEAM_REQUESTS_ERROR } from '../../constants/actionTypes';
import { FetchTeamRequestsAction } from '../../models/actionTypes';
import { ListResponse, Response } from '../../models/Response';
import { HOBBY_TEAM_REQUESTS } from '../../constants/paths';
import { State } from '../../models/State';
import { TEAM_REQUESTS_LIST_LIMIT } from '../../constants/customizations';
import { handleError } from '../../actions/errors/handleError';
import { fetchTeamRequestsSuccess, fetchTeamRequestsError } from '../../actions/teamRequests/FetchTeamRequests';
import { TeamRequest } from '../../models/TeamRequests';

const fetchTeamRequests = (
  action$: ActionsObservable<FetchTeamRequestsAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_TEAM_REQUESTS),
  withLatestFrom(state$),
  mergeMap(([
    {
      payload: {
        page, field, order, search,
      },
    },
    {
      authentication: { authToken },
    },
  ]) => {
    const query = queryString.stringify(pickBy({
      offset: ((page || 1) - 1) * TEAM_REQUESTS_LIST_LIMIT,
      limit: TEAM_REQUESTS_LIST_LIMIT,
    }));

    return ajax({
      url: `${HOBBY_TEAM_REQUESTS}?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify({
        ...((field && order) && ({ sort: { field, order } })),
        ...search && ({ search }),
      }),
    }).pipe(
      map(({ response: { data, count } }: Response<ListResponse<TeamRequest>>) => (
        fetchTeamRequestsSuccess(data, count)
      )),
      catchError(error => ([
        handleError(error, FETCH_TEAM_REQUESTS_ERROR),
        fetchTeamRequestsError(error),
      ])),
    );
  }),
);

export default fetchTeamRequests;
