import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import CreateTeam from './CreateTeam';
import { State } from '../../models/State';
import { CreateTeamProps, DispatchProps } from './CreateTeamProps';
import { createTeamRequest } from '../../actions/clubTeams/createTeam';

const mapStatesToProps = ({
  clubTeams: { error, loadingTeams },

}: State, { match: { params: { clubId } } }: CreateTeamProps) => ({
  loading: loadingTeams,
  error,
  clubId,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  createTeam: bindActionCreators(createTeamRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(withRouter(CreateTeam));
