import React, { FC } from 'react';
import classNames from 'classnames';
import { Button, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { HeaderTypes, HeaderProps } from './HeaderProps';
import styles from './Header.module.scss';

const HeaderComponent: FC<HeaderProps> = ({
  backLinkTo,
  backLabel = 'CANCEL',
  border,
  renderButton,
  title,
  type = HeaderTypes.H2,
}) => {
  const [translate] = useTranslation();

  return (
    <div className={classNames(styles.wrapper, type === HeaderTypes.H2 && styles.wrapperPadded)}>
      <div className={classNames(styles.container, border && styles.border)}>
        <div className={classNames(!renderButton && styles.wide)}>
          {!!backLinkTo && (
            <div className={styles.backLinkWrapper}>
              <Button
                as={Link}
                basic
                className="simple"
                content={translate(backLabel)}
                icon="caret left"
                primary
                to={backLinkTo}
              />
            </div>
          )}
          <Header as={type} content={title} />
        </div>
        <div className={styles.rightContainer}>
          {!!renderButton && renderButton()}
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
