import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { DELETE_ORDERS_REQUEST, DELETE_ORDERS_SUCCESS, DELETE_ORDERS_ERROR } from '../../constants/actionTypes';
import { DeleteOrdersRequestAction, NotificationType } from '../../models/actionTypes';
import { ORDERS } from '../../constants/paths';
import { State } from '../../models/State';
import { handleError } from '../../actions/errors/handleError';
import { deleteOrdersSuccess, deleteOrdersError } from '../../actions/orders/deleteOrders';
import { addNotification } from '../../actions/notifications/addNotification';

const deleteOrders = (
  action$: ActionsObservable<DeleteOrdersRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(DELETE_ORDERS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { orderIds } },
    {
      authentication: { authToken },
    },
  ]) => ajax({
    url: ORDERS,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      orderIds,
    }),
  }).pipe(
    mergeMap(() => ([
      deleteOrdersSuccess(orderIds),
      addNotification(NotificationType.SUCCESS, DELETE_ORDERS_SUCCESS),
    ])),
    catchError(error => ([
      handleError(error, DELETE_ORDERS_ERROR),
      deleteOrdersError(error),
    ])),
  )),
);

export default deleteOrders;
