import { AjaxError } from 'rxjs/ajax';
import {
  CREATE_CATALOG_REQUEST,
  CREATE_CATALOG_SUCCESS,
  CREATE_CATALOG_ERROR,
} from '../../constants/actionTypes';
import { Catalog } from '../../models/Catalog';
import {
  CreateCatalogRequestAction,
  CreateCatalogSuccessAction,
  CreateCatalogErrorAction,
} from '../../models/actionTypes';

export const createCatalogRequest = (
  clubId: number,
  catalog: Catalog,
): CreateCatalogRequestAction => ({
  type: CREATE_CATALOG_REQUEST,
  payload: {
    clubId,
    catalog,
  },
});

export const createCatalogSuccess = (
  clubId: number,
): CreateCatalogSuccessAction => ({
  type: CREATE_CATALOG_SUCCESS,
  payload: {
    clubId,
  },
});

export const createCatalogError = (error: AjaxError): CreateCatalogErrorAction => ({
  type: CREATE_CATALOG_ERROR,
  payload: {
    error,
  },
});
