import React, { FC, useEffect, useState } from 'react';
import includes from 'lodash/includes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button, Dimmer, Image, Label, Loader,
} from 'semantic-ui-react';

import PriceDiscountsPopup from '../../../ProductDetails/components/PriceDiscountsPopup';
import styles from './ProductCard.module.scss';
import { CATALOG_PRODUCT_ROUTE, HOBBY_CATALOG_PRODUCT_ROUTE } from '../../../../constants/routes';
import { IMAGES_EXTENSIONS } from '../../../../constants/customizations';
import { ProductCardProps } from './ProductCardProps';
import { formatPrice } from '../../../../utils/PricingUtil';
import HobbyProductPrice from '../../../ProductDetails/components/HobbyProductPrice';
import { ProductEmbellishment } from '../../../../models/Product';
import { Embellishment } from '../../../../models/Embellishment';

const ProductCard: FC<ProductCardProps> = ({
  clubId,
  clubDiscountPercent,
  isHobbyCatalog = false,
  fetchFile,
  updateProduct,
  filesByHash,
  product,
  product: {
    id, externalId, name, originalPrice, originalImages, files, manufacturerArticleNumber,
    discountPercent: productDiscountPercent, embellishments: productEmbellishments,
  },
  embellishmentsByHash,
}) => {
  const [translate] = useTranslation();
  const [status] = useState<boolean>(true);
  const productInClubCatalogPath = CATALOG_PRODUCT_ROUTE.replace(':clubId', String(clubId)).replace(':id', String(id));
  const productInHobbyCatalogPath = HOBBY_CATALOG_PRODUCT_ROUTE.replace(':id', String(id));
  const hasPdfFiles = !!files.find(({ extension }) => IMAGES_EXTENSIONS.indexOf(extension) === -1);
  const image = files.find(({ extension }) => includes(
    IMAGES_EXTENSIONS.map(ex => ex.toLowerCase()), extension.toLowerCase(),
  ));

  const mapEmbellishment = ({ embellishment }: ProductEmbellishment) => (
    embellishmentsByHash[embellishment]
  );
  const mandatoryEmbellishments: Embellishment[] = productEmbellishments
    .filter(({ mandatory }) => mandatory).map(mapEmbellishment);

  useEffect(() => {
    if (image) {
      fetchFile(image.id, image.extension);
    }
  }, [fetchFile, image]);

  const deactivate = () => {
    if (clubId) {
      updateProduct(clubId, { ...product, productCatalogStatus: 'INACTIVE' });
    }
  };
  const reactivate = () => {
    if (clubId) {
      updateProduct(clubId, { ...product, productCatalogStatus: 'ACTIVE' });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.imageContainer}>
        {!!image && (
          filesByHash[image.id]
            ? <Image src={filesByHash[image.id]} className={styles.image} />
            : <Dimmer active inverted><Loader /></Dimmer>
        )}
        {!image && originalImages && (
          <Image src={originalImages[0]} className={styles.image} />
        )}
      </div>

      <div className={styles.descriptionContainer}>
        <p className={styles.id}>
          {`${translate('ID')}: ${externalId}`}
        </p>
        <p className={status ? styles.complete : styles.incomplete}>
          {translate(status ? 'COMPLETE' : 'INCOMPLETE')}
        </p>
      </div>

      <div className={styles.manufacturerNumberWrapper}>
        <span>{`${translate('MANUFACTURER_ARTICLE_NUMBER_SHORT')} `}</span>
        <span className={[!manufacturerArticleNumber ? styles.italic : ''].join(' ')}>
          {manufacturerArticleNumber || translate('UNKNOWN')}
        </span>
      </div>

      <p className={styles.text}>
        {name}
      </p>

      <div className={styles.bottomContainer}>
        <div className={styles.pdfLabel}>
          {hasPdfFiles && (<Label color="violet" content="PDF" />)}
        </div>

        <div className={styles.price}>
          {!!clubDiscountPercent && (
            <del>
              {formatPrice(
                originalPrice.amount,
                originalPrice.currency,
              )}
            </del>
          )}

          {isHobbyCatalog ? (
            <HobbyProductPrice
              embellishments={mandatoryEmbellishments}
              productEmbellishments={productEmbellishments.filter(({ mandatory }) => mandatory)}
              price={originalPrice}
              productDiscountPercent={productDiscountPercent}
            />
          ) : (
            <PriceDiscountsPopup
              clubId={clubId!}
              embellishments={mandatoryEmbellishments}
              productEmbellishments={productEmbellishments.filter(({ mandatory }) => mandatory)}
              price={originalPrice}
              productDiscountPercent={productDiscountPercent}
              underline={false}
            />
          )}

          {productDiscountPercent !== null && [
            <span key="star">*</span>,
            <span key="footnote" className={styles.footnote}>
              {`*${translate('SPECIAL_PRODUCT_DISCOUNT')}`}
            </span>,
          ]}
        </div>
      </div>
      {(product.productCatalogStatus === 'INACTIVE')
      && (
      <Dimmer className={styles.deactivated}>
        <span>{translate('INACTIVE')}</span>
      </Dimmer>
      )
      }

      <Dimmer className={styles.dimmer}>
        <Button
          as={Link}
          content={translate('EDIT')}
          to={isHobbyCatalog ? productInHobbyCatalogPath : productInClubCatalogPath}
        />
        {clubId && product.productCatalogStatus === 'INACTIVE'
        && (
        <Button
          content={translate('ACTIVATE')}
          onClick={reactivate}
        />
        )
        }
        {clubId && (product.productCatalogStatus === 'ACTIVE' || product.productCatalogStatus === null)
        && (
        <Button
          content={translate('DEACTIVATE')}
          onClick={deactivate}
        />
        )
        }
      </Dimmer>
    </div>
  );
};

export default ProductCard;
