import { AjaxError } from 'rxjs/ajax';
import {
  FETCH_CLUB_ORDERS_ERROR,
  FETCH_CLUB_ORDERS_REQUEST,
  FETCH_CLUB_ORDERS_SUCCESS,
} from '../../constants/actionTypes';
import { OrderList } from '../../models/Order';
import {
  FetchClubOrdersError,
  FetchClubOrdersRequest,
  FetchClubOrdersSuccess,
} from '../../models/actionTypes/ClubOrdersActions';

const fetchOrdersDefaultPayload = {
  page: 1,
  clubId: '',
};

export const fetchClubOrdersRequest = (
  payload = fetchOrdersDefaultPayload,
): FetchClubOrdersRequest => ({
  type: FETCH_CLUB_ORDERS_REQUEST,
  payload,
});

export const fetchClubOrdersSuccess = (
  orders: OrderList[], count: number,
): FetchClubOrdersSuccess => ({
  type: FETCH_CLUB_ORDERS_SUCCESS,
  payload: {
    orders,
    count,
  },
});

export const fetchClubOrdersError = (error: AjaxError): FetchClubOrdersError => ({
  type: FETCH_CLUB_ORDERS_ERROR,
  payload: {
    error,
  },
});
