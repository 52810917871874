import { HobbyCatalog } from '../../models/Catalog';
import { enrichProduct } from './enrichProduct';

const enrichHobbyCatalog = ({
  products,
  productDiscountPercent,
  embellishmentsDiscountPercent,
  embellishmentFixedPriceDiscounts = [],
}: HobbyCatalog) => ({
  products: (products || []).map(enrichProduct),
  productDiscount: {
    generalDiscountPercent: productDiscountPercent,
  },
  embellishmentDiscount: {
    generalDiscountPercent: embellishmentsDiscountPercent,
  },
  embellishmentFixedPriceDiscounts: embellishmentFixedPriceDiscounts
    .filter(({ embellishment, groupId }) => groupId && embellishment)
    .map(({
      embellishment, groupType, groupId, price,
    }) => ({
      embellishment, groupType, groupId, price,
    })),
});

export default enrichHobbyCatalog;
