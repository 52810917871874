import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Form, Input, Modal,
} from 'semantic-ui-react';

import styles from './TitleEditModal.module.scss';
import { TitleEditModalProps } from './TitleEditModalProps';

const FileUploadModal: FC<TitleEditModalProps> = ({ title, setTitle }) => {
  const [translate] = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState<string>(title);

  const onChange = useCallback(
    (event, data) => setValue(String(data.value)),
    [],
  );

  const onOpen = useCallback(() => (
    setModalOpen(true)
  ), []);

  const onClose = useCallback(() => {
    setValue(title);
    setModalOpen(false);
  }, [setValue, title]);

  const onSubmit = useCallback(() => {
    setTitle(value);
    setModalOpen(false);
  }, [setTitle, value]);

  return (
    <Modal
      closeIcon
      onClose={onClose}
      open={modalOpen}
      trigger={(
        <span className={styles.triggerButtonContainer}>
          <Button
            basic
            className={`simple ${styles.triggerButton}`}
            icon="edit outline"
            onClick={onOpen}
          />
        </span>
      )}
    >
      <Modal.Header content={translate('EDIT_TITLE')} />
      <Modal.Content scrolling>
        <Form>
          <Form.Field>
            <label>{translate('PRODUCT.TITLE')}</label>
            <Input onChange={onChange} value={value} fluid />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions className={styles.actionsContainer}>
        <Button
          basic
          circular
          content={translate('CANCEL')}
          onClick={onClose}
          primary
        />
        <Button
          circular
          content={translate('SAVE')}
          onClick={onSubmit}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
};

export default FileUploadModal;
