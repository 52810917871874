import { HobbyCatalog } from '../../models/Catalog';
import { Embellishment } from '../../models/Embellishment';
import { Price } from '../../models/Price';
import { ProductEmbellishment } from '../../models/Product';
import { calculatePrice, sumPrices } from '../PricingUtil';
import calculateEmbellishmentsPrice from './calculateEmbellishmentsPrice';

const calculateHobbyProductPrice = (
  productDiscountPercent: number | null,
  catalogDiscountPercent: number,
  price: Price,
) => {
  const discount = productDiscountPercent
    || catalogDiscountPercent;

  return calculatePrice(price.amount, discount);
};

const calculateProductPricesInHobbyCatalog = (
  embellishments: Embellishment[],
  price: Price,
  productDiscountPercent: number | null,
  catalog?: HobbyCatalog,
  productEmbellishments?: ProductEmbellishment[],
) => {
  const {
    productDiscountPercent: catalogDiscountPercent = 0,
  } = catalog || {};

  const hobbyProductPrice = calculateHobbyProductPrice(
    productDiscountPercent,
    catalogDiscountPercent,
    price,
  );

  const embellishmentsPrice = calculateEmbellishmentsPrice(
    null,
    null,
    embellishments,
    catalog,
    productEmbellishments,
  );

  return sumPrices([hobbyProductPrice, embellishmentsPrice]);
};

export default calculateProductPricesInHobbyCatalog;
