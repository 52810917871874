import { v4 as uuidv4 } from 'uuid';

import {
  ExternalProductResponse, Product,
} from '../../models/Product';

export const serializeProduct = ({
  id, ...rest
}: Product): Product => ({
  id: String(id),
  ...rest,
});

export const serializeExternalProduct = ({
  createdAt, description, externalId, images, name, price, url, producerExternalId, category,
}: ExternalProductResponse): Product => ({
  id: uuidv4(),
  createdAt,
  description,
  discountPercent: null,
  manufacturerArticleNumber: producerExternalId,
  embellishments: [],
  externalId,
  files: [],
  name,
  originalImages: images,
  originalPrice: price,
  tagIds: [],
  url,
  category,
});
