import { AjaxError } from 'rxjs/ajax';
import {
  CreateEmbellishmentValueErrorAction,
  CreateEmbellishmentValueRequestAction,
  CreateEmbellishmentValueSuccessAction,
} from '../../models/actionTypes';
import {
  CREATE_EMBELLISHMENT_VALUE_ERROR,
  CREATE_EMBELLISHMENT_VALUE_REQUEST,
  CREATE_EMBELLISHMENT_VALUE_SUCCESS,
} from '../../constants/actionTypes';
import { EmbellishmentValue } from '../../models/Embellishment';

export const createEmbellishmentValueRequest = (
  clubId: number,
  embellishmentValue: EmbellishmentValue,
): CreateEmbellishmentValueRequestAction => ({
  type: CREATE_EMBELLISHMENT_VALUE_REQUEST,
  payload: { clubId, embellishmentValue },
});

export const createEmbellishmentValueSuccess = (
  embellishmentValue: EmbellishmentValue,
): CreateEmbellishmentValueSuccessAction => ({
  type: CREATE_EMBELLISHMENT_VALUE_SUCCESS,
  payload: { embellishmentValue },
});

export const createEmbellishmentValueError = (
  error: AjaxError,
): CreateEmbellishmentValueErrorAction => ({
  type: CREATE_EMBELLISHMENT_VALUE_ERROR,
  payload: {
    error,
  },
});
