import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom, map,
} from 'rxjs/operators';

import { CHECK_EMAIL_AVAILABILITY } from '../../constants/paths';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { CHECK_EMAIL_AVAILABILITY_REQUEST, CHECK_EMAIL_AVAILABILITY_ERROR } from '../../constants/actionTypes';
import {
  checkEmailAvailabilityError,
  checkEmailAvailabilitySuccess,
} from '../../actions/clubs/checkEmailAvailability';
import {
  CheckEmailAvailabilityRequestAction,
} from '../../models/actionTypes/CheckEmailAvailability';
import EmailAvailabilityResponse from '../../models/EmailAvailabilityResponse';
import { handleError } from '../../actions/errors/handleError';

export default (
  action$: ActionsObservable<CheckEmailAvailabilityRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CHECK_EMAIL_AVAILABILITY_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { email, clubId } }, { authentication: { authToken } }]) => ajax({
    url: CHECK_EMAIL_AVAILABILITY.replace('$clubId', `${clubId}`),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      email,
    }),
  }).pipe(
    map(({ response }: Response<EmailAvailabilityResponse>) => (
      checkEmailAvailabilitySuccess(response)
    )),
    catchError(error => [
      handleError(error, CHECK_EMAIL_AVAILABILITY_ERROR),
      checkEmailAvailabilityError(error),
    ]),
  )),
);
