import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Form, Message,
} from 'semantic-ui-react';
import { Subject } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import styles from './CreateMember.module.scss';
import {
  createSchema, createValidationSchema,
} from './schema';
import { CreateMemberProps } from './CreateMemberProps';
import useForm from '../../hooks/useForm';
import { isValidEmail } from '../../utils/formValidators';
import { CreateInvitation } from '../../models/InvitationData';
import { Roles } from '../../constants/roles';
import Header from '../../components/Header';
import FormFields from '../../components/FormFields';
import { CLUB_TEAMS_ROUTE } from '../../constants/routes';

const CreateMember = ({
  sendInvitation,
  error,
  fetchTeam,
  emailAvailable,
  loading,
  byHash,
  checkEmail,
  checkingEmail,
  match,
}: CreateMemberProps) => {
  const [translate] = useTranslation();
  const [emailChecked, setEmailChecked] = useState(false);
  const [email$] = useState(() => new Subject<string>());
  const { clubId, teamId } = match.params;

  const {
    values: createValues,
    setValues: setCreateValues,
    errors: createErrors,
    handleChange: handleCreateChange,
    validate: validateCreate,
  } = useForm(createValidationSchema);

  useEffect(() => {
    email$.next(createValues.email);
  }, [createValues.email, email$]);

  useEffect(() => {
    const emailChangeListener = email$
      .pipe(
        tap(() => { setEmailChecked(false); }),
        debounceTime(500),
        distinctUntilChanged(),
      ).subscribe((email: string) => {
        if (isValidEmail.rule(email)) {
          setEmailChecked(true);
          checkEmail(email, clubId);
        }
      });

    return () => {
      emailChangeListener.unsubscribe();
    };
  }, [email$, checkEmail, clubId]);

  useEffect(() => {
    if (byHash[teamId]) {
      setCreateValues({});
    }
  }, [byHash, setCreateValues, teamId]);

  const submitInviteMember = () => {
    if (validateCreate()) {
      const {
        firstName, lastName, email,
      } = createValues;
      const invitationPersonalData = { firstName, lastName };
      const createInvitation: CreateInvitation = {
        receiverEmail: email,
        receiverRole: Roles.TEAM_LEAD,
        teamId: +teamId,
        clubId,
      };
      sendInvitation(
        createInvitation,
        invitationPersonalData,
        true,
      );
    }
  };

  useEffect(() => {
    fetchTeam(+teamId, +clubId);
  }, [fetchTeam, teamId, clubId]);

  const renderSaveButton = () => (
    <div>
      <Button
        primary
        content={translate('SAVE')}
        disabled={(checkingEmail || !emailChecked || !emailAvailable)}
        key="button"
        onClick={submitInviteMember}
      />
    </div>
  );
  return (
    <div className={styles.wrapper}>
      <div className={[styles.topGroup, styles.fixHeight].join(' ')}>
        <Header
          backLabel="BACK_TO_TEAM_MEMBERS"
          title={
            translate('ADD_TEAM_MEMBER')
          }
          backLinkTo={CLUB_TEAMS_ROUTE.replace(':clubId', clubId).replace(':teamId', teamId)}
          renderButton={renderSaveButton}
        />
        <div className={styles.contentWrapper}>
          <Form error={error} onSubmit={submitInviteMember} loading={loading} className={styles.form} id="create-form">
            {error && (<Message error={error} header={translate('ERROR')} content={translate('GENERAL_ERROR')} />)}

            <FormFields
              schema={createSchema}
              errors={createErrors}
              handleChange={handleCreateChange}
              values={createValues}
              inline
            />
            {!emailAvailable && !checkingEmail && emailChecked && (
            <Message
              negative
              header={translate('EMAIL_NOT_AVAILABLE_ERROR_HEADER')}
              content={translate('EMAIL_NOT_AVAILABLE_ERROR_TEXT')}
            />
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreateMember;
