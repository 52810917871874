import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { CLUBS_PATH } from '../../constants/paths';
import { Club, ClubDetailsResponse } from '../../models/Club';
import { FETCH_CLUB_ERROR, FETCH_CLUB_REQUEST } from '../../constants/actionTypes';
import { FetchClubRequestAction } from '../../models/actionTypes';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { fetchClubSuccess, fetchClubError } from '../../actions/clubs/fetchClub';
import { serializeClubDetails } from '../../utils/serializers/serializeClub';
import { handleError } from '../../actions/errors/handleError';

export default (
  action$: ActionsObservable<FetchClubRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_CLUB_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { id } }, { authentication: { authToken } }]) => ajax({
    url: `${CLUBS_PATH}/${id}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: club }: Response<ClubDetailsResponse>): Club => serializeClubDetails(club)),
    map(fetchClubSuccess),
    catchError((error: any) => ([
      handleError(error, FETCH_CLUB_ERROR),
      fetchClubError(error),
    ])),
  )),
);
