import { EmbellishmentTypes } from '../models/Embellishment';

const embellishmentsPriceOverride = [{
  type: EmbellishmentTypes.CLUB_NAME_XL,
  priceWithBig11TSLogo: 2.80,
  priceWithSmall11TSLogo: 3.40,
}, {
  type: EmbellishmentTypes.ADVERTISING_PRINT_600_MONOCHROMATIC,
  priceWithBig11TSLogo: 7,
  priceWithSmall11TSLogo: 8.50,
}, {
  type: EmbellishmentTypes.CLUB_EMBLEM_MONOCHROMATIC,
  priceWithBig11TSLogo: 2.45,
  priceWithSmall11TSLogo: 2.98,
}, {
  type: EmbellishmentTypes.SMALL_NUMBERS,
  priceWithBig11TSLogo: 2.45,
  priceWithSmall11TSLogo: 2.98,
}, {
  type: EmbellishmentTypes.ADVERTISING_PRINT_800_MONOCHROMATIC,
  priceWithBig11TSLogo: 8.40,
  priceWithSmall11TSLogo: 10.20,
}, {
  type: EmbellishmentTypes.EMBOSSING,
  priceWithBig11TSLogo: 3.50,
  priceWithSmall11TSLogo: 4.25,
}, {
  type: EmbellishmentTypes.ADVERTISING_PRINT_200_POLYCHROMATIC,
  priceWithBig11TSLogo: 5.60,
  priceWithSmall11TSLogo: 6.80,
}, {
  type: EmbellishmentTypes.CLUB_EMBLEM_POLYCHROMATIC,
  priceWithBig11TSLogo: 3.50,
  priceWithSmall11TSLogo: 4.25,
}, {
  type: EmbellishmentTypes.CLUB_NAME_S,
  priceWithBig11TSLogo: 2.10,
  priceWithSmall11TSLogo: 2.55,
}, {
  type: EmbellishmentTypes.PLAYER_NAME_XL,
  priceWithBig11TSLogo: 3.15,
  priceWithSmall11TSLogo: 3.83,
}, {
  type: EmbellishmentTypes.PLAYER_NAME_S,
  priceWithBig11TSLogo: 2.10,
  priceWithSmall11TSLogo: 2.55,
}, {
  type: EmbellishmentTypes.INITIALS,
  priceWithBig11TSLogo: 2.10,
  priceWithSmall11TSLogo: 2.55,
}, {
  type: EmbellishmentTypes.LARGE_NUMBERS,
  priceWithBig11TSLogo: 3.15,
  priceWithSmall11TSLogo: 3.83,
}, {
  type: EmbellishmentTypes.ADVERTISING_PRINT_400_POLYCHROMATIC,
  priceWithBig11TSLogo: 9.10,
  priceWithSmall11TSLogo: 11.05,
}, {
  type: EmbellishmentTypes.ADVERTISING_PRINT_100_MONOCHROMATIC,
  priceWithBig11TSLogo: 2.45,
  priceWithSmall11TSLogo: 2.98,
}, {
  type: EmbellishmentTypes.SUBLIMATION,
  priceWithBig11TSLogo: 3.50,
  priceWithSmall11TSLogo: 4.25,
}, {
  type: EmbellishmentTypes.ADVERTISING_PRINT_100_POLYCHROMATIC,
  priceWithBig11TSLogo: 3.50,
  priceWithSmall11TSLogo: 4.25,
}, {
  type: EmbellishmentTypes.ADVERTISING_PRINT_200_MONOCHROMATIC,
  priceWithBig11TSLogo: 3.50,
  priceWithSmall11TSLogo: 4.25,
}, {
  type: EmbellishmentTypes.ADVERTISING_PRINT_600_POLYCHROMATIC,
  priceWithBig11TSLogo: 13.30,
  priceWithSmall11TSLogo: 16.15,
}, {
  type: EmbellishmentTypes.STRIPES,
  priceWithBig11TSLogo: 5.60,
  priceWithSmall11TSLogo: 6.80,
}, {
  type: EmbellishmentTypes.INITIALS_NUMBERS,
  priceWithBig11TSLogo: 2.10,
  priceWithSmall11TSLogo: 2.55,
}, {
  type: EmbellishmentTypes.ADVERTISING_PRINT_800_POLYCHROMATIC,
  priceWithBig11TSLogo: 17.50,
  priceWithSmall11TSLogo: 21.25,
}, {
  type: EmbellishmentTypes.ADVERTISING_PRINT_400_MONOCHROMATIC,
  priceWithBig11TSLogo: 4.20,
  priceWithSmall11TSLogo: 5.10,
}];
export default embellishmentsPriceOverride;
