import { CurrencySign } from '../constants/currencySign.enum';

export const sumPrices = (prices: Number[]) => prices
  .map(price => Number(price) * 100)
  .reduce((sum, price) => sum + price, 0)
  / 100;

export const calculatePrice = (price: number = 0, discount: number = 0) => Math.round(
  (100 - discount) * price,
) / 100;

export const calculatePriceWithQuantity = (
  price: number = 0, quantity: number, discount: number = 0,
) => Math.round((100 - discount) * price * quantity) / 100;

export const calculateDiscount = (oldPrice: number, newPrice: number) => (
  (oldPrice - newPrice) / oldPrice
) * 100;

export const formatPrice = (amount: number = 0, currency?: keyof typeof CurrencySign) => (
  `${
    Number(amount)
      .toFixed(2)
      .replace('.', ',')
      .replace(/\d(?=(\d{3})+,)/g, '$&.')
  }\xa0${currency ? CurrencySign[currency] : ''}`
);
