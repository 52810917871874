import { AjaxError } from 'rxjs/ajax';
import {
  SAVE_HOBBY_PRODUCT_FILES_ERROR,
  SAVE_HOBBY_PRODUCT_FILES_REQUEST,
  SAVE_HOBBY_PRODUCT_FILES_SUCCESS,
  UPDATE_HOBBY_PRODUCT,
} from '../../constants/actionTypes';
import {
  SaveHobbyProductFilesErrorAction,
  SaveHobbyProductFilesRequestAction,
  SaveHobbyProductFilesSuccessAction,
  UpdateHobbyProductAction,
} from '../../models/actionTypes';
import { Product } from '../../models/Product';

export const updateHobbyProduct = (product: Product): UpdateHobbyProductAction => ({
  type: UPDATE_HOBBY_PRODUCT,
  payload: {
    product,
  },
});

export const saveHobbyProductFilesRequest = (
  product: Product,
): SaveHobbyProductFilesRequestAction => ({
  type: SAVE_HOBBY_PRODUCT_FILES_REQUEST,
  payload: {
    product,
  },
});

export const saveHobbyProductFilesSuccess = (): SaveHobbyProductFilesSuccessAction => ({
  type: SAVE_HOBBY_PRODUCT_FILES_SUCCESS,
});

export const saveHobbyProductFilesError = (error: AjaxError): SaveHobbyProductFilesErrorAction => ({
  type: SAVE_HOBBY_PRODUCT_FILES_ERROR,
  payload: {
    error,
  },
});
