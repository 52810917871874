import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import CatalogDiscounts from './CatalogDiscounts';
import { CatalogDiscountsProps } from './CatalogDiscountsProps';
import { State } from '../../models/State';
import { fetchCatalogRequest } from '../../actions/catalogs/fetchCatalog';
import { fetchClubRequest } from '../../actions/clubs/fetchClub';
import { updateCatalog } from '../../actions/catalogs/updateCatalog';
import { fetchHobbyCatalogRequest } from '../../actions/catalogs/fetchHobbyCatalog';
import { updateHobbyCatalog } from '../../actions/catalogs/updateHobbyCatalog';
import { HOBBY_CLUBS_ROUTE } from '../../constants/routes';

const mapStateToProps = (
  {
    catalogs: {
      byHash: catalogByHash, loading: catalogLoading, hobbyCatalog,
    },
    clubs: {
      byHash: clubByHash, loading: clubLoading,
    },
  }: State,
  { match: { params: { clubId } }, location: { pathname } }: CatalogDiscountsProps,
) => {
  const isHobbyCatalog = (pathname as string).startsWith(HOBBY_CLUBS_ROUTE);
  const catalog = isHobbyCatalog ? hobbyCatalog : catalogByHash[clubId];
  const club = clubByHash[clubId];

  return {
    catalog,
    isHobbyCatalog,
    club,
    clubId: clubId ? +clubId : null,
    loading: catalogLoading || clubLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchCatalog: fetchCatalogRequest,
  fetchHobbyCatalog: fetchHobbyCatalogRequest,
  fetchClub: fetchClubRequest,
  updateCatalog,
  updateHobbyCatalog,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CatalogDiscounts);
