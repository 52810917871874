import { AjaxError } from 'rxjs/ajax';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import {
  TOR24_TEAMS_ERROR,
  TOR24_TEAMS_REQUEST,
} from '../../constants/actionTypes';
import { TOR24_TEAMS_PATH } from '../../constants/paths';
import { State } from '../../models/State';
import { tor24TeamsError, tor24TeamsSuccess } from '../../actions/externalSystems/tor24Teams';
import { Tor24TeamsRequestAction } from '../../models/actionTypes/Tor24Teams';
import { Tor24Team } from '../../models/tor24Team';
import { handleError } from '../../actions/errors/handleError';

export default (
  action$: ActionsObservable<Tor24TeamsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(TOR24_TEAMS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      tor24ClubId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: TOR24_TEAMS_PATH.replace('$clubId', tor24ClubId),
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap((teams: { response: Tor24Team[] }) => [
      tor24TeamsSuccess(teams),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, TOR24_TEAMS_ERROR),
      tor24TeamsError(error),
    ]),
  )),
);
