import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import TeamBasketOrders from './TeamBasketOrders';
import { State } from '../../models/State';
import { fetchTeamBasketOrdersRequest } from '../../actions/teamBasketOrders/fetchTeamBasketOrders';

const mapStateToProps = ({
  teamBasketOrders: {
    loading,
    byId,
    byHash,
    count,
  },
}: State) => ({
  orders: byId.map(id => byHash[id]),
  loading,
  count,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchOrders: bindActionCreators(fetchTeamBasketOrdersRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamBasketOrders);
