import React, { FC, useMemo } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import UnprotectedRoute from './components/UnprotectedRoute';
import Login from '../Login';
import ClubCatalogs from '../Catalogs';
import PersonalOrders from '../PersonalOrders';
import Clubs from '../Clubs';
import ResetPassword from '../ResetPassword';
import Settings from '../Settings';
import SettingsEdit from '../SettingsEdit';
import menuItems from './menuItems';
import { ViewportProvider } from '../../utils/useViewport';
import { AppProps } from './AppProps';

import {
  SIGN_IN_ROUTE,
  RESET_PASSWORD,
  CATALOG_PRODUCT_ROUTE,
  CLUBS_ROUTE,
  CLUBS_CATALOGS_ROUTE,
  CATALOG_CREATOR_ROUTE,
  CLUB_INVITATION_ROUTE,
  NEW_CLUB_ROUTE,
  PERSONAL_ORDERS_ROUTE,
  CLUB_DETAILS_ROUTE,
  CLUB_EDIT_ROUTE,
  DISCOUNTS_ROUTE,
  TEAM_REQUESTS_ROUTE,
  REWARDS_ROUTE,
  HOBBY_CLUBS_ROUTE,
  ASSIGN_SALES_REP_ROUTE,
  REWARDS_TEAMS_ROUTE,
  HOBBY_CATALOG_ROUTE,
  HOBBY_CATALOG_DISCOUNTS_ROUTE,
  HOBBY_CATALOG_PRODUCT_ROUTE,
  INDIVIDUAL_ORDERS_ROUTE,
  TEAM_BASKET_ORDERS_ROUTE,
  CLUB_INDIVIDUAL_ORDERS_ROUTE,
  CLUB_TEAM_BASKET_ORDERS_ROUTE,
  CLUB_TEAMS_ROUTE,
  CREATE_TEAM_ROUTE,
  CLUB_TEAMS_CREATE_MEMBER_ROUTE,
  CLUB_TEAMS_PENDING_INVITATIONS_ROUTE,
  CLUB_TEAMS_UPDATE_CLUB_ADMIN_TO_TEAM_LEAD_ROUTE,
  EMBELLISHMENT_VALUE_SELECTION_ROUTE,
} from '../../constants/routes';
import CatalogCreator from '../CatalogCreator';
import CreateClub from '../Clubs/components/CreateClub';
import Notifications from './components/Notifications';
import InviteClubAdmin from '../Clubs/components/InviteClubAdmin';
import Layout from '../../components/Layout';
import ProductDetails from '../ProductDetails';
import CatalogDiscounts from '../CatalogDiscounts';
import TeamRequests from '../TeamRequests';
import { OrganizationType } from '../../models/OrganizationType';
import Rewards from '../Rewards';
import HobbyClubs from '../HobbyClubs';
import SalesRepReassign from '../SalesRepReassign';
import TeamsRewards from '../TeamRewards';
import IndividualOrders from '../IndividualOrders';
import TeamBasketOrders from '../TeamBasketOrders';
import ClubIndividualOrders from '../ClubIndividualOrders';
import ClubTeamBasketOrders from '../ClubTeamBasketOrders';
import ClubTeams from '../ClubTeams';
import CreateTeam from '../CreateTeam';
import CreateMember from '../CreateMember';
import PendingInvitations from '../PendingInvitations';
import AddTeamLeadRoleToClubAdmin from '../AddTeamLeadRoleToClubAdmin';
import EmbellishmentsValueSelection from '../EmbellishmentValueSelection';

const App: FC<AppProps> = ({
  signOut, menuExpanded, setMenuExpanded, userRoles, organizationType,
}) => {
  const filteredMenuItems = useMemo(
    () => menuItems(userRoles, organizationType),
    [userRoles, organizationType],
  );

  const renderProtected = (Component: any) => (props: any) => (
    <Layout
      signOut={signOut}
      menuExpanded={menuExpanded}
      setMenuExpanded={setMenuExpanded}
      menuItems={filteredMenuItems}
    >
      <Component {...props} />
    </Layout>
  );

  return (
    <>
      <ViewportProvider>
        <Switch>
          <UnprotectedRoute exact path={SIGN_IN_ROUTE} component={Login} />
          <UnprotectedRoute exact path={RESET_PASSWORD} component={ResetPassword} />
          <ProtectedRoute
            path={ASSIGN_SALES_REP_ROUTE}
            render={renderProtected(SalesRepReassign)}
          />
          <ProtectedRoute path={CLUB_EDIT_ROUTE} render={renderProtected(SettingsEdit)} />
          <ProtectedRoute path={CLUB_DETAILS_ROUTE} render={renderProtected(Settings)} />
          <ProtectedRoute
            path={CLUB_INDIVIDUAL_ORDERS_ROUTE}
            render={renderProtected(ClubIndividualOrders)}
          />
          <ProtectedRoute
            path={CLUB_TEAM_BASKET_ORDERS_ROUTE}
            render={renderProtected(ClubTeamBasketOrders)}
          />
          <ProtectedRoute
            path={CLUB_TEAMS_ROUTE}
            render={renderProtected(ClubTeams)}
          />
          <ProtectedRoute
            path={CLUB_TEAMS_CREATE_MEMBER_ROUTE}
            render={renderProtected(CreateMember)}
          />
          <ProtectedRoute
            path={CLUB_TEAMS_UPDATE_CLUB_ADMIN_TO_TEAM_LEAD_ROUTE}
            render={renderProtected(AddTeamLeadRoleToClubAdmin)}
          />
          <ProtectedRoute
            path={CLUB_TEAMS_PENDING_INVITATIONS_ROUTE}
            render={renderProtected(PendingInvitations)}
          />
          <ProtectedRoute
            path={CREATE_TEAM_ROUTE}
            render={renderProtected(CreateTeam)}
          />
          <ProtectedRoute path={CLUBS_CATALOGS_ROUTE} render={renderProtected(ClubCatalogs)} />
          <ProtectedRoute exact path={NEW_CLUB_ROUTE} render={renderProtected(CreateClub)} />
          <ProtectedRoute path={CLUB_INVITATION_ROUTE} render={renderProtected(InviteClubAdmin)} />
          <ProtectedRoute
            path={TEAM_BASKET_ORDERS_ROUTE}
            render={renderProtected(TeamBasketOrders)}
          />
          <ProtectedRoute path={PERSONAL_ORDERS_ROUTE} render={renderProtected(PersonalOrders)} />
          <ProtectedRoute
            path={INDIVIDUAL_ORDERS_ROUTE}
            render={renderProtected(IndividualOrders)}
          />
          <ProtectedRoute path={DISCOUNTS_ROUTE} render={renderProtected(CatalogDiscounts)} />
          <ProtectedRoute path={CATALOG_PRODUCT_ROUTE} render={renderProtected(ProductDetails)} />
          <ProtectedRoute path={CATALOG_CREATOR_ROUTE} render={renderProtected(CatalogCreator)} />
          <ProtectedRoute
            path={EMBELLISHMENT_VALUE_SELECTION_ROUTE}
            render={renderProtected(EmbellishmentsValueSelection)}
          />
          <ProtectedRoute
            exact
            path={HOBBY_CATALOG_ROUTE}
            render={renderProtected(CatalogCreator)}
          />
          <ProtectedRoute
            path={HOBBY_CATALOG_PRODUCT_ROUTE}
            render={renderProtected(ProductDetails)}
          />
          <ProtectedRoute
            path={HOBBY_CATALOG_DISCOUNTS_ROUTE}
            render={renderProtected(CatalogDiscounts)}
          />
          <ProtectedRoute path={REWARDS_TEAMS_ROUTE} render={renderProtected(TeamsRewards)} />
          <ProtectedRoute path={REWARDS_ROUTE} render={renderProtected(Rewards)} />
          <ProtectedRoute path={HOBBY_CLUBS_ROUTE} render={renderProtected(HobbyClubs)} />
          <ProtectedRoute path={CLUBS_ROUTE} render={renderProtected(Clubs)} />
          {
            organizationType === OrganizationType.MASTER && (
              <ProtectedRoute path={TEAM_REQUESTS_ROUTE} render={renderProtected(TeamRequests)} />
            )
          }
          <Redirect to={CLUBS_ROUTE} />
        </Switch>
        <Notifications />
      </ViewportProvider>
    </>
  );
};

export default App;
