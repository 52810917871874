import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { EmbellishmentValue } from '../../models/Embellishment';
import {
  CREATE_EMBELLISHMENT_VALUE_ERROR,
  CREATE_EMBELLISHMENT_VALUE_REQUEST,
} from '../../constants/actionTypes';
import {
  CreateEmbellishmentValueRequestAction,
} from '../../models/actionTypes';
import { State } from '../../models/State';
import { handleError } from '../../actions/errors/handleError';
import { EMBELLISHMENTS_VALUES_PATH } from '../../constants/paths';
import { Response } from '../../models/Response';
import {
  createEmbellishmentValueError,
  createEmbellishmentValueSuccess,
} from '../../actions/catalogs/createEmbellishmentValue';
import { fetchEmbellishmentValuesRequest } from '../../actions/catalogs/fetchEmbellishmentValues';

export default (
  action$: ActionsObservable<CreateEmbellishmentValueRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CREATE_EMBELLISHMENT_VALUE_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: { clubId, embellishmentValue },
  }, { authentication: { authToken } }]) => ajax({
    url: `${EMBELLISHMENTS_VALUES_PATH.replace('$clubId', `${clubId}`)}`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(embellishmentValue),
  }).pipe(
    mergeMap(({ response: embellishmentVal }: Response<EmbellishmentValue>) => [
      fetchEmbellishmentValuesRequest(clubId),
      createEmbellishmentValueSuccess(embellishmentVal),
    ]),
    catchError((error: AjaxError) => ([
      handleError(error, CREATE_EMBELLISHMENT_VALUE_ERROR),
      createEmbellishmentValueError(error),
    ])),
  )),
);
