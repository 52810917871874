import pickBy from 'lodash/pickBy';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';
import { push } from 'connected-react-router';

import { CLUBS_PATH } from '../../constants/paths';
import { CLUBS_ROUTE } from '../../constants/routes';
import { CREATE_CLUB_ERROR, CREATE_CLUB_REQUEST, CREATE_CLUB_SUCCESS } from '../../constants/actionTypes';
import { CreateClubRequestAction, NotificationType } from '../../models/actionTypes';
import { State } from '../../models/State';
import { addNotification } from '../../actions/notifications/addNotification';
import { createClubError, createClubSuccess } from '../../actions/clubs/createClub';
import { saveExternalReference } from '../../actions/externalSystems/saveExternalReference';
import { handleError } from '../../actions/errors/handleError';
import { sendInvitationRequest } from '../../actions/invitations';
import { ExternalReferencedEntityType, ExternalSystemName } from '../../models/ExternalSystems';

export default (
  action$: ActionsObservable<CreateClubRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CREATE_CLUB_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      name, location, salesRepOrganizationId,
      createInvitation, invitationPersonalData,
      externalClubId, customerId, clubSalesId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: CLUBS_PATH,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(pickBy({
      name, salesRepOrganizationId, customerId, address: { displayName: location }, clubSalesId,
    })),
  }).pipe(
    mergeMap(({ response: createdClub }) => {
      const createInvitationWithClubId = { ...createInvitation, clubId: createdClub.id };
      const invitationPersonalDataWithClubName = {
        ...invitationPersonalData, clubName: createdClub.name,
      };

      return [
        createClubSuccess(createdClub),
        addNotification(NotificationType.SUCCESS, CREATE_CLUB_SUCCESS),
        externalClubId && saveExternalReference({
          entityId: createdClub.id,
          externalSystemEntityId: externalClubId,
          entityType: ExternalReferencedEntityType.CLUB,
          externalSystemName: ExternalSystemName.TOR_24,
        }),
        createInvitationWithClubId.receiverEmail
          ? sendInvitationRequest(createInvitationWithClubId, invitationPersonalDataWithClubName)
          : push(CLUBS_ROUTE),
      ].filter(Boolean);
    }),
    catchError((error: AjaxError) => ([
      handleError(error, CREATE_CLUB_ERROR),
      createClubError(error),
    ])),
  )),
);
