import { CatalogUpdate } from '../../models/Catalog';
import { UPDATE_CATALOG } from '../../constants/actionTypes';
import { UpdateCatalogAction } from '../../models/actionTypes';

export const updateCatalog = (clubId: number, catalog: CatalogUpdate): UpdateCatalogAction => ({
  type: UPDATE_CATALOG,
  payload: {
    clubId,
    catalog,
  },
});
