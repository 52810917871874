import queryString from 'query-string';
import React, {
  FC, SyntheticEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  Button, DropdownOnSearchChangeData, Menu,
} from 'semantic-ui-react';
import Header from '../../../../components/Header';
import { Roles } from '../../../../constants/roles';
import {
  CATALOG_CREATOR_ROUTE,
  CLUBS_CATALOGS_ROUTE,
  CLUBS_ROUTE,
  DISCOUNTS_ROUTE, EMBELLISHMENT_VALUE_SELECTION_ROUTE,
  HOBBY_CATALOG_DISCOUNTS_ROUTE,
  HOBBY_CATALOG_ROUTE,
  PARAM_CATALOGS,
} from '../../../../constants/routes';
import { Catalog } from '../../../../models/Catalog';
import CopyCatalog from '../CopyCatalog';
import styles from './CatalogHeader.module.scss';
import { CatalogHeaderProps } from './CatalogHeaderProps';

const INITIAL_CLUB_FILTER_LIMIT = 50;

const CatalogHeader: FC<CatalogHeaderProps> = ({
  clubId,
  catalog,
  createCatalog,
  createHobbyCatalog,
  fetchCatalog,
  fetchHobbyCatalog,
  fetchClubs,
  clubsById,
  loading,
  clubsByHash,
  isHobbyCatalog,
  copyCatalog,
  memberships,
  currentlyUsedOrganizationId,
  history: { location: { search, pathname } },
}) => {
  const [translate] = useTranslation();
  const [clubSearch$] = useState(() => new Subject<string>());
  const { from } = queryString.parse(search);
  const [copyCatalogModalOpened, setCopyCatalogModalOpened] = useState(false);
  const [selectedClubId, setSelectedClubId] = useState<number | null>(null);
  const [userRoles, setUserRoles] = useState<Roles[]>([]);

  const linkWithParams = (value: string) => value.replace(':clubId', String(clubId));
  const productsLink = isHobbyCatalog ? HOBBY_CATALOG_ROUTE : linkWithParams(CATALOG_CREATOR_ROUTE);
  const discountsLink = isHobbyCatalog
    ? HOBBY_CATALOG_DISCOUNTS_ROUTE : linkWithParams(DISCOUNTS_ROUTE);
  const embellishmentsTypesRoute = linkWithParams(EMBELLISHMENT_VALUE_SELECTION_ROUTE);
  const backLinkTo = from === PARAM_CATALOGS ? CLUBS_CATALOGS_ROUTE : CLUBS_ROUTE;
  const {
    products = [], isDraft = false,
  } = catalog || {};

  const onSave = useCallback(() => {
    if (catalog) {
      if (isHobbyCatalog) {
        createHobbyCatalog(catalog);
      } else {
        createCatalog(clubId!, catalog as Catalog);
      }
    }
  }, [isHobbyCatalog, createHobbyCatalog, createCatalog, clubId, catalog]);

  const onClear = useCallback(() => {
    if (isHobbyCatalog) {
      fetchHobbyCatalog();
    } else {
      fetchCatalog(clubId!);
    }
  }, [isHobbyCatalog, fetchHobbyCatalog, fetchCatalog, clubId]);

  useEffect(() => {
    if (currentlyUsedOrganizationId) {
      setUserRoles(memberships[currentlyUsedOrganizationId] || []);
    }
  }, [memberships, currentlyUsedOrganizationId]);

  const isUserSalesRepresentative = useMemo(
    () => userRoles.includes(Roles.SALES_REPRESENTATIVE), [userRoles],
  );

  const handleCopyCatalog = () => {
    copyCatalog(clubId!, selectedClubId!);
  };

  const handleCopyCatalogModalClose = () => {
    setCopyCatalogModalOpened(opened => !opened);
  };

  const renderButtons = () => (isUserSalesRepresentative ? (
    <>
      {!isHobbyCatalog && <Button content={translate('COPY_CATALOG')} basic onClick={handleCopyCatalogModalClose} />}
      {isDraft && <Button content={translate('CLEAR_CHANGES')} basic onClick={onClear} /> }
      {isDraft && <Button content={translate('RELEASE_TO_CLUB')} primary onClick={onSave} disabled={!products.length} />}
    </>
  ) : null);

  const clubsOptions = useMemo(
    () => clubsById.map(id => clubsByHash[id]).map(club => ({
      value: club.id,
      text: club.name,
    })).filter(item => item.value !== clubId), [clubsById, clubsByHash, clubId],
  );

  useEffect(() => {
    if (!isHobbyCatalog) {
      fetchClubs({ limit: INITIAL_CLUB_FILTER_LIMIT });
    }
  }, [fetchClubs, isHobbyCatalog]);

  useEffect(() => {
    const searchListener = clubSearch$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
      ).subscribe((value: string) => {
        fetchClubs({ search: value, limit: INITIAL_CLUB_FILTER_LIMIT });
      });

    return () => {
      searchListener.unsubscribe();
    };
  }, [clubSearch$, fetchClubs]);

  const onSearchChange = useCallback((
    event: SyntheticEvent<HTMLElement, Event>, data: DropdownOnSearchChangeData,
  ) => {
    clubSearch$.next(data.searchQuery);
  }, [clubSearch$]);

  return (
    <div>
      <Header
        backLinkTo={isHobbyCatalog ? null : backLinkTo}
        backLabel={isUserSalesRepresentative ? 'SAVE_DRAFT_AND_GO_BACK' : 'BACK'}
        // @ts-ignore
        renderButton={renderButtons}
        title={isHobbyCatalog ? translate('HOBBY_CATALOG') : `${translate('CATALOG_FOR')} ${(catalog as Catalog).clubName}`}
      />
      {copyCatalogModalOpened && clubId && (
        <CopyCatalog
          selectedClubId={selectedClubId}
          setSelectedClubId={setSelectedClubId}
          clubsOptions={clubsOptions}
          handleCopyCatalog={handleCopyCatalog}
          handleCopyCatalogModalClose={handleCopyCatalogModalClose}
          originalClubName={clubsByHash[clubId] ? clubsByHash[clubId].name : ''}
          onSearchChange={onSearchChange}
          loading={loading}
        />
      )}
      <div className={styles.container}>
        <Menu className="filter" pointing secondary>
          <Menu.Item
            active={pathname === productsLink}
            as={Link}
            content={translate('PRODUCTS')}
            to={`${productsLink}${search}`}
          />
          { isUserSalesRepresentative && (
            <Menu.Item
              active={pathname === discountsLink}
              as={Link}
              content={translate('DISCOUNTS')}
              to={`${discountsLink}${search}`}
            />
          )}
          {!isHobbyCatalog && (
          <Menu.Item
            active={pathname === embellishmentsTypesRoute}
            as={Link}
            content={translate('PREDEFINED_EMBELLISHMENT_VALUES')}
            to={`${embellishmentsTypesRoute}`}
          />
          )}
        </Menu>
      </div>
    </div>
  );
};

export default CatalogHeader;
