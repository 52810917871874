import {
  FETCH_EMBELLISHMENT_VALUES_ERROR,
  FETCH_EMBELLISHMENT_VALUES_REQUEST,
  FETCH_EMBELLISHMENT_VALUES_SUCCESS,
  FETCH_EMBELLISHMENTS_REQUEST,
  FETCH_EMBELLISHMENTS_SUCCESS,
} from '../constants/actionTypes';
import { Actions } from '../models/actionTypes';
import { SIGN_OUT_ERROR, SIGN_OUT_SUCCESS } from '../packages/authentication/constants';
import { Embellishment, EmbellishmentsByHash, EmbellishmentsReducer } from '../models/Embellishment';

export const initialState: EmbellishmentsReducer = {
  embellishmentsByHash: {},
  embellishmentsById: [],
  loadingEmbellishments: false,
  embellishmentValues: [],
  loadingEmbellishmentValues: false,
  fetchEmbellishmentValuesError: false,
};

export default (
  state = initialState,
  action: Actions,
) => {
  switch (action.type) {
    case FETCH_EMBELLISHMENTS_SUCCESS: {
      const { embellishments } = action.payload;
      return {
        ...state,
        loadingEmbellishments: false,
        embellishmentsByHash: embellishments
          .reduce((embellishmentsByHash: EmbellishmentsByHash, embellishment: Embellishment) => ({
            ...embellishmentsByHash,
            [embellishment.type]: embellishment,
          }), state.embellishmentsByHash),
        embellishmentsById: embellishments.map(({ type }: Embellishment) => type),
      };
    }
    case FETCH_EMBELLISHMENT_VALUES_SUCCESS: {
      const { embellishmentValues } = action.payload;
      return {
        ...state,
        loadingEmbellishmentValues: false,
        embellishmentValues: embellishmentValues.embellishmentSelectOptions,
      };
    }
    case FETCH_EMBELLISHMENTS_REQUEST: {
      return {
        ...state,
        loadingEmbellishments: true,
      };
    }
    case FETCH_EMBELLISHMENT_VALUES_REQUEST: {
      return {
        ...state,
        loadingEmbellishmentValues: true,
      };
    }
    case FETCH_EMBELLISHMENT_VALUES_ERROR: {
      return {
        ...state,
        loadingEmbellishmentValues: false,
        fetchEmbellishmentValuesError: true,
      };
    }
    case SIGN_OUT_SUCCESS:
    case SIGN_OUT_ERROR:
      return initialState;
    default:
      return state;
  }
};
