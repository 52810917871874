import React, { FC } from 'react';
import { Button, Header, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import ProfileAvatar from '../ProfileAvatar';
import styles from './AvatarWithPopup.module.scss';
import { ClubAdmin } from '../../../models/Club';

interface AvatarWithPopupProps {
  linkTo?: string;
  user: ClubAdmin;
  italic?: boolean;
}

const AvatarWithPopup: FC<AvatarWithPopupProps> = ({ user, linkTo, italic }) => {
  const [translate] = useTranslation();

  return (
    <Popup
      flowing
      hoverable
      key={user.personId || uuidv4()}
      position="bottom left"
      trigger={<ProfileAvatar user={user} italic={italic} />}
    >
      <div className={styles.popupContent}>
        <ProfileAvatar user={user} size="medium" />
        <div>
          <Header as="h3" content={`${user.firstName} ${user.lastName}`} />
          {linkTo && (
            <Button
              as={Link}
              basic
              compact
              content={translate('VIEW_PROFILE')}
              floated="right"
              primary
              size="mini"
              to={linkTo}
            />
          )}
        </div>
      </div>
    </Popup>
  );
};

export default AvatarWithPopup;
