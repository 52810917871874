import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { handleError } from '../../actions/errors/handleError';
import { fetchHobbyClubsError, fetchHobbyClubsSuccess } from '../../actions/hobbyClubs/fetchHobbyClubs';
import { FETCH_HOBBY_CLUBS_ERROR, FETCH_HOBBY_CLUBS_REQUEST } from '../../constants/actionTypes';
import { CLUBS_LIST_LIMIT } from '../../constants/customizations';
import { CLUBS_PATH } from '../../constants/paths';
import { FetchHobbyClubsAction } from '../../models/actionTypes';
import { ClubType } from '../../models/Club';
import { HobbyClub } from '../../models/HobbyClub';
import { ListResponse, Response } from '../../models/Response';
import { State } from '../../models/State';

export default (
  action$: ActionsObservable<FetchHobbyClubsAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_HOBBY_CLUBS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      page, field, order, search,
    },
  },
  { authentication: { authToken } },
  ]) => {
    const query = queryString.stringify(pickBy({
      offset: ((page || 1) - 1) * CLUBS_LIST_LIMIT,
      limit: CLUBS_LIST_LIMIT,
    }));

    return ajax({
      url: `${CLUBS_PATH}/filter?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify({
        type: ClubType.HOBBY,
        ...((field && order) && ({ sort: { field, order } })),
        ...search && ({ search }),
      }),
    }).pipe(
      map(({ response: { data, count } }: Response<ListResponse<HobbyClub>>) => ({
        clubs: data && data.length ? data : [],
        count,
      })),
      map(fetchHobbyClubsSuccess),
      catchError((error: any) => ([
        handleError(error, FETCH_HOBBY_CLUBS_ERROR),
        fetchHobbyClubsError(error),
      ])),
    );
  }),
);
