import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { EmbellishmentValue } from '../../models/Embellishment';
import {
  DELETE_EMBELLISHMENT_VALUE_ERROR,
  DELETE_EMBELLISHMENT_VALUE_REQUEST,
  DELETE_EMBELLISHMENT_VALUE_SUCCESS,
} from '../../constants/actionTypes';
import {
  DeleteEmbellishmentValueRequestAction, NotificationType,
} from '../../models/actionTypes';
import { State } from '../../models/State';
import { handleError } from '../../actions/errors/handleError';
import { EMBELLISHMENTS_VALUES_PATH } from '../../constants/paths';
import { Response } from '../../models/Response';
import {
  deleteEmbellishmentValueError,
  deleteEmbellishmentValueSuccess,
} from '../../actions/catalogs/deleteEmbellishmentValue';
import { fetchEmbellishmentValuesRequest } from '../../actions/catalogs/fetchEmbellishmentValues';
import { addNotification } from '../../actions/notifications/addNotification';

export default (
  action$: ActionsObservable<DeleteEmbellishmentValueRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(DELETE_EMBELLISHMENT_VALUE_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: { clubId, optionId },
  }, { authentication: { authToken } }]) => ajax({
    url: `${EMBELLISHMENTS_VALUES_PATH.replace('$clubId', `${clubId}`)}/${optionId}`,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(({ response: embellishmentVal }: Response<EmbellishmentValue>) => [
      deleteEmbellishmentValueSuccess(embellishmentVal),
      fetchEmbellishmentValuesRequest(clubId),
      addNotification(NotificationType.SUCCESS, DELETE_EMBELLISHMENT_VALUE_SUCCESS),
    ]),
    catchError((error: AjaxError) => ([
      handleError(error, DELETE_EMBELLISHMENT_VALUE_ERROR),
      deleteEmbellishmentValueError(error),
    ])),
  )),
);
