import React, { FC, useCallback, useState } from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Header, { HeaderTypes } from '../../../../components/Header';
import InputWithSymbol from '../../../../components/InputWithSymbol';
import PriceDiscountsPopup from '../PriceDiscountsPopup';
import styles from './ProductDetailsPrice.module.scss';
import { CurrencySign } from '../../../../constants/currencySign.enum';
import { Embellishment } from '../../../../models/Embellishment';
import { InputTypes, FocusInputType, ProductDetailsPriceProps } from './ProductDetailsPriceProps';
import { ProductEmbellishment } from '../../../../models/Product';
import { calculateDiscount, calculatePrice } from '../../../../utils/PricingUtil';
import { maxLimitPercentageValidator } from '../../../CatalogDiscounts/customizations';
import HobbyProductPrice from '../HobbyProductPrice';

const ProductDetailsPrice: FC<ProductDetailsPriceProps> = ({
  clubId,
  isHobbyCatalog,
  discountPercent,
  embellishments: productEmbellishments,
  embellishmentsByHash,
  onDiscountPercentChange,
  originalPrice,
}) => {
  const [translate] = useTranslation();
  const [inputFocused, setInputFocused] = useState<FocusInputType>(null);
  const discountDecimalScale = Math.ceil(Math.log10(originalPrice.amount));
  const symbol = ` ${CurrencySign[originalPrice.currency as keyof typeof CurrencySign]}`;
  const price = calculatePrice(originalPrice.amount, discountPercent || 0);

  const mapEmbellishment = ({ embellishment }: ProductEmbellishment) => (
    embellishmentsByHash[embellishment]
  );
  const embellishments: Embellishment[] = productEmbellishments.map(mapEmbellishment);
  const embellishmentsMandatory: Embellishment[] = productEmbellishments
    .filter(({ mandatory }) => mandatory).map(mapEmbellishment);

  const onFocus = (inputType: FocusInputType) => () => setInputFocused(inputType);

  const handleDiscountChange = useCallback((event, { value: newDiscount }) => {
    onDiscountPercentChange(newDiscount);
  }, [onDiscountPercentChange]);

  const handlePriceChange = useCallback((event, { value: newPrice }) => {
    const newDiscount = calculateDiscount(originalPrice.amount, newPrice);
    onDiscountPercentChange(newDiscount);
  }, [onDiscountPercentChange, originalPrice.amount]);

  const handleDiscountCheckboxChange = (
    event: React.FormEvent<HTMLInputElement>,
    { checked }: any,
  ) => {
    onDiscountPercentChange(checked ? 0 : null);
  };

  return (
    <div className={styles.wrapper}>
      <Header title={translate('CLUB_PRICE_CALCULATION')} type={HeaderTypes.H3} />
      <div className={styles.container}>
        <Form>
          <Form.Field>
            <label>{translate('PRICE_IN_CATALOG')}</label>
            <InputWithSymbol
              decimalScale={2}
              disabled
              size="large"
              symbol={symbol}
              value={originalPrice.amount}
            />
            <span className={styles.labelInfo}>{translate('VAT_INCLUDED')}</span>
          </Form.Field>

          <Form.Field>
            <Checkbox
              checked={discountPercent !== null}
              label={translate('DEFINE_SPECIAL_DISCOUNT')}
              onChange={handleDiscountCheckboxChange}
            />
          </Form.Field>

          {discountPercent !== null && [
            <Form.Field key="discount">
              <label>{translate('SPECIAL_PRODUCT_DISCOUNT')}</label>
              <InputWithSymbol
                decimalScale={discountDecimalScale}
                focused={inputFocused === InputTypes.DISCOUNT}
                max={100}
                min={0}
                validators={[maxLimitPercentageValidator(50, 'FIFTYPERCENT_AND_MORE_DISCOUNT_WARNING')]}
                onChange={handleDiscountChange}
                onFocus={onFocus(InputTypes.DISCOUNT)}
                size="large"
                step={0.1 ** discountDecimalScale}
                symbol=" %"
                value={discountPercent}
              />
            </Form.Field>,
            <Form.Field key="price">
              <label>{translate('FIXED_PRODUCT_PRICE')}</label>
              <InputWithSymbol
                decimalScale={2}
                focused={inputFocused === InputTypes.PRICE}
                max={originalPrice.amount}
                min={0}
                onChange={handlePriceChange}
                onFocus={onFocus(InputTypes.PRICE)}
                size="large"
                step={0.01}
                symbol=" €"
                value={price || ''}
              />
            </Form.Field>,
          ]}

          <Form.Field>
            <label>{translate('PRICE_WITH_DISCOUNT')}</label>
            <strong>
              {isHobbyCatalog ? (
                <HobbyProductPrice
                  embellishments={[]}
                  productEmbellishments={[]}
                  price={originalPrice}
                  productDiscountPercent={discountPercent}
                />
              ) : (
                <PriceDiscountsPopup
                  clubId={clubId!}
                  productEmbellishments={[]}
                  embellishments={[]}
                  price={originalPrice}
                  productDiscountPercent={discountPercent}
                  popupPosition="left center"
                />
              )}
            </strong>
          </Form.Field>
          <Form.Field>
            <label>{translate('PRICE_INCL_MANDATORY_EMBELLISHMENT')}</label>
            <strong>
              {isHobbyCatalog ? (
                <HobbyProductPrice
                  embellishments={embellishmentsMandatory}
                  productEmbellishments={productEmbellishments.filter(({ mandatory }) => mandatory)}
                  price={originalPrice}
                  productDiscountPercent={discountPercent}
                />
              ) : (
                <PriceDiscountsPopup
                  clubId={clubId!}
                  embellishments={embellishmentsMandatory}
                  productEmbellishments={productEmbellishments.filter(({ mandatory }) => mandatory)}
                  price={originalPrice}
                  productDiscountPercent={discountPercent}
                  popupPosition="left center"
                />
              )}
            </strong>
          </Form.Field>
          <Form.Field>
            <label>{translate('PRICE_INCL_OPTIONAL_EMBELLISHMENT')}</label>
            <strong>
              {isHobbyCatalog ? (
                <HobbyProductPrice
                  embellishments={embellishments}
                  productEmbellishments={productEmbellishments}
                  price={originalPrice}
                  productDiscountPercent={discountPercent}
                />
              ) : (
                <PriceDiscountsPopup
                  clubId={clubId!}
                  productEmbellishments={productEmbellishments}
                  embellishments={embellishments}
                  price={originalPrice}
                  productDiscountPercent={discountPercent}
                  popupPosition="left center"
                />
              )}
            </strong>
          </Form.Field>
        </Form>
      </div>
    </div>
  );
};

export default ProductDetailsPrice;
