import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import TeamRequestApprovalModal from './TeamRequestApprovalModal';
import { State } from '../../../../models/State';
import { fetchClubsRequest } from '../../../../actions/clubs/fetchClubs';
import { fetchSalesRepOrgsRequest } from '../../../../actions/organizations/FetchSalesRepOrgs';
import { approvePromotionRequest } from '../../../../actions/teamRequests/ApprovePromotionRequest';
import { rejectPromotionRequest } from '../../../../actions/teamRequests/RejectPromotionRequest';

const mapStateToProps = ({
  clubs: {
    byHash: clubsByHash,
    byId: clubsById,
    loading,
    count,
  },
  organizations: {
    byHash: organizationsByHash,
    byId: organizationsById,
  },
  teamRequests: {
    loading: loadingActions,
  },
}: State) => ({
  clubs: clubsById.map(id => clubsByHash[id]),
  loading,
  count,
  organizationsByHash,
  organizationsById,
  loadingActions,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchClubs: bindActionCreators(fetchClubsRequest, dispatch),
  fetchSalesRepOrgs: bindActionCreators(fetchSalesRepOrgsRequest, dispatch),
  approveRequest: bindActionCreators(approvePromotionRequest, dispatch),
  rejectRequest: bindActionCreators(rejectPromotionRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamRequestApprovalModal);
