import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import ProductsUploadImages from './ProductsUploadImages';
import { State } from '../../../../models/State';
import { fetchFileRequest } from '../../../../actions/files/fetchFile';
import { removeDraftFile, setDraftFiles } from '../../../../actions/files/draftFiles';
import { uploadFileRequest } from '../../../../actions/files/uploadFile';

const mapStateToProps = ({ files: { loadingUpload, fileUploaded, byHash } }: State) => ({
  filesByHash: byHash,
  loadingUpload,
  fileUploaded,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFile: bindActionCreators(fetchFileRequest, dispatch),
  removeDraftFile: bindActionCreators(removeDraftFile, dispatch),
  uploadFile: bindActionCreators(uploadFileRequest, dispatch),
  setDraftFiles: bindActionCreators(setDraftFiles, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsUploadImages);
