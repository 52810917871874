import { FormSchema } from '../../models/Form';
import { isRequired, isValidEmail } from '../../utils/formValidators';

export const createSchema: FormSchema = {
  firstName: {
    type: 'text',
    label: 'FIRST_NAME',
    required: true,
  },
  lastName: {
    type: 'text',
    label: 'LAST_NAME',
    required: true,
  },
  email: {
    type: 'text',
    label: 'EMAIL',
    required: true,
  },
};

export const createValidationSchema = {
  firstName: [isRequired],
  lastName: [isRequired],
  email: [isRequired, isValidEmail],
};
