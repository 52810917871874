import { serializeProduct } from './serializeProduct';
import {
  Catalog, CatalogResponse, EmbellishmentFixedPriceDiscountType, TeamDiscountsType,
} from '../../models/Catalog';

const mapTeamDiscounts = (teamDiscount: TeamDiscountsType) => ({
  ...teamDiscount,
  key: String(teamDiscount.teamId),
});

const mapGroupDiscounts = (fixedPrice: EmbellishmentFixedPriceDiscountType) => ({
  ...fixedPrice,
  key: `${fixedPrice.embellishment}${fixedPrice.groupId}`,
});

const serializeCatalog = ({
  club,
  products,
  productDiscountPercent,
  embellishmentsDiscountPercent,
  teamProductDiscounts = [],
  teamEmbellishmentsDiscounts = [],
  embellishmentFixedPriceDiscounts = [],
  ...candidate
}: CatalogResponse): Catalog => ({
  ...candidate,
  id: club.id,
  clubId: club.id,
  clubName: club.name,
  products: Array.isArray(products) ? products.map(serializeProduct) : [],
  productDiscountPercent: productDiscountPercent || 0,
  embellishmentsDiscountPercent: embellishmentsDiscountPercent || 0,
  teamProductDiscounts: teamProductDiscounts.map(mapTeamDiscounts),
  teamEmbellishmentsDiscounts: teamEmbellishmentsDiscounts.map(mapTeamDiscounts),
  embellishmentFixedPriceDiscounts: embellishmentFixedPriceDiscounts.map(mapGroupDiscounts),
});

export default serializeCatalog;
