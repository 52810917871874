import { AjaxError } from 'rxjs/ajax';
import {
  UpdateEmbellishmentValueErrorAction,
  UpdateEmbellishmentValueRequestAction,
  UpdateEmbellishmentValueSuccessAction,
} from '../../models/actionTypes';
import {
  UPDATE_EMBELLISHMENT_VALUE_ERROR,
  UPDATE_EMBELLISHMENT_VALUE_REQUEST,
  UPDATE_EMBELLISHMENT_VALUE_SUCCESS,
} from '../../constants/actionTypes';
import { EmbellishmentValue } from '../../models/Embellishment';

export const updateEmbellishmentValueRequest = (
  clubId: number,
  embellishmentValue: EmbellishmentValue,
): UpdateEmbellishmentValueRequestAction => ({
  type: UPDATE_EMBELLISHMENT_VALUE_REQUEST,
  payload: { clubId, embellishmentValue },
});

export const updateEmbellishmentValueSuccess = (
  embellishmentValue: EmbellishmentValue,
): UpdateEmbellishmentValueSuccessAction => ({
  type: UPDATE_EMBELLISHMENT_VALUE_SUCCESS,
  payload: { embellishmentValue },
});

export const updateEmbellishmentValueError = (
  error: AjaxError,
): UpdateEmbellishmentValueErrorAction => ({
  type: UPDATE_EMBELLISHMENT_VALUE_ERROR,
  payload: {
    error,
  },
});
