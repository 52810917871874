import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import Rewards from './Rewards';
import { State } from '../../models/State';
import { RewardsProps } from './RewardsProps';
import { fetchClubRequest } from '../../actions/clubs/fetchClub';

const mapStateToProps = ({
  clubs: {
    byHash: clubsByHash,
    loading,
  },
}: State, {
  match: { params: { clubId } },
}: RewardsProps) => ({
  clubId: +clubId,
  club: clubsByHash[clubId],
  loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchClubById: bindActionCreators(fetchClubRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
