import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import Row from '../../../components/Table/components/Row';
import Text from '../../../components/Text';
import styles from './IndividualOrdersRow.module.scss';
import { IndividualOrderListItem } from '../../../models/IndividualOrder';
import { getFullNameWithNullGuards } from '../../../utils/getFullName';
import { ConfirmIndividualOrderRequest, FetchFileRequestAction } from '../../../models/actionTypes';
import { formatPrice } from '../../../utils/PricingUtil';
import Confirm from '../../../components/Confirm';
import { formatDate } from '../../../utils/DateUtils';
import XlsModal from '../../../components/XlsModal';
import ClubSalesRepsCell from '../../../components/SalesRep/ClubSalesRepsCell';

export interface IndividualOrdersRowProps {
  order: IndividualOrderListItem;
  confirmIndividualOrder: (orderId: number, finalPrice: number) => ConfirmIndividualOrderRequest;
  setModalActiveOrder: (order: number | null) => void;
  fetchFile: (
    id: string,
    extension: string,
    download: boolean,
    fileName?: string
  ) => FetchFileRequestAction;
}

const IndividualOrdersRow: FC<IndividualOrdersRowProps> = ({
  order: {
    orderId,
    club,
    createdBy,
    itemsSummedPrice,
    salesOrganization,
    createdAt: orderDate,
    fileReference,
    shopwareOrderNumber,
  },
  confirmIndividualOrder,
  setModalActiveOrder,
  fetchFile,
}) => {
  const [translate] = useTranslation();
  const [finishModalOpened, setFinishModalOpened] = useState(false);
  const [previewOpened, setPreviewOpened] = useState(false);

  const handleFinishOrderConfirmation = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (itemsSummedPrice) {
      confirmIndividualOrder(orderId, itemsSummedPrice.amount || 0);
    }
    event.stopPropagation();
  };

  const handleChangeBillingAmountClick = () => {
    setModalActiveOrder(orderId);
  };

  const handleFinishOrderCancel = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setFinishModalOpened(false);
    event.stopPropagation();
  };

  const handleFinishOrder = () => {
    setFinishModalOpened(true);
  };

  const handleMenuClick = (event: React.KeyboardEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleDownload = (e: React.MouseEvent) => {
    e.stopPropagation();
    fetchFile(fileReference, 'xlsx', true, shopwareOrderNumber);
  };

  const handlePreviewClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setPreviewOpened(true);
  };

  const onPreviewClose = () => {
    setPreviewOpened(false);
  };

  return (
    <Row
      key={orderId || uuidv4()}
      classNames={styles.wrapper}
    >
      <Text>{((shopwareOrderNumber && shopwareOrderNumber) || (orderId && `#${orderId}`) || '')}</Text>
      <Text>{formatDate(orderDate)}</Text>
      <Text>{createdBy ? getFullNameWithNullGuards(createdBy.firstName, createdBy.lastName) : ''}</Text>
      <Text>{(club && club.name) || ''}</Text>
      <ClubSalesRepsCell
        clubSalesReps={salesOrganization ? salesOrganization.users : []}
      />
      <Text>{itemsSummedPrice ? formatPrice(itemsSummedPrice.amount, itemsSummedPrice.currency) : ''}</Text>
      <div className={styles.actions}>
        <div onClick={(e: any) => { e.stopPropagation(); }}>
          {previewOpened && (
          <XlsModal
            file={{ id: fileReference, originalFilename: `order_#${orderId}.xls`, extension: 'xls' }}
            onClose={onPreviewClose}
            opened={previewOpened}
          />
          )}
        </div>
        <Button icon="eye" primary basic className="simple" disabled={!fileReference} onClick={handlePreviewClick} />
        <Button icon="download" primary basic className="simple" disabled={!fileReference} onClick={handleDownload} />
        <Dropdown icon={<Icon name="ellipsis vertical" color="red" size="large" />} onClick={handleMenuClick}>
          <Dropdown.Menu direction="left">
            <Confirm
              as={Dropdown.Item}
              open={finishModalOpened}
              onClick={handleFinishOrder}
              onCancel={handleFinishOrderCancel}
              className={styles.menuOptionConfirm}
              confirmText={translate('ARE_YOU_SURE_TO_FINISH_ORDER')}
              onConfirm={handleFinishOrderConfirmation}
              triggerText={translate('FINISH_ORDER')}
            />
            <Dropdown.Item
              text={translate('CHANGE_BILLING_AMOUNT')}
              onClick={handleChangeBillingAmountClick}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Row>
  );
};

export default IndividualOrdersRow;
