import React, { FC } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Row from '../../../../components/Table/components/Row';
import Text from '../../../../components/Text';
import { CATALOG_CREATOR_ROUTE, PARAM_CATALOGS } from '../../../../constants/routes';
import { Catalog } from '../../../../models/Catalog';
import { formatDate } from '../../../../utils/DateUtils';
import { history } from '../../../../configureStore';
import ClubSalesRepsCell from '../../../../components/SalesRep/ClubSalesRepsCell';

interface CatalogsRow {
  catalog: Catalog;
}

const CatalogRow: FC<CatalogsRow> = ({ catalog }) => {
  const {
    id,
    clubId,
    clubName,
    createdAt,
    productsCount,
    isDraft,
    salesOrganization,
  } = catalog;
  const [translate] = useTranslation();

  const handleRowClick = () => {
    history.push(`${CATALOG_CREATOR_ROUTE.replace(':clubId', String(clubId))}?from=${PARAM_CATALOGS}`);
  };

  return (
    <Row key={id} itemOnClick={handleRowClick}>
      <Text bold ellipsis>{clubName}</Text>
      <Text>{formatDate(createdAt)}</Text>
      <ClubSalesRepsCell
        clubSalesReps={salesOrganization ? salesOrganization.users : []}
      />
      <Text>{String(productsCount)}</Text>
      <Text>
        {isDraft ? translate('DRAFT') : ' '}
      </Text>
      <Text>
        {translate('SENT_TO_CLUB')}
      </Text>
      <Dropdown icon={<Icon name="ellipsis vertical" color="red" size="large" />}>
        <Dropdown.Menu direction="left">
          <Dropdown.Item
            as={Link}
            text={translate('EDIT_CATALOG')}
            to={`${CATALOG_CREATOR_ROUTE.replace(':clubId', String(clubId))}?from=${PARAM_CATALOGS}`}
          />
          <Link to="/" />
        </Dropdown.Menu>
      </Dropdown>
    </Row>
  );
};

export default CatalogRow;
