interface GetFullNameProps {
  firstName: string;
  lastName: string;
}

const getFullName = ({ firstName, lastName }: GetFullNameProps) => `${firstName} ${lastName}`;

export const getFullNameWithNullGuards = (firstName: string | null, lastName: string | null) => {
  const firstNameString = firstName || '';
  const lastNameString = lastName || '';
  return `${firstNameString}${firstName && lastName ? ' ' : ''}${lastNameString}`;
};

export default getFullName;
