import { Column } from '../../components/Table/Column';

const columns: Column[] = [{
  width: '15%',
  header: 'CLUB_NAME',
  align: 'left',
  key: 'name',
  sortable: true,
}, {
  width: '15%',
  header: 'TEAM_NAME',
  align: 'left',
  key: 'teamName',
  sortable: false,
}, {
  width: '10%',
  header: 'PLAYERS',
  align: 'center',
  key: 'playerCount',
  sortable: true,
}, {
  width: '10%',
  header: 'LOCATION',
  align: 'left',
  key: 'addressDisplayName',
  sortable: true,
}, {
  width: '10%',
  header: 'ZIP_CODE',
  align: 'left',
  key: 'postCode',
  sortable: true,
}, {
  width: '15%',
  header: 'CONTACT_FOUNDER',
  align: 'left',
  key: 'createdBy',
  sortable: false,
}, {
  width: '15%',
  header: 'HOBBY_TEAM_TYPE',
  align: 'left',
  key: 'hobbyType',
  sortable: true,
}, {
  width: '10%',
  header: 'CLUB_CREATED',
  align: 'left',
  key: 'createdAt',
  sortable: true,
}];

export default columns;
