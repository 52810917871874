import { AjaxError } from 'rxjs/ajax';
import {
  FETCH_TEAM_BASKET_ORDERS_REQUEST,
  FETCH_TEAM_BASKET_ORDERS_SUCCESS,
  FETCH_TEAM_BASKET_ORDERS_ERROR,
} from '../../constants/actionTypes';
import {
  FetchTeamBasketOrdersRequest,
  FetchTeamBasketOrdersSuccess,
  FetchTeamBasketOrdersError,
} from '../../models/actionTypes';
import { TeamBasketOrder } from '../../models/TeamBasketOrder';

const fetchOrdersDefaultPayload = {
  page: 1,
};

export const fetchTeamBasketOrdersRequest = (
  payload = fetchOrdersDefaultPayload,
): FetchTeamBasketOrdersRequest => ({
  type: FETCH_TEAM_BASKET_ORDERS_REQUEST,
  payload,
});

export const fetchTeamBasketOrdersSuccess = (
  orders: TeamBasketOrder[], count: number,
): FetchTeamBasketOrdersSuccess => ({
  type: FETCH_TEAM_BASKET_ORDERS_SUCCESS,
  payload: {
    orders,
    count,
  },
});

export const fetchTeamBasketOrdersError = (error: AjaxError): FetchTeamBasketOrdersError => ({
  type: FETCH_TEAM_BASKET_ORDERS_ERROR,
  payload: {
    error,
  },
});
