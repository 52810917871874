import { Column } from '../../components/Table/Column';

const columns: Column[] = [{
  width: '15%',
  align: 'left',
  sortable: false,
  key: 'id',
}, {
  header: 'EMBELLISHMENT',
  width: '30%',
  align: 'left',
  sortable: false,
  key: 'embellishment',
}, {
  header: 'VARIANT',
  align: 'left',
  width: '55%',
  sortable: false,
  key: 'value',
}];

export default columns;
