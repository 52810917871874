import {
  PlayersReducer,
} from '../models/Player';
import {
  CREATE_PLAYER_ERROR,
  CREATE_PLAYER_REQUEST,
  CREATE_PLAYER_SUCCESS,
} from '../constants/actionTypes';
import { PlayersActions } from '../models/actionTypes';

export const initialState: PlayersReducer = {
  byHash: {},
  byId: [],
  selectedId: [],
  count: 0,
  loading: false,
  selectedMembersTeam: null,
  error: false,
  errorMessage: '',
  playerDeleted: false,
};

export default (
  state = initialState,
  action: PlayersActions,
) => {
  switch (action.type) {
    case CREATE_PLAYER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PLAYER_SUCCESS: {
      const { player } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        byId: [
          ...state.byId.filter(id => id !== player.id),
          player.id,
        ],
        byHash: { ...state.byHash, [player.id]: player },
      };
    }
    case CREATE_PLAYER_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.error.message,
      };
    default:
      return state;
  }
};
