import React, { FC, useState } from 'react';
import { Button } from 'semantic-ui-react';
import Row from '../../../../components/Table/components/Row';
import Text from '../../../../components/Text';
import { TeamBasketOrdersRowProps } from './TeamBasketOrdersRowProps';
import { formatPrice } from '../../../../utils/PricingUtil';
import { formatDate } from '../../../../utils/DateUtils';
import styles from '../../../../components/OrdersRow/OrdersRow.module.scss';
import XlsModal from '../../../../components/XlsModal';
import ClubSalesRepsCell from '../../../../components/SalesRep/ClubSalesRepsCell';

const TeamBasketOrdersRow: FC<TeamBasketOrdersRowProps> = ({
  order: {
    id,
    price,
    articleCount,
    club: { name: clubName } = { name: '' },
    team: { name: teamName } = { name: '' },
    orderDate,
    fileReference,
    salesOrganization,
    shopwareOrderNumber,
  },
  fetchFile,

}) => {
  const [previewOpened, setPreviewOpened] = useState(false);
  const handleDownload = () => {
    fetchFile(fileReference, 'xlsx', true, shopwareOrderNumber);
  };

  const onPreviewClose = () => {
    setPreviewOpened(false);
  };

  const handlePreviewClick = () => {
    setPreviewOpened(true);
  };

  return (
    <Row key={id}>
      <Text bold>{shopwareOrderNumber || `#${id}`}</Text>
      <Text>{formatDate(orderDate)}</Text>
      <Text>{clubName}</Text>
      <Text>{teamName}</Text>
      <ClubSalesRepsCell
        clubSalesReps={salesOrganization ? salesOrganization.users : []}
      />
      <Text>{articleCount || ''}</Text>
      <Text>{price ? formatPrice(price.amount, price.currency) : ''}</Text>
      <div className={styles.actions} onClick={(e: any) => { e.stopPropagation(); }}>
        {previewOpened && (
          <XlsModal
            file={{ id: fileReference, originalFilename: `order_#${id}.xls`, extension: 'xls' }}
            onClose={onPreviewClose}
            opened={previewOpened}
          />
        )}
        <Button icon="eye" primary basic className="simple" disabled={!fileReference} onClick={handlePreviewClick} />
        <Button icon="download" primary basic className="simple" disabled={!fileReference} onClick={handleDownload} />
      </div>
    </Row>
  );
};

export default TeamBasketOrdersRow;
