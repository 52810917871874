import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError, mergeMap, withLatestFrom, map,
} from 'rxjs/operators';
import { Embellishment } from '../../models/Embellishment';
import { FETCH_EMBELLISHMENTS_ERROR, FETCH_EMBELLISHMENTS_REQUEST } from '../../constants/actionTypes';
import { FetchEmbellishmentsRequestAction } from '../../models/actionTypes';
import { State } from '../../models/State';
import { fetchEmbellishmentsSuccess, fetchEmbellishmentsError } from '../../actions/catalogs/fetchEmbellishments';
import { handleError } from '../../actions/errors/handleError';
import { EMBELLISHMENTS_PATH } from '../../constants/paths';
import { Response } from '../../models/Response';

export default (
  action$: ActionsObservable<FetchEmbellishmentsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_EMBELLISHMENTS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([, { authentication: { authToken } }]) => ajax({
    url: EMBELLISHMENTS_PATH,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: embellishments }: Response<Embellishment[]>) => (
      fetchEmbellishmentsSuccess(embellishments)
    )),
    catchError((error: AjaxError) => ([
      handleError(error, FETCH_EMBELLISHMENTS_ERROR),
      fetchEmbellishmentsError(error),
    ])),
  )),
);
