import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Form, Input,
} from 'semantic-ui-react';
import styles from './RewardsPointsEdition.module.scss';
import { EditRewardsRequestAction } from '../../../models/actionTypes/RewardsActions';
import { EntityTypesEnum } from '../../../models/Entity';

export interface RewardsPointsEditionProps {
  entityType: EntityTypesEnum;
  entityId: number;
  action: 'ADD' | 'REDEEM',
  clubId: number,
  editRewards: (
    entityId: number,
    entityType: EntityTypesEnum,
    pointsAmount: number,
    reason: string,
    clubId: number,
    tags: string[]
  ) => EditRewardsRequestAction
}

const RewardsPointsEdition: FC<RewardsPointsEditionProps> = ({
  entityId, entityType, action, clubId, editRewards,
}) => {
  const [translate] = useTranslation();
  const [reason, setReason] = useState<string>('');
  const [points, setPoints] = useState<number>(0);
  const handlePointsChange = useCallback((event, { value: newPoints }) => {
    setPoints(newPoints);
  }, [setPoints]);
  const handleReasonChange = useCallback((event, { value: newReason }) => {
    setReason(newReason);
  }, []);

  const handleFormSubmit = useCallback(() => {
    editRewards(
      entityId,
      entityType,
      action === 'ADD' ? +points : -points,
      reason,
      clubId,
      action === 'ADD' ? [] : ['REDEMPTION'],
    );
    setReason('');
    setPoints(0);
  }, [action, clubId, editRewards, entityId, entityType, points, reason]);
  return (
    <div className={styles.reward}>
      <Form className={styles.form} onSubmit={handleFormSubmit}>
        <div className={styles.reason}>
          <Input
            placeholder={translate(`REWARDS.${action === 'ADD' ? 'BALLERS' : 'REDEMPTION'}.REASON`)}
            value={reason}
            onChange={handleReasonChange}
            required
            className={styles.fullWidth}
          />
        </div>
        { (clubId !== entityId || action !== 'REDEEM') && (
        <div className={styles.points}>
          <div>
            <Input type="number" min={0} step={1} value={points} onChange={handlePointsChange} required />
          </div>
        </div>

        )}
        <div className={styles.action}>
          <Button basic>{ translate(`REWARDS.${action}`)}</Button>
        </div>
      </Form>
    </div>
  );
};

export default RewardsPointsEdition;
