import SalesRepFilter from '../../components/SalesRep/SalesRepFilter';
import { Column } from '../../components/Table/Column';

const columns: Column[] = [{
  width: '10%',
  header: 'ID',
  align: 'left',
  key: 'orderId',
  sortable: true,
}, {
  width: '12%',
  header: 'TEAM_BASKET_ORDERS.ORDER_DATE',
  align: 'left',
  key: 'createdAt',
  sortable: true,
}, {
  width: '18%',
  header: 'TEAM_BASKET_ORDERS.CLUB_NAME',
  align: 'left',
  key: 'clubName',
  sortable: true,
}, {
  width: '15%',
  header: 'TEAM_BASKET_ORDERS.TEAM_NAME',
  align: 'left',
  key: 'teamName',
  sortable: true,
}, {
  header: 'SALES_REPS',
  align: 'left',
  width: '20%',
  sortable: false,
  key: 'salesRepIds',
  filter: SalesRepFilter,
}, {
  width: '10%',
  header: 'TEAM_BASKET_ORDERS.ARTICLE_COUNT',
  align: 'center',
  key: 'articleCount',
  sortable: false,
}, {
  width: '10%',
  header: 'TEAM_BASKET_ORDERS.PRICE',
  align: 'right',
  key: 'price',
  sortable: false,
}, {
  width: '5%',
  header: '',
  align: 'right',
  sortable: false,
  key: 'actions',
}];

export default columns;
