import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Form, Message, Label,
} from 'semantic-ui-react';

import FormFields from '../../../../components/FormFields';
import Header from '../../../../components/Header';
import styles from './InviteClubAdmin.module.scss';
import useForm from '../../../../hooks/useForm';
import { CLUBS_ROUTE } from '../../../../constants/routes';
import { InviteClubAdminProps } from './InviteClubAdminProps';
import { Roles } from '../../../../constants/roles';
import { clubAdminSchema, validationSchema } from './schema';

const InviteClubAdmin = ({
  error,
  loading,
  sendInvitation,
  fetchClub,
  byHash,
  match: { params },
}: InviteClubAdminProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [translate] = useTranslation();
  const clubId = +params.clubId;
  const { name: clubName = '' } = byHash[clubId] || {};
  const {
    values,
    errors,
    handleChange,
    validate,
  } = useForm(validationSchema);

  useEffect(() => {
    fetchClub(clubId);
  }, [fetchClub, clubId]);

  const handleSubmit = () => {
    if (validate()) {
      const { email, firstName, lastName } = values;
      const createInvitation = {
        receiverEmail: email,
        receiverRole: Roles.CLUB_ADMIN,
        ...(clubId && { clubId }),
      };

      const invitationPersonalData = {
        firstName,
        lastName,
        ...(clubName && { clubName }),
      };
      sendInvitation(createInvitation, invitationPersonalData);
      setIsSubmitted(true);
    }
  };

  const renderButton = () => (
    <Button
      content={translate('SAVE')}
      disabled={loading}
      form="invite-form"
      primary
      type="submit"
    />
  );

  return (
    <div className={styles.wrapper}>
      <Header
        backLabel="BACK"
        backLinkTo={CLUBS_ROUTE}
        border
        renderButton={renderButton}
        title={translate('INVITE_CLUB_ADMIN')}
      />

      <Form error={error} onSubmit={handleSubmit} loading={loading} className={styles.form} id="invite-form">
        {isSubmitted && error && (<Message error={error} header={translate('ERROR')} content={translate('GENERAL_ERROR')} />)}
        <Form.Field>
          <label>{translate('CLUB_NAME')}</label>
          <Label size="large">{clubName}</Label>
        </Form.Field>

        <FormFields
          schema={clubAdminSchema}
          errors={errors}
          values={values}
          handleChange={handleChange}
        />
      </Form>
    </div>
  );
};

export default InviteClubAdmin;
