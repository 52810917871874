export const FETCH_CLUBS_REQUEST = 'FETCH_CLUBS_REQUEST';
export const FETCH_CLUBS_SUCCESS = 'FETCH_CLUBS_SUCCESS';
export const FETCH_CLUBS_ERROR = 'FETCH_CLUBS_ERROR';

export const FETCH_CLUB_REQUEST = 'FETCH_CLUB_REQUEST';
export const FETCH_CLUB_SUCCESS = 'FETCH_CLUB_SUCCESS';
export const FETCH_CLUB_ERROR = 'FETCH_CLUB_ERROR';

export const FETCH_CATALOG_REQUEST = 'FETCH_CATALOG_REQUEST';
export const FETCH_CATALOG_SUCCESS = 'FETCH_CATALOG_SUCCESS';
export const FETCH_CATALOG_ERROR = 'FETCH_CATALOG_ERROR';

export const FETCH_TEAM_BASKET_ORDERS_REQUEST = 'FETCH_TEAM_BASKET_ORDERS_REQUEST';
export const FETCH_TEAM_BASKET_ORDERS_SUCCESS = 'FETCH_TEAM_BASKET_ORDERS_SUCCESS';
export const FETCH_TEAM_BASKET_ORDERS_ERROR = 'FETCH_TEAM_BASKET_ORDERS_ERROR';

export const FETCH_CATALOGS_REQUEST = 'FETCH_CATALOGS_REQUEST';
export const FETCH_CATALOGS_SUCCESS = 'FETCH_CATALOGS_SUCCESS';
export const FETCH_CATALOGS_ERROR = 'FETCH_CATALOGS_ERROR';

export const FETCH_TEAM_ORDERS_REQUEST = 'FETCH_TEAM_ORDERS_REQUEST';
export const FETCH_TEAM_ORDERS_SUCCESS = 'FETCH_TEAM_ORDERS_SUCCESS';
export const FETCH_TEAM_ORDERS_ERROR = 'FETCH_TEAM_ORDERS_ERROR';

export const FETCH_CLUB_ORDERS_REQUEST = 'FETCH_CLUB_ORDERS_REQUEST';
export const FETCH_CLUB_ORDERS_SUCCESS = 'FETCH_CLUB_ORDERS_SUCCESS';
export const FETCH_CLUB_ORDERS_ERROR = 'FETCH_CLUB_ORDERS_ERROR';

export const FETCH_INDIVIDUAL_ORDERS_REQUEST = 'FETCH_INDIVIDUAL_ORDERS_REQUEST';
export const FETCH_INDIVIDUAL_ORDERS_SUCCESS = 'FETCH_INDIVIDUAL_ORDERS_SUCCESS';
export const FETCH_INDIVIDUAL_ORDERS_ERROR = 'FETCH_INDIVIDUAL_ORDERS_ERROR';

export const CONFIRM_INDIVIDUAL_ORDER_REQUEST = 'CONFIRM_INDIVIDUAL_ORDER_REQUEST';
export const CONFIRM_INDIVIDUAL_ORDER_SUCCESS = 'CONFIRM_INDIVIDUAL_ORDER_SUCCESS';
export const CONFIRM_INDIVIDUAL_ORDER_ERROR = 'CONFIRM_INDIVIDUAL_ORDER_ERROR';

export const FETCH_HOBBY_CLUBS_REQUEST = 'FETCH_HOBBY_CLUBS_REQUEST';
export const FETCH_HOBBY_CLUBS_SUCCESS = 'FETCH_HOBBY_CLUBS_SUCCESS';
export const FETCH_HOBBY_CLUBS_ERROR = 'FETCH_HOBBY_CLUBS_ERROR';

export const FETCH_HOBBY_CATALOG_REQUEST = 'FETCH_HOBBY_CATALOG_REQUEST';
export const FETCH_HOBBY_CATALOG_SUCCESS = 'FETCH_HOBBY_CATALOG_SUCCESS';
export const FETCH_HOBBY_CATALOG_ERROR = 'FETCH_HOBBY_CATALOG_ERROR';

export const FETCH_EXTERNAL_CLUBS_REQUEST = 'FETCH_EXTERNAL_CLUBS_REQUEST';
export const FETCH_EXTERNAL_CLUBS_SUCCESS = 'FETCH_EXTERNAL_CLUBS_SUCCESS';
export const FETCH_EXTERNAL_CLUBS_ERROR = 'FETCH_EXTERNAL_CLUBS_ERROR';

export const SAVE_EXTERNAL_REFERENCE_REQUEST = 'SAVE_EXTERNAL_REFERENCE_REQUEST';
export const SAVE_EXTERNAL_REFERENCE_SUCCESS = 'SAVE_EXTERNAL_REFERENCE_SUCCESS';
export const SAVE_EXTERNAL_REFERENCE_ERROR = 'SAVE_EXTERNAL_REFERENCE_ERROR';

export const FETCH_SALES_REP_ORGS_REQUEST = 'FETCH_SALES_REP_ORGS_REQUEST';
export const FETCH_SALES_REP_ORGS_SUCCESS = 'FETCH_SALES_REP_ORGS_SUCCESS';
export const FETCH_SALES_REP_ORGS_ERROR = 'FETCH_SALES_REP_ORGS_ERROR';

export const REASSIGN_SALES_REP_REQUEST = 'REASSIGN_SALES_REP_REQUEST';
export const REASSIGN_SALES_REP_SUCCESS = 'REASSIGN_SALES_REP_SUCCESS';
export const REASSIGN_SALES_REP_ERROR = 'REASSIGN_SALES_REP_ERROR';

export const APPROVE_PROMOTION_REQUEST = 'APPROVE_PROMOTION_REQUEST';
export const APPROVE_PROMOTION_SUCCESS = 'APPROVE_PROMOTION_SUCCESS';
export const APPROVE_PROMOTION_ERROR = 'APPROVE_PROMOTION_ERROR';

export const REJECT_PROMOTION_REQUEST = 'REJECT_PROMOTION_REQUEST';
export const REJECT_PROMOTION_SUCCESS = 'REJECT_PROMOTION_SUCCESS';
export const REJECT_PROMOTION_ERROR = 'REJECT_PROMOTION_ERROR';

export const EDIT_TEAM_PROMOTION_REQUEST = 'EDIT_TEAM_PROMOTION_REQUEST';
export const EDIT_TEAM_PROMOTION_REQUEST_SUCCESS = 'EDIT_TEAM_PROMOTION_REQUEST_SUCCESS';
export const EDIT_TEAM_PROMOTION_REQUEST_ERROR = 'EDIT_TEAM_PROMOTION_REQUEST_ERROR';

export const DELETE_ORDERS_REQUEST = 'DELETE_ORDERS_REQUEST';
export const DELETE_ORDERS_SUCCESS = 'DELETE_ORDERS_SUCCESS';
export const DELETE_ORDERS_ERROR = 'DELETE_ORDERS_ERROR';

export const FETCH_TEAM_REQUESTS = 'FETCH_TEAM_REQUESTS';
export const FETCH_TEAM_REQUESTS_SUCCESS = 'FETCH_TEAM_REQUESTS_SUCCESS';
export const FETCH_TEAM_REQUESTS_ERROR = 'FETCH_TEAM_REQUESTS_ERROR';

export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_ERROR = 'FETCH_TAGS_ERROR';

export const FETCH_EMBELLISHMENTS_ERROR = 'FETCH_EMBELLISHMENTS_ERROR';
export const FETCH_EMBELLISHMENTS_REQUEST = 'FETCH_EMBELLISHMENTS_REQUEST';
export const FETCH_EMBELLISHMENTS_SUCCESS = 'FETCH_EMBELLISHMENTS_SUCCESS';

export const FETCH_EMBELLISHMENT_VALUES_ERROR = 'FETCH_EMBELLISHMENT_VALUES_ERROR';
export const FETCH_EMBELLISHMENT_VALUES_REQUEST = 'FETCH_EMBELLISHMENT_VALUES_REQUEST';
export const FETCH_EMBELLISHMENT_VALUES_SUCCESS = 'FETCH_EMBELLISHMENT_VALUES_SUCCESS';

export const COPY_CATALOG_REQUEST = 'COPY_CATALOG_REQUEST';
export const COPY_CATALOG_SUCCESS = 'COPY_CATALOG_SUCCESS';
export const COPY_CATALOG_ERROR = 'COPY_CATALOG_ERROR';

export const SET_APPLICATION_PARAMETERS = 'SET_APPLICATION_PARAMETERS';
export const RESET_APPROVE_REQUEST_MODAL = 'RESET_APPROVE_REQUEST_MODAL';

export const RESET_FILE_UPLOADED = 'RESET_FILE_UPLOADED';

export const ERROR = 'ERROR';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const CREATE_CLUB_REQUEST = 'CREATE_CLUB_REQUEST';
export const CREATE_CLUB_SUCCESS = 'CREATE_CLUB_SUCCESS';
export const CREATE_CLUB_ERROR = 'CREATE_CLUB_ERROR';

export const CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_REQUEST = 'CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_REQUEST';
export const CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_SUCCESS = 'CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_SUCCESS';
export const CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_ERROR = 'CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_ERROR';

export const DELETE_CLUB_REQUEST = 'DELETE_CLUB_REQUEST';
export const DELETE_CLUB_SUCCESS = 'DELETE_CLUB_SUCCESS';
export const DELETE_CLUB_ERROR = 'DELETE_CLUB_ERROR';

export const CREATE_CATALOG_REQUEST = 'CREATE_CATALOG_REQUEST';
export const CREATE_CATALOG_SUCCESS = 'CREATE_CATALOG_SUCCESS';
export const CREATE_CATALOG_ERROR = 'CREATE_CATALOG_ERROR';

export const CREATE_HOBBY_CATALOG_REQUEST = 'CREATE_HOBBY_CATALOG_REQUEST';
export const CREATE_HOBBY_CATALOG_SUCCESS = 'CREATE_HOBBY_CATALOG_SUCCESS';
export const CREATE_HOBBY_CATALOG_ERROR = 'CREATE_HOBBY_CATALOG_ERROR';

export const SAVE_PRODUCT_FILES_REQUEST = 'SAVE_PRODUCT_FILES_REQUEST';
export const SAVE_PRODUCT_FILES_SUCCESS = 'SAVE_PRODUCT_FILES_SUCCESS';
export const SAVE_PRODUCT_FILES_ERROR = 'SAVE_PRODUCT_FILES_ERROR';

export const SAVE_HOBBY_PRODUCT_FILES_REQUEST = 'SAVE_HOBBY_PRODUCT_FILES_REQUEST';
export const SAVE_HOBBY_PRODUCT_FILES_SUCCESS = 'SAVE_HOBBY_PRODUCT_FILES_SUCCESS';
export const SAVE_HOBBY_PRODUCT_FILES_ERROR = 'SAVE_HOBBY_PRODUCT_FILES_ERROR';

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_HOBBY_PRODUCT_SUCCESS = 'ADD_HOBBY_PRODUCT_SUCCESS';
export const ADD_PRODUCT_ERROR = 'ADD_PRODUCT_ERROR';

export const SAVE_SELECTION_DRAFT = 'SAVE_SELECTION_DRAFT';
export const PERSIST_SELECTION = 'PERSIST_SELECTION';
export const CLEAR_CATALOG_DATA = 'CLEAR_CATALOG_DATA';
export const HIDE_RELEASE_CATALOG_MODAL = 'HIDE_RELEASE_CATALOG_MODAL';

export const UPDATE_CATALOG = 'UPDATE_CATALOG';
export const UPDATE_HOBBY_CATALOG = 'UPDATE_HOBBY_CATALOG';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_HOBBY_PRODUCT = 'UPDATE_HOBBY_PRODUCT';
export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';
export const REMOVE_HOBBY_ATTACHMENT = 'REMOVE_HOBBY_ATTACHMENT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const REMOVE_HOBBY_PRODUCT = 'REMOVE_HOBBY_PRODUCT';

export const SEND_INVITATION = 'SEND_INVITATION';
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS';
export const SEND_INVITATION_ERROR = 'SEND_INVITATION_ERROR';

export const DELETE_PLAYER_REQUEST = 'DELETE_PLAYER_REQUEST';
export const DELETE_PLAYER_SUCCESS = 'DELETE_PLAYER_SUCCESS';
export const DELETE_PLAYER_ERROR = 'DELETE_PLAYER_ERROR';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';

export const FETCH_FILE_REQUEST = 'FETCH_FILE_REQUEST';
export const FETCH_FILE_SUCCESS = 'FETCH_FILE_SUCCESS';
export const FETCH_FILE_ERROR = 'FETCH_FILE_ERROR';

export const SET_DRAFT_FILES = 'SET_DRAFT_FILES';
export const REMOVE_DRAFT_FILE = 'REMOVE_DRAFT_FILE';

export const SET_MENU_EXPANDED = 'SET_MENU_EXPANDED';

export const UPDATE_CLUB_REQUEST = 'UPDATE_CLUB_REQUEST';
export const UPDATE_CLUB_SUCCESS = 'UPDATE_CLUB_SUCCESS';
export const UPDATE_CLUB_ERROR = 'UPDATE_CLUB_ERROR';

export const UPLOAD_CLUB_LOGO_REQUEST = 'UPLOAD_CLUB_LOGO_REQUEST';
export const UPLOAD_CLUB_LOGO_SUCCESS = 'UPLOAD_CLUB_LOGO_SUCCESS';
export const UPLOAD_CLUB_LOGO_ERROR = 'UPLOAD_CLUB_LOGO_ERROR';

export const DELETE_EXTERNAL_REF_REQUEST = 'DELETE_EXTERNAL_REF_REQUEST';
export const DELETE_EXTERNAL_REF_SUCCESS = 'DELETE_EXTERNAL_REF_SUCCESS';
export const DELETE_EXTERNAL_REF_ERROR = 'DELETE_EXTERNAL_REF_ERROR';

export const EDIT_REWARDS_REQUEST = 'EDIT_REWARDS_REQUEST';
export const EDIT_REWARDS_SUCCESS = 'EDIT_REWARDS_SUCCESS';
export const EDIT_REWARDS_ERROR = 'EDIT_REWARDS_ERROR';

export const FETCH_ENTITY_REWARDS_REQUEST = 'FETCH_ENTITY_REWARDS_REQUEST';
export const FETCH_ENTITY_REWARDS_SUCCESS = 'FETCH_ENTITY_REWARDS_SUCCESS';
export const FETCH_ENTITY_REWARDS_ERROR = 'FETCH_ENTITY_REWARDS_ERROR';

export const FETCH_CLUB_TEAMS_REQUEST = 'FETCH_CLUB_TEAMS_REQUEST';
export const FETCH_CLUB_TEAMS_SUCCESS = 'FETCH_CLUB_TEAMS_SUCCESS';
export const FETCH_CLUB_TEAMS_ERROR = 'FETCH_CLUB_TEAMS_ERROR';

export const CREATE_TEAM_REQUEST = 'CREATE_TEAM_REQUEST';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_ERROR = 'CREATE_TEAM_ERROR';

export const SET_TOR24_TEAMS_REQUEST = 'SET_TOR24_TEAMS_REQUEST';
export const SET_TOR24_TEAMS_SUCCESS = 'SET_TOR24_TEAMS_SUCCESS';
export const SET_TOR24_TEAMS_ERROR = 'SET_TOR24_TEAMS_ERROR';

export const TOR24_TEAMS_ERROR = 'TOR24_TEAMS_ERROR';
export const TOR24_TEAMS_REQUEST = 'TOR24_TEAMS_REQUEST';
export const TOR24_TEAMS_SUCCESS = 'TOR24_TEAMS_SUCCESS';

export const CHANGE_TEAM_STATUS_REQUEST = 'CHANGE_TEAM_STATUS_REQUEST';
export const CHANGE_TEAM_STATUS_SUCCESS = 'CHANGE_TEAM_STATUS_SUCCESS';
export const CHANGE_TEAM_STATUS_ERROR = 'CHANGE_TEAM_STATUS_ERROR';

export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_ERROR = 'DELETE_TEAM_ERROR';

export const CHECK_EMAIL_AVAILABILITY_REQUEST = 'CHECK_EMAIL_AVAILABILITY_REQUEST';
export const CHECK_EMAIL_AVAILABILITY_SUCCESS = 'CHECK_EMAIL_AVAILABILITY_SUCCESS';
export const CHECK_EMAIL_AVAILABILITY_ERROR = 'CHECK_EMAIL_AVAILABILITY_ERROR';

export const CREATE_PLAYER_REQUEST = 'CREATE_PLAYER_REQUEST';
export const CREATE_PLAYER_SUCCESS = 'CREATE_PLAYER_SUCCESS';
export const CREATE_PLAYER_ERROR = 'CREATE_PLAYER_ERROR';

export const FETCH_MEMBERS_REQUEST = 'FETCH_MEMBERS_REQUEST';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_ERROR = 'FETCH_MEMBERS_ERROR';

export const ASSIGN_MEMBER_REQUEST = 'ASSIGN_MEMBER_REQUEST';
export const ASSIGN_MEMBER_SUCCESS = 'ASSIGN_MEMBER_SUCCESS';
export const ASSIGN_MEMBER_ERROR = 'ASSIGN_MEMBER_ERROR';

export const FETCH_TEAM_REQUEST = 'FETCH_TEAM_REQUEST';
export const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS';
export const FETCH_TEAM_ERROR = 'FETCH_TEAM_ERROR';

export const RESEND_INVITATION_ERROR = 'RESEND_INVITATION_ERROR';
export const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS';
export const RESEND_INVITATION_REQUEST = 'RESEND_INVITATION_REQUEST';

export const FETCH_STAFF_ERROR = 'FETCH_STAFF_ERROR';
export const FETCH_STAFF_SUCCESS = 'FETCH_STAFF_SUCCESS';
export const FETCH_STAFF_REQUEST = 'FETCH_STAFF_REQUEST';

export const FETCH_TEAM_INVITE_TOKEN_REQUEST = 'FETCH_TEAM_INVITE_TOKEN_REQUEST';
export const FETCH_TEAM_INVITE_TOKEN_SUCCESS = 'FETCH_TEAM_INVITE_TOKEN_SUCCESS';
export const FETCH_TEAM_INVITE_TOKEN_ERROR = 'FETCH_TEAM_INVITE_TOKEN_ERROR';

export const DEACTIVATE_INVITE_TOKEN_REQUEST = 'DEACTIVATE_INVITE_TOKEN_REQUEST';
export const DEACTIVATE_INVITE_TOKEN_SUCCESS = 'DEACTIVATE_INVITE_TOKEN_SUCCESS';
export const DEACTIVATE_INVITE_TOKEN_ERROR = 'DEACTIVATE_INVITE_TOKEN_ERROR';

export const GENERATE_INVITE_TOKEN_REQUEST = 'GENERATE_INVITE_TOKEN_REQUEST';
export const GENERATE_INVITE_TOKEN_ERROR = 'GENERATE_INVITE_TOKEN_ERROR';
export const GENERATE_INVITE_TOKEN_SUCCESS = 'GENERATE_INVITE_TOKEN_SUCCESS';

export const ADD_ROLE_TO_MEMBER_REQUEST = 'ADD_ROLE_TO_MEMBER_REQUEST';
export const ADD_ROLE_TO_MEMBER_ERROR = 'ADD_ROLE_TO_MEMBER_ERROR';
export const ADD_ROLE_TO_MEMBER_SUCCESS = 'ADD_ROLE_TO_MEMBER_SUCCESS';

export const CONVERT_TO_OFFICIAL_REQUEST = 'CONVERT_TO_OFFICIAL_REQUEST';
export const CONVERT_TO_OFFICIAL_SUCCESS = 'CONVERT_TO_OFFICIAL_SUCCESS';
export const CONVERT_TO_OFFICIAL_ERROR = 'CONVERT_TO_OFFICIAL_ERROR';

export const CREATE_EMBELLISHMENT_VALUE_REQUEST = 'CREATE_EMBELLISHMENT_VALUE_REQUEST';
export const CREATE_EMBELLISHMENT_VALUE_SUCCESS = 'CREATE_EMBELLISHMENT_VALUE_SUCCESS';
export const CREATE_EMBELLISHMENT_VALUE_ERROR = 'CREATE_EMBELLISHMENT_VALUE_ERROR';

export const DELETE_EMBELLISHMENT_VALUE_REQUEST = 'DELETE_EMBELLISHMENT_VALUE_REQUEST';
export const DELETE_EMBELLISHMENT_VALUE_SUCCESS = 'DELETE_EMBELLISHMENT_VALUE_SUCCESS';
export const DELETE_EMBELLISHMENT_VALUE_ERROR = 'DELETE_EMBELLISHMENT_VALUE_ERROR';

export const UPDATE_EMBELLISHMENT_VALUE_REQUEST = 'UPDATE_EMBELLISHMENT_VALUE_REQUEST';
export const UPDATE_EMBELLISHMENT_VALUE_SUCCESS = 'UPDATE_EMBELLISHMENT_VALUE_SUCCESS';
export const UPDATE_EMBELLISHMENT_VALUE_ERROR = 'UPDATE_EMBELLISHMENT_VALUE_ERROR';
