import pickBy from 'lodash/pickBy';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { handleError } from '../../actions/errors/handleError';
import { addNotification } from '../../actions/notifications/addNotification';
import { editTeamRequestSuccess, editTeamRequestError } from '../../actions/teamRequests/EditTeamRequest';
import { EDIT_TEAM_PROMOTION_REQUEST, EDIT_TEAM_PROMOTION_REQUEST_ERROR, EDIT_TEAM_PROMOTION_REQUEST_SUCCESS } from '../../constants/actionTypes';
import { EDIT_TEAM_PROMOTION_PATH } from '../../constants/paths';
import { EditTeamRequestAction, NotificationType } from '../../models/actionTypes';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { TeamRequest } from '../../models/TeamRequests';

const editTeamRequest = (
  action$: ActionsObservable<EditTeamRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(EDIT_TEAM_PROMOTION_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      id, request,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: EDIT_TEAM_PROMOTION_PATH.replace('$promotionId', `${id}`),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(pickBy({
      ...request,
    })),
  }).pipe(
    mergeMap(({ response }: Response<TeamRequest>) => [
      addNotification(NotificationType.SUCCESS, EDIT_TEAM_PROMOTION_REQUEST_SUCCESS),
      editTeamRequestSuccess(response),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, EDIT_TEAM_PROMOTION_REQUEST_ERROR),
      editTeamRequestError(error),
    ]),
  )),
);

export default editTeamRequest;
