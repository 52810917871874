import { AjaxError } from 'rxjs/ajax';

import { HobbyCatalog } from '../../models/Catalog';
import { FETCH_HOBBY_CATALOG_REQUEST, FETCH_HOBBY_CATALOG_SUCCESS, FETCH_HOBBY_CATALOG_ERROR } from '../../constants/actionTypes';
import {
  FetchHobbyCatalogRequestAction,
  FetchHobbyCatalogSuccessAction,
  FetchHobbyCatalogErrorAction,
} from '../../models/actionTypes';

export const fetchHobbyCatalogRequest = (): FetchHobbyCatalogRequestAction => ({
  type: FETCH_HOBBY_CATALOG_REQUEST,
  payload: {},
});

export const fetchHobbyCatalogSuccess = (
  catalog: HobbyCatalog,
): FetchHobbyCatalogSuccessAction => ({
  type: FETCH_HOBBY_CATALOG_SUCCESS,
  payload: {
    catalog,
  },
});

export const fetchHobbyCatalogError = (error: AjaxError): FetchHobbyCatalogErrorAction => ({
  type: FETCH_HOBBY_CATALOG_ERROR,
  payload: {
    error,
  },
});
