import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { history } from '../../configureStore';
import styles from './IndividualOrders.module.scss';
import { IndividualOrdersProps } from './IndividualOrdersProps';
import IndividualOrdersRow from './IndividualOrdersRow';
import columns from './columns';
import { IndividualOrderListItem, IndividualOrderStatus } from '../../models/IndividualOrder';
import TableContainer from '../../components/Layout/components/TableContainer';
import HeaderComponent from '../../components/Header/Header';
import ConfirmOrderModal from './ConfirmOrderModal';
import params from '../../utils/params';
import {
  TABLE_PAGE_URL_PARAM,
  TABLE_SEARCH_URL_PARAM,
  TABLE_SORT_ORDER_URL_PARAM,
  TABLE_SORT_FIELD_URL_PARAM,
} from '../../constants/customizations';
import OrdersListFilter from '../../components/OrdersListFilter/OrdersListFilter';

const IndividualOrders: FC<IndividualOrdersProps> = ({
  loading,
  individualOrders,
  count,
  orderConfirmed,
  loadingConfirmation,
  fetchIndividualOrders,
  confirmIndividualOrder,
}) => {
  const [translate] = useTranslation();
  const { getParams } = params(history);
  const [modalActiveOrder, setModalActiveOrder] = useState<number | null>(null);

  const {
    [TABLE_SEARCH_URL_PARAM]: search,
    [TABLE_PAGE_URL_PARAM]: page,
    [TABLE_SORT_FIELD_URL_PARAM]: field,
    [TABLE_SORT_ORDER_URL_PARAM]: sortOrder,
  } = getParams();

  useEffect(() => {
    if (orderConfirmed) {
      setModalActiveOrder(null);
      fetchIndividualOrders({
        status: IndividualOrderStatus.PENDING,
        page,
        search,
        field,
        sortOrder,
      });
    }
  // eslint-disable-next-line
  }, [orderConfirmed]);

  const fetchPendingOrders = useCallback((payload?: any) => {
    fetchIndividualOrders({
      status: IndividualOrderStatus.PENDING,
      ...payload,
    });
  }, [fetchIndividualOrders]);

  const renderRows = (order: IndividualOrderListItem) => (
    <IndividualOrdersRow
      order={order}
      key={order.orderId}
      confirmIndividualOrder={confirmIndividualOrder}
      setModalActiveOrder={setModalActiveOrder}
    />
  );

  return (
    <div className={styles.wrapper}>
      {modalActiveOrder && (
        <ConfirmOrderModal
          order={individualOrders.find(o => o.orderId === modalActiveOrder)!}
          confirmIndividualOrder={confirmIndividualOrder}
          setModalActiveOrder={setModalActiveOrder}
          loadingConfirmation={loadingConfirmation}
        />
      )}
      <HeaderComponent border title={translate('OPEN_INDIVIDUAL_ORDERS')} />
      <div className={styles.tableWrapper}>
        <TableContainer
          extraFilters={<OrdersListFilter history={history} />}
          columns={columns}
          count={count}
          fetch={fetchPendingOrders}
          history={history}
          loading={loading}
          renderItems={individualOrders.map(renderRows)}
          searchPlaceholder="SEARCH_FOR_INDIVIDUAL_ORDERS"
          invertedHeader
          emptyTableText="NO_INDIVIDUAL_ORDERS_FOUND"
          notFoundTableText="NO_INDIVIDUAL_ORDERS_FOUND"
        />
      </div>
    </div>
  );
};

export default IndividualOrders;
