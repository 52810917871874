import {
  SEND_INVITATION,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_ERROR,
} from '../constants/actionTypes';

import { InvitationActions } from '../models/actionTypes';
import { InvitationsReducer } from '../models/State';

export const initialState: InvitationsReducer = {
  loading: false,
  error: false,
  errorMessage: '',
};

export default (state = initialState, action : InvitationActions) => {
  switch (action.type) {
    case SEND_INVITATION:
      return {
        ...state,
        loading: true,
      };
    case SEND_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
      };
    case SEND_INVITATION_ERROR: {
      const { error: { message } } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: message,
      };
    }
    default:
      return state;
  }
};
