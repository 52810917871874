import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import serializeCatalog from '../../utils/serializers/serializeCatalog';
import { CATALOG_PATH } from '../../constants/paths';
import { CatalogResponse } from '../../models/Catalog';
import { FETCH_CATALOG_ERROR, FETCH_CATALOG_REQUEST } from '../../constants/actionTypes';
import { FetchCatalogRequestAction } from '../../models/actionTypes';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { fetchCatalogSuccess, fetchCatalogError } from '../../actions/catalogs/fetchCatalog';
import { handleError } from '../../actions/errors/handleError';

export default (
  action$: ActionsObservable<FetchCatalogRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_CATALOG_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { clubId } }, { authentication: { authToken } }]) => ajax({
    url: CATALOG_PATH.replace('$clubId', String(clubId)),
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: catalog }: Response<CatalogResponse>) => serializeCatalog(catalog)),
    map(fetchCatalogSuccess),
    catchError((error: any) => ([
      handleError(error, FETCH_CATALOG_ERROR),
      fetchCatalogError(error),
    ])),
  )),
);
