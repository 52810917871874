import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../constants/actionTypes';
import { NotificationsActions } from '../models/actionTypes';
import { NotificationsReducer } from '../models/State';

export const initialState: NotificationsReducer = {
  notifications: [],
};

export default (state = initialState, action: NotificationsActions) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          action.payload,
        ],
      };
    }
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(({ id }) => id !== action.payload.id),
      };
    default:
      return state;
  }
};
