import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import ProductCard from './ProductCard';
import { State } from '../../../../models/State';
import { fetchFileRequest } from '../../../../actions/files/fetchFile';
import { updateProduct } from '../../../../actions/catalogs/updateProduct';

const mapStateToProps = ({
  files: { byHash: filesByHash },
  embellishments: { embellishmentsByHash },
}: State) => ({
  filesByHash,
  embellishmentsByHash,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFile: bindActionCreators(fetchFileRequest, dispatch),
  updateProduct: bindActionCreators(updateProduct, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
