import { ActionsObservable, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

import { NotificationType, RemoveProductAction } from '../../models/actionTypes';
import { REMOVE_PRODUCT } from '../../constants/actionTypes';
import { addNotification } from '../../actions/notifications/addNotification';

export default (
  action$: ActionsObservable<RemoveProductAction>,
) => action$.pipe(
  ofType(REMOVE_PRODUCT),
  map(() => addNotification(NotificationType.SUCCESS, 'REMOVE_PRODUCT_SUCCESS')),
);
