import { Tor24Team } from './tor24Team';

export interface Tor24ClubDto {
  id: string;
  name: string;
  district: string;
}

export interface ExternalClubsByHash {
  [key: string]: Tor24ClubDto;
}
export interface ExternalTeamsByHash {
  [key: string]: Tor24Team;
}

export interface ExternalSystemsReducer {
  externalClubByHash: ExternalClubsByHash;
  externalClubById: number[];
  externalTeamByHash: ExternalTeamsByHash;
  externalTeamById: number[];
  error: boolean;
  loading: boolean;
}

export enum ExternalReferencedEntityType {
  CLUB = 'CLUB',
  TEAM = 'TEAM',
}

export enum ExternalSystemName {
  YOU_SPORT = 'YOU_SPORT',
  TOR_24 = 'TOR_24',
  DFB_DIVISION = 'DFB_DIVISION',
}

export interface ExternalSystemReference {
  entityType: ExternalReferencedEntityType;
  entityId: number;
  externalSystemName: ExternalSystemName;
  externalSystemEntityId: string;
}

export interface UpdateExternalSystemReferenceRequest {
  references: ExternalSystemReference[];
}
