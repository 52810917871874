import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimmer, Icon, Image, Loader,
} from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import { Club } from '../../models/Club';
import styles from './Settings.module.scss';
import LabelValueField from '../../components/LabelValueField';
import { FetchClubRequestAction, UploadClubLogoRequestAction } from '../../models/actionTypes';
import colors from '../../constants/colors';
import FileUploadModal from '../../components/FileUploadModal';
import { FileUploadTypes } from '../../components/FileUploadModal/FileUploadModalProps';
import ClubDetailsHeader from '../../components/ClubDetailsHeader';

export interface SettingsProps extends RouteComponentProps {
  club: Club;
  loadingUpload: boolean;
  clubLogoUploaded: boolean;
  loading: boolean;
  uploadClubLogo: (clubId: number, file: File) => UploadClubLogoRequestAction;
  match: any;
  fetchClub: (id: number) => FetchClubRequestAction;
}

const Settings = ({
  clubLogoUploaded,
  club: {
    name, socialMedia, websiteEventsAddress, websiteNewsAddress, address: {
      street, number, city, postCode, displayName,
    }, phoneNumber, email, faxNumber, websiteAddress, logoUrl,
    primaryColor, customerId, clubSalesId,
  }, uploadClubLogo, loadingUpload, match, fetchClub, loading,
}: SettingsProps) => {
  const [translate] = useTranslation();
  const { clubId } = match.params;
  const color = colors.find(c => c.name === primaryColor);

  useEffect(() => {
    fetchClub(clubId);
  }, [clubId, fetchClub]);

  const uploadImage = useCallback(
    image => {
      if (clubId) {
        uploadClubLogo(clubId, image);
      }
    },
    [uploadClubLogo, clubId],
  );

  return (
    <>
      {loading && <Dimmer active inverted><Loader /></Dimmer>}
      <ClubDetailsHeader clubId={clubId} clubName={name} />
      <div className={styles.wrapper}>
        <h3 className={styles.header}>{translate('BASIC_DATA')}</h3>
        <div className={styles.layout}>
          <div className={styles.left}>
            <LabelValueField label={translate('CUSTOMER_ID')} value={customerId} />
            <LabelValueField label={translate('CLUB_SALES_ID')} value={clubSalesId} />
            <LabelValueField label={translate('CLUB_NAME')} value={name} />
            <LabelValueField label={translate('STREET')} value={street} />
            <LabelValueField label={translate('NUMBER')} value={number} />
            <LabelValueField label={translate('CITY')} value={city} />
            <LabelValueField label={translate('ZIP_CODE')} value={postCode} />
            <LabelValueField label={translate('DISPLAY_NAME')} value={displayName} />
            <LabelValueField label={translate('PHONE_NUMBER')} value={phoneNumber} />
            <LabelValueField label={translate('EMAIL')} value={email} />
          </div>
          <div className={styles.right}>
            <div className={styles.logoSection}>
              <span className={styles.label}>{translate('LOGO')}</span>
              <div className={styles.valueSection}>
                {logoUrl && (
                <Image src={logoUrl} size="small" alt="" className={styles.image} />
                )}
                <FileUploadModal
                  uploadFile={uploadImage}
                  loading={loadingUpload}
                  fileUploaded={clubLogoUploaded}
                  type={FileUploadTypes.IMAGE}
                />
              </div>
            </div>
            <LabelValueField label={translate('FAX_NUMBER')} value={faxNumber} />
            <LabelValueField label={translate('WEBSITE')} value={websiteAddress} isLink />
          </div>
        </div>
        <div className={styles.field}>
          <span className={styles.label}>{translate('PRIMARY_COLOR')}</span>
          { primaryColor && (
            <div className={styles.group}>
              <div className={styles.circle} style={{ backgroundColor: color && color.hex, borderColor: color && color.hex === '#FFFFFF' ? '#979797' : 'none' }} />
              <span className={styles.text}>{color && color.name}</span>
            </div>
          )}
        </div>
        <div className={styles.field}>
          <span className={styles.label}>{translate('SOCIAL_MEDIA')}</span>
          { socialMedia && (
            <div className={styles.group}>
              { socialMedia.facebookUrl && (
              <a href={socialMedia.facebookUrl} target="__blank">
                <Icon name="facebook f" circular className={styles.media} />
              </a>
              )}
              { socialMedia.instagramUrl && (
              <a href={socialMedia.instagramUrl} target="__blank">
                <Icon name="instagram" circular className={styles.media} />
              </a>
              )}
              { socialMedia.snapchatUrl && (
              <a href={socialMedia.snapchatUrl} target="__blank">
                <Icon name="twitter" circular className={styles.media} />
              </a>
              )}
            </div>
          )}
        </div>
        <h3 className={styles.header}>{translate('CURRENT')}</h3>
        <LabelValueField label={translate('WEBSITE_EVENTS_ADDRESS')} value={websiteEventsAddress} isLink />
        <LabelValueField label={translate('WEBSITE_NEWS_ADDRESS')} value={websiteNewsAddress} isLink />
      </div>
    </>
  );
};

export default Settings;
