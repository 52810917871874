import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dropdown, DropdownProps, Form, Modal,
} from 'semantic-ui-react';
import { HeaderTypes } from '../../../../components/Header';

import styles from './CopyCatalog.module.scss';

export interface CopyCatalogProps {
  originalClubName: string,
  clubsOptions: any,
  handleCopyCatalogModalClose: any,
  handleCopyCatalog: any,
  selectedClubId: any,
  setSelectedClubId: any,
  onSearchChange: any,
  loading: any,
}

const CopyCatalog: FC<CopyCatalogProps> = ({
  originalClubName,
  clubsOptions,
  handleCopyCatalogModalClose,
  handleCopyCatalog,
  selectedClubId,
  setSelectedClubId,
  onSearchChange,
  loading,
}) => {
  const [translate] = useTranslation();

  const handleClubSelection = (
    event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps,
  ) => {
    setSelectedClubId(+data.value!);
  };

  return (
    <Modal
      closeIcon
      size="small"
      onClose={handleCopyCatalogModalClose}
      open
    >
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <Modal.Header className={styles.title} as={HeaderTypes.H2} content={translate('COPY_CATALOG')} />
        </div>
        <Modal.Content>
          <Form>
            <Form.Field
              className={classNames(styles.formField, styles.formFieldInline)}
              key="org-club-catalog"
            >
              <label className={styles.inputLabel}>{translate('ORIGINAL_CLUB_CATALOG')}</label>
              <div className={styles.inputWithLabel}>
                <span>{originalClubName}</span>
              </div>
            </Form.Field>
            <Form.Field
              className={classNames(styles.formField, styles.formFieldInline)}
              key="dest-club-catalog"
            >
              <label className={styles.inputLabel}>{translate('CLUB_CATALOG_TO')}</label>
              <div className={styles.inputWithLabel}>
                <Dropdown
                  name="sales-rep-org"
                  onChange={handleClubSelection}
                  options={clubsOptions || []}
                  selection
                  fluid
                  placeholder={translate('ENTER_CLUB_NAME')}
                  value={selectedClubId || ''}
                  onSearchChange={onSearchChange}
                  loading={loading}
                  search
                />
              </div>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions className={styles.actionsContainer}>
          <Button
            basic
            circular
            content={translate('CANCEL')}
            onClick={handleCopyCatalogModalClose}
            primary
          />
          <Button
            circular
            disabled={!selectedClubId}
            content={translate('PASTE')}
            onClick={handleCopyCatalog}
            primary
          />
        </Modal.Actions>
      </div>
    </Modal>
  );
};

export default CopyCatalog;
