import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import serializeCatalog from '../../utils/serializers/serializeCatalog';
import { CATALOGS_LIST_LIMIT } from '../../constants/customizations';
import { CATALOGS_PATH } from '../../constants/paths';
import { CatalogResponse } from '../../models/Catalog';
import { FETCH_CATALOGS_ERROR, FETCH_CATALOGS_REQUEST } from '../../constants/actionTypes';
import { FetchCatalogsRequestAction } from '../../models/actionTypes';
import { ListResponse, Response } from '../../models/Response';
import { State } from '../../models/State';
import { fetchCatalogsError, fetchCatalogsSuccess } from '../../actions/catalogs/fetchCatalogs';
import { handleError } from '../../actions/errors/handleError';

export default (
  action$: ActionsObservable<FetchCatalogsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_CATALOGS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      page, search, field, order, filter,
    },
  }, {
    authentication: { authToken },
  }]) => {
    const query = queryString.stringify(pickBy({
      offset: ((page || 1) - 1) * CATALOGS_LIST_LIMIT,
      limit: CATALOGS_LIST_LIMIT,
    }));

    return ajax({
      url: `${CATALOGS_PATH}/filter?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify({
        search,
        ...((field || order) && { sort: { field, order } }),
        ...(filter && JSON.parse(filter!)),
      }),
    }).pipe(
      map(({ response: { data, count } }: Response<ListResponse<CatalogResponse>>) => ({
        catalogs: data.map(serializeCatalog),
        count,
      })),
      map(fetchCatalogsSuccess),
      catchError((error: any) => ([
        handleError(error, FETCH_CATALOGS_ERROR),
        fetchCatalogsError(error),
      ])),
    );
  }),
);
