import { Column } from '../../components/Table/Column';

const columns: Column[] = [{
  width: '25%',
  header: 'TEAM_NAME',
  align: 'left',
  key: 'teamName',
  sortable: false,
}, {
  width: '25%',
  header: 'REWARDS.BALLERS_IN_TOTAL',
  align: 'left',
  key: 'ballersTotal',
  sortable: false,
}, {
  width: '25%',
  header: 'REWARDS.LEVEL',
  align: 'left',
  key: 'level',
  sortable: false,
}, {
  width: '25%',
  header: 'REWARDS.BALLERS_AVAILABLE',
  align: 'left',
  key: 'ballersAvailable',
  sortable: false,
}];

export default columns;
