import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';

import { MembershipResponse, NtsUserMembership } from '../../models/Membership';
import { SIGN_IN_SUCCESS } from '../../packages/authentication/constants';
import { SignInSuccessAction } from '../../models/actionTypes';
import { State } from '../../models/State';
import { setApplicationParameters } from '../../actions/application/setApplicationProperties';

const parseMemberships = (memberships: MembershipResponse[]): NtsUserMembership => (
  memberships.reduce((fin, { organizationId, roles }) => ({
    ...fin,
    [organizationId]: roles,
  }), {})
);

export default (
  action$: ActionsObservable<SignInSuccessAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(SIGN_IN_SUCCESS),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      signInResponse: {
        user: {
          memberships, currentlyUsedOrganizationId,
        },
        organizationType,
      },
    },
  }]) => {
    const parsedMemberships = parseMemberships(memberships);

    return [
      setApplicationParameters({
        memberships: parsedMemberships,
        clubId: currentlyUsedOrganizationId,
        organizationType,
      }),
    ];
  }),
);
