import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { withRouter } from 'react-router';
import IndividualOrders from './IndividualOrders';
import { State } from '../../models/State';
import { fetchIndividualOrdersRequest } from '../../actions/individualOrders/fetchIndividualOrders';
import { confirmIndividualOrderRequest } from '../../actions/individualOrders/confirmIndividualOrder';

const mapStateToProps = ({
  individualOrders: {
    byHash,
    byId,
    loading,
    count,
    orderConfirmed,
    loadingConfirmation,
  },
}: State) => ({
  individualOrders: byId.map(id => byHash[id]),
  loading,
  count,
  orderConfirmed,
  loadingConfirmation,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchIndividualOrders: bindActionCreators(fetchIndividualOrdersRequest, dispatch),
  confirmIndividualOrder: bindActionCreators(confirmIndividualOrderRequest, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IndividualOrders));
