import { AjaxError } from 'rxjs/ajax';
import {
  EDIT_TEAM_PROMOTION_REQUEST,
  EDIT_TEAM_PROMOTION_REQUEST_SUCCESS,
  EDIT_TEAM_PROMOTION_REQUEST_ERROR,
} from '../../constants/actionTypes';
import {
  EditTeamRequestAction,
  EditTeamRequestSuccessAction,
  EditTeamRequestErrorAction,
} from '../../models/actionTypes';
import { TeamRequest, EditTeamRequest } from '../../models/TeamRequests';

export const editTeamRequest = (
  id: number, request: EditTeamRequest,
): EditTeamRequestAction => ({
  type: EDIT_TEAM_PROMOTION_REQUEST,
  payload: {
    id,
    request,
  },
});

export const editTeamRequestSuccess = (
  request: TeamRequest,
): EditTeamRequestSuccessAction => ({
  type: EDIT_TEAM_PROMOTION_REQUEST_SUCCESS,
  payload: {
    request,
  },
});

export const editTeamRequestError = (error: AjaxError): EditTeamRequestErrorAction => ({
  type: EDIT_TEAM_PROMOTION_REQUEST_ERROR,
  payload: {
    error,
  },
});
