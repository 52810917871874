import { AjaxError } from 'rxjs/ajax';
import { OrderList } from '../../models/Order';
import {
  FETCH_TEAM_ORDERS_REQUEST,
  FETCH_TEAM_ORDERS_SUCCESS,
  FETCH_TEAM_ORDERS_ERROR,
} from '../../constants/actionTypes';
import {
  FetchTeamOrdersRequestAction,
  FetchTeamOrdersSuccessAction,
  FetchTeamOrdersErrorAction,
} from '../../models/actionTypes';

const fetchOrdersDefaultPayload = {
  page: 1,
};

export const fetchTeamOrdersRequest = (
  payload = fetchOrdersDefaultPayload,
): FetchTeamOrdersRequestAction => ({
  type: FETCH_TEAM_ORDERS_REQUEST,
  payload,
});

export const fetchTeamOrdersSuccess = (
  orders: OrderList[], count: number,
): FetchTeamOrdersSuccessAction => ({
  type: FETCH_TEAM_ORDERS_SUCCESS,
  payload: {
    orders,
    count,
  },
});

export const fetchTeamOrdersError = (error: AjaxError): FetchTeamOrdersErrorAction => ({
  type: FETCH_TEAM_ORDERS_ERROR,
  payload: {
    error,
  },
});
