import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { CLUBS_PATH } from '../../constants/paths';
import { DELETE_CLUB_ERROR, DELETE_CLUB_REQUEST, DELETE_CLUB_SUCCESS } from '../../constants/actionTypes';
import { DeleteClubRequestAction, NotificationType } from '../../models/actionTypes';
import { State } from '../../models/State';
import { addNotification } from '../../actions/notifications/addNotification';
import { deleteClubError, deleteClubSuccess } from '../../actions/clubs/deleteClub';
import { handleError } from '../../actions/errors/handleError';

export default (
  action$: ActionsObservable<DeleteClubRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(DELETE_CLUB_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { clubId } }, { authentication: { authToken } }]) => ajax({
    url: `${CLUBS_PATH}/${clubId}`,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(() => [
      deleteClubSuccess(clubId),
      addNotification(NotificationType.SUCCESS, DELETE_CLUB_SUCCESS),
    ]),
    catchError((error: any) => ([
      handleError(error, DELETE_CLUB_ERROR),
      deleteClubError(error),
    ])),
  )),
);
