import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import App from './App';
import { State } from '../../models/State';
import { signOut } from '../../packages/authentication/actions';
import setMenuExpanded from '../../actions/application/setMenuExpanded';

const mapStateToProps = ({
  application: {
    menuExpanded, memberships, clubId, organizationType,
  },
}: State) => ({
  menuExpanded,
  userRoles: clubId ? memberships[clubId] : [],
  organizationType,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  signOut,
  setMenuExpanded,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
