import { AjaxError } from 'rxjs/ajax';
import {
  RESET_FILE_UPLOADED, UPLOAD_FILE_ERROR,
  UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS,
} from '../../constants/actionTypes';
import {
  ResetFileUploadedAction,
  UploadFileErrorAction,
  UploadFileRequestAction,
  UploadFileSuccessAction,
} from '../../models/actionTypes';

export const uploadFileRequest = (
  file: File, replaceId?: string,
): UploadFileRequestAction => ({
  type: UPLOAD_FILE_REQUEST,
  payload: {
    file,
    replaceId,
  },
});

export const resetFileUploaded = (
): ResetFileUploadedAction => ({
  type: RESET_FILE_UPLOADED,
});

export const uploadFileSuccess = (
  id: string, originalFilename: string, extension: string, replaceId?: string,
): UploadFileSuccessAction => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: {
    id,
    originalFilename,
    extension,
    replaceId,
  },
});

export const uploadFileError = (error: AjaxError): UploadFileErrorAction => ({
  type: UPLOAD_FILE_ERROR,
  payload: {
    error,
  },
});
