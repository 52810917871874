import { AjaxError } from 'rxjs/ajax';
import {
  DeleteEmbellishmentValueErrorAction,
  DeleteEmbellishmentValueRequestAction,
  DeleteEmbellishmentValueSuccessAction,
} from '../../models/actionTypes';
import {
  DELETE_EMBELLISHMENT_VALUE_ERROR,
  DELETE_EMBELLISHMENT_VALUE_REQUEST,
  DELETE_EMBELLISHMENT_VALUE_SUCCESS,
} from '../../constants/actionTypes';
import { EmbellishmentValue } from '../../models/Embellishment';

export const deleteEmbellishmentValueRequest = (
  clubId: number,
  optionId: number,
): DeleteEmbellishmentValueRequestAction => ({
  type: DELETE_EMBELLISHMENT_VALUE_REQUEST,
  payload: { clubId, optionId },
});

export const deleteEmbellishmentValueSuccess = (
  embellishmentValue: EmbellishmentValue,
): DeleteEmbellishmentValueSuccessAction => ({
  type: DELETE_EMBELLISHMENT_VALUE_SUCCESS,
  payload: { embellishmentValue },
});

export const deleteEmbellishmentValueError = (
  error: AjaxError,
): DeleteEmbellishmentValueErrorAction => ({
  type: DELETE_EMBELLISHMENT_VALUE_ERROR,
  payload: {
    error,
  },
});
