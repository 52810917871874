import { AjaxError } from 'rxjs/ajax';
import {
  DELETE_ORDERS_ERROR,
  DELETE_ORDERS_REQUEST,
  DELETE_ORDERS_SUCCESS,
} from '../../constants/actionTypes';
import {
  DeleteOrdersRequestAction,
  DeleteOrdersSuccessAction,
  DeleteOrdersErrorAction,
} from '../../models/actionTypes';

export const deleteOrdersRequest = (orderIds: number[]): DeleteOrdersRequestAction => ({
  type: DELETE_ORDERS_REQUEST,
  payload: {
    orderIds,
  },
});

export const deleteOrdersSuccess = (
  orderIds: number[],
): DeleteOrdersSuccessAction => ({
  type: DELETE_ORDERS_SUCCESS,
  payload: {
    orderIds,
  },
});

export const deleteOrdersError = (error: AjaxError): DeleteOrdersErrorAction => ({
  type: DELETE_ORDERS_ERROR,
  payload: {
    error,
  },
});
