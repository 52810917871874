import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EmbellishmentValueSelectionProps } from './EmbellishmentValueSelectionProps';
import EmbellishmentValueSelectionHeader
  from './components/EmbellishmentValueSelectionHeader';
import TableContainer from '../../components/Layout/components/TableContainer';
import columns from './columns';
import { EmbellishmentValue } from '../../models/Embellishment';
import EmbellishmentValueRow from './components/EmbellishmentValueRow/EmbellishmentValueRow';
import styles from './EmbellishmentValueSelection.module.scss';
import Confirm from '../../components/Confirm';
import AddEditEmbellishmentValuesModal
  from './components/AddEditEmbellishmentValuesModal/AddEditEmbellishmentValuesModal';

const EmbellishmentValueSelection = ({
  club,
  clubId,
  embellishmentsByHash,
  fetchEmbellishmentValues,
  fetchEmbellishments,
  embellishmentValues,
  loadingEmbellishmentValues,
  fetchEmbellishmentValuesError,
  createEmbellishmentValue,
  updateEmbellishmentValue,
  deleteEmbellishmentValue,
}: EmbellishmentValueSelectionProps) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  const [translate] = useTranslation();
  const history = useHistory();
  const [
    currentEmbellishmentValue,
    setCurrentEmbellishmentValue,
  ] = useState<EmbellishmentValue | undefined>();

  const fetch = () => {
    if (clubId && !loadingEmbellishmentValues && !fetchEmbellishmentValuesError) {
      fetchEmbellishmentValues(clubId);
    }
  };

  useEffect(() => {
    fetch();
    fetchEmbellishments();
    // eslint-disable-next-line
  }, [clubId]);

  const onSelect = (id: number) => {
    if (selectedValues.includes(id)) {
      setSelectedValues(selectedValues.filter(val => val === id));
    } else {
      setSelectedValues([...selectedValues, id]);
    }
  };

  const onRowClick = (id: number) => {
    const found = embellishmentValues.find(embellishmentValue => embellishmentValue.id === id);
    if (found) {
      setCurrentEmbellishmentValue(found);
      setShowModal(true);
    }
  };

  const onDelete = () => {
    if (selectedValues.length) {
      selectedValues.forEach((id: number) => {
        if (clubId) {
          deleteEmbellishmentValue(clubId, id);
        }
      });
      setSelectedValues([]);
    }
  };

  const renderEmbellishmentValueRow = (embellishmentValue: EmbellishmentValue) => (
    <EmbellishmentValueRow
      embellishments={Object.values(embellishmentsByHash)}
      onRowClick={onRowClick}
      embellishmentValue={embellishmentValue}
      isSelected={embellishmentValue.id ? selectedValues.includes(embellishmentValue.id) : false}
      onSelect={onSelect}
    />
  );

  const saveEmbellishmentValue = (embellishmentValue: EmbellishmentValue) => {
    if (clubId) {
      if (embellishmentValue.id) {
        updateEmbellishmentValue(clubId, embellishmentValue);
      } else {
        createEmbellishmentValue(clubId, embellishmentValue);
      }
    }
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
      <AddEditEmbellishmentValuesModal
        embellishmentValue={currentEmbellishmentValue}
        onClose={() => {
          setShowModal(false);
        }}
        onSave={saveEmbellishmentValue}
        embellishments={Object.values(embellishmentsByHash).filter((embellishment) => embellishment.valueType === 'select')}
      />
      )}
      <div className={styles.wrapper}>
        <EmbellishmentValueSelectionHeader
          club={club || null}
          clubId={clubId}
          showEmbellishmentValuesModal={() => {
            setCurrentEmbellishmentValue(undefined);
            setShowModal(true);
          }}
        />
        <div className={[styles.topGroup, styles.fixHeight].join(' ')}>
          { !fetchEmbellishmentValuesError && (
          <TableContainer
            disableSearch
            columns={columns}
            fetch={fetch}
            disableChangeListeners
            loading={loadingEmbellishmentValues}
            renderItems={embellishmentValues.map(renderEmbellishmentValueRow)}
            invertedHeader
            emptyTableText="NO_EMBELLISHMENT_VALUES_AVAILABLE"
            notFoundTableText="NO_EMBELLISHMENT_VALUES_AVAILABLE"
            count={
                    embellishmentValues && embellishmentValues.length
                      ? embellishmentValues.length : 0}
            history={history}
          />
          )
          }
          {!loadingEmbellishmentValues && fetchEmbellishmentValuesError && (
          <div>
            <span>{translate('FETCH_EMBELLISHMENT_VALUES_ERROR')}</span>
          </div>
          )}
        </div>

        {!fetchEmbellishmentValuesError && (
        <div className={styles.footer}>
          <span className={styles.selectedPlayersLabel}>
            {`${selectedValues.length} ${translate('ITEMS_SELECTED')}`}
          </span>
          <Confirm
            basic
            primary
            confirmText={translate('ARE_YOU_SURE_TO_DELETE_EMBELLISHMENT_VALUES')}
            onConfirm={onDelete}
            className={[styles.action, styles.button].join(' ')}
            triggerText={`${translate('DELETE_EMBELLISHMENT_VALUES')} (${selectedValues.length})`}
            disabled={!selectedValues.length}
          />
        </div>
        )}
      </div>

    </>
  );
};

export default EmbellishmentValueSelection;
