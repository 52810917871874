import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { push } from 'connected-react-router';

import { CATALOG_PRODUCTS_PATH } from '../../constants/paths';
import { CATALOG_CREATOR_ROUTE } from '../../constants/routes';
import { SAVE_PRODUCT_FILES_REQUEST, SAVE_PRODUCT_FILES_ERROR } from '../../constants/actionTypes';
import { SaveProductFilesRequestAction } from '../../models/actionTypes';
import { State } from '../../models/State';
import { handleError } from '../../actions/errors/handleError';
import { saveProductFilesSuccess, saveProductFilesError } from '../../actions/catalogs/updateProduct';

export default (
  action$: ActionsObservable<SaveProductFilesRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(SAVE_PRODUCT_FILES_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { product, clubId } },
    { authentication: { authToken } },
  ]) => ajax({
    url: CATALOG_PRODUCTS_PATH.replace('$clubId', String(clubId)),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      ...product,
      productId: product.id,
      status: product.productCatalogStatus || 'ACTIVE',
    }),
  }).pipe(
    mergeMap(() => {
      const backLink = `${CATALOG_CREATOR_ROUTE.replace(':clubId', String(clubId))}`;
      return [
        saveProductFilesSuccess(),
        push(backLink),
      ];
    }),
    catchError((error: AjaxError) => {
      if (error.status === 400) {
        return [saveProductFilesError(error)];
      }
      return ([
        handleError(error, SAVE_PRODUCT_FILES_ERROR),
        saveProductFilesError(error),
      ]);
    }),
  )),
);
