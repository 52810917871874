import React, { FC } from 'react';
import SortArrow from '../../../../icons/SortArrow';
import styles from './SortArrows.module.scss';
import SortingOrder from '../../../../constants/sortingOrder';

interface SortArrowProps {
  sortOrder?: string | null;
}

const SortArrows: FC<SortArrowProps> = ({ sortOrder }) => (
  <div className={styles['sort-icons']}>
    <SortArrow fill={sortOrder === SortingOrder.ASC ? '#FFF' : '#B4B4B4'} />
    <SortArrow fill={sortOrder === SortingOrder.DESC ? '#FFF' : '#B4B4B4'} />
  </div>
);

export default SortArrows;
