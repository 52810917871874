import React, { useEffect, useState } from 'react';
import {
  Button,
  Input, Pagination, PaginationProps,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../../hooks/usePagination';
import useSearchFilter from '../../../../hooks/useSearchFilter';
import useSorting from '../../../../hooks/useSorting';
import params from '../../../../utils/params';
import styles from './TableContainer.module.scss';
import { TableContainerProps } from './TableContainerProps';
import Table from '../../../Table';
import {
  TABLE_PAGE_URL_PARAM,
  TABLE_SEARCH_URL_PARAM,
  TABLE_SORT_ORDER_URL_PARAM,
  TABLE_SORT_FIELD_URL_PARAM,
  TABLE_FILTER_URL_PARAM, DEFAULT_LIST_LIMIT,
} from '../../../../constants/customizations';
import useFilters from '../../../../hooks/useFilters';

const TableContainer = ({
  fetch,
  loading,
  count,
  history,
  columns,
  renderItems,
  renderFilters,
  renderNewElementLogic,
  searchPlaceholder,
  invertedHeader = false,
  disableSearch = false,
  disableChangeListeners = false,
  emptyTableText,
  notFoundTableText,
  extraFetchParameters,
  limit = DEFAULT_LIST_LIMIT,
  extraFilters,
}: TableContainerProps) => {
  const { getParams, setParam } = params(history);
  const [translate] = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [onPageChange, totalPages, showPagination] = usePagination(history, count, loading, limit);
  const [toggleFilter, onChange, filterPanelVisible] = useSearchFilter(history);
  const {
    onSortChange,
    sortOrder,
    setSortOrder,
    sortField,
    setSortField,
  } = useSorting(history);
  const { onFilterChange, filter: savedFilters, setFilter } = useFilters(history);

  const {
    [TABLE_SEARCH_URL_PARAM]: search,
    [TABLE_PAGE_URL_PARAM]: page,
    [TABLE_SORT_FIELD_URL_PARAM]: field,
    [TABLE_SORT_ORDER_URL_PARAM]: order,
    [TABLE_FILTER_URL_PARAM]: filter,
  } = getParams();

  useEffect(() => {
    if (search && !searchValue) {
      setSearchValue(search as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (filter) {
      setFilter(JSON.parse(filter as string));
    }
  }, [filter, setFilter]);

  useEffect(() => {
    if (!disableChangeListeners) {
      fetch({
        page, search, field, order, filter, ...extraFetchParameters,
      });
      setSortField(field as string);
      setSortOrder(order as string);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, page, search, field, filter, order, setSortField, setSortOrder]);

  const handleSearchChange = ({ target: { value } }: any) => {
    // @ts-ignore
    onChange(value);
    setParam(TABLE_PAGE_URL_PARAM, '');
    setSearchValue(value);
  };

  const handleResetSearch = () => {
    // @ts-ignore
    onChange('');
    setSearchValue('');
    setParam(TABLE_PAGE_URL_PARAM, '');
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.searchExtraFiltersWrapper}>
          {(!disableSearch || !!extraFilters) && (
          <div className={styles.header}>
            {!disableSearch && (
            <div className={styles.searchFilters}>
              {!!renderNewElementLogic && renderNewElementLogic()}

              <Input
                className={`search ${styles.searchBar}`}
                value={searchValue || ''}
                onChange={handleSearchChange as any}
                placeholder={translate(searchPlaceholder || 'SEARCH')}
                icon={search
                  ? { name: 'close', link: true, onClick: handleResetSearch }
                  : 'search'
                }
              />

              {!!renderFilters && (
              <Button
                basic
                icon="filter"
                // @ts-ignore
                onClick={toggleFilter}
              />
              )}
            </div>
            )}
            {!!extraFilters && (extraFilters)}
          </div>
          )}
        </div>
        <div className={styles.wrapper}>
          <Table
            columns={columns}
            loading={loading}
            onSortChange={onSortChange}
            onFilterChange={onFilterChange}
            sortOrder={sortOrder}
            sortField={sortField}
            filters={savedFilters}
            invertedHeader={invertedHeader}
            emptyTableText={search ? notFoundTableText : emptyTableText}
          >
            {renderItems}
          </Table>
        </div>
        <div className={styles.pagination}>
          {showPagination && (
            <Pagination
              defaultActivePage={page as string || 1}
              totalPages={totalPages as number}
              onPageChange={onPageChange as (event: any, data: PaginationProps) => void}
            />
          )}
        </div>
      </div>
      { !!renderFilters && renderFilters({ visible: filterPanelVisible }) }
    </>
  );
};

export default TableContainer;
