import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Form, Message,
} from 'semantic-ui-react';

import FormFields from '../../components/FormFields';
import Header from '../../components/Header';
import styles from './CreateTeam.module.scss';
import useFocusFirstInput from '../../hooks/useFocusFirstInput';
import useForm from '../../hooks/useForm';
import { CLUB_TEAMS_ROUTE } from '../../constants/routes';
import { CreateTeamProps } from './CreateTeamProps';
import { createTeamSchema, validationSchema } from './schema';

const CreateTeam = ({
  createTeam,
  error,
  loading,
  match,
}: CreateTeamProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [translate] = useTranslation();
  const { clubId } = match.params;

  const {
    values,
    errors,
    handleChange,
    validate,
  } = useForm(validationSchema);

  useFocusFirstInput();

  const handleSubmit = () => {
    setIsSubmitted(true);
    if (validate() && clubId) {
      const { teamName } = values;
      createTeam(+clubId, teamName);
    }
  };

  const renderButton = () => (
    <Button
      primary
      disabled={loading}
      type="submit"
      content={translate('SAVE')}
      form="create-form"
    />
  );

  return (
    <div className={styles.wrapper}>
      <Header
        backLabel="TO_CLUB_TEAMS"
        backLinkTo={CLUB_TEAMS_ROUTE.replace(':clubId', clubId)}
        renderButton={renderButton}
        title={translate('ADD_NEW_TEAM')}
        border
      />

      <Form error={error} onSubmit={handleSubmit} loading={loading} className={styles.form} id="create-form">
        {isSubmitted && error && (
        <Message error={error} header={translate('ERROR')} content={translate('GENERAL_ERROR')} />)}
        <FormFields
          errors={errors}
          handleChange={handleChange}
          schema={createTeamSchema}
          values={values}
        />
      </Form>
    </div>
  );
};

export default CreateTeam;
