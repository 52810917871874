import { FormSchema } from '../../models/Form';
import { isRequired } from '../../utils/formValidators';

export const signInSchema: FormSchema = ({
  email: {
    type: 'text',
    label: 'EMAIL',
  },
  password: {
    type: 'password',
    label: 'PASSWORD_TEXT',
  },
});

export const validationSchema = {
  email: [isRequired],
  password: [isRequired],
};
