import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox, CheckboxProps,
  Dimmer, Dropdown, Icon, Loader,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Row from '../../components/Table/components/Row';
import Text from '../../components/Text';
import TableContainer from '../../components/Layout/components/TableContainer';
import styles from '../Settings/Settings.module.scss';
import ClubDetailsHeader from '../../components/ClubDetailsHeader';
import filteredColumns from './filteredColumns';
import { ClubTeamsProps } from './ClubTeamsProps';
import { ClubTeam } from '../../models/ClubTeam';
import TeamStatus from '../../constants/teamStatus';
import Confirm from '../../components/Confirm/Confirm';
import {
  CLUB_TEAMS_CREATE_MEMBER_ROUTE,
  CLUB_TEAMS_PENDING_INVITATIONS_ROUTE, CLUB_TEAMS_UPDATE_CLUB_ADMIN_TO_TEAM_LEAD_ROUTE,
} from '../../constants/routes';
import TeamInvitationPopup from './components/TeamInvitationPopup';
import SetDfbDivisionPopup from './components/SetDfbDivisionPopup';
import { formatDate } from '../../utils/DateUtils';

const ClubTeams = ({
  fetchClubTeams,
  fetchClub,
  fetchInviteToken,
  deleteTeam,
  changeTeamStatus,
  loading,
  count,
  clubTeams,
  match,
  history,
  club: { name },
} : ClubTeamsProps) => {
  const [translate] = useTranslation();
  const [showInvitationPopup, setShowInvitationPopup] = useState<boolean>(false);
  const [showInactiveTeams, setShowInactiveTeams] = useState<boolean>(false);
  const [shownTeams, setShownTeams] = useState<ClubTeam[]>(
    clubTeams.filter(team => team.status === TeamStatus.ACTIVE),
  );
  const [
    divisionPopupTeam,
    setDivisionPopupTeam,
  ] = useState<ClubTeam | undefined>(undefined);
  const { clubId } = match.params;

  useEffect(() => {
    if (showInactiveTeams) {
      setShownTeams(clubTeams);
    } else {
      setShownTeams(clubTeams.filter(team => team.status === TeamStatus.ACTIVE));
    }
  }, [clubTeams, showInactiveTeams]);

  useEffect(() => {
    if (!name) {
      fetchClub(clubId);
    }
  }, [clubId, fetchClub, name]);

  const onDeleteTeam = (id: number) => {
    deleteTeam(id);
  };

  const renderRows = (
    team: ClubTeam,
  ) => {
    const isTeamActive = team.status === TeamStatus.ACTIVE;

    return (
      <Row key={team.id}>
        <Text bold>{`#${team.id}`}</Text>
        <Text>{team.name}</Text>
        <Text>{translate(team.season)}</Text>
        <Text>{team.teamLeads ? team.teamLeads.length.toString() : '0'}</Text>
        <Text>{`${team.pendingTeamLeads ? team.pendingTeamLeads.length : '0'}`}</Text>
        <Text>{`${team.players ? team.players.length : '0'}`}</Text>
        <Text>{`${team.seasonStartDate ? formatDate(team.seasonStartDate) : translate('UNKNOWN')}`}</Text>
        <Text>{`${team.seasonEndDate ? formatDate(team.seasonEndDate) : translate('UNKNOWN')}`}</Text>
        <Text>{translate(team.status)}</Text>
        <div onClick={(e: any) => {
          e.stopPropagation();
        }}
        >
          <Dropdown
            icon={<Icon name="ellipsis vertical" color="blue" size="large" />}
            direction="left"
            basic
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text={translate('ADD_TEAM_MEMBER')}
                as={Link}
                to={CLUB_TEAMS_CREATE_MEMBER_ROUTE.replace(':clubId', clubId).replace(':teamId', `${team.id}`)}
              />
              <Dropdown.Item
                text={translate(isTeamActive ? 'DEACTIVATE_TEAM' : 'ACTIVATE_TEAM')}
                onClick={() => {
                  changeTeamStatus(team.id, isTeamActive ? TeamStatus.INACTIVE : TeamStatus.ACTIVE);
                }}
              />
              {(team.pendingTeamLeads && team.pendingTeamLeads.length > 0) && (
              <Dropdown.Item
                text={translate('RESEND_INVITATIONS')}
                as={Link}
                to={CLUB_TEAMS_PENDING_INVITATIONS_ROUTE.replace(':clubId', clubId).replace(':teamId', `${team.id}`)}
              />
              )}
              {isTeamActive && (
              <>
                <Dropdown.Item
                  text={translate('BULK_INVITE_PLAYERS')}
                  onClick={() => {
                    setShowInvitationPopup(true);
                    fetchInviteToken(team.id, clubId);
                  }}
                />
                <Dropdown.Item
                  text={translate('ADD_TEAM_LEAD_ROLE_TO_CLUB_ADMIN')}
                  as={Link}
                  to={`${CLUB_TEAMS_UPDATE_CLUB_ADMIN_TO_TEAM_LEAD_ROUTE.replace(
                    ':clubId',
                    `${clubId}`,
                  )
                  }?teamId=${team.id}`}
                />
              </>
              )}
              <Dropdown.Item
                text={translate('SET_DFB_DIVISION')}
                onClick={() => {
                  setDivisionPopupTeam(team);
                }}
              />
              {isTeamActive && (
              <Confirm
                as={Dropdown.Item}
                className={styles.menuOptionDelete}
                confirmText={translate('ARE_YOU_SURE_TO_DELETE_TEAM')}
                onConfirm={() => onDeleteTeam(team.id)}
                triggerText={translate('DELETE_TEAM')}
                disabled={!team.removable}
              />
              )}
            </Dropdown.Menu>
          </Dropdown>
          <TeamInvitationPopup
            isOpen={showInvitationPopup}
            teamId={team.id}
            closePopup={() => {
              setShowInvitationPopup(false);
            }}
          />
          {divisionPopupTeam !== undefined && (
          <SetDfbDivisionPopup
            team={divisionPopupTeam}
            clubId={clubId}
            closePopup={() => {
              setDivisionPopupTeam(undefined);
            }}
          />
          )}
        </div>
      </Row>
    );
  };

  const toggleInactiveTeams = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    setShowInactiveTeams(data.checked || false);
  };

  return (
    <>
      {loading && <Dimmer active inverted><Loader /></Dimmer>}
      <ClubDetailsHeader clubName={name} clubId={clubId} />
      <div className={styles.wrapper}>
        <TableContainer
          extraFetchParameters={{ clubId }}
          fetch={fetchClubTeams}
          loading={loading}
          count={count}
          history={history}
          extraFilters={(
            <Checkbox label={translate('SHOW_INACTIVE_TEAMS')} onChange={toggleInactiveTeams} />
          )}
          columns={filteredColumns(translate)}
          renderItems={shownTeams ? shownTeams.map(renderRows) : []}
          disableSearch
          invertedHeader
          emptyTableText="NO_TEAMS"
        />
      </div>
    </>
  );
};

export default ClubTeams;
