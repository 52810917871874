import React from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ClubsRow from './components/ClubsRow';
import Header from '../../components/Header';
import TableContainer from '../../components/Layout/components/TableContainer';
import columns from './columns';
import styles from './Clubs.module.scss';
import { Club } from '../../models/Club';
import { ClubProps } from './ClubProps';
import { NEW_CLUB_ROUTE } from '../../constants/routes';
import { Roles } from '../../constants/roles';

const Clubs = ({
  loadingList,
  clubs,
  count,
  fetch,
  history,
  userRoles,
}: ClubProps) => {
  const [translate] = useTranslation();
  const isUserSalesRep = userRoles.includes(Roles.SALES_REPRESENTATIVE);

  const renderClubRows = (club: Club) => (
    <ClubsRow club={club} key={club.id} />
  );

  const renderNewElementLogic = () => (
    <Button
      as={Link}
      primary
      content={`+ ${translate('ADD_NEW_CLUB')}`}
      key="button"
      to={NEW_CLUB_ROUTE}
    />
  );

  return (
    <div className={styles.wrapper}>
      <Header
        border
        title={translate('CLUBS')}
        renderButton={isUserSalesRep ? renderNewElementLogic : undefined}
      />

      <div className={styles.tableWrapper}>
        <TableContainer
          fetch={fetch}
          loading={loadingList}
          count={count}
          history={history}
          columns={columns}
          renderItems={clubs.map(renderClubRows)}
          searchPlaceholder="SEARCH_FOR_CLUBS"
          invertedHeader
          emptyTableText="NO_CLUBS_AVAILABLE"
          notFoundTableText="NO_CLUBS_FOUND"
        />
      </div>
    </div>
  );
};

export default Clubs;
