import { FormSchema } from '../../../../models/Form';
import { isRequired } from '../../../../utils/formValidators';
import { Embellishment } from '../../../../models/Embellishment';

const getOptions = (values: Embellishment[]) => values.map(value => ({
  key: value.type,
  text: value.name,
  value: value.type,
}));

export const getFormSchema = (embellishments: Embellishment[]) => ({
  selectOption: {
    type: 'text',
    label: 'EMBELLISHMENT_VALUE',
    placeholder: 'EMBELLISHMENT_VALUE',
  },
  embellishment: {
    type: 'select',
    label: 'EMBELLISHMENT',
    placeholder: 'EMBELLISHMENT',
    options: () => getOptions(embellishments),
  },
} as FormSchema);

export const validationSchema = {
  selectOption: [isRequired],
  embellishment: [isRequired],
};
