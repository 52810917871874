import queryString from 'query-string';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { push } from 'connected-react-router';

import enrichCatalog from '../../utils/serializers/enrichCatalog';
import { CATALOG_PATH } from '../../constants/paths';
import { CLUBS_CATALOGS_ROUTE, CLUBS_ROUTE, PARAM_CATALOGS } from '../../constants/routes';
import { CREATE_CATALOG_ERROR, CREATE_CATALOG_REQUEST, CREATE_CATALOG_SUCCESS } from '../../constants/actionTypes';
import { CreateCatalogRequestAction, NotificationType } from '../../models/actionTypes';
import { State } from '../../models/State';
import { addNotification } from '../../actions/notifications/addNotification';
import { createCatalogError, createCatalogSuccess } from '../../actions/catalogs/createCatalog';
import { handleError } from '../../actions/errors/handleError';

export default (
  action$: ActionsObservable<CreateCatalogRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CREATE_CATALOG_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { clubId, catalog } },
    { authentication: { authToken }, router: { location: { search } } },
  ]) => ajax({
    url: CATALOG_PATH.replace('$clubId', String(clubId)),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(enrichCatalog(catalog)),
  }).pipe(
    mergeMap(() => {
      const { from } = queryString.parse(search);
      const backLinkTo = from === PARAM_CATALOGS ? CLUBS_CATALOGS_ROUTE : CLUBS_ROUTE;

      return [
        createCatalogSuccess(clubId),
        addNotification(NotificationType.SUCCESS, CREATE_CATALOG_SUCCESS),
        push(backLinkTo),
      ];
    }),
    catchError((error: AjaxError) => ([
      handleError(error, CREATE_CATALOG_ERROR),
      createCatalogError(error),
    ])),
  )),
);
