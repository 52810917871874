import React, {
  FC,
} from 'react';
import {
  Menu,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';
import styles from './RewardsActivitiesList.module.scss';
import { REWARDS_ROUTE, REWARDS_TEAMS_ROUTE } from '../../../constants/routes';

export interface RewardsParams {
  clubId: string;
  teamId?: string;
}

export interface RewardsHeadersProps extends RouteComponentProps<RewardsParams> {
  clubId: number
}

const RewardsHeader: FC<RewardsHeadersProps> = ({
  clubId,
  history: { location: { pathname } },
}) => {
  const [translate] = useTranslation();
  const linkWithParams = (value: string) => value.replace(':clubId', String(clubId));
  const clubRewardsRoute = linkWithParams(REWARDS_ROUTE);
  const teamsRewardsRoute = linkWithParams(REWARDS_TEAMS_ROUTE);
  return (
    <div className={styles.container}>
      <Menu className="filter" pointing secondary>
        <Menu.Item
          active={pathname === clubRewardsRoute}
          as={Link}
          content={translate('CLUB')}
          to={`${clubRewardsRoute}`}
        />
        <Menu.Item
          active={pathname === teamsRewardsRoute}
          as={Link}
          content={translate('TEAMS')}
          to={`${teamsRewardsRoute}`}
        />
      </Menu>
    </div>
  );
};

export default RewardsHeader;
