import { AjaxError } from 'rxjs/ajax';

import {
  ADD_PRODUCT_ERROR,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_HOBBY_PRODUCT_SUCCESS,
} from '../../constants/actionTypes';
import { Product } from '../../models/Product';
import {
  AddProductRequestAction,
  AddProductSuccessAction,
  AddProductErrorAction,
  AddHobbyProductSuccessAction,
} from '../../models/actionTypes';

export const addProductRequest = (
  clubId: number | null, productId: string,
): AddProductRequestAction => ({
  type: ADD_PRODUCT_REQUEST,
  payload: {
    clubId,
    productId,
  },
});

export const addProductSuccess = (clubId: number, product: Product): AddProductSuccessAction => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: {
    clubId,
    product,
  },
});

export const addHobbyProductSuccess = (product: Product): AddHobbyProductSuccessAction => ({
  type: ADD_HOBBY_PRODUCT_SUCCESS,
  payload: {
    product,
  },
});

export const addProductError = (error: AjaxError): AddProductErrorAction => ({
  type: ADD_PRODUCT_ERROR,
  payload: {
    error,
  },
});
