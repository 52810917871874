import React, { FC, useState } from 'react';
import { Button, Confirm, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './Confirm.module.scss';
import { ConfirmProps } from './ConfirmProps';

const ConfirmComponent: FC<ConfirmProps> = ({
  onConfirm, triggerText, confirmText, as, disabled,
  renderPopup = false, popupText, popupOnDisabled = false,
  open, onClick, onCancel, ...props
}) => {
  const [translate] = useTranslation();
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  const setOpen = (value: boolean) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setConfirmIsOpen(value);
  };

  const handleConfirm = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setConfirmIsOpen(false);
    onConfirm(event);
  };

  const TriggerButton = (
    <Button
      as={as}
      content={triggerText}
      onClick={onClick || setOpen(true)}
      {...props}
      disabled={disabled}
    />
  );

  return (
    <>
      {renderPopup && popupText ? (
        <Popup
          trigger={TriggerButton}
          content={translate(popupText)}
          disabled={popupOnDisabled ? !disabled : false}
          position="bottom center"
        />
      ) : TriggerButton}
      <Confirm
        className={styles.confirmModal}
        onCancel={onCancel || setOpen(false)}
        onConfirm={handleConfirm}
        open={open || confirmIsOpen}
        header={translate('ARE_YOU_SURE')}
        content={confirmText}
        cancelButton={(
          <Button
            basic
            className={styles.button}
            content={translate('NO')}
            fluid
            primary
          />
        )}
        confirmButton={(
          <Button
            className={styles.button}
            content={translate('YES')}
            fluid
            primary
          />
        )}
      />
    </>
  );
};

export default ConfirmComponent;
