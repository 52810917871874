import { Column } from '../../../../components/Table/Column';

const columns: Column[] = [{
  width: '35%',
  header: 'CLUB_NAME',
  align: 'left',
  key: 'name',
  sortable: true,
}, {
  width: '35%',
  header: 'LOCATION',
  align: 'left',
  key: 'addressDisplayName',
  sortable: true,
}, {
  width: '15%',
  header: 'TEAMS',
  align: 'center',
  key: 'teamsCount',
  sortable: true,
}, {
  width: '15%',
  header: 'PLAYERS',
  align: 'center',
  key: 'playerCount',
  sortable: true,
}];

export default columns;
