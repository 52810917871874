import React, { FC, useEffect } from 'react';

import Sidebar from './components/Sidebar';
import styles from './Layout.module.scss';
import { LayoutProps } from './LayoutProps';

const Layout: FC<LayoutProps> = ({
  children,
  signOut,
  menuExpanded,
  menuItems,
  setMenuExpanded,
  location: { pathname },
}) => {
  useEffect(() => window.scrollTo(0, 0), [pathname]);

  return (
    <div className={`${styles.wrapper} ${menuExpanded ? '' : styles.paddingSmall}`}>
      <Sidebar
        signOut={signOut}
        menuExpanded={menuExpanded}
        menuItems={menuItems}
        setMenuExpanded={setMenuExpanded}
      />

      {children}
    </div>
  );
};

export default Layout;
