import { AjaxError } from 'rxjs/ajax';
import {
  FETCH_EMBELLISHMENT_VALUES_ERROR,
  FETCH_EMBELLISHMENT_VALUES_REQUEST,
  FETCH_EMBELLISHMENT_VALUES_SUCCESS,
} from '../../constants/actionTypes';
import { EmbellishmentValue } from '../../models/Embellishment';
import {
  FetchEmbellishmentValuesErrorAction,
  FetchEmbellishmentValuesRequestAction,
  FetchEmbellishmentValuesSuccessAction,
} from '../../models/actionTypes';

export const fetchEmbellishmentValuesRequest = (
  clubId: number,
): FetchEmbellishmentValuesRequestAction => ({
  type: FETCH_EMBELLISHMENT_VALUES_REQUEST,
  payload: { clubId },
});

export const fetchEmbellishmentValuesSuccess = (
  embellishmentValues: { embellishmentSelectOptions: EmbellishmentValue[] },
): FetchEmbellishmentValuesSuccessAction => ({
  type: FETCH_EMBELLISHMENT_VALUES_SUCCESS,
  payload: { embellishmentValues },
});

export const fetchEmbellishmentValuesError = (
  error: AjaxError,
): FetchEmbellishmentValuesErrorAction => ({
  type: FETCH_EMBELLISHMENT_VALUES_ERROR,
  payload: {
    error,
  },
});
