import { ApplicationParameters } from '../../models/Application';
import { SetApplicationParameterAction } from '../../models/actionTypes/ApplicationActions';
import { SET_APPLICATION_PARAMETERS } from '../../constants/actionTypes';

export const setApplicationParameters = (
  params: ApplicationParameters,
): SetApplicationParameterAction => ({
  type: SET_APPLICATION_PARAMETERS,
  payload: {
    params,
  },
});
