import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Divider, Header, Input, Label, Modal, Table,
} from 'semantic-ui-react';

import PdfModal from '../../../../containers/ProductDetails/components/PdfModal';
import styles from './SelectFiles.module.scss';
import { SelectFilesProps } from './SelectFilesProps';

const SelectFiles = ({ filesToSelect, selectedFile, toggleFile }: SelectFilesProps) => {
  const [translate] = useTranslation();
  const [search, setSearch] = useState('');

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value);
  const handleSelect = (id: string, originalFilename: string, extension: string) => () => {
    toggleFile({ id, originalFilename, extension });
  };

  const filesToSelectFiltered = (filesToSelect || []).filter(({ originalFilename }) => (
    originalFilename.toLowerCase().includes(search.toLowerCase())
  ));

  return (
    <Modal.Content>
      <Divider />
      <Header
        as="h4"
        color="red"
        content={translate('ATTACH_LIBRARY_FROM')}
        styles={styles.header}
      />
      <Input
        className={`search ${styles.search}`}
        fluid
        icon="search"
        onChange={handleSearch}
        placeholder={translate('SEARCH_ATTACHMENTS')}
        type="text"
      />

      <Table striped basic="very" className={styles.table}>
        <Table.Body>
          {filesToSelectFiltered.map(({ id, originalFilename, extension }) => (
            <Table.Row key={id}>
              <Table.Cell collapsing>
                <Label color="black" content={extension} className={styles.label} />
              </Table.Cell>
              <Table.Cell
                className={styles.clickable}
              >
                {originalFilename}
              </Table.Cell>
              <Table.Cell collapsing>
                <PdfModal
                  file={{ id, originalFilename, extension }}
                  triggerButton={(
                    <Button size="mini" icon="unhide" content="Preview" />
                  )}
                />
              </Table.Cell>
              <Table.Cell textAlign="right" collapsing>
                <div>
                  <Button
                    basic={!selectedFile || selectedFile.id !== id}
                    compact
                    content={translate('SELECT')}
                    onClick={handleSelect(id, originalFilename, extension)}
                    primary
                    size="tiny"
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Modal.Content>
  );
};

export default SelectFiles;
