import { AjaxError } from 'rxjs/ajax';
import {
  FETCH_STAFF_ERROR,
  FETCH_STAFF_REQUEST,
  FETCH_STAFF_SUCCESS,
} from '../../constants/actionTypes';
import { PendingStaffMember, StaffMember } from '../../models/Staff';
import {
  FetchStaffErrorAction,
  FetchStaffRequestAction,
  FetchStaffSuccessAction,
} from '../../models/actionTypes/StaffActions';

export const fetchStaffRequest = (teamId: number): FetchStaffRequestAction => ({
  type: FETCH_STAFF_REQUEST,
  payload: {
    teamId,
  },
});

export const fetchStaffSuccess = (
  staffMembers: StaffMember[], pendingStaffMembers: PendingStaffMember[],
): FetchStaffSuccessAction => ({
  type: FETCH_STAFF_SUCCESS,
  payload: {
    staffMembers,
    pendingStaffMembers,
  },
});

export const fetchStaffError = (error: AjaxError): FetchStaffErrorAction => ({
  type: FETCH_STAFF_ERROR,
  payload: {
    error,
  },
});
