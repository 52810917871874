import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Header, { HeaderTypes } from '../../../../components/Header';
import styles from './ManufacturerArticleNumber.module.scss';

interface ManufacturerArticleNumberProps {
  articleNumber: string;
}

const ManufacturerArticleNumber: FC<ManufacturerArticleNumberProps> = ({
  articleNumber,
}) => {
  const [translate] = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Header
        title={translate('MANUFACTURER_ARTICLE_NUMBER')}
        type={HeaderTypes.H3}
      />
      <p>
        {articleNumber}
      </p>
    </div>
  );
};

export default ManufacturerArticleNumber;
