import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Icon } from 'semantic-ui-react';
import styles from './PendingInvitations.module.scss';
import Header from '../../components/Header';
import filteredColumns from './filteredColumns';
import { PendingStaffMember } from '../../models/Staff';
import Row from '../../components/Table/components/Row';
import Text from '../../components/Text';
import { PendingInvitationsProps } from './PendingInvitationsProps';
import TableContainer from '../../components/Layout/components/TableContainer';
import { CLUB_TEAMS_ROUTE } from '../../constants/routes';
import { NotificationType } from '../../models/actionTypes';
import config from '../../config';

const PendingInvitations = ({
  fetchTeam,
  fetchStaff,
  resendInvitation,
  match,
  history,
  loading,
  pendingStaffMembers,
  addNotification,
}: PendingInvitationsProps) => {
  const [translate] = useTranslation();
  const { clubId, teamId } = match.params;
  const copyInviteLink = (token: string | undefined) => {
    if (token) {
      navigator.clipboard.writeText(`${config.CLUB_WEB_URL}?invitationToken=${token}`).then(() => {
        addNotification(NotificationType.SUCCESS, 'COPY_INVITE_LINK');
      });
    }
  };

  useEffect(() => {
    fetchTeam(+teamId, +clubId);
    fetchStaff(+teamId);
  }, [fetchTeam, fetchStaff, teamId, clubId]);

  const renderItems = (member: PendingStaffMember) => (
    <Row key={member.invitationToken}>
      <Text>
        {member.firstName}
        {' '}
        {member.lastName}
      </Text>
      <Text>
        {member.email}
      </Text>
      <div>
        <Dropdown
          icon={<Icon name="ellipsis vertical" color="blue" size="large" />}
          direction="left"
          basic
        >
          <Dropdown.Menu>
            <Dropdown.Item
              text={translate('RESEND_INVITE')}
              onClick={() => resendInvitation(member.invitationToken)}
            />
            <Dropdown.Item
              text={translate('COPY_INVITE_LINK')}
              onClick={() => copyInviteLink(member.invitationToken)}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>

    </Row>
  );

  return (
    <>
      <Header
        title={translate('PENDING_TRAINERS')}
        backLabel="BACK_TO_TEAM_MEMBERS"
        backLinkTo={CLUB_TEAMS_ROUTE.replace(':clubId', clubId)}
      />
      <div className={styles.wrapper}>
        <TableContainer
          columns={filteredColumns()}
          renderItems={pendingStaffMembers.map(renderItems)}
          loading={loading}
          count={pendingStaffMembers.length}
          fetch={() => {}}
          history={history}
          invertedHeader
          disableSearch
          limit={100}
        />
      </div>
    </>

  );
};

export default PendingInvitations;
