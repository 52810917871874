import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { handleError } from '../../actions/errors/handleError';
import { fetchSalesRepOrgsError, fetchSalesRepOrgsSuccess } from '../../actions/organizations/FetchSalesRepOrgs';
import { FETCH_SALES_REP_ORGS_ERROR, FETCH_SALES_REP_ORGS_REQUEST } from '../../constants/actionTypes';
import { FILTER_SALES_REP_PATH } from '../../constants/paths';
import { FetchSalesRepOrgsRequestAction } from '../../models/actionTypes';
import { SalesOrganization } from '../../models/Organizations';
import { Response } from '../../models/Response';
import { State } from '../../models/State';

const fetchSalesRepOrgs = (
  action$: ActionsObservable<FetchSalesRepOrgsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_SALES_REP_ORGS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { salesRepOrgsRequest } },
    {
      authentication: { authToken },
    },
  ]) => ajax({
    url: FILTER_SALES_REP_PATH,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(salesRepOrgsRequest),
  }).pipe(
    map(({ response }: Response<SalesOrganization[]>) => (
      fetchSalesRepOrgsSuccess(response)
    )),
    catchError(error => ([
      handleError(error, FETCH_SALES_REP_ORGS_ERROR),
      fetchSalesRepOrgsError(error),
    ])),
  )),
);

export default fetchSalesRepOrgs;
