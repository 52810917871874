import { AjaxError } from 'rxjs/ajax';
import { FetchSalesRepOrgsRequestAction, FetchSalesRepOrgsSuccessAction, FetchSalesRepOrgsErrorAction } from '../../models/actionTypes';
import { FETCH_SALES_REP_ORGS_ERROR, FETCH_SALES_REP_ORGS_SUCCESS, FETCH_SALES_REP_ORGS_REQUEST } from '../../constants/actionTypes';
import { SalesOrganization, FilterSalesOrganizationsRequest } from '../../models/Organizations';

export const fetchSalesRepOrgsRequest = (
  salesRepOrgsRequest: FilterSalesOrganizationsRequest,
): FetchSalesRepOrgsRequestAction => ({
  type: FETCH_SALES_REP_ORGS_REQUEST,
  payload: {
    salesRepOrgsRequest,
  },
});

export const fetchSalesRepOrgsSuccess = (
  organizations: SalesOrganization[],
): FetchSalesRepOrgsSuccessAction => ({
  type: FETCH_SALES_REP_ORGS_SUCCESS,
  payload: {
    organizations,
  },
});

export const fetchSalesRepOrgsError = (error: AjaxError): FetchSalesRepOrgsErrorAction => ({
  type: FETCH_SALES_REP_ORGS_ERROR,
  payload: {
    error,
  },
});
