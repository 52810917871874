import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Login from './Login';
import { signIn } from '../../packages/authentication';
import { State } from '../../models/State';

const mapStateToProps = ({ authentication: { loading, error } }: State) => ({
  loading,
  error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  signIn: bindActionCreators(signIn, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
