import { Catalog } from '../../models/Catalog';
import { enrichProduct } from './enrichProduct';

const enrichCatalog = ({
  products,
  productDiscountPercent,
  teamProductDiscounts = [],
  embellishmentsDiscountPercent,
  teamEmbellishmentsDiscounts = [],
  embellishmentFixedPriceDiscounts = [],
}: Catalog) => ({
  products: (products || []).map(enrichProduct),
  productDiscount: {
    generalDiscountPercent: productDiscountPercent,
    teamDiscounts: teamProductDiscounts
      .filter(({ teamId }) => teamId)
      .map(({ teamId, discountPercent }) => ({ teamId, discountPercent })),
  },
  embellishmentDiscount: {
    generalDiscountPercent: embellishmentsDiscountPercent,
    teamDiscounts: teamEmbellishmentsDiscounts
      .filter(({ teamId }) => teamId)
      .map(({ teamId, discountPercent }) => ({ teamId, discountPercent })),
  },
  embellishmentFixedPriceDiscounts: embellishmentFixedPriceDiscounts
    .filter(({ embellishment, groupId }) => groupId && embellishment)
    .map(({
      embellishment, groupType, groupId, price,
    }) => ({
      embellishment, groupType, groupId, price,
    })),
});

export default enrichCatalog;
