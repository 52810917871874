import { AjaxError } from 'rxjs/ajax';
import { FETCH_HOBBY_CLUBS_ERROR, FETCH_HOBBY_CLUBS_REQUEST, FETCH_HOBBY_CLUBS_SUCCESS } from '../../constants/actionTypes';
import { FetchHobbyClubsAction, FetchHobbyClubsErrorAction, FetchHobbyClubsSuccessAction } from '../../models/actionTypes';
import { HobbyClub } from '../../models/HobbyClub';

const fetchClubsDefaultPayload = {
  page: 1,
  search: '',
};

export const fetchHobbyClubsRequest = (payload = {}): FetchHobbyClubsAction => ({
  type: FETCH_HOBBY_CLUBS_REQUEST,
  payload: {
    ...fetchClubsDefaultPayload,
    ...payload,
  },
});

export const fetchHobbyClubsSuccess = (
  payload: { clubs: HobbyClub[], count: number },
): FetchHobbyClubsSuccessAction => ({
  type: FETCH_HOBBY_CLUBS_SUCCESS,
  payload,
});

export const fetchHobbyClubsError = (error: AjaxError): FetchHobbyClubsErrorAction => ({
  type: FETCH_HOBBY_CLUBS_ERROR,
  payload: {
    error,
  },
});
