import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Text.module.scss';
import { TextProps } from './TextProps';

const Text: FC<TextProps> = ({
  bold,
  children,
  translate,
  placeholder,
  fluid = true,
  ellipsis,
}) => {
  const [trans] = useTranslation();

  return (
    <span
      className={classNames(
        styles.default,
        bold && styles.bold,
        placeholder && styles.placeholder,
        ellipsis && styles.ellipsis,
        fluid && styles.fluid,
      )}
    >
      {/*
      // @ts-ignore */}
      {translate ? trans(children) : children}
      {!children && <em>{trans('UNKNOWN')}</em>}
    </span>
  );
};

Text.defaultProps = {
  bold: false,
  translate: false,
  placeholder: false,
  ellipsis: false,
};

export default Text;
