import { SAVE_SELECTION_DRAFT } from '../../constants/actionTypes';
import { SaveSelectionDraftAction } from '../../models/actionTypes';
import { ProductEmbellishment } from '../../models/Product';

export const saveSelectionDraft = (
  productEmbellishments: ProductEmbellishment[],
): SaveSelectionDraftAction => ({
  type: SAVE_SELECTION_DRAFT,
  payload: {
    productEmbellishments,
  },
});
