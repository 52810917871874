import { Product, ProductRequest } from '../../models/Product';

export const enrichProduct = ({
  id,
  externalId,
  files,
  tagIds,
  embellishments,
  description,
  exclusiveToTeams,
  name,
  discountPercent = 0,
}: Product): ProductRequest => ({
  id,
  description,
  embellishments,
  externalId,
  files,
  name,
  tagIds,
  discountPercent,
  exclusiveToTeams,
});
