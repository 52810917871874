import { connect } from 'react-redux';

import PriceDiscountsPopup from './PriceDiscountsPopup';
import { PriceDiscountsPopupParams } from './PriceDiscountsPopupProps';
import { State } from '../../../../models/State';

const mapStateToProps = (
  {
    catalogs: { byHash: catalogsByHash },
    clubs: { byHash: clubsByHash, loading },
  }: State,
  { clubId }: PriceDiscountsPopupParams,
) => {
  const catalog = catalogsByHash[clubId];
  const { teams = [] } = clubsByHash[clubId] || {};

  return {
    catalog,
    teams,
    loading,
  };
};

export default connect(mapStateToProps)(PriceDiscountsPopup);
