import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import FileUploadModal from './FileUploadModal';
import { resetFileUploaded } from '../../actions/files/uploadFile';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetFileUploaded: bindActionCreators(resetFileUploaded, dispatch),
});

export default connect(null, mapDispatchToProps)(FileUploadModal);
