import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import InviteClubAdmin from './InviteClubAdmin';
import { State } from '../../../../models/State';
import { fetchClubRequest } from '../../../../actions/clubs/fetchClub';
import { sendInvitationRequest } from '../../../../actions/invitations';
import { StateProps, DispatchProps } from './InviteClubAdminProps';

const mapStatesToProps = (state: State): StateProps => {
  const {
    clubs: {
      byHash,
    },
    invitations: {
      error,
      loading,
    },
  } = state;

  return ({
    loading,
    error,
    byHash,
  });
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  sendInvitation: bindActionCreators(sendInvitationRequest, dispatch),
  fetchClub: bindActionCreators(fetchClubRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(InviteClubAdmin);
