import { EmbellishmentTypes } from './Embellishment';
import { Price } from './Price';
import { UploadFile } from './File';

export interface ProductEmbellishment {
  embellishment: EmbellishmentTypes;
  mandatory: boolean;
  fixedPricePerUnit?: Price;
  quantity?: number;
}

export enum ProductCatalogStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export interface ProductBase {
  id: string;
  createdAt: number;
  description: string;
  externalId: string;
  name: string;
  url: string;
  discountPercent: number | null;
  manufacturerArticleNumber?: string;
  category: string;
}

export interface Product extends ProductBase {
  embellishments: ProductEmbellishment[];
  files: UploadFile[];
  originalImages: string[];
  originalPrice: Price;
  tagIds: number[];
  productUsedInActiveOrder?: boolean;
  exclusiveToTeams?: number[];
  productCatalogStatus?: 'ACTIVE' | 'INACTIVE';
  customCategory?: string;
}

export interface ExternalProductResponse extends ProductBase {
  price: Price;
  images: string[],
  producerExternalId: string,
}

export interface ProductRequest {
  id: string;
  externalId: string;
  files: UploadFile[];
  tagIds: number[];
  exclusiveToTeams?: number[];
  embellishments: ProductEmbellishment[];
  name: string;
  description: string;
  discountPercent: number | null;
}
