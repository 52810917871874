import { Column } from '../../components/Table/Column';

const columns: Column[] = [{
  width: '20%',
  header: 'REQUESTED_HOBBY_TEAM',
  align: 'left',
  key: 'teamName',
  sortable: true,
}, {
  width: '13%',
  header: 'LOCATION',
  align: 'left',
  key: 'location',
  sortable: false,
}, {
  width: '20%',
  header: 'CONTACT_CLUB_ADMIN',
  align: 'left',
  key: 'createdBy',
  sortable: false,
}, {
  width: '20%',
  header: 'REQUEST_BY',
  align: 'left',
  key: 'contact',
  sortable: false,
}, {
  width: '10%',
  header: 'REQUEST_DATE',
  align: 'left',
  key: 'createdAt',
  sortable: true,
}, {
  width: '10%',
  header: 'STATUS',
  align: 'left',
  key: 'status',
  sortable: true,
}, {
  width: '7%',
  header: '',
  align: 'right',
  sortable: false,
  key: 'actions',
}];

export default columns;
