import { AjaxError } from 'rxjs/ajax';
import {
  FETCH_CLUB_REQUEST,
  FETCH_CLUB_SUCCESS,
  FETCH_CLUB_ERROR,
} from '../../constants/actionTypes';
import { Club } from '../../models/Club';
import {
  FetchClubRequestAction,
  FetchClubSuccessAction,
  FetchClubErrorAction,
} from '../../models/actionTypes';

export const fetchClubRequest = (id: number): FetchClubRequestAction => ({
  type: FETCH_CLUB_REQUEST,
  payload: {
    id,
  },
});

export const fetchClubSuccess = (club: Club): FetchClubSuccessAction => ({
  type: FETCH_CLUB_SUCCESS,
  payload: {
    club,
  },
});

export const fetchClubError = (error: AjaxError): FetchClubErrorAction => ({
  type: FETCH_CLUB_ERROR,
  payload: {
    error,
  },
});
