import { SalesOrganization } from './Organizations';
import { Price } from './Price';

export interface IndividualOrderListItem {
  orderId: number;
  finalPrice?: Price;
  createdAt?: number;
  itemsSummedPrice?: Price;
  club: {
    id: number;
    name: string;
  },
  salesOrganization: SalesOrganization,
  status: string;
  createdBy: {
    id: number;
    firstName: string;
    lastName: string;
  };
  fileReference: string;
  shopwareOrderNumber: string;
}

export interface IndividualOrdersByHash {
  [key: string]: IndividualOrderListItem;
}

export interface IndividualOrdersListReducer {
  byHash: IndividualOrdersByHash;
  byId: number[];
  count: number;
  error: boolean;
  loading: boolean;
  loadingConfirmation: boolean;
  ordersDeleted: boolean;
  orderConfirmed: boolean;
}

export enum IndividualOrderStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}
