import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { FETCH_TEAM_ORDERS_ERROR, FETCH_TEAM_ORDERS_REQUEST } from '../../constants/actionTypes';
import { FetchTeamOrdersRequestAction } from '../../models/actionTypes';
import { ListResponse, Response } from '../../models/Response';
import { ORDERS_LIST } from '../../constants/paths';
import { OrderList } from '../../models/Order';
import { State } from '../../models/State';
import { TEAM_ORDERS_LIST_LIMIT } from '../../constants/customizations';
import { fetchTeamOrdersError, fetchTeamOrdersSuccess } from '../../actions/orders/FetchTeamOrders';
import { handleError } from '../../actions/errors/handleError';
import { orderTypeFilterParser } from '../../utils/filterParsers/orderTypeFilterParser';

const fetchOrders = (
  action$: ActionsObservable<FetchTeamOrdersRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_TEAM_ORDERS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    {
      payload: {
        page, field, order, filter, search,
      },
    },
    {
      authentication: { authToken },
    },
  ]) => {
    const query = queryString.stringify(pickBy({
      offset: ((page || 1) - 1) * TEAM_ORDERS_LIST_LIMIT,
      limit: TEAM_ORDERS_LIST_LIMIT,
      ...(search && ({ search })),
    }));
    const filters = orderTypeFilterParser(filter);
    return ajax({
      url: `${ORDERS_LIST}?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify({
        ...((field || order) && ({
          sort: {
            field,
            order,
          },
        })),
        ...(search && ({ search })),
        ...(filters || {}),
      }),
    }).pipe(
      map(({ response: { data, count } }: Response<ListResponse<OrderList>>) => (
        fetchTeamOrdersSuccess(data, count)
      )),
      catchError(error => ([
        handleError(error, FETCH_TEAM_ORDERS_ERROR),
        fetchTeamOrdersError(error),
      ])),
    );
  }),
);

export default fetchOrders;
