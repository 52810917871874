import React, { useEffect, useState } from 'react';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { SalesRepFilterProps } from './SalesRepFilterProps';
import styles from './SalesRepFilter.module.scss';
import { OrganizationType } from '../../../models/OrganizationType';
import { User } from '../../../models/User';
import ProfileAvatar from '../ProfileAvatar';

const SalesRepFilter = ({
  onFilterChange,
  filterValue,
  column,
  error,
  loading,
  salesReps,
  fetchSalesReps,
}: SalesRepFilterProps) => {
  const [translate] = useTranslation();
  const [salesRepsOptions, setSalesRepsOptions] = useState<DropdownItemProps[]>([]);
  const [salesRepOrganizationId, setSalesRepOrganizationId] = useState<number | null>(null);

  useEffect(() => {
    if (filterValue) {
      setSalesRepOrganizationId(filterValue as number);
    }
  }, [filterValue]);

  useEffect(() => {
    fetchSalesReps({ type: OrganizationType.SALES });
  }, [fetchSalesReps]);

  useEffect(() => {
    setSalesRepsOptions(salesReps.map((user: User) => ({
      value: user.id,
      text: `${user.firstName} ${user.lastName}`,
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ProfileAvatar user={user} />
          <span>{`${user.firstName} ${user.lastName}`}</span>
        </div>
      ),
      key: user.id,
    })));
  }, [salesReps]);

  const handleSalesRepChange = (e: any, data: DropdownProps) => {
    setSalesRepOrganizationId(+data.value!);
    if (onFilterChange) {
      onFilterChange({
        [column]: data.value
          ? Number(data.value as number) : data.value as undefined,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <Form id="create-form">
        <Form.Field
          className={styles.formField}
          key="sales-rep-id"
        >
          <label>{translate('SALES_REP_FILTER.SALES_REP_LABEL')}</label>
          <div className={styles.inputWithLabel}>
            <Form.Dropdown
              name="sales-rep-id-select"
              onChange={handleSalesRepChange}
              placeholder={translate('SALES_REP_FILTER.SALES_REP_PLACEHOLDER')}
              options={salesRepsOptions}
              disabled={loading || error}
              loading={loading}
              selection
              clearable
              search
              value={salesRepOrganizationId || ''}
            />
          </div>
        </Form.Field>
      </Form>
    </div>
  );
};

export default SalesRepFilter;
