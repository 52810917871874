import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import TableContainer from '../../components/Layout/components/TableContainer';
import { TeamBasketOrdersProps } from './TeamBasketOrdersProps';
import TeamBasketOrdersRow from './components/TeamBasketOrdersRow';
import Header from '../../components/Header';
import styles from './TeamBasketOrders.module.scss';
import { TeamBasketOrder } from '../../models/TeamBasketOrder';
import columns from './columns';
import OrdersListFilter from '../../components/OrdersListFilter/OrdersListFilter';

const TeamBasketOrders: FC<TeamBasketOrdersProps> = ({
  count,
  history,
  loading,
  orders,
  fetchOrders,
}) => {
  const [translate] = useTranslation();

  const renderRows = (order: TeamBasketOrder) => (
    <TeamBasketOrdersRow
      order={order}
      key={order.id}
    />
  );

  return (
    <div className={styles.wrapper}>
      <Header border title={translate('TEAM_BASKET_ORDERS.TITLE')} />
      <div className={styles.tableWrapper}>
        <TableContainer
          extraFilters={<OrdersListFilter history={history} />}
          fetch={fetchOrders}
          loading={loading}
          count={count}
          history={history}
          columns={columns}
          renderItems={orders.map(renderRows)}
          searchPlaceholder="SEARCH_FOR_TEAM_BASKET_ORDERS"
          invertedHeader
          emptyTableText="TEAM_BASKET_ORDERS.NO_ORDERS_AVAILABLE"
          notFoundTableText="TEAM_BASKET_ORDERS.NO_ORDERS_FOUND"
        />
      </div>
    </div>
  );
};

export default TeamBasketOrders;
