import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { State } from '../../models/State';
import { EmbellishmentValueSelectionProps } from './EmbellishmentValueSelectionProps';
import { fetchClubRequest } from '../../actions/clubs/fetchClub';
import { fetchEmbellishmentsRequest } from '../../actions/catalogs/fetchEmbellishments';
import EmbellishmentValueSelection from './EmbellishmentValueSelection';
import { fetchEmbellishmentValuesRequest } from '../../actions/catalogs/fetchEmbellishmentValues';
import { createEmbellishmentValueRequest } from '../../actions/catalogs/createEmbellishmentValue';
import { updateEmbellishmentValueRequest } from '../../actions/catalogs/updateEmbellishmentValue';
import { deleteEmbellishmentValueRequest } from '../../actions/catalogs/deleteEmbellishmentValue';

const mapStateToProps = (
  {
    catalogs: {
      byHash,
    },
    embellishments: {
      loadingEmbellishments,
      embellishmentValues,
      embellishmentsByHash,
      loadingEmbellishmentValues,
      fetchEmbellishmentValuesError,
    },
    clubs: {
      byHash: clubByHash,
    },

  }: State,
  { match: { params: { clubId } } }: EmbellishmentValueSelectionProps,

) => ({
  catalog: byHash[clubId],
  club: clubByHash[clubId],
  clubId: (clubId && +clubId) || null,
  embellishmentsByHash,
  embellishmentValues,
  loadingEmbellishments,
  loadingEmbellishmentValues,
  fetchEmbellishmentValuesError,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchClub: fetchClubRequest,
  fetchEmbellishments: fetchEmbellishmentsRequest,
  fetchEmbellishmentValues: fetchEmbellishmentValuesRequest,
  createEmbellishmentValue: createEmbellishmentValueRequest,
  updateEmbellishmentValue: updateEmbellishmentValueRequest,
  deleteEmbellishmentValue: deleteEmbellishmentValueRequest,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmbellishmentValueSelection);
