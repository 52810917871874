import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { State } from '../../models/State';
import { Club } from '../../models/Club';
import { fetchClubRequest } from '../../actions/clubs/fetchClub';
import { ClubTeamsProps } from './ClubTeamsProps';
import ClubTeams from './ClubTeams';
import { fetchClubTeamsRequest } from '../../actions/clubTeams/fetchClubTeams';
import { changeTeamStatusRequest } from '../../actions/clubTeams/changeTeamStatus';
import { deleteTeamRequest } from '../../actions/clubTeams/deleteTeam';
import { fetchTeamInviteRequest } from '../../actions/clubTeams/fetchTeamInvite';

const mapStateToProps = ({
  clubs: {
    byHash: clubsByHash,
    byId: clubsById,
  },
  clubTeams: {
    byHash: teamsByHash,
    byId: teamsById,
    count,
    loading,
  },
}: State, { match: { params: { clubId } } }: ClubTeamsProps) => ({
  clubs: clubsById.map(id => clubsByHash[id]),
  clubTeams: teamsById.map(id => teamsByHash[id]),
  loading,
  count,
  club: clubId && clubsByHash[clubId]
    ? clubsByHash[clubId]
    : {
      address: {},
    } as Club,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchClubTeams: bindActionCreators(fetchClubTeamsRequest, dispatch),
  fetchClub: bindActionCreators(fetchClubRequest, dispatch),
  changeTeamStatus: bindActionCreators(changeTeamStatusRequest, dispatch),
  deleteTeam: bindActionCreators(deleteTeamRequest, dispatch),
  fetchInviteToken: bindActionCreators(fetchTeamInviteRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClubTeams);
