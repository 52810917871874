import { AjaxError } from 'rxjs/ajax';
import {
  FetchTeamErrorAction,
  FetchTeamRequestAction,
  FetchTeamSuccessAction,
} from '../../models/actionTypes';
import {
  FETCH_TEAM_ERROR,
  FETCH_TEAM_REQUEST,
  FETCH_TEAM_SUCCESS,
} from '../../constants/actionTypes';
import { ClubTeam } from '../../models/ClubTeam';

export const fetchTeamsError = (error: AjaxError): FetchTeamErrorAction => ({
  type: FETCH_TEAM_ERROR,
  payload: {
    error,
  },
});

export const fetchTeamRequest = (id: number, clubId: number): FetchTeamRequestAction => ({
  type: FETCH_TEAM_REQUEST,
  payload: {
    id,
    clubId,
  },
});

export const fetchTeamSuccess = (team: ClubTeam): FetchTeamSuccessAction => ({
  type: FETCH_TEAM_SUCCESS,
  payload: {
    team,
  },
});
