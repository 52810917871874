import { ApplicationActions } from './ApplicationActions';
import { AuthenticationActions } from './AuthenticationActions';
import { CatalogActions } from './CatalogsActions';
import { ClubActions } from './ClubsActions';
import { EmbellishmentsActions } from './EmbellishmentsActions';
import { ErrorsActions } from './ErrorsActions';
import { FilesActions } from './FilesActions';
import { InvitationActions } from './InvitationActions';
import { NotificationsActions } from './NotificationsActions';
import { OrderActions } from './OrderActions';
import { TeamBasketOrderActions } from './TeamBasketOrderActions';
import { IndividualOrdersActions } from './IndividualOrdersActions';
import { PlayersActions } from './PlayersActions';
import { ProductsActions } from './ProductsActions';
import { TagsActions } from './TagsActions';
import { OrganizationActions } from './OrganizationActions';
import { TeamRequestsActions } from './TeamRequestsActions';
import { HobbyClubsActions } from './HobbyClubsActions';
import { ExternalSystemsActions } from './ExternalSystemsActions';
import { EditRewardsActions } from './RewardsActions';
import { ClubOrdersActions } from './ClubOrdersActions';
import { ClubTeamsActions } from './ClubTeamsActions';
import { SetTor24TeamActions } from './SetTor24Team';
import { Tor24Actions } from './Tor24Teams';
import { MembersActions } from './Members';
import { StaffActions } from './StaffActions';

export * from './ApplicationActions';
export * from './AuthenticationActions';
export * from './CatalogsActions';
export * from './ClubsActions';
export * from './EmbellishmentsActions';
export * from './ErrorsActions';
export * from './OrganizationActions';
export * from './FilesActions';
export * from './InvitationActions';
export * from './NotificationsActions';
export * from './OrderActions';
export * from './IndividualOrdersActions';
export * from './PlayersActions';
export * from './ProductsActions';
export * from './TagsActions';
export * from './HobbyClubsActions';
export * from './TeamRequestsActions';
export * from './ExternalSystemsActions';
export * from './TeamBasketOrderActions';
export * from './ClubTeamsActions';

export type Actions =
  | ApplicationActions
  | AuthenticationActions
  | CatalogActions
  | ClubActions
  | EmbellishmentsActions
  | ErrorsActions
  | FilesActions
  | OrganizationActions
  | InvitationActions
  | NotificationsActions
  | OrderActions
  | IndividualOrdersActions
  | HobbyClubsActions
  | TeamRequestsActions
  | ProductsActions
  | TeamBasketOrderActions
  | ExternalSystemsActions
  | TagsActions
  | EditRewardsActions
  | ClubOrdersActions
  | ClubTeamsActions
  | SetTor24TeamActions
  | Tor24Actions
  | PlayersActions
  | MembersActions
  | StaffActions;
