import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import AvatarWithPopup from '../../../../components/SalesRep/AvatarWithPopup';
import getFullName from '../../../../utils/getFullName';
import styles from './ClubAdminCell.module.scss';
import { ClubAdmin } from '../../../../models/Club';
import { ClubAdminCellProps } from './ClubAdminCellProps';

const ClubAdminCell: FC<ClubAdminCellProps> = ({ clubAdmins, pendingClubAdmins }) => {
  const [translate] = useTranslation();
  const hasOneAdmin = [...clubAdmins, ...pendingClubAdmins].length === 1;

  return (
    <div className={classNames(styles.avatarsContainer, hasOneAdmin && styles.noWrap)}>
      {clubAdmins.map((user: ClubAdmin) => (
        <AvatarWithPopup user={user} key={user.userId} />
      ))}
      {pendingClubAdmins.map((user: ClubAdmin) => (
        <AvatarWithPopup user={user} italic key={uuidv4()} />
      ))}

      {hasOneAdmin && (clubAdmins.length ? (
        <span>{getFullName(clubAdmins[0])}</span>
      ) : (
        <em>
          {getFullName(pendingClubAdmins[0])}
          <small>
            {`(${translate('PENDING')})`}
          </small>
        </em>
      ))}
    </div>
  );
};

export default ClubAdminCell;
