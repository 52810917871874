import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dimmer, Divider, Image,
} from 'semantic-ui-react';
import {
  GridContextProvider, GridDropZone, GridItem,
} from 'react-grid-dnd';
import FileUploadModal from '../../../../components/FileUploadModal';
import styles from './ProductsUploadImages.module.scss';
import { ProductsUploadImagesProps } from './ProductsUploadImagesProps';
import ProductUploadImage from '../ProductUploadImage';
import { useViewport } from '../../../../utils/useViewport';
import { FileUploadTypes } from '../../../../components/FileUploadModal/FileUploadModalProps';

const ProductsUploadImages: FC<ProductsUploadImagesProps> = ({
  fetchFile,
  filesByHash,
  images,
  loadingUpload,
  originalImages, // eslint-disable-next-line
  fileUploaded,
  removeDraftFile,
  setDraftFiles,
  uploadFile,
}) => {
  const [translate] = useTranslation();
  const [activeImageId, setActiveImageId] = useState('');
  const { isTablet } = useViewport();
  const uploadImage = useCallback((image: File, id?: string) => {
    uploadFile(image, id);
  }, [uploadFile]);

  useEffect(() => {
    images
      .filter(({ id }) => !filesByHash[id])
      .map(({ id, extension }) => fetchFile(id, extension));
  }, [fetchFile, images]); // eslint-disable-line

  const renderUploadButton = (onClick: () => void) => (
    <Button
      className={styles.uploadButton}
      content={translate('UPLOAD_IMAGE')}
      inverted
      onClick={onClick}
      primary
    />
  );

  // @ts-ignore
  const onChange = (sourceId, sourceIndex, targetIndex) => {
    const newImages = JSON.parse(JSON.stringify(images));
    newImages.splice(targetIndex, 0, newImages.splice(sourceIndex, 1)[0]);
    setDraftFiles(newImages);
  };

  return (
    <div>
      {!!images.length && (
        <GridContextProvider onChange={onChange}>
          <div className={styles.customImagesContainer}>
            <GridDropZone
              className={styles.dropzone}
              id="left"
              boxesPerRow={isTablet ? 3 : 4}
              rowHeight={330}
              style={{ height: Math.ceil(images.length / (isTablet ? 3 : 4)) * 330 }}
            >
              {images.map(image => (
                <GridItem key={image.id} className={styles.gridItem}>
                  <ProductUploadImage
                    key={image.id}
                    id={image.id}
                    setActiveImageId={setActiveImageId}
                    filesByHash={filesByHash}
                    removeDraftFile={removeDraftFile}
                  />
                </GridItem>
              ))}
            </GridDropZone>
          </div>
        </GridContextProvider>
      )}
      {!!images.length && <Divider />}
      <div className={styles.originalImagesContainer}>
        {originalImages.map(imageUrl => (
          <div className={styles.imageWrapper} key={imageUrl}>
            <Image src={imageUrl} />
            <Dimmer className={styles.dimmer}>
              <span>
                {translate('CANNOT_REMOVE_IMAGE')}
              </span>
            </Dimmer>
          </div>
        ))}
        <FileUploadModal
          loading={loadingUpload}
          triggerButton={renderUploadButton}
          type={FileUploadTypes.IMAGE}
          uploadFile={uploadImage}
        />
      </div>
      <FileUploadModal
        loading={loadingUpload}
        onClose={() => { setActiveImageId(''); }}
        renderTriggerButton={false}
        fileUploaded={fileUploaded}
        modalOpened={!!activeImageId}
        type={FileUploadTypes.IMAGE}
        uploadFile={file => uploadImage(file, activeImageId)}
      />
    </div>
  );
};

export default ProductsUploadImages;
