import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { push } from 'connected-react-router';

import { NotificationType, UpdateHobbyProductAction } from '../../models/actionTypes';
import { State } from '../../models/State';
import { UPDATE_HOBBY_PRODUCT } from '../../constants/actionTypes';
import { addNotification } from '../../actions/notifications/addNotification';
import { HOBBY_CATALOG_ROUTE } from '../../constants/routes';

export default (
  action$: ActionsObservable<UpdateHobbyProductAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(UPDATE_HOBBY_PRODUCT),
  withLatestFrom(state$),
  mergeMap(() => [push(HOBBY_CATALOG_ROUTE), addNotification(NotificationType.SUCCESS, 'UPDATE_PRODUCT_SUCCESS')]),
);
