import {
  ADD_HOBBY_PRODUCT_SUCCESS,
  ADD_PRODUCT_ERROR,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  CLEAR_CATALOG_DATA,
  COPY_CATALOG_SUCCESS,
  CREATE_CATALOG_SUCCESS,
  CREATE_HOBBY_CATALOG_SUCCESS,
  FETCH_CATALOG_ERROR,
  FETCH_CATALOG_REQUEST,
  FETCH_CATALOG_SUCCESS,
  FETCH_CATALOGS_ERROR,
  FETCH_CATALOGS_REQUEST,
  FETCH_CATALOGS_SUCCESS,
  FETCH_HOBBY_CATALOG_ERROR,
  FETCH_HOBBY_CATALOG_REQUEST,
  FETCH_HOBBY_CATALOG_SUCCESS,
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_SUCCESS,
  HIDE_RELEASE_CATALOG_MODAL,
  PERSIST_SELECTION,
  REMOVE_ATTACHMENT,
  REMOVE_HOBBY_ATTACHMENT,
  REMOVE_HOBBY_PRODUCT,
  REMOVE_PRODUCT,
  SAVE_PRODUCT_FILES_ERROR,
  SAVE_SELECTION_DRAFT,
  UPDATE_CATALOG,
  UPDATE_HOBBY_CATALOG,
  UPDATE_HOBBY_PRODUCT,
  UPDATE_PRODUCT,
} from '../constants/actionTypes';
import { Actions } from '../models/actionTypes';
import { Catalog, CatalogReducer, CatalogsByHash } from '../models/Catalog';
import { SIGN_OUT_ERROR, SIGN_OUT_SUCCESS } from '../packages/authentication/constants';
import { Tag, TagsByHash } from '../models/Tag';

export const initialState: CatalogReducer = {
  byHash: {},
  hobbyCatalog: null,
  byId: [],
  count: 0,
  error: false,
  errorMessage: '',
  errorTags: false,
  loading: false,
  loadingList: false,
  loadingProduct: null,
  loadingTags: false,
  tagsByHash: {},
  tagsById: [],
  previousEmbellishmentSelection: [],
  selectedEmbellishmentsDraft: [],
  showReleaseCatalogModal: false,
};

export default (
  state = initialState,
  action: Actions,
) => {
  switch (action.type) {
    case FETCH_HOBBY_CATALOG_REQUEST:
    case FETCH_CATALOG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CATALOG_SUCCESS: {
      const { catalog } = action.payload;
      return {
        ...state,
        loading: false,
        byHash: {
          ...state.byHash,
          [catalog.id]: {
            ...state.byHash[catalog.id],
            ...catalog,
            isIncomplete: false,
            productsCount: (catalog.products || []).length,
          },
        },
        byId: [
          catalog.id,
          ...state.byId.filter(id => id !== catalog.id),
        ],
      };
    }
    case FETCH_HOBBY_CATALOG_SUCCESS: {
      const { catalog } = action.payload;
      return {
        ...state,
        loading: false,
        hobbyCatalog: {
          ...state.hobbyCatalog,
          ...catalog,
          isIncomplete: false,
          productsCount: (catalog.products || []).length,
        },
      };
    }
    case FETCH_CATALOGS_REQUEST:
      return {
        ...state,
        loadingList: true,
      };
    case COPY_CATALOG_SUCCESS:
      return {
        ...state,
        byHash: {
          ...state.byHash,
          [action.payload.clubId]: ({
            ...state.byHash[action.payload.clubId],
            isDraft: true,
          }),
        },
      };
    case FETCH_CATALOGS_SUCCESS: {
      const { catalogs, count } = action.payload;

      return {
        ...state,
        loadingList: false,
        byHash: catalogs
          .reduce((byHash: CatalogsByHash, catalog: Catalog) => ({
            ...byHash,
            [catalog.id]: {
              ...byHash[catalog.id],
              ...catalog,
              isIncomplete: true,
            },
          }), state.byHash),
        byId: catalogs.map((catalog: Catalog) => catalog.id),
        count,
      };
    }
    case UPDATE_HOBBY_CATALOG: {
      const { catalog } = action.payload;

      return {
        ...state,
        hobbyCatalog: {
          ...state.hobbyCatalog,
          ...catalog,
          isDraft: true,
        },
      };
    }
    case UPDATE_CATALOG: {
      const { clubId, catalog } = action.payload;
      return {
        ...state,
        byHash: {
          ...state.byHash,
          [clubId]: {
            ...state.byHash[clubId],
            ...catalog,
            isDraft: true,
          },
        },
      };
    }
    case CREATE_CATALOG_SUCCESS: {
      const { clubId } = action.payload;

      return {
        ...state,
        showReleaseCatalogModal: false,
        byHash: {
          ...state.byHash,
          [clubId]: {
            ...state.byHash[clubId],
            isDraft: false,
          },
        },
      };
    }
    case CREATE_HOBBY_CATALOG_SUCCESS:
      return {
        ...state,
        hobbyCatalog: {
          ...state.hobbyCatalog,
          isDraft: false,
        },
      };
    case ADD_PRODUCT_REQUEST:
      return {
        ...state,
        loadingProduct: action.payload.productId,
      };
    case ADD_HOBBY_PRODUCT_SUCCESS:
    case UPDATE_HOBBY_PRODUCT: {
      const { product } = action.payload;
      const products = [
        ...((state.hobbyCatalog && state.hobbyCatalog.products) || []).filter(
          ({ id }) => id !== product.id,
        ),
        product,
      ];

      return {
        ...state,
        loadingProduct: null,
        hobbyCatalog: {
          ...state.hobbyCatalog,
          isDraft: true,
          products,
          productsCount: products.length,
        },
      };
    }
    case ADD_PRODUCT_SUCCESS:
    case UPDATE_PRODUCT: {
      const { clubId, product } = action.payload;
      const products = [
        ...(state.byHash[clubId].products || []).filter(({ id }) => id !== product.id),
      ];
      products.push(product);
      return {
        ...state,
        loadingProduct: null,
        byHash: {
          ...state.byHash,
          [clubId]: {
            ...state.byHash[clubId],
            isDraft: true,
            products,
            productsCount: products.length,
          },
        },
      };
    }
    case REMOVE_PRODUCT: {
      const { clubId, productId } = action.payload;
      const products = (state.byHash[clubId].products || []).filter(({ id }) => id !== productId);

      return {
        ...state,
        byHash: {
          ...state.byHash,
          [clubId]: {
            ...state.byHash[clubId],
            isDraft: true,
            products,
            productsCount: products.length,
          },
        },
      };
    }
    case REMOVE_HOBBY_PRODUCT: {
      const { productId } = action.payload;
      const products = ((state.hobbyCatalog && state.hobbyCatalog.products) || [])
        .filter(({ id }) => id !== productId);

      return {
        ...state,
        hobbyCatalog: {
          ...state.hobbyCatalog,
          isDraft: true,
          products,
          productsCount: products.length,
        },
      };
    }
    case REMOVE_HOBBY_ATTACHMENT: {
      const { fileId } = action.payload;
      const { products = [] } = state.hobbyCatalog!;

      const product = products.find(({ files }) => (
        files.map(({ id }) => id).includes(fileId)
      ));

      return product ? {
        ...state,
        hobbyCatalog: {
          ...state.hobbyCatalog,
          isDraft: true,
          products: [
            ...products.filter(({ id }) => id !== product.id),
            {
              ...product,
              files: product.files.filter(({ id }) => id !== fileId),
            },
          ],
        },
      } : state;
    }
    case REMOVE_ATTACHMENT: {
      const { clubId, fileId } = action.payload;
      const { products = [] } = state.byHash[clubId];
      const product = products.find(({ files }) => (
        files.map(({ id }) => id).includes(fileId)
      ));

      return product ? {
        ...state,
        byHash: {
          ...state.byHash,
          [clubId]: {
            ...state.byHash[clubId],
            isDraft: true,
            products: [
              ...products.filter(({ id }) => id !== product.id),
              {
                ...product,
                files: product.files.filter(({ id }) => id !== fileId),
              },
            ],
          },
        },
      } : state;
    }
    case SAVE_SELECTION_DRAFT:
      return {
        ...state,
        selectedEmbellishmentsDraft: action.payload.productEmbellishments,
      };
    case PERSIST_SELECTION:
      return {
        ...state,
        previousEmbellishmentSelection: [...state.selectedEmbellishmentsDraft],
        selectedEmbellishmentsDraft: [],
      };
    case CLEAR_CATALOG_DATA:
      return {
        ...state,
        selectedEmbellishmentsDraft: [],
      };
    case ADD_PRODUCT_ERROR:
    case FETCH_CATALOGS_ERROR:
    case FETCH_CATALOG_ERROR:
    case FETCH_HOBBY_CATALOG_ERROR:
      return {
        ...state,
        loading: false,
        loadingList: false,
        loadingProduct: null,
        error: true,
        errorMessage: action.payload.error.message,
      };
    case FETCH_TAGS_REQUEST:
      return {
        ...state,
        loadingTags: true,
      };
    case FETCH_TAGS_SUCCESS: {
      const { tags } = action.payload;
      return {
        ...state,
        loadingTags: false,
        tagsByHash: tags
          .reduce((tagsbyHash: TagsByHash, tag: Tag) => ({
            ...tagsbyHash,
            [tag.id]: tag,
          }), state.tagsByHash),
        tagsById: tags.map(({ id }: Tag) => id),
      };
    }
    case SAVE_PRODUCT_FILES_ERROR:
      return {
        ...state,
        showReleaseCatalogModal: true,
      };
    case HIDE_RELEASE_CATALOG_MODAL:
      return {
        ...state,
        showReleaseCatalogModal: false,
      };
    case SIGN_OUT_SUCCESS:
    case SIGN_OUT_ERROR:
      return initialState;
    default:
      return state;
  }
};
