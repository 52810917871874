import { AjaxError } from 'rxjs/ajax';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { serializeExternalProduct } from '../../utils/serializers/serializeProduct';
import { ADD_PRODUCT_ERROR, ADD_PRODUCT_REQUEST } from '../../constants/actionTypes';
import { AddProductRequestAction } from '../../models/actionTypes';
import { PRODUCTS_BY_ID_PATH } from '../../constants/paths';
import { ExternalProductResponse, Product } from '../../models/Product';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { addProductError, addProductSuccess, addHobbyProductSuccess } from '../../actions/catalogs/addProduct';
import { handleError } from '../../actions/errors/handleError';

export default (
  action$: ActionsObservable<AddProductRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(ADD_PRODUCT_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { clubId, productId } }, { authentication: { authToken } }]) => ajax({
    url: PRODUCTS_BY_ID_PATH.replace('$id', productId),
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: product }: Response<ExternalProductResponse>) => (
      serializeExternalProduct(product)
    )),
    map((product: Product) => (
      !clubId ? addHobbyProductSuccess(product) : addProductSuccess(clubId, product))),
    catchError((error: AjaxError) => ([
      handleError(error, ADD_PRODUCT_ERROR),
      addProductError(error),
    ])),
  )),
);
