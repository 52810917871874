import { AjaxError } from 'rxjs/ajax';
import {
  REASSIGN_SALES_REP_ERROR,
  REASSIGN_SALES_REP_REQUEST, REASSIGN_SALES_REP_SUCCESS,
} from '../../constants/actionTypes';
import {
  ReassignSalesRepToClubErrorAction,
  ReassignSalesRepToClubRequestAction,
  ReassignSalesRepToClubSuccessAction,
} from '../../models/actionTypes/reassignActions';

export const reassignSalesRepToClubRequest = (
  salesRepOrganizationId: number,
  clubId: number,
): ReassignSalesRepToClubRequestAction => ({
  type: REASSIGN_SALES_REP_REQUEST,
  payload: {
    salesRepOrganizationId,
    clubId,
  },
});

export const reassignSalesRepToClubSuccess = (
): ReassignSalesRepToClubSuccessAction => ({
  type: REASSIGN_SALES_REP_SUCCESS,
  payload: {},
});

export const reassignSalesRepToClubError = (error: AjaxError):
ReassignSalesRepToClubErrorAction => ({
  type: REASSIGN_SALES_REP_ERROR,
  payload: {
    error,
  },
});
