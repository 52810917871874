import { Epic } from 'redux-observable';

import { addNotification } from './notifications';
import { fetchFile, uploadFile } from './files';
import { fetchOrders, deleteOrders } from './orders';
import { fetchIndividualOrders, confirmIndividualOrder } from './individualOrders';
import { handleError } from './errors';
import { resendInvitation, sendInvitation } from './invitation';
import {
  fetchTeamRequests, editTeamRequest, rejectPromotionRequest, approvePromotionRequest,
} from './teamRequests';
import { fetchSalesRepOrgs, reassignSalesRepToClub } from './organizations';
import { initializeApplicationParameters } from './application';
import {
  fetchClubs, fetchClub, createClub, deleteClub,
  updateClub, uploadClubLogo, checkEmailAvailability,
  createClubAndApproveHobbyTeamRequest,
} from './clubs';
import {
  createCatalog,
  fetchCatalogs,
  fetchCatalog,
  addProduct,
  updateHobbyProduct,
  fetchTags,
  fetchEmbellishments,
  updateProduct,
  removeProduct,
  saveProductFiles,
  copyCatalog,
  fetchHobbyCatalog,
  createHobbyCatalog,
  createEmbellishmentValue,
  deleteEmbellishmentValue,
  updateEmbellishmentValue,
} from './catalogs';
import { fetchHobbyClubs } from './hobbyClubs';
import {
  deleteExternalSystemReference,
  fetchExternalClubs,
  fetchTor24Teams,
  saveExternalReferences,
} from './externalSystems';
import { editRewards, fetchEntityRewards } from './rewards';
import { fetchTeamBasketOrders } from './teamBasketOrders';
import { fetchClubOrders } from './clubOrders';
import {
  assignMemberToTeam,
  changeTeamStatus,
  createTeam,
  deactivateInviteToken, deleteTeam,
  fetchClubTeams,
  fetchInviteToken,
  fetchTeam,
  generateInviteToken,
} from './teams';
import { createPlayer } from './players';
import { assignMember, fetchMembers } from './members';
import { fetchStaffMembers } from './staff';
import fetchEmbellishmentValues from './catalogs/fetchEmbellishmentValues';

const epics: Array<Epic> = [
  addNotification,
  addProduct,
  createCatalog,
  createClub,
  deleteClub,
  fetchCatalog,
  fetchSalesRepOrgs,
  rejectPromotionRequest,
  approvePromotionRequest,
  deleteOrders,
  fetchTeamRequests,
  fetchCatalogs,
  fetchClub,
  fetchIndividualOrders,
  confirmIndividualOrder,
  fetchClubs,
  fetchEmbellishments,
  fetchFile,
  fetchOrders,
  updateHobbyProduct,
  fetchHobbyClubs,
  copyCatalog,
  editTeamRequest,
  fetchTags,
  handleError,
  removeProduct,
  fetchTeamBasketOrders,
  sendInvitation,
  resendInvitation,
  initializeApplicationParameters,
  saveProductFiles,
  updateClub,
  updateProduct,
  uploadClubLogo,
  uploadFile,
  fetchExternalClubs,
  fetchHobbyCatalog,
  createHobbyCatalog,
  saveExternalReferences,
  reassignSalesRepToClub,
  deleteExternalSystemReference,
  editRewards,
  fetchEntityRewards,
  fetchClubOrders,
  fetchClubTeams,
  fetchTor24Teams,
  createTeam,
  changeTeamStatus,
  checkEmailAvailability,
  createPlayer,
  fetchMembers,
  assignMember,
  fetchTeam,
  fetchStaffMembers,
  fetchInviteToken,
  deactivateInviteToken,
  generateInviteToken,
  assignMemberToTeam,
  deleteTeam,
  createClubAndApproveHobbyTeamRequest,
  fetchEmbellishmentValues,
  createEmbellishmentValue,
  deleteEmbellishmentValue,
  updateEmbellishmentValue,
];

export default epics;
