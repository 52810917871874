import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { AddEditEmbellishmentValuesModalProps } from './AddEditEmbellishmentValuesModalProps';
import useForm from '../../../../hooks/useForm';
import styles from './AddEditEmbellishmentValuesModal.module.scss';
import FormFields from '../../../../components/FormFields';
import { getFormSchema, validationSchema } from './schema';
import { FormSchema } from '../../../../models/Form';

const AddEditEmbellishmentValuesModal: FC<AddEditEmbellishmentValuesModalProps> = ({
  onClose,
  onSave,
  embellishmentValue,
  embellishments,
}) => {
  const [translate] = useTranslation();
  const [formSchema, setFormSchema] = useState<FormSchema>();
  const {
    values,
    errors,
    handleChange,
    validate,
    setValues,
  } = useForm(validationSchema);
  useEffect(() => {
    if (embellishmentValue) {
      setValues(embellishmentValue);
    }
  }, [embellishmentValue, setValues]);

  useEffect(() => {
    if (embellishments && embellishments.length) {
      const schema = getFormSchema(embellishments);
      if (schema) {
        setFormSchema(schema);
      }
    }
  }, [embellishments]);

  const handleSaveClick = () => {
    if (values && validate()) {
      onSave(values);
    }
  };

  return (
    <Modal
      open
      onClose={onClose}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header content={
        translate(embellishmentValue && embellishmentValue.id
          ? 'EDIT_EMBELLISHMENT_VALUE' : 'ADD_EMBELLISHMENT_VALUE')
      }
      />
      <Form onSubmit={handleSaveClick}>
        <Modal.Content className={styles.modalContent}>
          {!!formSchema && (
          <FormFields
            schema={formSchema}
            errors={errors}
            values={values}
            handleChange={handleChange}
          />
          )}
        </Modal.Content>
        <Modal.Actions className={styles.actionsContainer}>
          <Button
            basic
            circular
            content={translate('CANCEL')}
            onClick={onClose}
            primary
          />
          <Button
            circular
            content={translate('SAVE')}
            onClick={handleSaveClick}
            primary
            type="submit"
          />
        </Modal.Actions>
      </Form>
    </Modal>
  );
};

export default AddEditEmbellishmentValuesModal;
