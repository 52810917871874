import React from 'react';
import { Action } from 'redux';
import { Button, Form, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import FormFields from '../../../../components/FormFields';
import styles from './RequestTokenForm.module.scss';
import useFocusFirstInput from '../../../../hooks/useFocusFirstInput';
import useForm from '../../../../hooks/useForm';
import { requestTokenSchema, validationSchema } from './schema';

type Props = {
  emailSent: boolean;
  errorMessage: string;
  resetPasswordTokenRequest: (username: string) => Action;
  loading: boolean;
};

const RequestTokenForm: React.FC<Props> = ({
  emailSent, errorMessage, resetPasswordTokenRequest, loading,
}: Props) => {
  const [translate] = useTranslation();
  const {
    values,
    errors,
    handleChange,
    validate,
  } = useForm(validationSchema);

  useFocusFirstInput();

  const handleSubmit = () => {
    if (validate()) {
      resetPasswordTokenRequest(values.email);
    }
  };

  return emailSent ? (
    <Message
      positive
      header={translate('SUCCESS')}
      content={translate('RESET_PASSWORD_EMAIL_HAS_BEEN_SENT')}
    />
  ) : (
    <Form
      onSubmit={handleSubmit}
      loading={loading}
      error={Boolean(errorMessage)}
    >
      <div className={styles.formWrapper}>
        <FormFields
          errors={errors}
          handleChange={handleChange}
          schema={requestTokenSchema}
          values={values}
        />
      </div>

      {errorMessage && (
        <Message size="mini" negative header={translate(errorMessage)} />
      )}

      <Button primary fluid content={translate('SEND_RESET_PASSWORD_EMAIL')} />
    </Form>
  );
};

export default RequestTokenForm;
