import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Menu,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import {
  CLUB_DETAILS_ROUTE,
  CLUB_EDIT_ROUTE,
  CLUB_INDIVIDUAL_ORDERS_ROUTE,
  CLUB_TEAM_BASKET_ORDERS_ROUTE,
  CLUB_TEAMS_ROUTE,
  CREATE_TEAM_ROUTE,
} from '../../constants/routes';
import HeaderComponent from '../Header';
import styles from './ClubDetailsHeader.module.scss';

export interface ClubDetailsHeaderProps extends RouteComponentProps {
  clubName: string
  clubId: string;
  location: any;
}

const ClubDetailsHeader = ({
  clubId,
  clubName,
  history: { location: { pathname } },
}: ClubDetailsHeaderProps) => {
  const [translate] = useTranslation();

  const isActive = (route: string) => (pathname === route);

  const renderButton = () => {
    if (isActive(CLUB_DETAILS_ROUTE.replace(':clubId', clubId))) {
      return (
        <Button
          primary
          as={Link}
          to={CLUB_EDIT_ROUTE.replace(':clubId', clubId)}
        >
          {translate('EDIT')}
        </Button>
      );
    }
    if (isActive(CLUB_TEAMS_ROUTE.replace(':clubId', clubId))) {
      return (
        <Button
          primary
          as={Link}
          to={CREATE_TEAM_ROUTE.replace(':clubId', clubId)}
        >
          {translate('CREATE_TEAM')}
        </Button>
      );
    }
    return (<></>);
  };

  return (
    <>
      <HeaderComponent title={clubName} renderButton={renderButton} />
      <div className={styles.wrapper}>
        <Menu className="filter" pointing secondary>
          <Menu.Item
            name={translate('CLUB_INFO')}
            key="club-info"
            as={Link}
            to={CLUB_DETAILS_ROUTE.replace(':clubId', clubId)}
            active={isActive(CLUB_DETAILS_ROUTE.replace(':clubId', clubId))}
          />
          <Menu.Item
            name={translate('INDIVIDUAL_ORDERS')}
            key="club-individual-orders"
            active={isActive(CLUB_INDIVIDUAL_ORDERS_ROUTE.replace(':clubId', clubId))}
            as={Link}
            to={CLUB_INDIVIDUAL_ORDERS_ROUTE.replace(':clubId', clubId)}
          />
          <Menu.Item
            name={translate('TEAM_BASKET_ORDER')}
            key="club-team-basket-orders"
            active={isActive(CLUB_TEAM_BASKET_ORDERS_ROUTE.replace(':clubId', clubId))}
            as={Link}
            to={CLUB_TEAM_BASKET_ORDERS_ROUTE.replace(':clubId', clubId)}
          />
          <Menu.Item
            name={translate('TEAMS')}
            key="club-teams"
            active={isActive(CLUB_TEAMS_ROUTE.replace(':clubId', clubId))}
            as={Link}
            to={CLUB_TEAMS_ROUTE.replace(':clubId', clubId)}
          />
        </Menu>
      </div>
    </>
  );
};

export default ClubDetailsHeader;
