import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import qs from 'query-string';
import { RouterProps } from 'react-router';
import { TeamInClubRewards } from '../../../models/Team';
import Row from '../../../components/Table/components/Row';
import Text from '../../../components/Text';

export interface TeamRewardsRowProps extends RouterProps {
  rewards: TeamInClubRewards;
}

const TeamRewardsRow: FC<TeamRewardsRowProps> = ({
  rewards: {
    teamScore, id,
  },
  history,
}) => {
  const [translate] = useTranslation();
  const setTeamId = () => {
    const queryString = qs.stringify({ teamId: id });
    history.push({ search: queryString });
  };
  return (
    <Row
      itemOnClick={setTeamId}
      key={(teamScore && teamScore.name) || uuidv4()}
    >
      <Text>{(teamScore && teamScore.name) || ''}</Text>
      <Text>{(teamScore && teamScore.totalPointsEverCollected) || '0'}</Text>
      <Text>{teamScore && teamScore.level ? translate(`REWARDS.LEVELS.${teamScore.level}`) : ''}</Text>
      <Text>{(teamScore && teamScore.points) || '0'}</Text>
    </Row>
  );
};

export default TeamRewardsRow;
