import React, { FC } from 'react';
import styles from './AuthenticationFormsWrapper.module.scss';

const AuthenticationFormsWrapper: FC = ({ children }) => (
  <div className={styles.authenticationFormsContainer}>
    {children}
  </div>
);

export default AuthenticationFormsWrapper;
