import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import Table from '../../components/Table';
import Header from '../../components/Header';
import { CLUBS_ROUTE } from '../../constants/routes';
import styles from './TeamsRewards.module.scss';
import { TeamRewardsProps } from './TeamsRewardsProps';
import { EntityTypesEnum } from '../../models/Entity';
import RewardsHeader from '../Rewards/RewardsHeader';
import RewardsActivitiesList from '../Rewards/RewardsActivityList';
import RewardsPointsEdition from '../Rewards/TeamRewardsBallersCreation';
import { TeamInClubRewards } from '../../models/Team';
import TeamRewardsRow from './TeamRewardsRow';
import columns from './columns';
import TeamRewardsBallers from './TeamRewardsBallers';

const TeamsRewards: FC<TeamRewardsProps> = ({
  loading,
  club,
  fetchClubById,
  clubId,
  history,
}) => {
  const [translate] = useTranslation();
  const { teamId } = queryString.parse(history.location.search);
  const [selectedTeam, setSelectedTeam] = useState<TeamInClubRewards | null>(null);
  useEffect(() => {
    fetchClubById(clubId);
  }, [clubId, fetchClubById]);
  useEffect(() => {
    if (teamId) {
      const team = club.teams && club.teams.find(_team => _team.id === +teamId);
      setSelectedTeam(team || null);
    }
  }, [club.teams, teamId]);

  const renderRows = (teamRewardsRow: TeamInClubRewards) => (
    <TeamRewardsRow
      history={history}
      rewards={teamRewardsRow}
      key={teamRewardsRow.id}
    />
  );
  return (
    <div className={styles.wrapper}>
      <Header
        backLinkTo={CLUBS_ROUTE}
        backLabel="BACK"
        title={translate('REWARDS.REWARDS_FOR_HEADER', { club: club.name })}
      />
      <div className={styles.clubOverviewWrapper}>
        <RewardsHeader clubId={clubId} />
        <div className={styles.teamsRewardsWrapper}>
          <div className={styles.tableWrapper}>
            <h3 className={styles.teamOverviewHeader}>
              {translate('TEAM_OVERVIEW')}
            </h3>
            <Table
              columns={columns}
              loading={loading}
              invertedHeader
              emptyTableText="NO_TEAM_REWARDS_FOUND"
            >
              {club && club.teams && club.teams.map(renderRows)}
            </Table>
          </div>
          {(teamId && selectedTeam) && (
          <div className={styles.teamRewardsWrapper}>
            <TeamRewardsBallers clubId={clubId} team={selectedTeam} />
            <h3 className={styles.teamOverviewHeader}>
              {translate('CLUB_ACTIVITY')}
            </h3>
            <div className={styles.rewardsRedeem}>
              <RewardsPointsEdition
                clubId={club.id}
                entityId={+teamId}
                entityType={EntityTypesEnum.TEAM}
                action="REDEEM"
              />
            </div>
            <RewardsActivitiesList entityType={EntityTypesEnum.TEAM} entityId={+teamId} />
          </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default TeamsRewards;
