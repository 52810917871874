import { Club, ClubDataResponse, ClubDetailsResponse } from '../../models/Club';

export const serializeClubData = (club: ClubDataResponse): Club => club;

export const serializeClubDetails = ({
  teams,
  ...candidate
}: ClubDetailsResponse): Club => ({
  ...candidate,
  teams: Array.isArray(teams) ? teams : [],
});
