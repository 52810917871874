import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';
import queryString from 'query-string';
import {
  FETCH_ENTITY_REWARDS_ERROR, FETCH_ENTITY_REWARDS_REQUEST,
} from '../../constants/actionTypes';
import { State } from '../../models/State';
import { handleError } from '../../actions/errors/handleError';
import { FetchEntityRewardsRequestActions } from '../../models/actionTypes/RewardsActions';
import { editRewardsError } from '../../actions/rewards/editRewards';
import { FETCH_ENTITY_REWARDS_PATH } from '../../constants/paths';
import { fetchEntityRewardsSuccess } from '../../actions/rewards/fetchRewards';
import { FiltersDirectionsEnum } from '../../models/Rewards';

export default (
  action$: ActionsObservable<FetchEntityRewardsRequestActions>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_ENTITY_REWARDS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      entityId,
      entityType,
      limit,
      direction,
      dateTime,
      tags,
    },
  }, { authentication: { authToken } }]) => {
    const query = queryString.stringify({
      ...(dateTime && ({ dateTime })),
      limit: limit || 10,
      direction: direction || FiltersDirectionsEnum.BEFORE,
    });
    return ajax({
      url: `${FETCH_ENTITY_REWARDS_PATH.replace('$entityType', entityType).replace('$entityId', entityId.toString())}?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify(tags ? { tags } : { tags: [] }),
    }).pipe(
      mergeMap(({
        response: {
          data,
          count,
        },
      }) => [
        fetchEntityRewardsSuccess(data, entityId, count, tags || []),
      ]),
      catchError((error: AjaxError) => ([
        handleError(error, FETCH_ENTITY_REWARDS_ERROR),
        editRewardsError(error),
      ])),
    );
  }),
);
