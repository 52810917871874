import React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './ProductLoader.module.scss';

interface ProductLoaderProps {
  productId: string;
}

const ProductLoader = ({ productId }: ProductLoaderProps) => {
  const [translate] = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Message
        content={translate('PRODUCT_IS_BEING_SEARCHED', { productId })}
        header={translate('OVERTIME')}
        icon={<Icon name="circle notched" loading />}
        size="tiny"
        warning
      />
    </div>
  );
};

export default ProductLoader;
