import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { CONFIRM_INDIVIDUAL_ORDER_ERROR, CONFIRM_INDIVIDUAL_ORDER_REQUEST, CONFIRM_INDIVIDUAL_ORDER_SUCCESS } from '../../constants/actionTypes';
import { ConfirmIndividualOrderRequest, NotificationType } from '../../models/actionTypes';
import { CONFIRM_INDIVIDUAL_ORDER } from '../../constants/paths';
import { State } from '../../models/State';
import { handleError } from '../../actions/errors/handleError';
import { confirmIndividualOrderError, confirmIndividualOrderSuccess } from '../../actions/individualOrders/confirmIndividualOrder';
import { addNotification } from '../../actions/notifications/addNotification';

const confirmOrders = (
  action$: ActionsObservable<ConfirmIndividualOrderRequest>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CONFIRM_INDIVIDUAL_ORDER_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    {
      payload:
        {
          orderId, finalPrice,
        },
    },
    {
      authentication: { authToken },
    },
  ]) => ajax({
    url: CONFIRM_INDIVIDUAL_ORDER.replace('$id', `${orderId}`),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      finalPrice,
    }),
  }).pipe(
    mergeMap(() => [
      confirmIndividualOrderSuccess(orderId),
      addNotification(NotificationType.SUCCESS, CONFIRM_INDIVIDUAL_ORDER_SUCCESS),
    ]),
    catchError(error => [
      handleError(error, CONFIRM_INDIVIDUAL_ORDER_ERROR),
      confirmIndividualOrderError(error),
    ]),
  )),
);

export default confirmOrders;
