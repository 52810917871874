import { Column } from '../../components/Table/Column';

const columns = (translate: any): Column[] => [{
  width: '15%',
  align: 'left',
  header: `# ${translate('ORDER')}`,
  key: 'orderId',
  sortable: true,
}, {
  width: '10%',
  header: 'ORDER_DATE',
  align: 'left',
  key: 'createdAt',
  sortable: true,
}, {
  width: '15%',
  header: 'TEAM_BASKET_ORDERS.STATUS_HEADER',
  align: 'left',
  key: 'status',
  sortable: false,
}, {
  width: '5%',
  header: 'TEAM_BASKET_ORDERS.ARTICLE_COUNT',
  align: 'center',
  key: 'articleCount',
  sortable: false,
}, {
  width: '10%',
  header: 'TEAM_BASKET_ORDERS.PRICE',
  align: 'right',
  key: 'price',
  sortable: false,
}, {
  width: '15%',
  header: 'TEAM_BASKET_ORDERS.FINAL_PRICE',
  align: 'right',
  key: 'finalPrice',
  sortable: true,
}, {
  width: '15%',
  header: 'TEAM_BASKET_ORDERS.CHECKOUT_TYPE.HEADER',
  align: 'left',
  key: 'checkoutType',
  sortable: false,
}];

export default columns;
