import React, { FC } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Checkbox, Image, Menu } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from './MenuItem';
import { SidebarProps } from './SidebarProps';
import styles from './Sidebar.module.scss';

const isActive = (pathname: string, route: string) => pathname === route;

const Sidebar: FC<SidebarProps> = ({
  signOut, location: { pathname }, menuExpanded, setMenuExpanded, menuItems,
}) => {
  const [translate] = useTranslation();
  const renderItems = ({ icon, route, name }: MenuItem) => (
    <Menu.Item
      key={name}
      as={Link}
      icon={icon}
      active={isActive(pathname, route)}
      name={translate(name)}
      to={route}
      className="nts"
    />
  );

  return (
    <Menu vertical key="menu1" className={menuExpanded ? '' : 'collapsed'} fixed="left">
      <Menu.Item as={Link} to="/" fitted header>
        {menuExpanded ? (
          <Image src="/images/logo-full.png" className={styles.logo} />
        ) : (
          <Image src="/images/logo-small.png" className={styles.logoSmall} />
        )}
      </Menu.Item>

      {menuItems.map(renderItems)}

      <div className="bottom">
        <Menu.Item
          name={translate('LOGOUT')}
          onClick={signOut}
          icon="sign-out"
        />
        <Menu.Item className="bordered toggle" onClick={() => setMenuExpanded(!menuExpanded)}>
          <Checkbox toggle fitted checked={menuExpanded} />
          {translate('HIDE_MENU')}
        </Menu.Item>
      </div>
    </Menu>
  );
};

export { Sidebar };
export default withRouter(Sidebar);
