import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Header, { HeaderTypes } from '../../../../components/Header';
import styles from './ProductDescription.module.scss';
import { ProductDescriptionProps } from './ProductDescriptionProps';
import DescriptionEditModal from '../DescriptionEditModal';

const ProductDescription: FC<ProductDescriptionProps> = ({
  description, setDescription, isUserSalesRep,
}) => {
  const [translate] = useTranslation();

  const renderButton = () => (isUserSalesRep ? (
    <DescriptionEditModal description={description} setDescription={setDescription} />
  ) : null);

  return (
    <div className={styles.wrapper}>
      <Header
        title={translate('DESCRIPTION')}
        type={HeaderTypes.H3}
        // @ts-ignore
        renderButton={renderButton}
      />

      <p>
        {description}
      </p>
    </div>
  );
};

export default ProductDescription;
