import React, {
  FC, SyntheticEvent, useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Input,
  InputOnChangeData,
} from 'semantic-ui-react';
import { ProductCategoryProps } from './ProductCategoryProps';
import Header, { HeaderTypes } from '../../../../components/Header';
import styles from './ProductCategory.module.scss';
import { productCategories } from '../../../../constants/productCategories';

const ProductCategory: FC<ProductCategoryProps> = (
  {
    category,
    customCategory,
    setCustomCategory,
  },
) => {
  const [translate] = useTranslation();
  const [categoryOptions] = useState<DropdownItemProps[]>(
    productCategories.map(cat => ({ text: cat, value: cat })),
  );

  const [showUserDefinedCategory, setShowUserDefinedCategory] = useState<boolean>(
    !!(customCategory && !productCategories.includes(customCategory)),
  );

  const handleCustomCategoryChange = (
    event: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) => {
    if (data.value !== 'Benutzerdefiniert') {
      setCustomCategory(data.value as string);
      setShowUserDefinedCategory(false);
    } else {
      setCustomCategory(undefined);
      setShowUserDefinedCategory(true);
    }
  };

  const getDropdownValue = useCallback(() => {
    if (customCategory) {
      if (productCategories.includes(customCategory)) {
        return customCategory;
      }
      return 'Benutzerdefiniert';
    }
    return undefined;
  }, [customCategory]);

  return (
    <div className={styles.wrapper}>
      <Header
        title={translate('CATEGORY')}
        type={HeaderTypes.H3}
      />
      <p>{category}</p>
      <div>
        <span>{translate('REPLACE_WITH')}</span>
        <div className={styles.customCategorySelection}>
          <Dropdown
            className={styles.growingContainer}
            name="custom-category-select"
            onChange={handleCustomCategoryChange}
            placeholder={translate('CUSTOM_CATEGORY_SELECT')}
            options={categoryOptions}
            selection
            value={getDropdownValue()}
          />
          {showUserDefinedCategory && (
          <Input
            className={styles.growingContainer}
            value={customCategory}
            placeholder={translate('CUSTOM_CATEGORY_INPUT')}
            onChange={(
              e: SyntheticEvent<HTMLInputElement>,
              data: InputOnChangeData,
            ) => setCustomCategory(data.value ? data.value : undefined)}
          />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCategory;
