import some from 'lodash/some';
import { Club, ClubEditRequest } from '../../../models/Club';

export default (club: Club): ClubEditRequest => ({
  name: club.name,
  address: some(club.address, Boolean) ? {
    ...club.address,
    country: 'DE',
  } : undefined,
  phoneNumber: club.phoneNumber,
  faxNumber: club.faxNumber,
  email: club.email,
  websiteAddress: club.websiteAddress,
  websiteEventsAddress: club.websiteEventsAddress,
  websiteNewsAddress: club.websiteNewsAddress,
  primaryColor: club.primaryColor,
  socialMedia: club.socialMedia,
  customerId: club.customerId,
  clubSalesId: club.clubSalesId,
});
