import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import TeamInvitationPopup from './TeamInvitationPopup';
import { State } from '../../../../models/State';
import { StateProps, DispatchProps } from './TeamInvitationPopupProps';
import { fetchTeamInviteRequest } from '../../../../actions/clubTeams/fetchTeamInvite';

const mapStateToProps = ({
  clubTeams: { inviteToken, loadingInviteToken, tokenDeactivated },
}: State): StateProps => ({
  inviteToken,
  loadingInviteToken,
  tokenDeactivated,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchInviteToken: bindActionCreators(fetchTeamInviteRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamInvitationPopup);
