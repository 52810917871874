import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import RewardsPointsEdition from './RewardsPointsEdition';
import { editRewardsRequest } from '../../../actions/rewards/editRewards';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  editRewards: bindActionCreators(editRewardsRequest, dispatch),
});

export default connect(null, mapDispatchToProps)(RewardsPointsEdition);
