import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Settings, { SettingsProps } from './Settings';
import { State } from '../../models/State';
import { Club } from '../../models/Club';
import { uploadClubLogoRequest } from '../../actions/clubs/uploadClubLogo';
import { fetchClubRequest } from '../../actions/clubs/fetchClub';

const mapStatesToProps = ({
  clubs: {
    byHash,
    loadingUpload,
    loading,
    clubLogoUploaded,
  },
}: State, { match: { params: { clubId } } }: SettingsProps) => ({
  club: clubId && byHash[clubId] && byHash[clubId].address
    ? byHash[clubId]
    : {
      address: {},
    } as Club,
  loadingUpload,
  loading,
  clubLogoUploaded,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  uploadClubLogo: bindActionCreators(uploadClubLogoRequest, dispatch),
  fetchClub: bindActionCreators(fetchClubRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(withRouter(Settings));
