import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import CatalogCreator from './CatalogCreator';
import { CatalogCreatorProps } from './CatalogCreatorProps';
import { State } from '../../models/State';
import { addProductRequest } from '../../actions/catalogs/addProduct';
import { createCatalogRequest } from '../../actions/catalogs/createCatalog';
import { createHobbyCatalogRequest } from '../../actions/catalogs/createHobbyCatalog';
import { fetchCatalogRequest } from '../../actions/catalogs/fetchCatalog';
import { fetchHobbyCatalogRequest } from '../../actions/catalogs/fetchHobbyCatalog';
import { fetchClubRequest } from '../../actions/clubs/fetchClub';
import { fetchEmbellishmentsRequest } from '../../actions/catalogs/fetchEmbellishments';
import { updateCatalog } from '../../actions/catalogs/updateCatalog';
import { HOBBY_CLUBS_ROUTE } from '../../constants/routes';
import {
  hideReleaseCatalogModalRequest,
} from '../../actions/catalogs/hideReleaseCatalogModalRequest';

const mapStateToProps = (
  {
    catalogs: {
      byHash, loading, loadingProduct, hobbyCatalog, showReleaseCatalogModal,
    },
    clubs: {
      byHash: clubByHash,
    },
    application: {
      memberships,
      clubId: currentlyUsedOrganizationId,
    },
    embellishments: {
      embellishmentsById,
      loadingEmbellishments,
    },
  }: State,
  { match: { params: { clubId } }, location: { pathname } }: CatalogCreatorProps,
) => {
  const isHobbyCatalog = (pathname as string).startsWith(HOBBY_CLUBS_ROUTE);

  return {
    catalog: isHobbyCatalog ? hobbyCatalog : byHash[clubId],
    club: clubByHash[clubId],
    clubId: (clubId && +clubId) || null,
    embellishmentsById,
    loading,
    loadingProduct,
    isHobbyCatalog,
    memberships,
    currentlyUsedOrganizationId,
    loadingEmbellishments,
    showReleaseCatalogModal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  addProduct: addProductRequest,
  createCatalog: createCatalogRequest,
  fetchCatalog: fetchCatalogRequest,
  fetchHobbyCatalog: fetchHobbyCatalogRequest,
  fetchClub: fetchClubRequest,
  fetchEmbellishments: fetchEmbellishmentsRequest,
  updateCatalog,
  createHobbyCatalog: createHobbyCatalogRequest,
  hideReleaseCatalogModal: hideReleaseCatalogModalRequest,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CatalogCreator);
