import {
  CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_ERROR,
  CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_REQUEST,
  CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_SUCCESS,
  CREATE_CLUB_ERROR,
  CREATE_CLUB_REQUEST,
  CREATE_CLUB_SUCCESS,
  DELETE_CLUB_SUCCESS,
  EDIT_REWARDS_ERROR,
  EDIT_REWARDS_REQUEST,
  EDIT_REWARDS_SUCCESS,
  FETCH_CLUB_ERROR,
  FETCH_CLUB_REQUEST,
  FETCH_CLUB_SUCCESS,
  FETCH_CLUBS_ERROR,
  FETCH_CLUBS_REQUEST,
  FETCH_CLUBS_SUCCESS,
  UPDATE_CLUB_ERROR,
  UPDATE_CLUB_REQUEST,
  UPDATE_CLUB_SUCCESS,
  UPLOAD_CLUB_LOGO_ERROR,
  UPLOAD_CLUB_LOGO_REQUEST,
  UPLOAD_CLUB_LOGO_SUCCESS,
} from '../constants/actionTypes';

import { Club, ClubReducer, ClubsByHash } from '../models/Club';
import { ClubActions } from '../models/actionTypes';
import { EditRewardsActions } from '../models/actionTypes/RewardsActions';
import { EntityTypesEnum } from '../models/Entity';

export const initialState: ClubReducer = {
  loading: false,
  loadingList: false,
  byHash: {},
  byId: [],
  count: 0,
  error: false,
  errorMessage: '',
  loadingUpload: false,
  clubLogoUploaded: false,
};

export default (state = initialState, action: ClubActions | EditRewardsActions) => {
  switch (action.type) {
    case FETCH_CLUB_REQUEST:
    case UPDATE_CLUB_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CLUB_SUCCESS:
    case FETCH_CLUB_SUCCESS: {
      const { club } = action.payload;
      return {
        ...state,
        loading: false,
        byHash: {
          ...state.byHash,
          [club.id]: {
            ...state.byHash[club.id],
            ...club,
            isIncomplete: false,
          },
        },
      };
    }
    case FETCH_CLUBS_REQUEST:
      return {
        ...state,
        loadingList: true,
      };
    case UPLOAD_CLUB_LOGO_REQUEST:
      return {
        ...state,
        loadingUpload: true,
        clubLogoUploaded: false,
      };
    case UPLOAD_CLUB_LOGO_SUCCESS: {
      const { clubId, publicUrl } = action.payload;
      const timestamp = (new Date()).valueOf();
      const logoUrl = `${publicUrl}?ts=${timestamp}&alpha=true`;

      return {
        ...state,
        loadingUpload: false,
        clubLogoUploaded: true,
        byHash: {
          ...state.byHash,
          [clubId]: {
            ...state.byHash[clubId],
            logoUrl,
          },
        },
      };
    }
    case UPLOAD_CLUB_LOGO_ERROR:
      return {
        ...state,
        error: true,
        clubLogoUploaded: false,
        loadingUpload: false,
      };
    case FETCH_CLUBS_SUCCESS: {
      const { clubs, count } = action.payload;

      return {
        ...state,
        loadingList: false,
        byHash: clubs
          .reduce((byHash: ClubsByHash, club: Club) => ({
            ...byHash,
            [club.id]: {
              ...club,
              isIncomplete: true,
            },
          }), state.byHash),
        byId: clubs.map((club: Club) => club.id),
        count,
      };
    }
    case CREATE_CLUB_REQUEST:
    case CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_CLUB_SUCCESS:
    case CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
      };
    case DELETE_CLUB_SUCCESS: {
      const { clubId } = action.payload;
      const { [clubId]: toRemove, ...clubsByHash } = state.byHash;

      return {
        ...state,
        byHash: clubsByHash,
        byId: state.byId.filter(id => id !== clubId),
      };
    }
    case CREATE_CLUB_ERROR:
    case FETCH_CLUB_ERROR:
    case UPDATE_CLUB_ERROR:
    case CREATE_CLUB_AND_ACCEPT_HOBBY_TEAM_PROMOTION_ERROR:
    case FETCH_CLUBS_ERROR: {
      const { error: { message } } = action.payload;
      return {
        ...state,
        loading: false,
        loadingList: false,
        error: true,
        errorMessage: message,
      };
    }
    case EDIT_REWARDS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case EDIT_REWARDS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case EDIT_REWARDS_SUCCESS: {
      const {
        clubId, entityType, pointsAmount, entityId,
      } = action.payload;
      if (entityType === EntityTypesEnum.ORGANIZATION) {
        const newClub = state.byHash[clubId];
        const newTotal = newClub.clubScore.points + pointsAmount;
        const newTotalCollected = newClub.clubScore.totalPointsEverCollected
        + (pointsAmount > 0 ? pointsAmount : 0);
        return {
          ...state,
          loading: false,
          byHash: {
            ...state.byHash,
            [clubId]: {
              ...state.byHash[clubId],
              clubScore: {
                ...state.byHash[clubId].clubScore,
                points: newTotal,
                totalPointsEverCollected: newTotalCollected,
              },
            },
          },
        };
      }
      if (entityType === EntityTypesEnum.TEAM) {
        let teams = state.byHash[clubId].teams ? [...state.byHash[clubId].teams!] : [];
        teams = teams.map(team => {
          if (+team.id === +entityId) {
            const points = team.teamScore.points + pointsAmount;
            const totalPointsEverCollected = team.teamScore.totalPointsEverCollected
                  + (pointsAmount > 0 ? pointsAmount : 0);
            return {
              ...team,
              teamScore: {
                ...team.teamScore,
                points,
                totalPointsEverCollected,
              },
            };
          }
          return team;
        });
        return {
          ...state,
          loading: false,
          byHash: {
            ...state.byHash,
            [clubId]: {
              ...state.byHash[clubId],
              teams,
            },
          },
        };
      }
      return { ...state, loading: false };
    }
    case 'FETCH_ENTITY_REWARDS_ERROR':
      return { ...state, loading: false };
    default:
      return state;
  }
};
