import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Row from '../../../components/Table/components/Row';
import Text from '../../../components/Text';
import styles from './HobbyClubRow.module.scss';
import { HobbyClubRowProps } from './HobbyClubRowProps';
import { getFullNameWithNullGuards } from '../../../utils/getFullName';
import { formatDate } from '../../../utils/DateUtils';
import { ClubFilterClubAdminResponse } from '../../../models/HobbyClub';
import { LightweightNtsTeamDto } from '../../../models/Team';

const HobbyClubRow: FC<HobbyClubRowProps> = ({
  hobbyClub,
}) => {
  const [translate] = useTranslation();
  const createdBy: ClubFilterClubAdminResponse = useMemo(
    () => (hobbyClub.clubAdmins.length ? hobbyClub.clubAdmins[0] : {}),
    [hobbyClub],
  );
  const team: LightweightNtsTeamDto | null = useMemo(
    () => (hobbyClub.teams ? hobbyClub.teams[0] : null),
    [hobbyClub],
  );

  return (
    <Row key={hobbyClub.id} classNames={styles.wrapper}>
      <Text>{hobbyClub.name || ''}</Text>
      <Text>{team ? team.name : ''}</Text>
      <Text>{hobbyClub.playerCount || '0'}</Text>
      <Text>{(hobbyClub.address && hobbyClub.address.displayName) || ''}</Text>
      <Text>{(hobbyClub.address && hobbyClub.address.postCode) || ''}</Text>
      <div className={styles.cell}>
        <Text>{getFullNameWithNullGuards(createdBy.firstName!, createdBy.lastName!)}</Text>
        <Text>{createdBy.email}</Text>
      </div>
      <Text>{translate(hobbyClub.hobbyType) || ''}</Text>
      <Text>{formatDate(hobbyClub.createdAt) || ''}</Text>
    </Row>
  );
};

export default HobbyClubRow;
