import { AjaxError } from 'rxjs/ajax';
import { FETCH_FILE_ERROR, FETCH_FILE_REQUEST, FETCH_FILE_SUCCESS } from '../../constants/actionTypes';
import {
  FetchFileErrorAction,
  FetchFileRequestAction,
  FetchFileSuccessAction,
} from '../../models/actionTypes';

export const fetchFileRequest = (
  id: string, extension?: string, download: boolean = false, fileName?: string,
): FetchFileRequestAction => ({
  type: FETCH_FILE_REQUEST,
  payload: {
    id,
    extension,
    download,
    fileName,
  },
});

export const fetchFileSuccess = (id: string, file: string | Blob): FetchFileSuccessAction => ({
  type: FETCH_FILE_SUCCESS,
  payload: {
    id,
    file,
  },
});

export const fetchFileError = (error: AjaxError): FetchFileErrorAction => ({
  type: FETCH_FILE_ERROR,
  payload: {
    error,
  },
});
