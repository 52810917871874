import {
  FETCH_EXTERNAL_CLUBS_ERROR,
  FETCH_EXTERNAL_CLUBS_REQUEST,
  FETCH_EXTERNAL_CLUBS_SUCCESS, TOR24_TEAMS_ERROR, TOR24_TEAMS_REQUEST,
  TOR24_TEAMS_SUCCESS,
} from '../constants/actionTypes';
import { Actions } from '../models/actionTypes';
import {
  ExternalClubsByHash,
  ExternalSystemsReducer,
  ExternalTeamsByHash,
  Tor24ClubDto,
} from '../models/ExternalSystems';
import { SIGN_OUT_ERROR, SIGN_OUT_SUCCESS } from '../packages/authentication';
import { Tor24Team } from '../models/tor24Team';

export const initialState: ExternalSystemsReducer = {
  loading: false,
  externalClubByHash: {},
  externalClubById: [],
  externalTeamByHash: {},
  externalTeamById: [],
  error: false,
};

export default (state = initialState, action : Actions) => {
  switch (action.type) {
    case FETCH_EXTERNAL_CLUBS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EXTERNAL_CLUBS_SUCCESS: {
      const { clubs } = action.payload;

      return {
        ...state,
        externalClubByHash: clubs
          .reduce((byHash: ExternalClubsByHash, club: Tor24ClubDto) => ({
            ...byHash,
            [club.id]: club,
          }), state.externalClubByHash),
        externalClubById: clubs.map(({ id }: Tor24ClubDto) => id),
        loading: false,
      };
    }
    case FETCH_EXTERNAL_CLUBS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case TOR24_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TOR24_TEAMS_SUCCESS: {
      const teams = action.payload.teams.response;
      return {
        ...state,
        externalTeamByHash: teams
          .reduce((byHash: ExternalTeamsByHash, team: Tor24Team) => ({
            ...byHash,
            [team.id]: team,
          }), state.externalTeamByHash),
        externalTeamById: teams.map(({ id }: Tor24Team) => id),
        loading: false,
      };
    }
    case TOR24_TEAMS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SIGN_OUT_SUCCESS:
    case SIGN_OUT_ERROR:
      return initialState;
    default:
      return state;
  }
};
