import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dimmer, Image, Loader,
} from 'semantic-ui-react';

import styles from './ProductsUploadImage.module.scss';
import {
  ProductsUploadImageProps,
} from './ProductsUploadImageProps';
import Confirm from '../../../../components/Confirm';

const ProductUploadImage = ({
  id,
  filesByHash,
  setActiveImageId,
  removeDraftFile,
}: ProductsUploadImageProps) => {
  const [translate] = useTranslation();

  const onClick = () => {
    setActiveImageId(id);
  };

  return (
    <div
      className={styles.imageWrapper}
      key={id}
    >
      {filesByHash[id]
        ? <Image src={filesByHash[id]} className={styles.image} />
        : <Dimmer active inverted><Loader /></Dimmer>
      }
      <Dimmer className={styles.dimmer}>
        <Button
          content={translate('REPLACE_IMAGE')}
          onClick={onClick}
        />
        <Confirm
          as={Button}
          confirmText={translate('ARE_YOU_SURE_TO_DELETE_ATTACHMENT')}
          content={translate('REMOVE_IMAGE')}
          inverted
          onConfirm={() => removeDraftFile(id)}
        />
      </Dimmer>
    </div>
  );
};

export default ProductUploadImage;
