import { FETCH_HOBBY_CLUBS_REQUEST, FETCH_HOBBY_CLUBS_SUCCESS, FETCH_HOBBY_CLUBS_ERROR } from '../constants/actionTypes';
import { Actions } from '../models/actionTypes';
import { HobbyClubsReducer, HobbyClubsByHash, HobbyClub } from '../models/HobbyClub';
import { SIGN_OUT_ERROR, SIGN_OUT_SUCCESS } from '../packages/authentication';

export const initialState: HobbyClubsReducer = {
  loading: false,
  byHash: {},
  byId: [],
  count: 0,
  error: false,
  errorMessage: '',
};

export default (state = initialState, action : Actions) => {
  switch (action.type) {
    case FETCH_HOBBY_CLUBS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_HOBBY_CLUBS_SUCCESS: {
      const { count, clubs } = action.payload;
      return {
        ...state,
        byHash: clubs
          .reduce((byHash: HobbyClubsByHash, request: HobbyClub) => ({
            ...byHash,
            [request.id]: request,
          }), state.byHash),
        byId: clubs.map(({ id }: HobbyClub) => id),
        loading: false,
        count,
      };
    }
    case FETCH_HOBBY_CLUBS_ERROR: {
      const { error: { message } } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: message,
      };
    }
    case SIGN_OUT_SUCCESS:
    case SIGN_OUT_ERROR:
      return initialState;
    default:
      return state;
  }
};
