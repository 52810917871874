import config from '../config';

export const CLUBS_PATH = `${config.API_URL}/clubs`;
export const EMBELLISHMENTS_PATH = `${config.API_URL}/embellishments`;
export const EMBELLISHMENTS_VALUES_PATH = `${EMBELLISHMENTS_PATH}/$clubId/select-option`;
export const TAGS_PATH = `${config.API_URL}/tags`;
export const CATALOGS_PATH = `${CLUBS_PATH}/catalogs`;
export const CATALOG_PATH = `${CLUBS_PATH}/$clubId/catalog`;
export const HOBBY_CATALOG_DETAILS_PATH = `${config.API_URL}/hobby/catalog-details`;
export const HOBBY_CATALOG_UPSERT_PATH = `${config.API_URL}/hobby/catalog`;
export const CATALOG_PRODUCTS_PATH = `${CATALOG_PATH}/products`;
export const INVITATIONS_PATH = `${config.API_URL}/invitations`;
export const PRODUCTS_PATH = `${config.API_URL}/products`;
export const PRODUCTS_BY_ID_PATH = `${PRODUCTS_PATH}/by-external-id/$id`;
export const UPLOAD_FILE_PATH = `${config.API_URL}/catalog/files/upload`;
export const FETCH_FILE_PATH = `${config.API_URL}/files/$contentId`;
export const EDIT_TEAM_PROMOTION_PATH = `${config.API_URL}/hobby/teams/promotion/$promotionId`;
export const FILTER_SALES_REP_PATH = `${config.API_URL}/sales/organizations/filter`;
export const APPROVE_PROMOTION_PATH = `${config.API_URL}/hobby/teams/$teamId/promotion/accept`;
export const REJECT_PROMOTION_PATH = `${config.API_URL}/hobby/teams/$teamId/promotion/reject`;

export const ORDERS = `${config.API_URL}/orders`;
export const INDIVIDUAL_ORDERS = `${config.API_URL}/individual/orders`;
export const ORDERS_LIST = `${ORDERS}/list/sales-rep`;
export const ORDER_LIST_BY_CLUB = `${config.API_URL}/orders/list/sales-rep/clubs/$clubId`;
export const INDIVIDUAL_ORDERS_LIST = `${INDIVIDUAL_ORDERS}/filter/all`;
export const TEAM_BASKET_ORDERS_LIST = `${config.API_URL}/teambasket/orders/list`;
export const CONFIRM_INDIVIDUAL_ORDER = `${INDIVIDUAL_ORDERS}/$id/confirm`;
export const COPY_CATALOG = `${config.API_URL}/clubs/$originalClubId/catalog/copy/$targetClubId`;
export const EXTERNAL_CLUBS_TOR_24 = `${config.API_URL}/external-system-integration/helpers/tor24/clubs`;
export const EXTERNAL_REFERENCES = `${config.API_URL}/external-system-integration/references`;

export const HOBBY_TEAMS = `${config.API_URL}/hobby/teams`;
export const HOBBY_TEAM_REQUESTS = `${HOBBY_TEAMS}/promotion/filter`;

export const UPLOAD_LOGO_PATH = `${CLUBS_PATH}/upload/$clubId`;
export const REASSIGN_SALES_REP_PATH = `${CLUBS_PATH}/$clubId/sales-rep-assignment`;

export const EXTERNAL_PATH = `${config.API_URL}/external-system-integration`;
export const EXTERNAL_SYSTEM_SET_PATH = `${EXTERNAL_PATH}/references`;
export const EXTERNAL_REF_DELETION_PATH = `${EXTERNAL_SYSTEM_SET_PATH}/organization/$clubId/$externalRefName`;
export const TOR24_TEAMS_PATH = `${EXTERNAL_PATH}/helpers/tor24/clubs/$clubId/teams`;

export const REWARDS_PATH = `${config.API_URL}/rewards`;
export const REWARDS_EDIT_PATH = `${REWARDS_PATH}/modify`;
export const FETCH_ENTITY_REWARDS_PATH = `${REWARDS_PATH}/$entityType/$entityId/filter`;

export const CLUB_TEAMS_PATH = `${CLUBS_PATH}/$clubId/teams`;
export const FETCH_CLUB_TEAMS_PATH = `${CLUB_TEAMS_PATH}/filter`;

export const CHANGE_TEAM_STATUS_PATH = `${config.API_URL}/teams/$teamId/status`;
export const ONE_TEAM_PATH = `${config.API_URL}/teams/$teamId`;

export const CHECK_EMAIL_AVAILABILITY = `${CLUBS_PATH}/$clubId/members/verify-email-availability`;
export const TEAM_PLAYERS_PATH = `${config.API_URL}/teams/$teamId/players`;
export const MEMBERS_PATH = `${CLUBS_PATH}/$clubId/members/filter`;

export const ASSIGN_MEMBERS_PATH = `${CLUBS_PATH}/member/assign`;
export const TEAMS_PATH = `${CLUBS_PATH}/$clubId/teams`;
export const TEAM_PATH = `${TEAMS_PATH}/$teamId`;

export const RESEND_INVITATION_PATH = `${INVITATIONS_PATH}/:token/resend`;
export const TEAM_STAFF_PATH = `${config.API_URL}/teams/$teamId/staff`;
export const TEAM_INVITATION_TOKEN = `${config.API_URL}/teams/$teamId/invitation-token`;
export const DEACTIVATE_INVITE_TOKEN = `${config.API_URL}/invitations/$token/invalidate`;

export const CONVERT_TO_OFFICIAL = `${config.API_URL}/hobby/teams/$teamId/convert-to-official`;
