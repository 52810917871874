import { AjaxError } from 'rxjs/ajax';
import {
  UPLOAD_CLUB_LOGO_ERROR,
  UPLOAD_CLUB_LOGO_REQUEST,
  UPLOAD_CLUB_LOGO_SUCCESS,
} from '../../constants/actionTypes';
import {
  UploadClubLogoRequestAction, UploadClubLogoSuccessAction,
  UploadClubLogoErrorAction,
} from '../../models/actionTypes';

export const uploadClubLogoRequest = (
  clubId: number,
  image: File,
): UploadClubLogoRequestAction => ({
  type: UPLOAD_CLUB_LOGO_REQUEST,
  payload: {
    image,
    clubId,
  },
});

export const uploadClubLogoSuccess = (
  clubId: number,
  publicUrl: string,
): UploadClubLogoSuccessAction => ({
  type: UPLOAD_CLUB_LOGO_SUCCESS,
  payload: {
    clubId,
    publicUrl,
  },
});

export const uploadClubLogoError = (error: AjaxError): UploadClubLogoErrorAction => ({
  type: UPLOAD_CLUB_LOGO_ERROR,
  payload: {
    error,
  },
});
