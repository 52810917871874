import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dropdown, Icon, Popup,
} from 'semantic-ui-react';
import Row from '../../../../components/Table/components/Row';
import Text from '../../../../components/Text';
import EditTeamRequestModal from '../EditTeamRequestModal';
import styles from './TeamRequestRow.module.scss';
import { TeamRequestRowProps } from './TeamRequestRowProps';
import { getFullNameWithNullGuards } from '../../../../utils/getFullName';
import { formatDate } from '../../../../utils/DateUtils';
import { history } from '../../../../configureStore';
import { Roles } from '../../../../constants/roles';

const TeamRequestRow: FC<TeamRequestRowProps> = ({
  request: {
    id, club, team, status, createdBy, createdAt,
    clubAdminContactDetails, comment, actualRoleInClub,
  },
  request,
  setSelectedRequestId,
  rejectRequest,
}) => {
  const [translate] = useTranslation();
  const [clubAdmin, setClubAdmin] = useState(clubAdminContactDetails);
  const [editModalOpened, setEditModalOpened] = useState<boolean>(false);

  const handleRejectClick = () => {
    rejectRequest(team.id, id);
  };

  const handleFindClubClick = () => {
    history.push(history.location.pathname);
    setSelectedRequestId(id);
  };

  const handleAssignClub = () => {
    handleFindClubClick();
  };

  useEffect(() => {
    if (clubAdminContactDetails) {
      setClubAdmin(clubAdminContactDetails);
    } else if (actualRoleInClub === Roles.CLUB_ADMIN) {
      setClubAdmin(createdBy);
    }
  }, [clubAdminContactDetails, actualRoleInClub, createdBy]);

  return (
    <>
      <Row key={id} classNames={styles.wrapper} itemOnClick={handleFindClubClick}>
        <div className={styles.cell}>
          <Text>{team.name || ''}</Text>
          <Text>{club.name || ''}</Text>
        </div>
        <div className={styles.cell}>
          <Text>{(club.address && club.address.postCode) || ''}</Text>
          <Text>{(club.address && club.address.displayName) || ''}</Text>
        </div>
        <div className={styles.cell}>
          {clubAdmin ? (
            <>
              <Text>{getFullNameWithNullGuards(clubAdmin.firstName, clubAdmin.lastName)}</Text>
              <Text>{clubAdmin.email}</Text>
              <Text>{clubAdmin.phoneNumber}</Text>
            </>
          ) : <em>{translate('UNKNOWN')}</em>}
        </div>
        <div className={styles.cell}>
          <Text>{getFullNameWithNullGuards(createdBy.firstName, createdBy.lastName)}</Text>
          <Text>{createdBy.email}</Text>
          <Text>{createdBy.phoneNumber}</Text>
          <Text>{(actualRoleInClub && translate(actualRoleInClub)) || ''}</Text>
        </div>
        <Text>{formatDate(createdAt)}</Text>
        <Text>{translate(status)}</Text>
        <div className={styles.actionsCell} onClick={(e: any) => { e.stopPropagation(); }}>
          {comment && <Popup className={styles.commentPopup} content={comment} position="bottom right" trigger={<Button className={styles.infoButton} circular size="tiny" icon="info" />} />}
          <Dropdown icon={<Icon name="ellipsis vertical" color="red" size="large" />}>
            <Dropdown.Menu direction="left">
              <Dropdown.Item
                text={translate('FIND_CLUB')}
                onClick={handleAssignClub}
              />
              <Dropdown.Item text={translate('EDIT')} onClick={() => setEditModalOpened(true)} />
              <Dropdown.Item
                text={translate('REJECT')}
                onClick={handleRejectClick}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Row>
      {editModalOpened && (
        <EditTeamRequestModal
          setEditModalOpened={setEditModalOpened}
          request={request}
        />
      )}
    </>
  );
};

export default TeamRequestRow;
