import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { CLUBS_LIST_LIMIT } from '../../constants/customizations';
import { CLUBS_PATH } from '../../constants/paths';
import { ClubDataResponse } from '../../models/Club';
import { FETCH_CLUBS_ERROR, FETCH_CLUBS_REQUEST } from '../../constants/actionTypes';
import { FetchClubsRequestAction } from '../../models/actionTypes';
import { ListResponse, Response } from '../../models/Response';
import { State } from '../../models/State';
import { fetchClubsError, fetchClubsSuccess } from '../../actions/clubs/fetchClubs';
import { serializeClubData } from '../../utils/serializers/serializeClub';
import { handleError } from '../../actions/errors/handleError';

export default (
  action$: ActionsObservable<FetchClubsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_CLUBS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      page, search, field, order, limit, filter,
    },
  },
  { authentication: { authToken } },
  ]) => {
    const query = queryString.stringify(pickBy({
      offset: ((page || 1) - 1) * CLUBS_LIST_LIMIT,
      limit: limit || CLUBS_LIST_LIMIT,
    }));

    return ajax({
      url: `${CLUBS_PATH}/filter?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify({
        search,
        ...((field || order) && { sort: { field, order } }),
        ...(filter && JSON.parse(filter!)),
      }),
    }).pipe(
      map(({ response: { data, count } }: Response<ListResponse<ClubDataResponse>>) => ({
        clubs: data ? data.map(serializeClubData) : [],
        count,
      })),
      map(fetchClubsSuccess),
      catchError((error: any) => ([
        handleError(error, FETCH_CLUBS_ERROR),
        fetchClubsError(error),
      ])),
    );
  }),
);
