import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import queryString from 'query-string';
import pickBy from 'lodash/pickBy';
import { FETCH_CLUB_TEAMS_ERROR, FETCH_CLUB_TEAMS_REQUEST } from '../../constants/actionTypes';
import { State } from '../../models/State';
import { FetchClubTeamsRequestAction } from '../../models/actionTypes';
import { ListResponse, Response } from '../../models/Response';
import { ClubTeam } from '../../models/ClubTeam';
import { handleError } from '../../actions/errors/handleError';
import { FETCH_CLUB_TEAMS_PATH } from '../../constants/paths';
import { MAX_TEAMS_LIST_LIMIT, TEAMS_LIST_LIMIT } from '../../constants/customizations';
import TeamStatus from '../../constants/teamStatus';
import { fetchClubTeamsError, fetchClubTeamsSuccess } from '../../actions/clubTeams/fetchClubTeams';

export default (
  action$: ActionsObservable<FetchClubTeamsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_CLUB_TEAMS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    {
      payload: {
        clubId, page, search, pagination = true, filter,
      },
    },
    { authentication: { authToken } },
  ]) => {
    const query = queryString.stringify(pickBy({
      offset: ((page || 1) - 1) * (pagination ? TEAMS_LIST_LIMIT : MAX_TEAMS_LIST_LIMIT),
      limit: (pagination ? TEAMS_LIST_LIMIT : MAX_TEAMS_LIST_LIMIT),
    }));
    let seasons;
    if (filter) {
      seasons = JSON.parse(filter);
    }

    return ajax({
      url: `${FETCH_CLUB_TEAMS_PATH.replace('$clubId', `${clubId}`)}?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify({ search, ...seasons }),
    }).pipe(
      map(({ response: { data, count } }: Response<ListResponse<ClubTeam>>) => {
        const hasInactiveTeams = data.find(team => team.status === TeamStatus.INACTIVE);

        return fetchClubTeamsSuccess(
          count,
          data,
          !!hasInactiveTeams,
        );
      }),
      catchError(error => [
        handleError(error, FETCH_CLUB_TEAMS_ERROR),
        fetchClubTeamsError(error),
      ]),
    );
  }),
);
