import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { push } from 'connected-react-router';

import { NotificationType, UpdateProductAction } from '../../models/actionTypes';
import { State } from '../../models/State';
import { UPDATE_PRODUCT } from '../../constants/actionTypes';
import { addNotification } from '../../actions/notifications/addNotification';
import { saveProductFilesRequest } from '../../actions/catalogs/updateProduct';
import { CATALOG_CREATOR_ROUTE } from '../../constants/routes';

export default (
  action$: ActionsObservable<UpdateProductAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(UPDATE_PRODUCT),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { product, clubId } },
  ]) => {
    const backLink = `${CATALOG_CREATOR_ROUTE.replace(':clubId', String(clubId))}`;
    return [saveProductFilesRequest(clubId, product), push(backLink), addNotification(NotificationType.SUCCESS, 'UPDATE_PRODUCT_SUCCESS')];
  }),
);
