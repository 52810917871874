import React, { FC } from 'react';
import queryString, { ParsedQuery } from 'query-string';
import { Button, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import AuthenticationFormsWrapper from '../../components/AuthenticationFormsWrapper';
import RequestTokenForm from './components/RequestTokenForm';
import UpdatePasswordForm from './components/UpdatePasswordForm';
import { HOME_ROUTE, SIGN_IN_ROUTE } from '../../constants/routes';

interface ResetPasswordProps extends RouteComponentProps {
  resetPasswordCleanUp: () => void;
}

const ResetPassword: FC<ResetPasswordProps> = ({ location, history, resetPasswordCleanUp }) => {
  const { resettoken: resetToken, username }: ParsedQuery = queryString.parse(location.search);
  const [translate] = useTranslation();

  const handleGoBack = () => {
    history.push(HOME_ROUTE);
    resetPasswordCleanUp();
  };

  return (
    <AuthenticationFormsWrapper>
      <div>
        <Button
          as={Link}
          basic
          className="simple"
          content={translate('BACK_TO_LOGIN')}
          icon="triangle left"
          onClick={handleGoBack}
          to={SIGN_IN_ROUTE}
        />
      </div>

      <Header as="h2" content={translate('RESET_PASSWORD')} />

      {!(resetToken && username) ? (
        <RequestTokenForm />
      ) : (
        <UpdatePasswordForm
          token={resetToken as string}
          username={username as string}
        />
      )}
    </AuthenticationFormsWrapper>
  );
};

export default ResetPassword;
