export default [
  {
    name: 'RED',
    hex: '#D00808',
  }, {
    name: 'BLUE',
    hex: '#001CB5',
  }, {
    name: 'GREEN',
    hex: '#1BA912',
  }, {
    name: 'YELLOW',
    hex: '#FFD400',
  },
];
