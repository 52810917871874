import { AjaxError } from 'rxjs/ajax';
import {
  EditRewardsErrorAction,
  EditRewardsRequestAction,
  EditRewardsSuccessAction,
} from '../../models/actionTypes/RewardsActions';
import { EDIT_REWARDS_ERROR, EDIT_REWARDS_REQUEST, EDIT_REWARDS_SUCCESS } from '../../constants/actionTypes';
import { EntityTypesEnum } from '../../models/Entity';

export const editRewardsRequest = (
  entityId: number,
  entityType: EntityTypesEnum,
  pointsAmount: number,
  reason: string,
  clubId: number,
  tags?: string[],
): EditRewardsRequestAction => ({
  type: EDIT_REWARDS_REQUEST,
  payload: {
    entityId,
    entityType,
    pointsAmount,
    reason,
    clubId,
    tags,
  },
});

export const editRewardsSuccess = (
  entityId: number,
  entityType: EntityTypesEnum,
  pointsAmount: number,
  clubId: number,
): EditRewardsSuccessAction => ({
  type: EDIT_REWARDS_SUCCESS,
  payload: {
    entityId,
    entityType,
    pointsAmount,
    clubId,
  },
});

export const editRewardsError = (error: AjaxError): EditRewardsErrorAction => ({
  type: EDIT_REWARDS_ERROR,
  payload: {
    error,
  },
});
