import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Clubs from './Clubs';
import { createClubRequest } from '../../actions/clubs/createClub';
import { State } from '../../models/State';
import { fetchClubsRequest } from '../../actions/clubs/fetchClubs';
import { StateProps, DispatchProps } from './ClubProps';

const mapStatesToProps = (state: State): StateProps => {
  const {
    application: {
      clubId,
      memberships,
    },
    clubs: {
      error,
      errorMessage,
      loading,
      loadingList,
      byId,
      byHash,
      count,
    },
  } = state;

  return ({
    clubs: byId.map(id => byHash[id]),
    loading,
    loadingList,
    count,
    error,
    errorMessage,
    userRoles: clubId ? memberships[clubId] : [],
  });
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  createClub: bindActionCreators(createClubRequest, dispatch),
  fetch: bindActionCreators(fetchClubsRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(Clubs);
