import { uniq } from 'lodash';
import {
  EDIT_REWARDS_ERROR,
  EDIT_REWARDS_REQUEST,
  FETCH_ENTITY_REWARDS_ERROR,
  FETCH_ENTITY_REWARDS_REQUEST,
  FETCH_ENTITY_REWARDS_SUCCESS,
} from '../constants/actionTypes';
import { Actions } from '../models/actionTypes';
import { SIGN_OUT_ERROR, SIGN_OUT_SUCCESS } from '../packages/authentication';
import { ActivityRewardsByHash, ActivityRewardsReducer } from '../models/activityRewards';

export const initialState: ActivityRewardsReducer = {
  loading: false,
  byHash: {},
  byId: [],
  entityId: '',
  count: 0,
  error: false,
  errorMessage: '',
  lastDateTime: 0,
  tags: [],
};

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case FETCH_ENTITY_REWARDS_REQUEST:
    case EDIT_REWARDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ENTITY_REWARDS_SUCCESS: {
      const {
        data, entityId, count, tags,
      } = action.payload;
      if (data.length === 0) {
        return {
          ...state,
          data: [],
          entityId,
          loading: false,
          tags,
          count,
          byHash: {},
          byId: [],
        };
      }
      const lastUpdateTime = data[data.length - 1].createdAt;
      let newIds = data.map(reward => +reward.id);
      if ((+state.entityId === entityId) && (state.tags === tags)) {
        newIds = uniq(state.byId.concat(newIds));
        const newRewardByHash = newIds.reduce((byHash: ActivityRewardsByHash, itemId: number) => {
          const activityFound = state.byHash[itemId]
            ? state.byHash[itemId] : data.find(activity => +activity.id === itemId);
          return ({
            ...byHash,
            ...(activityFound && { [itemId]: activityFound }),
          });
        }, {});

        return {
          ...state,
          lastDateTime: +lastUpdateTime,
          count,
          byHash: newRewardByHash,
          byId: newIds,
          loading: false,
          tags,
        };
      }
      const newRewardByHash = newIds.reduce((byHash: ActivityRewardsByHash, itemId: number) => {
        const activityFound = data.find(activity => +activity.id === itemId);
        return ({
          ...byHash,
          ...(activityFound && { [itemId]: activityFound }),
        });
      }, {});

      return {
        ...state,
        byHash: newRewardByHash,
        byId: newIds,
        entityId,
        lastDateTime: lastUpdateTime,
        loading: false,
        tags,
        count,
      };
    }
    case FETCH_ENTITY_REWARDS_ERROR: {
      const { error: { message } } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: message,
      };
    }
    case EDIT_REWARDS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case SIGN_OUT_SUCCESS:
    case SIGN_OUT_ERROR:
      return initialState;
    default:
      return state;
  }
};
