import React, { FC, useEffect, useState } from 'react';
import {
  DropdownItemProps, DropdownProps, Form,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import useFilters from '../../hooks/useFilters';
import styles from './OrdersListFilter.module.scss';

interface OrdersListFilterProps {
  history: any
}

const OrdersListFilter : FC<OrdersListFilterProps> = ({ history }) => {
  const [translate] = useTranslation();
  const [selectedType, setSelectedType] = useState<string>('ALL');
  const { onFilterChange } = useFilters(history);
  const [typeOptions] = useState<DropdownItemProps[]>([{
    value: 'ALL',
    text: translate('ALL'),
  }, {
    value: 'FRANCHISE',
    text: translate('FRANCHISES'),
  }, {
    value: 'FORCE',
    text: translate('FORCE'),
  }]);

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange({
        orderType: selectedType,
      });
    }
    // eslint-disable-next-line
  }, [selectedType]);

  const handleTypeChanged = (e: any, data: DropdownProps) => {
    setSelectedType(data.value as string);
  };

  return (
    <Form className={styles.form}>
      <Form.Field className={styles.filterItem}>
        <Form.Dropdown
          className={styles.filterDropdown}
          name="order-type-select"
          onChange={handleTypeChanged}
          placeholder={translate('TYPE')}
          value={selectedType}
          options={typeOptions}
          selection
        />
      </Form.Field>

    </Form>
  );
};
export default OrdersListFilter;
