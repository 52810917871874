import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError, mergeMap, withLatestFrom, map,
} from 'rxjs/operators';
import { EmbellishmentValue } from '../../models/Embellishment';
import {
  FETCH_EMBELLISHMENT_VALUES_ERROR,
  FETCH_EMBELLISHMENT_VALUES_REQUEST,
} from '../../constants/actionTypes';
import { FetchEmbellishmentValuesRequestAction } from '../../models/actionTypes';
import { State } from '../../models/State';
import { handleError } from '../../actions/errors/handleError';
import { EMBELLISHMENTS_VALUES_PATH } from '../../constants/paths';
import { Response } from '../../models/Response';
import {
  fetchEmbellishmentValuesError,
  fetchEmbellishmentValuesSuccess,
} from '../../actions/catalogs/fetchEmbellishmentValues';

interface FetchEmbellishmentValueResponse {
  embellishmentSelectOptions: EmbellishmentValue[]
}

export default (
  action$: ActionsObservable<FetchEmbellishmentValuesRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_EMBELLISHMENT_VALUES_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { clubId } }, { authentication: { authToken } }]) => ajax({
    url: `${EMBELLISHMENTS_VALUES_PATH.replace('$clubId', `${clubId}`)}/list`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: embellishmentValues }: Response<FetchEmbellishmentValueResponse>) => (
      fetchEmbellishmentValuesSuccess(embellishmentValues)
    )),
    catchError((error: AjaxError) => ([
      handleError(error, FETCH_EMBELLISHMENT_VALUES_ERROR),
      fetchEmbellishmentValuesError(error),
    ])),
  )),
);
