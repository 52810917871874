import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { State } from '../../models/State';
import { uploadClubLogoRequest } from '../../actions/clubs/uploadClubLogo';
import { fetchClubRequest } from '../../actions/clubs/fetchClub';
import SalesRepReassign, { SalesRepReassignProps } from './SalesRepReassign';
import { fetchSalesRepOrgsRequest } from '../../actions/organizations/FetchSalesRepOrgs';
import { reassignSalesRepToClubRequest } from '../../actions/organizations/ReassignSalesRepToClub';

const mapStatesToProps = ({
  clubs: {
    byHash,
    loading,
  },
  organizations: {
    byHash: organizationsByHash,
    byId: organizationsById,
  },
}: State, { match: { params: { clubId } } }: SalesRepReassignProps) => ({
  club: clubId && byHash[clubId],
  loading,
  organizationsByHash,
  organizationsById,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  uploadClubLogo: bindActionCreators(uploadClubLogoRequest, dispatch),
  fetchClub: bindActionCreators(fetchClubRequest, dispatch),
  fetchSalesRepOrgs: bindActionCreators(fetchSalesRepOrgsRequest, dispatch),
  reassignSalesReptoClub: bindActionCreators(reassignSalesRepToClubRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(withRouter(SalesRepReassign));
