import { ActionsObservable, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

import { ERROR } from '../../constants/actionTypes';
import { HandleErrorAction, NotificationType } from '../../models/actionTypes';
import { addNotification } from '../../actions/notifications/addNotification';

const handleError = (
  action$: ActionsObservable<HandleErrorAction>,
) => action$.pipe(
  ofType(ERROR),
  map(({ payload: { error, actionType } }) => addNotification(
    NotificationType.ERROR,
    actionType,
    error.status,
    error.message,
  )),
);

export default handleError;
