import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import { CLUBS_ROUTE } from '../../constants/routes';
import styles from './Rewards.module.scss';
import { RewardsProps } from './RewardsProps';
import ClubRewardsBallers from './TeamRewardsBallers';
import RewardsActivitiesList from './RewardsActivityList';
import RewardsPointsEdition from './TeamRewardsBallersCreation';
import { EntityTypesEnum } from '../../models/Entity';
import RewardsHeader from './RewardsHeader';

const Rewards: FC<RewardsProps> = ({
  club,
  fetchClubById,
  clubId,
}) => {
  const [translate] = useTranslation();

  useEffect(() => {
    fetchClubById(clubId);
  }, [clubId, fetchClubById]);

  return (
    <div>
      <Header
        backLinkTo={CLUBS_ROUTE}
        backLabel="BACK"
        title={translate('REWARDS.REWARDS_FOR_HEADER', { club: club.name })}
      />
      <div className={styles.rewards}>
        <RewardsHeader clubId={clubId} />
        <ClubRewardsBallers club={club} />
        <h3>{translate('CLUB_ACTIVITY')}</h3>
        <div className={styles.rewardsRedeem}>
          <RewardsPointsEdition
            clubId={club.id}
            entityId={club.id}
            entityType={EntityTypesEnum.ORGANIZATION}
            action="REDEEM"
          />
        </div>
        <RewardsActivitiesList entityType={EntityTypesEnum.ORGANIZATION} entityId={clubId} />
      </div>
    </div>
  );
};

export default Rewards;
