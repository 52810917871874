import { AjaxError } from 'rxjs/ajax';
import { Player, PlayerFormData } from '../../models/Player';
import { CreateInvitation, InvitationPersonalData } from '../../models/InvitationData';
import {
  CREATE_PLAYER_ERROR,
  CREATE_PLAYER_REQUEST,
  CREATE_PLAYER_SUCCESS,
} from '../../constants/actionTypes';
import {
  CreatePlayerErrorAction,
  CreatePlayerRequestAction,
  CreatePlayerSuccessAction,
} from '../../models/actionTypes';

export const createPlayerRequest = (
  player: PlayerFormData,
  teamId: number,
  shouldInvite: boolean,
  createInvitation: CreateInvitation,
  invitationPersonalData: InvitationPersonalData,
): CreatePlayerRequestAction => ({
  type: CREATE_PLAYER_REQUEST,
  payload: {
    teamId,
    player,
    shouldInvite,
    createInvitation,
    invitationPersonalData,
  },
});

export const createPlayerSuccess = (player: Player): CreatePlayerSuccessAction => ({
  type: CREATE_PLAYER_SUCCESS,
  payload: {
    player,
  },
});

export const createPlayerError = (error: AjaxError): CreatePlayerErrorAction => ({
  type: CREATE_PLAYER_ERROR,
  payload: {
    error,
  },
});
