import { AjaxError } from 'rxjs/ajax';
import { COPY_CATALOG_ERROR, COPY_CATALOG_REQUEST, COPY_CATALOG_SUCCESS } from '../../constants/actionTypes';
import { CopyCatalogErrorAction, CopyCatalogRequestAction, CopyCatalogSuccessAction } from '../../models/actionTypes';

export const copyCatalogRequest = (
  originalClubId: number, targetClubId: number,
): CopyCatalogRequestAction => ({
  type: COPY_CATALOG_REQUEST,
  payload: {
    originalClubId,
    targetClubId,
  },
});

export const copyCatalogSuccess = (clubId: number): CopyCatalogSuccessAction => ({
  type: COPY_CATALOG_SUCCESS,
  payload: {
    clubId,
  },
});

export const copyCatalogError = (error: AjaxError): CopyCatalogErrorAction => ({
  type: COPY_CATALOG_ERROR,
  payload: {
    error,
  },
});
