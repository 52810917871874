import { CatalogUpdate } from '../../models/Catalog';
import { UPDATE_HOBBY_CATALOG } from '../../constants/actionTypes';
import { UpdateHobbyCatalogAction } from '../../models/actionTypes';

export const updateHobbyCatalog = (catalog: CatalogUpdate): UpdateHobbyCatalogAction => ({
  type: UPDATE_HOBBY_CATALOG,
  payload: {
    catalog,
  },
});
