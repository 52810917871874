import React, {
  FC, useState, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal, Button, Header, Radio, Input, Dropdown, DropdownProps, Form,
} from 'semantic-ui-react';
import styles from './TeamRequestApprovalModal.module.scss';
import { history } from '../../../../configureStore';
import { TeamBase } from '../../../../models/Team';
import TableContainer from '../../../../components/Layout/components/TableContainer';
import {
  FetchClubsRequestAction,
  FetchSalesRepOrgsRequestAction,
  ApprovePromotionRequestAction,
  RejectPromotionRequestAction,
} from '../../../../models/actionTypes';
import { Club, ClubLightweight } from '../../../../models/Club';
import columns from './columns';
import TeamRequestApprovalModalRow from '../TeamRequestApprovalModalRow';
import { Column } from '../../../../components/Table/Column';
import SortArrows from '../../../../components/Table/components/SortArrows';
import { HeaderTypes } from '../../../../components/Header';
import { FilterSalesOrganizationsRequest, SalesOrganizationsByHash } from '../../../../models/Organizations';
import { OrganizationType } from '../../../../models/OrganizationType';
import { AcceptPromotionRequest, ClubAdminContactDetails } from '../../../../models/TeamRequests';
import FormFields from '../../../../components/FormFields';
import { clubAdminSchema, validationSchema } from '../../../Clubs/components/CreateClub/schema';
import useForm from '../../../../hooks/useForm';

export interface TeamRequestApprovalModalProps {
  fetchClubs: () => FetchClubsRequestAction;
  team: TeamBase | null;
  setSelectedRequestId: (id: number | null) => void;
  club: ClubLightweight | null;
  clubs: Club[];
  loading: boolean;
  loadingActions: boolean;
  organizationsByHash: SalesOrganizationsByHash;
  organizationsById: number[];
  selectedRequestId: number;
  count: number;
  approveRequest: (
    teamId: number, promotionRequestId: number, approveRequest: AcceptPromotionRequest,
  ) => ApprovePromotionRequestAction;
  rejectRequest: (teamId: number, promotionRequestId: number) => RejectPromotionRequestAction;
  fetchSalesRepOrgs: (request: FilterSalesOrganizationsRequest) => FetchSalesRepOrgsRequestAction;
  clubAdminContactDetails: ClubAdminContactDetails | null;
}

const TeamRequestApprovalModal: FC<TeamRequestApprovalModalProps> = ({
  fetchClubs,
  loading,
  loadingActions,
  team,
  count,
  clubs,
  club,
  setSelectedRequestId,
  fetchSalesRepOrgs,
  selectedRequestId,
  approveRequest,
  rejectRequest,
  organizationsByHash,
  organizationsById,
  clubAdminContactDetails,
}) => {
  const [translate] = useTranslation();
  const [createClub, setCreateClub] = useState(false);
  const [clubName, setClubName] = useState('');
  const [selectedClubId, setSelectedClubId] = useState<number | null>(null);
  const [selectedSalesRepOrg, setSelectedSalesRepOrg] = useState<number | null>(null);
  const {
    values,
    setValues,
    errors,
    handleChange,
  } = useForm(validationSchema);

  useEffect(() => {
    if (clubAdminContactDetails) {
      setValues({
        email: clubAdminContactDetails.email,
        firstName: clubAdminContactDetails.firstName,
        lastName: clubAdminContactDetails.lastName,
      });
    }
  }, [clubAdminContactDetails, setValues]);

  const onClose = () => {
    setClubName('');
    setSelectedRequestId(null);
    history.push(history.location.pathname);
  };

  const organizationOptions = useMemo(
    () => organizationsById.map(id => organizationsByHash[id]).map(organization => ({
      value: organization.id,
      text: organization.name,
    })), [organizationsById, organizationsByHash],
  );

  useEffect(() => {
    fetchSalesRepOrgs({ type: OrganizationType.SALES });
  }, [fetchSalesRepOrgs]);

  useEffect(() => {
    if (club) {
      setClubName(club.name);
    }
  }, [club]);

  const handleRejectClick = () => {
    rejectRequest(team!.id, selectedRequestId);
  };

  const handleApproveClick = () => {
    const {
      firstName, lastName, email,
    } = values;
    let caContactDetails = null;
    if ((clubAdminContactDetails && firstName !== clubAdminContactDetails.firstName)
        || (clubAdminContactDetails && lastName !== clubAdminContactDetails.lastName)
        || (clubAdminContactDetails && email !== clubAdminContactDetails.email)
        || (!clubAdminContactDetails && firstName && lastName && email)
    ) {
      caContactDetails = {
        firstName,
        lastName,
        email,
      };
    }
    approveRequest(team!.id, selectedRequestId, {
      officialClubId: selectedClubId,
      salesRepOrganizationId: selectedSalesRepOrg,
      clubAdminContactDetails: caContactDetails,
    });
  };

  const [firstColumn, ...restColumns] = columns;
  const enrichedColumns: Column[] = [
    {
      ...firstColumn,
      headerComponent: sortOrder => (
        <div className={styles.headerComponent}>
          <div />
          <span>{translate('CLUB_NAME')}</span>
          <SortArrows sortOrder={sortOrder} />
        </div>
      ),
    },
    ...restColumns,
  ];

  const handleListClubRadioClick = (id: number) => {
    setSelectedClubId(id);
    setSelectedSalesRepOrg(null);
    setCreateClub(false);
  };

  const handleCreateClubRadioClick = () => {
    setSelectedClubId(null);
    setCreateClub(true);
  };

  const renderRows = (c: Club) => (
    <TeamRequestApprovalModalRow
      club={c}
      id={c.id}
      key={c.id}
      selectedClubId={selectedClubId}
      setSelectedClubId={handleListClubRadioClick}
    />
  );

  const handleSalesRepSelection = (
    event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps,
  ) => {
    setSelectedSalesRepOrg(+data.value!);
  };

  return (
    <Modal
      closeIcon
      onClose={onClose}
      open
    >
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <span className={styles.teamName}>{(team && team.name) || ''}</span>
          <Header className={styles.title} as={HeaderTypes.H2} content={translate('SELECT_CLUB_APPROVE_TEAM_REQUEST')} />
        </div>
        <div className={styles.listWrapper}>
          <TableContainer
            fetch={fetchClubs}
            loading={loading}
            count={count}
            history={history}
            columns={enrichedColumns}
            renderItems={clubs.map(renderRows)}
            invertedHeader
            searchPlaceholder="SEARCH_FOR_CLUBS"
            emptyTableText="NO_CLUBS_AVAILABLE"
            notFoundTableText="NO_CLUBS_FOUND"
          />
        </div>
        <div className={styles.footerWrapper}>
          <div className={styles.createClubWrapper}>
            <Radio
              label={translate('CREATE_NEW_CLUB')}
              name="radio"
              className={styles.createClubRadio}
              checked={createClub}
              onChange={handleCreateClubRadioClick}
            />
            <Input
              disabled
              className={styles.createClubInput}
              placeholder={translate('CLUB_NAME')}
              value={clubName}
            />
          </div>
          {createClub && (
            <div className={styles.newClubWrapper}>
              <div className={styles.assignSalesRepOrgWrapper}>
                <label className={styles.assignOrgLabel}>{translate('ASSIGN_NEW_CLUB_TO_SALES_REP_ORG')}</label>
                <Dropdown
                  name="sales-rep-org"
                  onChange={handleSalesRepSelection}
                  options={organizationOptions || []}
                  selection
                  fluid
                  placeholder={translate('CHOOSE_SALES_REP')}
                  value={selectedSalesRepOrg || ''}
                />
              </div>
              <div className={styles.clubAdminInfoWrapper}>
                <label className={styles.assignOrgLabel}>{translate('MISSING_CLUB_ADMIN')}</label>
                <Form.Group widths="equal" inline className={styles.clubAdminInfo}>
                  <FormFields
                    schema={clubAdminSchema}
                    errors={errors}
                    values={values}
                    handleChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>
          )}
          <div className={styles.actionsButtons}>
            <Button
              basic
              circular
              content={translate('REJECT')}
              onClick={handleRejectClick}
              primary
              fluid
            />
            <Button
              circular
              content={translate('APPROVE')}
              onClick={handleApproveClick}
              disabled={(!selectedClubId
                  && !(createClub && selectedSalesRepOrg
                      && (
                        (clubAdminContactDetails && clubAdminContactDetails.email) || values.email)
                  ))
                  || loadingActions}
              primary
              loading={loadingActions}
              fluid
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TeamRequestApprovalModal;
