import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { EmbellishmentValue } from '../../models/Embellishment';
import {
  UPDATE_EMBELLISHMENT_VALUE_ERROR,
  UPDATE_EMBELLISHMENT_VALUE_REQUEST, UPDATE_EMBELLISHMENT_VALUE_SUCCESS,
} from '../../constants/actionTypes';
import { NotificationType, UpdateEmbellishmentValueRequestAction } from '../../models/actionTypes';
import { State } from '../../models/State';
import { handleError } from '../../actions/errors/handleError';
import { EMBELLISHMENTS_VALUES_PATH } from '../../constants/paths';
import { Response } from '../../models/Response';
import {
  updateEmbellishmentValueError,
  updateEmbellishmentValueSuccess,
} from '../../actions/catalogs/updateEmbellishmentValue';
import { fetchEmbellishmentValuesRequest } from '../../actions/catalogs/fetchEmbellishmentValues';
import { addNotification } from '../../actions/notifications/addNotification';

export default (
  action$: ActionsObservable<UpdateEmbellishmentValueRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(UPDATE_EMBELLISHMENT_VALUE_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: { clubId, embellishmentValue },
  }, { authentication: { authToken } }]) => ajax({
    url: `${EMBELLISHMENTS_VALUES_PATH.replace('$clubId', `${clubId}`)}/${embellishmentValue.id}`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(embellishmentValue),
  }).pipe(
    mergeMap(({ response: embellishmentVal }: Response<EmbellishmentValue>) => [
      addNotification(NotificationType.SUCCESS, UPDATE_EMBELLISHMENT_VALUE_SUCCESS),
      updateEmbellishmentValueSuccess(embellishmentVal),
      fetchEmbellishmentValuesRequest(clubId),
    ]),
    catchError((error: AjaxError) => ([
      handleError(error, UPDATE_EMBELLISHMENT_VALUE_ERROR),
      updateEmbellishmentValueError(error),
    ])),
  )),
);
