import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Loader, Dimmer } from 'semantic-ui-react';

import SortArrows from './components/SortArrows';
import styles from './Table.module.scss';
import Text from '../Text';
import { TableProps } from './TableProps';
import { Column } from './Column';
import { TableFilter } from './components/TableFilter';

const renderEmptyElements = (text: string) => (
  <div className={styles.empty}>
    {text}
  </div>
);

const { Provider, Consumer } = React.createContext({});

const Table: FC<TableProps> = ({
  columns,
  children,
  emptyTableText,
  loading,
  loadingText,
  onSortChange,
  onFilterChange,
  filters,
  sortOrder,
  sortField,
  invertedHeader,
}) => {
  const [translate] = useTranslation();
  const shouldRenderItems = children && (children as JSX.Element[]).length !== 0;
  const shouldRenderHeaderRow = columns;
  const onSortClick = ({ sortable, key }: { sortable?: boolean, key?: string }) => () => (
    sortable && key && onSortChange && onSortChange(key)
  );

  return (
    <div className={styles.wrapper}>
      {loading && <Dimmer active inverted><Loader /></Dimmer>}

      {shouldRenderHeaderRow && (
        <div className={[styles.row, invertedHeader && styles.header].join(' ')}>
          {columns.map((column: Column, index: number) => (
            <div
              onClick={onSortClick(column)}
              key={column.key || column.header}
              className={[
                styles['column-name'],
                styles[`align-content-${column.align}`],
                styles[`flex-${column.size}`],
                columns.length === index + 1 ? styles['last-column'] : null,
              ].join(' ')}
              style={{
                ...(column.width ? ({ width: `${column.width}` }) : ''),
              }}
            >
              <div className={classNames(styles['table-header'], column.sortable && styles.pointer)}>
                {column.headerComponent
                  ? column.headerComponent(sortField === column.key ? sortOrder : null)
                  : ([
                    <Text fluid={false} bold translate key={column.key}>{column.header}</Text>,
                    column.sortable && (
                      <SortArrows
                        sortOrder={sortField === column.key ? sortOrder : null}
                        key={`sort${column.key}`}
                      />
                    ),
                    column.filter && (
                      <TableFilter
                        key={`filter${column.key}`}
                        columnName={column.key as string}
                        component={column.filter}
                        onFilterChange={onFilterChange}
                        filters={filters}
                      />
                    ),
                  ].filter(Boolean))
                }
              </div>
            </div>
          ))}
        </div>
      )}
      {shouldRenderItems
        ? <Provider value={columns}>{children}</Provider>
        : renderEmptyElements(translate((loading ? loadingText : emptyTableText) as string))}
    </div>
  );
};

Table.defaultProps = {
  emptyTableText: 'NO_ELEMENTS_AVAILABLE',
  columns: [],
  loading: false,
  loadingText: 'LOADING',
  invertedHeader: false,
};

export default Table;

export { Consumer };
