import { OrderList, OrdersByHash, OrdersListReducer } from '../models/Order';
import {
  FETCH_CLUB_ORDERS_ERROR,
  FETCH_CLUB_ORDERS_REQUEST, FETCH_CLUB_ORDERS_SUCCESS,
} from '../constants/actionTypes';
import { ClubOrdersActions } from '../models/actionTypes/ClubOrdersActions';

export const initialState: OrdersListReducer = {
  byHash: {},
  byId: [],
  count: 0,
  loading: false,
  error: false,
  ordersDeleted: false,
};

export default (state = initialState, action: ClubOrdersActions) => {
  switch (action.type) {
    case FETCH_CLUB_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CLUB_ORDERS_SUCCESS: {
      const { orders, count } = action.payload;
      return {
        ...state,
        byHash: orders
          .reduce((byHash: OrdersByHash, order: OrderList) => ({
            ...byHash,
            [order.id]: order,
          }), state.byHash),
        byId: orders.map(({ id }: OrderList) => id),
        loading: false,
        count,
      };
    }
    case FETCH_CLUB_ORDERS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
