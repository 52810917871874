import React, { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import {
  Button, Dimmer, Form, Input, Loader, Message, Modal,
} from 'semantic-ui-react';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import CatalogHeader from './components/CatalogHeader';
import Header from '../../components/Header';
import ProductCard from './components/ProductCard';
import ProductLoader from './components/ProductLoader';
import styles from './CatalogCreator.module.scss';
import { CLUBS_CATALOGS_ROUTE, CLUBS_ROUTE, PARAM_CATALOGS } from '../../constants/routes';
import { CatalogCreatorProps } from './CatalogCreatorProps';
import { Roles } from '../../constants/roles';
import { Product, ProductCatalogStatus } from '../../models/Product';
import { Catalog } from '../../models/Catalog';

const CatalogCreator = ({
  addProduct,
  catalog,
  club,
  clubId,
  embellishmentsById,
  fetchCatalog,
  fetchHobbyCatalog,
  isHobbyCatalog,
  fetchClub,
  fetchEmbellishments,
  loadingEmbellishments,
  loading,
  loadingProduct,
  location,
  memberships,
  currentlyUsedOrganizationId,
  showReleaseCatalogModal,
  createCatalog,
  createHobbyCatalog,
  hideReleaseCatalogModal,
}: CatalogCreatorProps) => {
  const [translate] = useTranslation();
  const [search, setSearch] = useState('');
  const [validProductId, setValidProductId] = useState(true);
  const [userRoles, setUserRoles] = useState<Roles[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [visibleProducts, setVisibleProducts] = useState<Product[]>([]);
  const [productStatus, setProductStatus] = useState<'ALL' | ProductCatalogStatus>(ProductCatalogStatus.ACTIVE);
  const [productDiscountPercent, setProductDiscountPercent] = useState<number>(0);
  const [search$] = useState(() => new Subject<string>());
  const { from } = queryString.parse(location.search);
  useEffect(() => {
    setVisibleProducts(products.filter(product => {
      if (productStatus === 'ALL') {
        return true;
      }
      if (productStatus === ProductCatalogStatus.ACTIVE) {
        return product.productCatalogStatus === ProductCatalogStatus.ACTIVE
            || !product.productCatalogStatus;
      }
      return product.productCatalogStatus === 'INACTIVE';
    }));
  }, [products, productStatus]);

  const backLinkTo = from === PARAM_CATALOGS ? CLUBS_CATALOGS_ROUTE : CLUBS_ROUTE;

  useEffect(() => {
    if (catalog) {
      setProductDiscountPercent(catalog.productDiscountPercent || 0);
      setProducts(catalog.products || []);
    }
  }, [catalog]);

  const onSearchKeyPress = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 && search.length === 8) {
      addProduct(isHobbyCatalog ? null : clubId!, search);
      setSearch('');
    }
  }, [addProduct, clubId, search, isHobbyCatalog]);

  const handleProductStatusChange = (_: any, event: any) => {
    setProductStatus(event.value);
  };

  useEffect(() => {
    const searchListener = search$
      .pipe(
        tap(() => { setValidProductId(true); }),
        debounceTime(500),
        distinctUntilChanged(),
      ).subscribe((value: string) => {
        if (![0, 8].includes(value.length)) {
          setValidProductId(false);
        }
      });

    return () => {
      searchListener.unsubscribe();
    };
  }, [search$]);

  const onSearchChange = useCallback((
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearch(value);
    search$.next(value);
  }, [search$]);

  useEffect(() => {
    if (currentlyUsedOrganizationId) {
      setUserRoles(memberships[currentlyUsedOrganizationId] || []);
    }
  }, [memberships, currentlyUsedOrganizationId]);

  useEffect(() => {
    if (!catalog || catalog.isIncomplete) {
      if (isHobbyCatalog) {
        fetchHobbyCatalog();
      } else {
        fetchCatalog(clubId!);
      }
    }
  }, [fetchHobbyCatalog, fetchCatalog, isHobbyCatalog, catalog, clubId]);

  useEffect(() => {
    if (!isHobbyCatalog
      && userRoles.includes(Roles.SALES_REPRESENTATIVE)
      && (!club || club.isIncomplete)) {
      fetchClub(clubId!);
    }
  }, [fetchClub, club, clubId, userRoles, isHobbyCatalog]);

  useEffect(() => {
    if (!loadingEmbellishments && (!embellishmentsById || !embellishmentsById.length)) {
      fetchEmbellishments();
    }
  }, [fetchEmbellishments, embellishmentsById, loadingEmbellishments]);

  return catalog ? (
    <div className={styles.wrapper}>
      <Modal
        open={showReleaseCatalogModal}
        onClose={() => hideReleaseCatalogModal()}
        closeIcon
        closeOnDimmerClick={false}
      >
        <Modal.Header content={translate('RELEASE_CATALOG_MODAL_HEADER')} />
        <Modal.Content content={translate('RELEASE_CATALOG_MODAL_BODY')} />
        <Modal.Actions>
          <Button
            basic
            circular
            content={translate('CANCEL')}
            onClick={() => hideReleaseCatalogModal()}
            primary
          />
          <Button
            circular
            content={translate('RELEASE_TO_CLUB')}
            onClick={() => (isHobbyCatalog
              ? createHobbyCatalog(catalog as Catalog)
              : createCatalog(clubId as number, catalog as Catalog))}
            primary
          />
        </Modal.Actions>
      </Modal>
      <CatalogHeader isHobbyCatalog={isHobbyCatalog} clubId={clubId} catalog={catalog} />

      {loading && (
        <Dimmer active inverted><Loader /></Dimmer>
      )}

      <Form className={styles.formHorizontal}>
        <Form.Field className={styles.searchField}>
          <label>{translate('ARTICLE_NUMBER')}</label>
          <Input
            className={`search ${styles.search} ${!validProductId ? styles.disabledSearchSubmit : null}`}
            icon="search"
            onChange={onSearchChange}
            onKeyDown={onSearchKeyPress}
            placeholder={translate('ID_EXAMPLE')}
            size="big"
            type="number"
            value={search}
          />
          { !validProductId && (
            <Message icon negative>
              <Message.Content>
                <Message.Header content={translate('ERROR_INVALID_PRODUCT_ID_HEADER')} />
                <p>{translate('ERROR_INVALID_PRODUCT_ID_TEXT')}</p>
              </Message.Content>
            </Message>
          )}
        </Form.Field>
        <Form.Field>
          <label>{translate('PRODUCT_STATUS')}</label>
          <Form.Dropdown
            clearable={false}
            name="product-status"
            onChange={handleProductStatusChange}
            options={[
              { value: ProductCatalogStatus.ACTIVE, text: translate('PRODUCT.ACTIVE') },
              { value: ProductCatalogStatus.INACTIVE, text: translate('PRODUCT.INACTIVE') },
              { value: 'ALL', text: translate('PRODUCT.ALL') },
            ]}
            selection
            value={productStatus}
          />
        </Form.Field>
      </Form>

      {!!loadingProduct && (
        <ProductLoader productId={loadingProduct} />
      )}
      <div className={styles.products}>
        {visibleProducts.map(product => (
          <ProductCard
            key={product.id}
            product={product}
            isHobbyCatalog={isHobbyCatalog}
            clubDiscountPercent={productDiscountPercent}
            clubId={clubId}
          />
        ))}
      </div>
    </div>
  ) : (
    <div className={styles.wrapper}>
      {loading
        ? <Dimmer active inverted><Loader /></Dimmer>
        : <Header title={translate('CATALOG.NOT_FOUND')} backLinkTo={backLinkTo} />
      }
    </div>
  );
};

export default CatalogCreator;
