import { AjaxError } from 'rxjs/ajax';
import {
  DELETE_TEAM_ERROR,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
} from '../../constants/actionTypes';
import {
  DeleteTeamErrorAction,
  DeleteTeamRequestAction,
  DeleteTeamSuccessAction,
} from '../../models/actionTypes';

export const deleteTeamRequest = (id: number): DeleteTeamRequestAction => ({
  type: DELETE_TEAM_REQUEST,
  payload: {
    id,
  },
});

export const deleteTeamSuccess = (id: number): DeleteTeamSuccessAction => ({
  type: DELETE_TEAM_SUCCESS,
  payload: {
    id,
  },
});

export const deleteTeamError = (error: AjaxError): DeleteTeamErrorAction => ({
  type: DELETE_TEAM_ERROR,
  payload: {
    error,
  },
});
