import { EmbellishmentsByHash } from '../../../../models/Embellishment';
import { Price } from '../../../../models/Price';
import { ProductEmbellishment } from '../../../../models/Product';

export enum InputTypes {
  PRICE = 'PRICE',
  DISCOUNT = 'DISCOUNT',
}

export type FocusInputType = InputTypes.PRICE | InputTypes.DISCOUNT | null;

export interface ProductDetailsPriceProps {
  clubId?: number;
  discountPercent: number | null;
  embellishments: ProductEmbellishment[];
  embellishmentsByHash: EmbellishmentsByHash;
  onDiscountPercentChange: (value: number | null) => void;
  originalPrice: Price;
  isHobbyCatalog: boolean;
}
