import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import get from 'lodash/get';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Checkbox, Modal, Table,
} from 'semantic-ui-react';
import { Embellishment, EmbellishmentTypes } from '../../../../models/Embellishment';
import { ProductEmbellishment } from '../../../../models/Product';
import { Direction, Sort } from '../../../../models/Sort';
import EmbellishmentModalRow from './components/EmbellishmentModalRow';
import styles from './EmbellishmentsModal.module.scss';
import { EmbellishmentsModalProps } from './EmbellishmentsModalProps';

const EmbellishmentsModal: FC<EmbellishmentsModalProps> = ({
  embellishmentsByHash,
  previousEmbellishmentSelection,
  saveSelectionDraft,
  embellishmentsById,
  setEmbellishments,
  embellishments,
  isHobbyCatalog,
  clubId,
}) => {
  const [translate] = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [enabledGlobal, setEnabledGlobal] = useState<boolean>(false);
  const [myMap, setMyMap] = useState(new Map<EmbellishmentTypes, ProductEmbellishment>());
  const [enabled, setEnabled] = useState<EmbellishmentTypes[]>([]);
  const [sort, setSort] = useState<Sort>({
    column: 'name',
    direction: Direction.ASCENDING,
  });
  const [sortedById, setSortedById] = useState<EmbellishmentTypes[]>(embellishmentsById);
  const setDefaultValues = useCallback(() => {
    setMyMap(new Map(embellishments.map(em => [em.embellishment, em])));
    setEnabled(embellishments.map(({ embellishment: type }) => type));
  }, [embellishments]);

  useEffect(() => setDefaultValues(), [setDefaultValues, embellishments]);

  const onToggleGlobalEnable = useCallback(() => {
    setEnabled(enabledGlobal ? [] : embellishmentsById);
    setEnabledGlobal(!enabledGlobal);
  }, [enabledGlobal, embellishmentsById]);

  const onClose = useCallback(() => {
    setDefaultValues();
    setModalOpen(false);
  }, [setDefaultValues]);

  const onSubmit = useCallback(() => {
    saveSelectionDraft(enabled.map(e => myMap.get(e)!).filter(Boolean)!);
    setEmbellishments(enabled.map(e => myMap.get(e)!).filter(Boolean)!);
    setModalOpen(false);
  }, [setEmbellishments, enabled, myMap, saveSelectionDraft]);

  const updateProductEmbellishment = (productEmbellishment: ProductEmbellishment) => {
    myMap.set(productEmbellishment.embellishment, productEmbellishment);
  };

  const handleSort = useCallback((column: string) => () => {
    if (column !== sort.column) {
      setSort({ column, direction: Direction.ASCENDING });
    } else {
      setSort({
        column: sort.column,
        direction: sort.direction === Direction.ASCENDING
          ? Direction.DESCENDING
          : Direction.ASCENDING,
      });
    }
  }, [sort]);

  useEffect(() => {
    const sorted = flow(
      map((type: EmbellishmentTypes) => ({
        ...embellishmentsByHash[type],
        name: translate(type),
      })),
      sortBy(embellishment => get(embellishment, `${sort.column}`)),
      map(({ type }) => type),
      byId => (sort.direction === Direction.DESCENDING ? byId.reverse() : byId),
    )(embellishmentsById);

    setSortedById(sorted);
  }, [embellishmentsByHash, embellishmentsById, sort, translate]);

  const renderHeader = () => (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell collapsing>
          <Checkbox
            checked={enabledGlobal}
            onClick={() => onToggleGlobalEnable()}
          />
        </Table.HeaderCell>
        <Table.HeaderCell
          content={translate('QUANTITY')}
        />
        <Table.HeaderCell
          content={translate('EMBELLISHMENT')}
          onClick={handleSort('name')}
          sorted={sort.column === 'name' ? sort.direction : undefined}
          className="sorted"
        />
        <Table.HeaderCell
          content={translate('CATALOG_PRICE')}
          onClick={handleSort('price.amount')}
          sorted={sort.column === 'price.amount' ? sort.direction : undefined}
          className="sorted"
        />
        <Table.HeaderCell content={translate('CLUB_PRICE')} />
        <Table.HeaderCell content={translate('FIXED_PRICE')} />
        <Table.HeaderCell content={translate('MANDATORY')} collapsing />
      </Table.Row>
    </Table.Header>
  );

  const applyLastSelection = () => {
    setMyMap(new Map(previousEmbellishmentSelection.map(em => [em.embellishment, em])));
    setEnabled(previousEmbellishmentSelection.map(({ embellishment: type }) => type));
  };

  const renderRow = (embellishment: Embellishment) => (
    <EmbellishmentModalRow
      key={embellishment.type.toString()}
      clubId={clubId}
      isHobbyCatalog={isHobbyCatalog}
      enabled={enabled}
      embellishment={embellishment}
      productEmbellishment={myMap.get(embellishment.type)}
      embellishmentsByHash={embellishmentsByHash}
      embellishmentsById={embellishmentsById}
      updateProductEmbellishment={updateProductEmbellishment}
      setEnabled={setEnabled}
      setEnabledGlobal={setEnabledGlobal}
    />
  );

  return (
    <Modal
      closeIcon
      onClose={onClose}
      open={modalOpen}
      size="large"
      trigger={(
        <Button
          basic
          className="simple"
          content={translate('MANAGE_EMBELLISHMENTS')}
          onClick={() => setModalOpen(true)}
        />
      )}
    >
      <Modal.Header>
        <div className={styles.modalHeader}>
          <h3>{translate('MANAGE_EMBELLISHMENTS')}</h3>
          <Button
            basic
            disabled={!previousEmbellishmentSelection || !previousEmbellishmentSelection.length}
            className="simple"
            content={translate('APPLY_LAST_SELECTION')}
            onClick={applyLastSelection}
          />
        </div>
      </Modal.Header>
      <Modal.Content scrolling>
        <Table striped sortable padded="very">
          {renderHeader()}
          {sortedById.map(type => renderRow(embellishmentsByHash[type]))}
        </Table>
      </Modal.Content>
      <Modal.Actions className={styles.actionsContainer}>
        <Button
          basic
          circular
          content={translate('CANCEL')}
          onClick={onClose}
          primary
        />
        <Button
          circular
          content={translate('SAVE')}
          onClick={onSubmit}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
};

export default EmbellishmentsModal;
