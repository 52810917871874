import { v4 as uuidv4 } from 'uuid';

import { ADD_NOTIFICATION } from '../../constants/actionTypes';
import { AddNotificationAction, NotificationType } from '../../models/actionTypes';

export const addNotification = (
  type: NotificationType,
  actionType: string,
  status?: number,
  message?: string,
  id: string = uuidv4(),
): AddNotificationAction => ({
  type: ADD_NOTIFICATION,
  payload: {
    type,
    actionType,
    status,
    message,
    id,
  },
});
