import React, { FC, useRef, useState } from 'react';
import classNames from 'classnames';

import colors from '../../../../constants/colors';
import styles from './ColorSelector.module.scss';
import useOutsideAlerter from '../../../../hooks/useOutsideAlerter';

interface ColorSelectorProps {
  value?: string;
  onChange: (value: string) => void;
}

const ColorSelector: FC<ColorSelectorProps> = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const wrapperRef = useRef(null);

  const open = () => {
    setIsOpen(true);
  };

  const outsideClick = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useOutsideAlerter(wrapperRef, outsideClick);

  const setColor = (newName: string) => () => {
    onChange(newName);
    setIsOpen(false);
  };

  const selectedColor = value ? colors.find(color => color.name === value) : null;

  return (
    <div ref={wrapperRef} className={styles.wrapper}>
      <button className={classNames(styles.dropdown, isOpen && styles.opened)} type="button" onClick={open}>
        {selectedColor && [
          <div className={styles.circle} style={{ background: selectedColor.hex, borderColor: selectedColor.hex === '#FFFFFF' ? '#979797' : 'none' }} />,
          <span className={styles.colorText}>{selectedColor.name}</span>,
        ]}
      </button>
      {isOpen && (
        <div className={styles.options}>
          {colors.map(color => (
            <button type="button" className={styles.item} onClick={setColor(color.name)}>
              <div className={styles.circle} style={{ background: color.hex, borderColor: color.hex === '#FFFFFF' ? '#979797' : 'none' }} />
              <span className={styles.colorText}>{color.name}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ColorSelector;
