import { AjaxError } from 'rxjs/ajax';
import {
  AssignMemberToTeamErrorAction, AssignMemberToTeamPayload,
  AssignMemberToTeamRequestAction,
  AssignMemberToTeamSuccessAction,
} from '../../models/actionTypes';
import {
  ADD_ROLE_TO_MEMBER_ERROR,
  ADD_ROLE_TO_MEMBER_REQUEST, ADD_ROLE_TO_MEMBER_SUCCESS,
} from '../../constants/actionTypes';

export const assignMemberToTeamRequest = (
  assignment: AssignMemberToTeamPayload,
): AssignMemberToTeamRequestAction => ({
  type: ADD_ROLE_TO_MEMBER_REQUEST,
  payload: {
    ...assignment,
  },
});

export const assignMemberToTeamSuccess = (): AssignMemberToTeamSuccessAction => ({
  type: ADD_ROLE_TO_MEMBER_SUCCESS,
});

export const assignMemberToTeamError = (error: AjaxError): AssignMemberToTeamErrorAction => ({
  type: ADD_ROLE_TO_MEMBER_ERROR,
  payload: {
    error,
  },
});
