import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import styles from './ClubRewardsBallers.module.scss';
import { Club } from '../../../models/Club';
import RewardsPointsEdition from '../TeamRewardsBallersCreation';
import { EntityTypesEnum } from '../../../models/Entity';

export interface TeamRewardsBallersProps {
  club: Club;
}

const ClubRewardsBallers: FC<TeamRewardsBallersProps> = ({
  club,
}) => {
  const [translate] = useTranslation();
  const [createMode, setCreateMode] = useState<boolean>(false);

  const toggleCreateMode = () => {
    setCreateMode(isCreateMode => !isCreateMode);
  };

  return (
    <div className={styles.clubOverviewWrapper}>
      <h3 className={styles.clubOverviewHeader}>
        {translate('CLUB_OVERVIEW')}
      </h3>
      <span>
        {`${(club && club.clubScore && club.clubScore.points) || 0}`}
      </span>
      <strong>{translate('REWARDS.BALLERS.BALLERS')}</strong>
      <span className={styles.buttonContainer}>
        <Button circular icon className="simple" basic onClick={toggleCreateMode}>
          <Icon name="plus" circular />
        </Button>
      </span>
      {club && club.clubScore && club.clubScore.level && [
        <span key="levelScore">{translate(`REWARDS.LEVELS.${club.clubScore.level}`)}</span>,
        <strong key="levelTranslation">{translate('LEVEL')}</strong>,
      ]}
      <div hidden={!createMode}>
        <RewardsPointsEdition clubId={club.id} entityId={club.id} entityType={EntityTypesEnum.ORGANIZATION} action="ADD" />
      </div>
    </div>
  );
};

export default ClubRewardsBallers;
