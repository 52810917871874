import get from 'lodash/get';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import ProductDetails from './ProductDetails';
import { Product } from '../../models/Product';
import { ProductDetailsProps } from './ProductDetailsProps';
import { State } from '../../models/State';
import { fetchCatalogRequest } from '../../actions/catalogs/fetchCatalog';
import { fetchClubRequest } from '../../actions/clubs/fetchClub';
import { fetchEmbellishmentsRequest } from '../../actions/catalogs/fetchEmbellishments';
import { removeProduct } from '../../actions/catalogs/removeProduct';
import { setDraftFiles } from '../../actions/files/draftFiles';
import { updateProduct } from '../../actions/catalogs/updateProduct';
import { fetchTagsRequest } from '../../actions/catalogs/fetchTags';
import { HOBBY_CLUBS_ROUTE } from '../../constants/routes';
import { updateHobbyProduct } from '../../actions/catalogs/updateHobbyProduct';
import { removeHobbyProduct } from '../../actions/catalogs/removeHobbyProduct';
import { clearCatalogData } from '../../actions/catalogs/clearCatalogData';
import { persistSelection } from '../../actions/catalogs/persistSelection';
import { fetchClubTeamsRequest } from '../../actions/clubTeams/fetchClubTeams';

const mapStateToProps = (
  {
    catalogs: {
      byHash: catalogsByHash,
      loading,
      tagsByHash,
      hobbyCatalog,
      tagsById,
      loadingTags,
    },
    embellishments: {
      embellishmentsById,
    },
    application: {
      memberships,
      clubId: currentlyUsedOrganizationId,
    },
    clubs: { byHash: clubsByHash },
    clubTeams: {
      byHash: teamsByHash,
      byId: teamsById,
    },
    files: { draftFiles },
  }: State,
  { match: { params: { clubId, id } }, location: { pathname } }: ProductDetailsProps,
) => {
  const isHobbyCatalog = (pathname as string).startsWith(HOBBY_CLUBS_ROUTE);
  const catalog = isHobbyCatalog ? hobbyCatalog : catalogsByHash[clubId];
  const club = clubsByHash[clubId];

  return {
    catalog,
    club,
    isHobbyCatalog,
    product: get(catalog, 'products', []).find((product: Product) => product.id === id),
    clubId: Number(clubId),
    allTags: tagsById.map(tagId => tagsByHash[tagId]),
    allClubTeams: teamsById.map(teamId => teamsByHash[teamId]),
    loadingTags,
    draftFiles,
    loading,
    memberships,
    currentlyUsedOrganizationId,
    embellishmentsById: embellishmentsById || [],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCatalog: bindActionCreators(fetchCatalogRequest, dispatch),
  fetchClub: bindActionCreators(fetchClubRequest, dispatch),
  fetchClubTeams: bindActionCreators(fetchClubTeamsRequest, dispatch),
  fetchEmbellishments: bindActionCreators(fetchEmbellishmentsRequest, dispatch),
  removeProduct: bindActionCreators(removeProduct, dispatch),
  removeHobbyProduct: bindActionCreators(removeHobbyProduct, dispatch),
  setDraftFiles: bindActionCreators(setDraftFiles, dispatch),
  updateProduct: bindActionCreators(updateProduct, dispatch),
  updateHobbyProduct: bindActionCreators(updateHobbyProduct, dispatch),
  fetchTags: bindActionCreators(fetchTagsRequest, dispatch),
  clearCatalogData: bindActionCreators(clearCatalogData, dispatch),
  persistSelection: bindActionCreators(persistSelection, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
