import uniqueId from 'lodash/uniqueId';
import { CurrencySign } from '../../constants/currencySign.enum';

export const defaultInputSymbolProps = {
  decimalScale: 1,
  focused: true,
  max: 100,
  min: 0,
  step: 0.1,
  symbol: '%',
};

export const maxLimitPercentageValidator = (maxLimit: number, message: string) => ({
  content: message,
  isValid: (value: any) => !value || value <= maxLimit,
});

export const defaultAddButtonProps = {
  basic: true,
  circular: true,
  icon: 'plus',
  primary: true,
};

export const emptyTeamDiscount = () => ({
  key: uniqueId('_key'),
  discountPercent: 0,
});

export const emptyGroupFixedPrice = () => ({
  key: uniqueId('_key'),
  price: {
    amount: 0,
    currency: 'EUR' as keyof typeof CurrencySign,
  },
});
