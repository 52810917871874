import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import EmbellishmentsModal from './EmbellishmentsModal';
import { State } from '../../../../models/State';
import { saveSelectionDraft } from '../../../../actions/catalogs/saveSelectionDraft';

const mapStateToProps = ({
  catalogs: {
    previousEmbellishmentSelection = [],
  },
  embellishments: {
    embellishmentsById,
    embellishmentsByHash,
  },
}: State) => ({
  embellishmentsByHash,
  embellishmentsById,
  previousEmbellishmentSelection,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveSelectionDraft: bindActionCreators(saveSelectionDraft, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmbellishmentsModal);
