import React, {
  FC, useState, useMemo, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import styles from './TeamRequests.module.scss';
import HeaderComponent from '../../components/Header/Header';
import TableContainer from '../../components/Layout/components/TableContainer';
import { TeamRequest } from '../../models/TeamRequests';
import columns from './columns';
import { history } from '../../configureStore';
import { TeamRequestsProps } from './TeamRequestsProps';
import TeamRequestRow from './components/TeamRequestRow';
import TeamRequestApprovalModal from './components/TeamRequestApprovalModal';
import ApproveSuccessModal from './components/ApproveSuccessModal';

const TeamRequests: FC<TeamRequestsProps> = ({
  fetchRequests,
  loading,
  count,
  requests,
  newClubCreated,
  teamApproved,
  rejectRequest,
  resetApproveModal,
  teamRejected,
}) => {
  const [translate] = useTranslation();
  const [selectedRequestId, setSelectedRequestId] = useState<number | null>(null);
  const [successModalOpened, setSuccessModalOpened] = useState<boolean>(false);

  const renderRows = (request: TeamRequest) => (
    <TeamRequestRow
      key={request.id}
      request={request}
      setSelectedRequestId={setSelectedRequestId}
      rejectRequest={rejectRequest}
    />
  );

  useEffect(() => {
    if (teamApproved || newClubCreated) {
      setSelectedRequestId(null);
      setSuccessModalOpened(true);
      history.push(history.location.pathname);
    }
  }, [newClubCreated, teamApproved, setSuccessModalOpened, teamRejected]);

  useEffect(() => {
    if (teamRejected) {
      setSelectedRequestId(null);
      history.push(history.location.pathname);
    }
  }, [teamRejected, setSelectedRequestId]);

  const selectedRequest = useMemo(() => {
    if (selectedRequestId) {
      return requests.find(request => request.id === selectedRequestId);
    }
    return null;
  }, [requests, selectedRequestId]);

  return (
    <div className={styles.wrapper}>
      <HeaderComponent border title={translate('HOBBY_TEAM_REQUESTS')} />
      <div className={styles['content-wrapper']}>
        <TableContainer
          fetch={fetchRequests}
          disableChangeListeners={!!selectedRequestId}
          loading={loading}
          count={count}
          history={history}
          columns={columns}
          renderItems={requests.map(renderRows)}
          invertedHeader
          searchPlaceholder="SEARCH_FOR_HOBBY_TEAMS"
          emptyTableText="NO_HOBBY_TEAM_REQUESTS_AVAILABLE"
          notFoundTableText="NO_HOBBY_TEAM_REQUESTS_FOUND"
        />
      </div>
      {selectedRequestId && (
        <TeamRequestApprovalModal
          team={selectedRequest ? selectedRequest!.team : null}
          club={selectedRequest ? selectedRequest!.club : null}
          setSelectedRequestId={setSelectedRequestId}
          selectedRequestId={selectedRequestId}
          clubAdminContactDetails={
            selectedRequest ? selectedRequest!.clubAdminContactDetails : null
        }
        />
      )}
      <ApproveSuccessModal
        successModalOpened={successModalOpened}
        setSuccessModalOpened={setSuccessModalOpened}
        newClubCreated={newClubCreated}
        resetApproveModal={resetApproveModal}
      />
    </div>
  );
};

export default TeamRequests;
