import { AjaxError } from 'rxjs/ajax';
import {
  UpdateProductAction, SaveProductFilesRequestAction,
  SaveProductFilesSuccessAction, SaveProductFilesErrorAction,
} from '../../models/actionTypes';
import {
  UPDATE_PRODUCT, SAVE_PRODUCT_FILES_REQUEST, SAVE_PRODUCT_FILES_ERROR,
  SAVE_PRODUCT_FILES_SUCCESS,
} from '../../constants/actionTypes';
import { Product } from '../../models/Product';

export const updateProduct = (clubId: number, product: Product): UpdateProductAction => ({
  type: UPDATE_PRODUCT,
  payload: {
    clubId,
    product,
  },
});

export const saveProductFilesRequest = (
  clubId: number, product: Product,
): SaveProductFilesRequestAction => ({
  type: SAVE_PRODUCT_FILES_REQUEST,
  payload: {
    clubId,
    product,
  },
});

export const saveProductFilesSuccess = (): SaveProductFilesSuccessAction => ({
  type: SAVE_PRODUCT_FILES_SUCCESS,
});

export const saveProductFilesError = (error: AjaxError): SaveProductFilesErrorAction => ({
  type: SAVE_PRODUCT_FILES_ERROR,
  payload: {
    error,
  },
});
