import { AjaxError } from 'rxjs/ajax';

import { DELETE_CLUB_REQUEST, DELETE_CLUB_SUCCESS, DELETE_CLUB_ERROR } from '../../constants/actionTypes';
import { DeleteClubSuccessAction, DeleteClubErrorAction, DeleteClubRequestAction } from '../../models/actionTypes';

export const deleteClubRequest = (
  clubId: number,
): DeleteClubRequestAction => ({
  type: DELETE_CLUB_REQUEST,
  payload: {
    clubId,
  },
});

export const deleteClubSuccess = (clubId: number): DeleteClubSuccessAction => ({
  type: DELETE_CLUB_SUCCESS,
  payload: {
    clubId,
  },
});

export const deleteClubError = (error: AjaxError): DeleteClubErrorAction => ({
  type: DELETE_CLUB_ERROR,
  payload: {
    error,
  },
});
