import React, { ChangeEvent, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import {
  Dimmer, Divider, Form, Loader,
} from 'semantic-ui-react';

import CatalogHeader from '../CatalogCreator/components/CatalogHeader';
import GroupFixedPriceRows from './components/GroupFixedPriceRows';
import Header, { HeaderTypes } from '../../components/Header';
import InputWithSymbol from '../../components/InputWithSymbol';
import TeamDiscountRows from './components/TeamDiscountRows';
import styles from './CatalogDiscounts.module.scss';
import {
  CLUBS_CATALOGS_ROUTE, CLUBS_ROUTE, PARAM_CATALOGS,
} from '../../constants/routes';
import { CatalogDiscountsProps } from './CatalogDiscountsProps';
import { EmbellishmentFixedPriceDiscountType, GroupType, TeamDiscountsType } from '../../models/Catalog';
import { EmbellishmentTypes } from '../../models/Embellishment';
import {
  defaultInputSymbolProps,
  emptyGroupFixedPrice,
  emptyTeamDiscount, maxLimitPercentageValidator,
} from './customizations';

const CatalogDiscounts = ({
  catalog,
  club,
  clubId,
  fetchCatalog,
  isHobbyCatalog,
  fetchHobbyCatalog,
  fetchClub,
  updateCatalog,
  updateHobbyCatalog,
  loading,
  location: { search },
}: CatalogDiscountsProps) => {
  const [translate] = useTranslation();
  const { teams = [] } = club || {};
  const { from } = queryString.parse(search);
  const backLinkTo = from === PARAM_CATALOGS ? CLUBS_CATALOGS_ROUTE : CLUBS_ROUTE;
  const teamsOptions = teams.map(({ id, name }) => ({ value: id, text: name }));
  const allTeamsOption = { value: clubId!, text: translate('ALL_TEAMS') };
  const embellishemntOptions = Object.values(EmbellishmentTypes)
    .map(value => ({ value, text: translate(value) }));

  const {
    productDiscountPercent = 0,
    embellishmentsDiscountPercent = 0,
    teamProductDiscounts = [],
    teamEmbellishmentsDiscounts = [],
    embellishmentFixedPriceDiscounts = [],
  } = catalog || {};

  useEffect(() => {
    if (!catalog || catalog.isIncomplete) {
      if (isHobbyCatalog) {
        fetchHobbyCatalog();
      } else {
        fetchCatalog(clubId!);
      }
    }
  }, [fetchHobbyCatalog, fetchCatalog, isHobbyCatalog, catalog, clubId]);

  useEffect(() => {
    if (!isHobbyCatalog && (!club || club.isIncomplete)) {
      fetchClub(clubId!);
    }
  }, [fetchClub, club, clubId, isHobbyCatalog]);

  const handleSetCatalogDiscount = (event: ChangeEvent | null, { value }: any) => {
    if (isHobbyCatalog) {
      updateHobbyCatalog({
        productDiscountPercent: value,
      });
    } else {
      updateCatalog(clubId!, {
        productDiscountPercent: value,
      });
    }
  };

  const handleSetEmbellishmentDiscount = (event: ChangeEvent | null, { value }: any) => {
    if (isHobbyCatalog) {
      updateHobbyCatalog({
        embellishmentsDiscountPercent: value,
      });
    } else {
      updateCatalog(clubId!, {
        embellishmentsDiscountPercent: value,
      });
    }
  };

  const handleSetTeamProductDiscount = (value: TeamDiscountsType[]) => {
    updateCatalog(clubId!, {
      teamProductDiscounts: value,
    });
  };

  const handleSetTeamEmbellishmentDiscount = (value: TeamDiscountsType[]) => {
    updateCatalog(clubId!, {
      teamEmbellishmentsDiscounts: value,
    });
  };

  const handleSetGroupFixedPrice = (value: EmbellishmentFixedPriceDiscountType[]) => {
    updateCatalog(clubId!, {
      embellishmentFixedPriceDiscounts: value.map(embellishmentDiscount => ({
        ...embellishmentDiscount,
        groupType: embellishmentDiscount.groupId === clubId ? GroupType.CLUB : GroupType.TEAM,
      })),
    });
  };

  return catalog ? (
    <div className={styles.wrapper}>
      <CatalogHeader isHobbyCatalog={isHobbyCatalog} clubId={clubId} catalog={catalog} />

      <Form className={styles.formContainer}>
        {loading && (
          <Dimmer active inverted><Loader /></Dimmer>
        )}

        <Header title={translate('GENERAL_CATALOG_DISCOUNT')} type={HeaderTypes.H3} />

        <Form.Field className={styles.discountField} width={8}>
          <label>{translate('DISCOUNT')}</label>
          <InputWithSymbol
            validators={[maxLimitPercentageValidator(50, 'FIFTYPERCENT_AND_MORE_DISCOUNT_WARNING')]}
            {...defaultInputSymbolProps}
            name="generalProductDiscount"
            onChange={handleSetCatalogDiscount}
            value={productDiscountPercent}
          />
        </Form.Field>

        {!isHobbyCatalog && [
          <Header title={translate('SPECIAL_CATALOG_DISCOUNT_FOR_TEAMS')} key="catalog-discount-header" type={HeaderTypes.H3} />,
          <TeamDiscountRows
            setTeamDisctounts={handleSetTeamProductDiscount}
            key="catalog-discounts"
            teamsOptions={teamsOptions}
            teamDiscounts={!isEmpty(teamProductDiscounts)
              ? teamProductDiscounts : [emptyTeamDiscount()]
            }
          />,
        ]}

        <Divider />

        <Header title={translate('GENERAL_EMBELLISHMENT_DISCOUNT')} type={HeaderTypes.H3} />

        <Form.Field className={styles.discountField} width={8}>
          <label>{translate('DISCOUNT_EMBELLISHMENT')}</label>
          <InputWithSymbol
            {...defaultInputSymbolProps}
            validators={[maxLimitPercentageValidator(50, 'FIFTYPERCENT_AND_MORE_DISCOUNT_WARNING')]}
            name="generalEmbellishmentDiscount"
            onChange={handleSetEmbellishmentDiscount}
            value={embellishmentsDiscountPercent}
          />
        </Form.Field>

        {!isHobbyCatalog && [
          <Header title={translate('SPECIAL_EMBELLISHMENT_DISCOUNT_FOR_TEAMS')} key="embellishment-discount-header" type={HeaderTypes.H3} />,
          <TeamDiscountRows
            setTeamDisctounts={handleSetTeamEmbellishmentDiscount}
            teamsOptions={teamsOptions}
            key="embellishment-discounts"
            rightLabel="DISCOUNT_EMBELLISHMENT"
            teamDiscounts={!isEmpty(teamEmbellishmentsDiscounts)
              ? teamEmbellishmentsDiscounts : [emptyTeamDiscount()]
            }
          />,
        ]}
        {!isHobbyCatalog && [
          <Header title={translate('SPECIAL_EMBELLISHMENT_FIX_PRICES_FOR_TEAMS')} key="embellishment-fixed-price-discount-header" type={HeaderTypes.H3} />,
          <GroupFixedPriceRows
            embellishemntOptions={embellishemntOptions}
            key="embellishment-fixed-price-discounts"
            setGroupFixedPrice={handleSetGroupFixedPrice}
            teamsOptions={[allTeamsOption, ...teamsOptions]}
            groupFixedPrice={!isEmpty(embellishmentFixedPriceDiscounts)
              ? embellishmentFixedPriceDiscounts : [emptyGroupFixedPrice()]
            }
          />,
        ]}

      </Form>
    </div>
  ) : (
    <div className={styles.wrapper}>
      {loading
        ? <Dimmer active inverted><Loader /></Dimmer>
        : <Header title={translate('CATALOG.NOT_FOUND')} backLinkTo={backLinkTo} />
      }
    </div>
  );
};

export default CatalogDiscounts;
