import React, { FC } from 'react';
import { Modal, Button, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './ApproveSuccessModal.module.scss';
import { HeaderTypes } from '../../../../components/Header';

interface ApproveSuccessModalProps {
  successModalOpened: boolean;
  newClubCreated: boolean;
  setSuccessModalOpened: (opened: boolean) => void;
  resetApproveModal: () => void;
}

const ApproveSuccessModal: FC<ApproveSuccessModalProps> = ({
  successModalOpened,
  setSuccessModalOpened,
  newClubCreated,
  resetApproveModal,
}) => {
  const [translate] = useTranslation();

  const handleOnClose = () => {
    setSuccessModalOpened(false);
    resetApproveModal();
  };

  return (
    <Modal
      closeIcon
      size="tiny"
      onClose={handleOnClose}
      open={successModalOpened}
    >
      <div className={styles.successModalWrapper}>
        <div className={styles.headerWrapper}>
          <Header className={styles.title} as={HeaderTypes.H2} content={translate(newClubCreated ? 'NEW_CLUB_CREATED_HEADER' : 'TEAM_APPROVED_HEADER')} />
        </div>
        <div className={styles.contentWrapper}>
          {translate(newClubCreated ? 'NEW_CLUB_CREATED_TEXT' : 'TEAM_APPROVED_TEXT')}
        </div>
        <div className={styles.actionsButtons}>
          <Button
            circular
            content={translate('DONE')}
            onClick={handleOnClose}
            primary
            fluid
          />
        </div>
      </div>
    </Modal>
  );
};

export default ApproveSuccessModal;
