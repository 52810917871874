import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import CreateClub from './CreateClub';
import { State } from '../../../../models/State';
import { StateProps, DispatchProps } from './CreateClubProps';
import { createClubRequest } from '../../../../actions/clubs/createClub';
import { fetchSalesRepOrgsRequest } from '../../../../actions/organizations/FetchSalesRepOrgs';
import { fetchExternalClubsRequest } from '../../../../actions/externalSystems/fetchExternalClubs';

const mapStatesToProps = ({
  clubs: { error, loading },
  organizations: {
    byHash,
    byId,
    loading: loadingSalesReps,
  },
  application: {
    organizationType,
  },
  externalSystems: {
    externalClubByHash,
    externalClubById,
    loading: loadingExternalClubs,
  },
}: State): StateProps => ({
  loading,
  error,
  loadingSalesReps,
  salesReps: byId.map(id => byHash[id]),
  externalClubs: externalClubById.map(id => externalClubByHash[id]),
  loadingExternalClubs,
  organizationType,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  createClub: bindActionCreators(createClubRequest, dispatch),
  fetchSalesReps: bindActionCreators(fetchSalesRepOrgsRequest, dispatch),
  fetchExternalClubs: bindActionCreators(fetchExternalClubsRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(CreateClub);
