export const productCategories = [
  'Accessoires',
  'Benutzerdefiniert',
  'Handschuhe',
  'Hoodys',
  'Hosen',
  'Jacken',
  'Shirts',
  'Socken',
  'Stutzen',
  'Sweatshirts',
  'Taschen',
  'Trikots',
  'Underwear',
  'Zubehör',
];
