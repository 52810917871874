import { AjaxError } from 'rxjs/ajax';
import {
  CHANGE_TEAM_STATUS_ERROR,
  CHANGE_TEAM_STATUS_REQUEST,
  CHANGE_TEAM_STATUS_SUCCESS,
} from '../../constants/actionTypes';
import {
  ChangeTeamStatusErrorAction, ChangeTeamStatusRequestAction,
  ChangeTeamStatusSuccessAction,
} from '../../models/actionTypes';
import TeamStatus from '../../constants/teamStatus';

export const changeTeamStatusRequest = (
  teamId: number,
  status: TeamStatus,
): ChangeTeamStatusRequestAction => ({
  type: CHANGE_TEAM_STATUS_REQUEST,
  payload: {
    teamId,
    status,
  },
});

export const changeTeamStatusSuccess = (
  teamId: number,
  status: TeamStatus,
): ChangeTeamStatusSuccessAction => ({
  type: CHANGE_TEAM_STATUS_SUCCESS,
  payload: {
    teamId,
    status,
  },
});

export const changeTeamStatusError = (error: AjaxError): ChangeTeamStatusErrorAction => ({
  type: CHANGE_TEAM_STATUS_ERROR,
  payload: {
    error,
  },
});
