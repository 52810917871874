import {
  FETCH_TEAM_REQUESTS,
  FETCH_TEAM_REQUESTS_SUCCESS,
  FETCH_TEAM_REQUESTS_ERROR,
  EDIT_TEAM_PROMOTION_REQUEST,
  EDIT_TEAM_PROMOTION_REQUEST_ERROR,
  EDIT_TEAM_PROMOTION_REQUEST_SUCCESS,
  APPROVE_PROMOTION_SUCCESS,
  REJECT_PROMOTION_SUCCESS,
  APPROVE_PROMOTION_REQUEST,
  RESET_APPROVE_REQUEST_MODAL,
  REJECT_PROMOTION_REQUEST,
  APPROVE_PROMOTION_ERROR,
  REJECT_PROMOTION_ERROR,
} from '../constants/actionTypes';

import { TeamRequestReducer, TeamRequestByHash, TeamRequest } from '../models/TeamRequests';
import { SIGN_OUT_SUCCESS, SIGN_OUT_ERROR } from '../packages/authentication';
import { Actions } from '../models/actionTypes';

export const initialState: TeamRequestReducer = {
  loading: false,
  byHash: {},
  byId: [],
  count: 0,
  error: false,
  editSuccessful: false,
  newClubCreated: false,
  teamRejected: false,
  teamApproved: false,
  errorMessage: '',
};

export default (state = initialState, action : Actions) => {
  switch (action.type) {
    case EDIT_TEAM_PROMOTION_REQUEST:
      return {
        ...state,
        loading: true,
        editSuccessful: false,
      };
    case FETCH_TEAM_REQUESTS:
      return {
        ...state,
        loading: true,
      };
    case EDIT_TEAM_PROMOTION_REQUEST_SUCCESS: {
      const { request } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        editSuccessful: true,
        byHash: state.byId.reduce((byHash: TeamRequestByHash, id: number) => ({
          ...byHash,
          [id]: {
            ...state.byHash[id],
            ...(request.id === id && request),
          },
        }), {}),
      };
    }
    case FETCH_TEAM_REQUESTS_SUCCESS: {
      const { count, requests } = action.payload;

      return {
        ...state,
        byHash: requests
          .reduce((byHash: TeamRequestByHash, request: TeamRequest) => ({
            ...byHash,
            [request.id]: request,
          }), state.byHash),
        byId: requests.map(({ id }: TeamRequest) => id),
        loading: false,
        count,
      };
    }
    case EDIT_TEAM_PROMOTION_REQUEST_ERROR:
    case FETCH_TEAM_REQUESTS_ERROR: {
      const { error: { message } } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: message,
      };
    }
    case APPROVE_PROMOTION_REQUEST: {
      return {
        ...state,
        loading: true,
        newClubCreated: false,
        teamApproved: false,
      };
    }
    case REJECT_PROMOTION_ERROR:
    case APPROVE_PROMOTION_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case APPROVE_PROMOTION_SUCCESS: {
      const { [action.payload.promotionRequestId]: promotionId, ...restByHash } = state.byHash;

      return ({
        ...state,
        byHash: restByHash,
        loading: false,
        byId: state.byId.filter(id => id !== action.payload.promotionRequestId),
        newClubCreated: action.payload.newClubCreated,
        teamApproved: action.payload.teamApproved,
      });
    }
    case REJECT_PROMOTION_REQUEST:
      return {
        ...state,
        teamRejected: false,
        loading: true,
      };
    case REJECT_PROMOTION_SUCCESS: {
      const { [action.payload.promotionRequestId]: promotionId, ...restByHash } = state.byHash;

      return ({
        ...state,
        teamRejected: true,
        loading: false,
        byHash: restByHash,
        byId: state.byId.filter(id => id !== action.payload.promotionRequestId),
      });
    }
    case RESET_APPROVE_REQUEST_MODAL:
      return {
        ...state,
        teamApproved: false,
        newClubCreated: false,
        teamRejected: false,
      };
    case SIGN_OUT_SUCCESS:
    case SIGN_OUT_ERROR:
      return initialState;
    default:
      return state;
  }
};
