import { REMOVE_DRAFT_FILE, SET_DRAFT_FILES } from '../../constants/actionTypes';
import { SetDraftFilesAction, RemoveDraftFileAction } from '../../models/actionTypes';
import { UploadFile } from '../../models/File';

export const setDraftFiles = (files: UploadFile[]): SetDraftFilesAction => ({
  type: SET_DRAFT_FILES,
  payload: {
    files,
  },
});

export const removeDraftFile = (id: string): RemoveDraftFileAction => ({
  type: REMOVE_DRAFT_FILE,
  payload: {
    id,
  },
});
