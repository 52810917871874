import { AjaxError } from 'rxjs/ajax';
import {
  FETCH_ENTITY_REWARDS_ERROR,
  FETCH_ENTITY_REWARDS_REQUEST,
  FETCH_ENTITY_REWARDS_SUCCESS,
} from '../../constants/actionTypes';
import {
  FetchEntityRewardsErrorsActions,
  FetchEntityRewardsRequestActions,
  FetchEntityRewardsSuccessActions,
} from '../../models/actionTypes/RewardsActions';
import { ActivityReward } from '../../models/activityRewards';
import { RewardsListDataActionPayload } from '../../models/Rewards';

export const fetchEntityRewardsRequest = (
  payload : RewardsListDataActionPayload,
): FetchEntityRewardsRequestActions => ({
  type: FETCH_ENTITY_REWARDS_REQUEST,
  payload,
});

export const fetchEntityRewardsSuccess = (
  data: ActivityReward[], entityId: number, count: number, tags: string[],
): FetchEntityRewardsSuccessActions => ({
  type: FETCH_ENTITY_REWARDS_SUCCESS,
  payload: {
    data,
    entityId,
    count,
    tags,
  },
});

export const fetchEntityRewardsErrorr = (error: AjaxError): FetchEntityRewardsErrorsActions => ({
  type: FETCH_ENTITY_REWARDS_ERROR,
  payload: {
    error,
  },
});
