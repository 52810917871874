import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';
import {
  debounceTime, distinctUntilChanged, filter, tap,
} from 'rxjs/operators';
import {
  Button, Divider, DropdownItemProps, DropdownProps, Form,
} from 'semantic-ui-react';
import FormFields from '../../../../components/FormFields';
import Header, { HeaderTypes } from '../../../../components/Header';
import { Roles } from '../../../../constants/roles';
import { CLUBS_ROUTE } from '../../../../constants/routes';
import useFocusFirstInput from '../../../../hooks/useFocusFirstInput';
import useForm from '../../../../hooks/useForm';
import { OrganizationType } from '../../../../models/OrganizationType';
import {
  clubSalesIdFormat,
  hasMaxLength,
  hasMinLength,
  hasOnlyNumbers,
} from '../../../../utils/formValidators';
import styles from './CreateClub.module.scss';
import { CreateClubProps } from './CreateClubProps';
import { clubAdminSchema, validationSchema } from './schema';

const CreateClub = ({
  createClub, error, loading, salesReps, fetchExternalClubs, organizationType,
  fetchSalesReps, loadingSalesReps, externalClubs, loadingExternalClubs,
}: CreateClubProps) => {
  const {
    values,
    errors,
    handleChange,
    validate,
  } = useForm(validationSchema);
  const isUserLeadOrRegion = [
    OrganizationType.MASTER, OrganizationType.REGION,
  ].includes(organizationType!);
  const [translate] = useTranslation();
  const [location, setLocation] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customerIdValid, setCustomerIdValid] = useState(true);
  const [clubSalesId, setClubSalesId] = useState('');
  const [clubSalesIdTouched, setClubSalesIdTouched] = useState(false);
  const [clubSalesIdValid, setClubSalesIdValid] = useState(false);
  const [clubName, setClubName] = useState('');
  const [location$] = useState(() => new Subject<string>());
  const [locationChanged, setLocationChanged] = useState(false);

  const [externalClubId, setExternalClubId] = useState<string | null>(null);
  const [externalClubsOptions, setExternalClubsOptions] = useState<DropdownItemProps[]>([]);

  const [addedClubId, setAddedClubId] = useState<string | null>(null);
  const [addedClubsOptions, setAddedClubsOptions] = useState<DropdownItemProps[]>([]);

  const [salesRepOrganizationId, setSalesRepOrganizationId] = useState<number | null>(null);
  const [salesRepsOptions, setSalesRepsOptions] = useState<DropdownItemProps[]>([]);

  useEffect(() => {
    setExternalClubsOptions(externalClubs.map(({ id, name }) => ({ value: id, text: name })));
  }, [externalClubs]);

  useEffect(() => {
    setSalesRepsOptions(salesReps.map(({ id, name }) => ({ value: id, text: name })));
  }, [salesReps]);

  useEffect(() => {
    fetchSalesReps({ type: OrganizationType.SALES });
  }, [fetchSalesReps]);
  useFocusFirstInput();

  useEffect(() => {
    const searchListener = location$
      .pipe(
        filter(_location => _location.length > 2),
        tap(() => {
          setLocationChanged(true);
        }),
        debounceTime(500),
        distinctUntilChanged(),
      ).subscribe((searchLocation: string) => {
        if (searchLocation) {
          fetchExternalClubs(searchLocation);
        } else {
          setExternalClubsOptions([]);
          setExternalClubId(null);
        }
        setLocationChanged(false);
      });

    return () => {
      searchListener.unsubscribe();
    };
  }, [fetchExternalClubs, location$]);

  const onLocationChange = useCallback((
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLocation(value);
    if (value.length <= 2) {
      setExternalClubId(null);
    }
    location$.next(value);
  }, [location$]);

  const handleSubmit = () => {
    if (validate()) {
      const {
        firstName, lastName, email,
      } = values;

      const createInvitation = {
        receiverEmail: email,
        receiverRole: Roles.CLUB_ADMIN,
      };

      const invitationPersonalData = {
        firstName,
        lastName,
        ...(clubName && { clubName }),
      };

      createClub(
        clubName,
        location,
        createInvitation,
        invitationPersonalData,
        salesRepOrganizationId!,
        externalClubId,
        customerId,
        clubSalesId,
      );
    }
  };

  const handleSalesRepChange = (e: any, data: DropdownProps) => {
    setSalesRepOrganizationId(+data.value!);
  };

  const handleExternalClubChange = (e: any, { value }: DropdownProps) => {
    const externalClubSelected = externalClubsOptions.find(o => o.value === value);
    setExternalClubId(externalClubSelected ? value as string : null);
    setAddedClubId(!externalClubSelected ? value as string : null);
    setClubName(externalClubSelected ? externalClubSelected.text as string : value as string);
  };

  const handleClubAddition = (
    e: any, { value }: DropdownProps,
  ) => {
    setAddedClubsOptions(options => [{ text: value, value: value as string },
      ...options]);
    setClubName(value as string);
  };

  const onSalesRepExternalIdChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setClubSalesId(value);
    setClubSalesIdTouched(true);
    setClubSalesIdValid(clubSalesIdFormat().rule(value) && hasMaxLength(5).rule(value));
  };
  const onCustomerIdChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCustomerId(value);
    setCustomerIdValid(!value || (hasMaxLength(7).rule(value)
        && hasMinLength(7).rule(value)
        && hasOnlyNumbers.rule(value)));
  };

  const shouldDisableSubmit = () => loading
        || !clubName
        || !location
        || (isUserLeadOrRegion && !salesRepOrganizationId)
        || !clubSalesIdValid
        || !customerIdValid;

  const renderButton = () => (
    <Button
      primary
      disabled={shouldDisableSubmit()}
      type="submit"
      content={translate('SAVE')}
      form="create-form"
    />
  );
  return (
    <div className={styles.wrapper}>
      <Header
        backLinkTo={CLUBS_ROUTE}
        backLabel="BACK"
        border
        renderButton={renderButton}
        title={translate('ADD_NEW_CLUB')}
      />

      <Form error={error} onSubmit={handleSubmit} loading={loading} className={styles.form} id="create-form">
        <div className={styles.fieldsContainer}>
          <Form.Field
            className={styles.formField}
            key="club-location-id"
            required
          >
            <label>{translate('LOCATION')}</label>
            <div className={styles.inputWithLabel}>
              <Form.Input
                name="club-location"
                onChange={onLocationChange}
                placeholder={translate('ENTER_LOCATION')}
                value={location}
              />
            </div>
          </Form.Field>
          <Form.Field
            className={styles.formField}
            key="club-name-id"
            required
          >
            <label>{translate('CLUB_NAME')}</label>
            <div className={styles.inputWithLabel}>
              <Form.Dropdown
                name="club-name"
                placeholder={`${translate('ENTER_CLUB_NAME_OR_CHOOSE_EXISTING_CLUB')} (${externalClubs.length || 0})`}
                onChange={handleExternalClubChange}
                className={styles.clubNameCount}
                options={[...externalClubsOptions, ...addedClubsOptions]}
                disabled={
                   !location
                   || location.length < 3
                   || loadingExternalClubs
                   || locationChanged
                             }
                loading={loadingExternalClubs || locationChanged}
                selection
                search
                allowAdditions
                additionLabel={`${translate('CLUB_CREATION.CREATE_CLUB_PLACEHOLDER')} `}
                onAddItem={handleClubAddition}
                value={externalClubId || addedClubId || ''}
                noResultsMessage={translate('CLUB_CREATION.NO_CLUBS_FOUND_FOR_PROVIDED_LOCATION')}
              />
            </div>
          </Form.Field>
          {isUserLeadOrRegion && (
            <Form.Field
              className={styles.formField}
              key="sales-rep-id"
              required
            >
              <label>{translate('CLUB_CREATION.SALES_REP_LABEL')}</label>
              <div className={styles.inputWithLabel}>
                <Form.Dropdown
                  name="sales-rep-id-select"
                  onChange={handleSalesRepChange}
                  placeholder={translate('CLUB_CREATION.SALES_REP_PLACEHOLDER')}
                  options={salesRepsOptions}
                  disabled={loadingSalesReps}
                  loading={loadingSalesReps}
                  selection
                  value={salesRepOrganizationId || ''}
                />
              </div>
            </Form.Field>
          )}
          <Form.Field
            className={styles.formField}
            key="club-sales-id"
            required
          >
            <label>{translate('CLUB_SALES_ID')}</label>
            <div className={styles.inputWithLabel}>
              <Form.Input
                name="sales-rep-external-id"
                onChange={onSalesRepExternalIdChange}
                placeholder={translate('CLUB_SALES_ID_FORMAT')}
                value={clubSalesId}
                error={clubSalesIdTouched
                    && !clubSalesIdValid}
              />
            </div>
          </Form.Field>
          <Form.Field
            className={styles.formField}
            key="club-customer-id"
          >
            <label>{translate('CUSTOMER_ID')}</label>
            <div className={styles.inputWithLabel}>
              <Form.Input
                name="customer-id"
                onChange={onCustomerIdChange}
                placeholder={translate('CUSTOMER_ID_FORMAT')}
                value={customerId}
                error={!customerIdValid}
              />
            </div>
          </Form.Field>
        </div>

        <Divider />

        <Header title={translate('CLUB_ADMIN')} type={HeaderTypes.H3} />

        <Form.Group widths="equal">
          <FormFields
            schema={clubAdminSchema}
            errors={errors}
            values={values}
            handleChange={handleChange}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default CreateClub;
