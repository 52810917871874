import { AjaxError } from 'rxjs/ajax';
import {
  CREATE_CLUB_REQUEST,
  CREATE_CLUB_SUCCESS,
  CREATE_CLUB_ERROR,
} from '../../constants/actionTypes';
import { Club } from '../../models/Club';
import { CreateInvitation, InvitationPersonalData } from '../../models/InvitationData';
import {
  CreateClubRequestAction,
  CreateClubSuccessAction,
  CreateClubErrorAction,
} from '../../models/actionTypes';

export const createClubRequest = (
  name: string,
  location: string,
  createInvitation: CreateInvitation,
  invitationPersonalData: InvitationPersonalData,
  salesRepOrganizationId: number,
  externalClubId: string | null,
  customerId: string | null,
  clubSalesId: string | null,
): CreateClubRequestAction => ({
  type: CREATE_CLUB_REQUEST,
  payload: {
    name,
    location,
    createInvitation,
    invitationPersonalData,
    salesRepOrganizationId,
    customerId,
    externalClubId,
    clubSalesId,
  },
});

export const createClubSuccess = (club: Club): CreateClubSuccessAction => ({
  type: CREATE_CLUB_SUCCESS,
  payload: {
    club,
  },
});

export const createClubError = (error: AjaxError): CreateClubErrorAction => ({
  type: CREATE_CLUB_ERROR,
  payload: {
    error,
  },
});
