import { AjaxError } from 'rxjs/ajax';
import { CONVERT_TO_OFFICIAL_REQUEST, CONVERT_TO_OFFICIAL_SUCCESS, CONVERT_TO_OFFICIAL_ERROR } from '../../constants/actionTypes';
import {
  ConvertToOfficialErrorAction,
  ConvertToOfficialRequestAction,
  ConvertToOfficialSuccessAction,
} from '../../models/actionTypes';

export const convertToOfficial = (
  teamId: number, officialClubId: number, salesRepOrganizationId: number,
): ConvertToOfficialRequestAction => ({
  type: CONVERT_TO_OFFICIAL_REQUEST,
  payload: {
    teamId,
    officialClubId,
    salesRepOrganizationId,
  },
});

export const convertToOfficialSuccess = (): ConvertToOfficialSuccessAction => ({
  type: CONVERT_TO_OFFICIAL_SUCCESS,
});

export const convertToOfficialError = (error: AjaxError): ConvertToOfficialErrorAction => ({
  type: CONVERT_TO_OFFICIAL_ERROR,
  payload: {
    error,
  },
});
