import { AjaxError } from 'rxjs/ajax';
import {
  CREATE_HOBBY_CATALOG_REQUEST,
  CREATE_HOBBY_CATALOG_SUCCESS,
  CREATE_HOBBY_CATALOG_ERROR,
} from '../../constants/actionTypes';
import { HobbyCatalog } from '../../models/Catalog';
import {
  CreateHobbyCatalogRequestAction,
  CreateHobbyCatalogSuccessAction,
  CreateHobbyCatalogErrorAction,
} from '../../models/actionTypes';

export const createHobbyCatalogRequest = (
  catalog: HobbyCatalog,
): CreateHobbyCatalogRequestAction => ({
  type: CREATE_HOBBY_CATALOG_REQUEST,
  payload: {
    catalog,
  },
});

export const createHobbyCatalogSuccess = (
): CreateHobbyCatalogSuccessAction => ({
  type: CREATE_HOBBY_CATALOG_SUCCESS,
  payload: {},
});

export const createHobbyCatalogError = (error: AjaxError): CreateHobbyCatalogErrorAction => ({
  type: CREATE_HOBBY_CATALOG_ERROR,
  payload: {
    error,
  },
});
