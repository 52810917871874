import { AjaxError } from 'rxjs/ajax';
import {
  UPDATE_CLUB_ERROR,
  UPDATE_CLUB_REQUEST,
  UPDATE_CLUB_SUCCESS,
} from '../../constants/actionTypes';
import { Club, ClubEditRequest } from '../../models/Club';
import { UpdateClubErrorAction, UpdateClubRequestAction, UpdateClubSuccessAction } from '../../models/actionTypes';

export const updateClubRequest = (
  updatedClub: ClubEditRequest,
  club: Club,
  externalClubId?: string,
): UpdateClubRequestAction => ({
  type: UPDATE_CLUB_REQUEST,
  payload: {
    updatedClub,
    club,
    externalClubId,
  },
});
export const updateClubSuccess = (club: Club): UpdateClubSuccessAction => ({
  type: UPDATE_CLUB_SUCCESS,
  payload: {
    club,
  },
});

export const updateClubError = (error: AjaxError): UpdateClubErrorAction => ({
  type: UPDATE_CLUB_ERROR,
  payload: {
    error,
  },
});
