import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { fetchCatalogError } from '../../actions/catalogs/fetchCatalog';
import { fetchHobbyCatalogSuccess } from '../../actions/catalogs/fetchHobbyCatalog';
import { handleError } from '../../actions/errors/handleError';
import { FETCH_HOBBY_CATALOG_ERROR, FETCH_HOBBY_CATALOG_REQUEST } from '../../constants/actionTypes';
import { HOBBY_CATALOG_DETAILS_PATH } from '../../constants/paths';
import { FetchHobbyCatalogRequestAction } from '../../models/actionTypes';
import { CatalogResponse } from '../../models/Catalog';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import serializeHobbyCatalog from '../../utils/serializers/serializeHobbyCatalog';

export default (
  action$: ActionsObservable<FetchHobbyCatalogRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_HOBBY_CATALOG_REQUEST),
  withLatestFrom(state$),
  mergeMap(([, { authentication: { authToken } }]) => ajax({
    url: HOBBY_CATALOG_DETAILS_PATH,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: catalog }: Response<CatalogResponse>) => serializeHobbyCatalog(catalog)),
    map(fetchHobbyCatalogSuccess),
    catchError((error: any) => ([
      handleError(error, FETCH_HOBBY_CATALOG_ERROR),
      fetchCatalogError(error),
    ])),
  )),
);
