import { ResetFileUploadedAction } from '../../models/actionTypes';
import { UploadFile } from '../../models/File';

export enum FileUploadTypes {
  IMAGE = 'IMAGE',
  FILE = 'FILE',
}

export interface FileUploadModalProps {
  modalOpened?: boolean;
  fileUploaded?: boolean;
  resetFileUploaded: () => ResetFileUploadedAction;
  renderTriggerButton?: boolean;
  onClose?: () => void;
  loading: boolean;
  uploadFile: (image: File) => void;
  triggerButton?: (onClick: () => void) => JSX.Element;
  type: FileUploadTypes;
  filesToSelect?: UploadFile[];
  selectFile?: (id: string, originalFilename: string, extension: string) => void;
  aspect?: number;
}
