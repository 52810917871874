import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import Catalogs from './Catalogs';
import { State } from '../../models/State';
import { StateProps, DispatchProps } from './CatalogsProps';
import { fetchCatalogsRequest } from '../../actions/catalogs/fetchCatalogs';

const mapStatesToProps = (state: State): StateProps => {
  const {
    catalogs: {
      error,
      errorMessage,
      loading,
      loadingList,
      byId,
      byHash,
      count,
    },
  } = state;

  return ({
    catalogs: byId.map(id => byHash[id]),
    loading,
    loadingList,
    count,
    error,
    errorMessage,
  });
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetch: bindActionCreators(fetchCatalogsRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(Catalogs);
