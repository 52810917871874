import React, {
  FC, FormEvent, useEffect, useState,
} from 'react';
import {
  Checkbox, CheckboxProps,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './RewardsActivitiesList.module.scss';
import { FetchEntityRewardsRequestActions } from '../../../models/actionTypes/RewardsActions';
import { ActivityReward, ActivityRewardsByHash } from '../../../models/activityRewards';
import { formatDate } from '../../../utils/DateUtils';
import { EntityTypesEnum } from '../../../models/Entity';
import { FiltersDirectionsEnum } from '../../../models/Rewards';
import columns from './columns';
import Row from '../../../components/Table/components/Row';
import Text from '../../../components/Text';
import Table from '../../../components/Table';

export interface RewardsActivitiesListProps {
  entityType: EntityTypesEnum;
  entityId: number;
  activityRewardsByHash: ActivityRewardsByHash,
  activityRewardsById: number[],
  lastDateTime: number,
  count: number,
  tags: string[],
  loading: boolean,
  fetchRewards: (
    payload: {
      entityId: number;
      entityType: EntityTypesEnum,
      dateTime?: number;
      direction?: FiltersDirectionsEnum;
      limit?: number;
      tags?: string[];
    }
  ) => FetchEntityRewardsRequestActions
}

const RewardsActivitiesList: FC<RewardsActivitiesListProps> = ({
  entityId,
  entityType,
  fetchRewards,
  activityRewardsByHash,
  activityRewardsById,
  lastDateTime,
  loading,
  count,
  tags,
}) => {
  const [translate] = useTranslation();
  const [rows, setRows] = useState<any>();
  const [showRewards, setShowRewards] = useState<boolean>(true);

  useEffect(() => {
    fetchRewards({ entityId, entityType });
  }, [entityId, entityType, fetchRewards]);

  const renderRow = (data: ActivityReward) => (
    <Row key={data.id} classNames={styles.teamRewardsRow}>
      <Text>{data.name}</Text>
      <Text>
        <div className={[styles.rewardsColumn,
          (+data.scoreChange > 0 ? styles.addition : styles.deletion)].join(' ')}
        >
          {+data.scoreChange > 0 ? '+' : ''}
          {data.scoreChange}
        </div>
      </Text>
      <Text>{formatDate(data.createdAt)}</Text>
    </Row>
  );

  const showMore = () => {
    if (lastDateTime) {
      fetchRewards({
        entityId, entityType, tags, dateTime: lastDateTime,
      });
    }
  };

  const toggleShowRewards = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    if (data.checked) {
      fetchRewards({ entityId, entityType });
    } else {
      fetchRewards({ entityId, entityType, tags: ['REDEMPTION'] });
    }
  };

  useEffect(() => {
    if (tags.length === 0) {
      setShowRewards(true);
    } else {
      setShowRewards(false);
    }
  }, [tags]);

  useEffect(() => {
    setRows(activityRewardsById.map(id => renderRow(activityRewardsByHash[id])));
  }, [activityRewardsByHash, activityRewardsById]);

  return (
    <>
      <Checkbox toggle label={translate('REWARDS.SHOW_REWARDS')} checked={showRewards} onChange={toggleShowRewards} />
      <div className={styles.teamRewardsTableContainer}>
        <div className={styles.wrapper}>
          <Table
            columns={columns}
            loading={loading}
            invertedHeader
            emptyTableText={translate('REWARDS.NO_REWARDS_YET')}
          >
            {rows}
          </Table>
        </div>
      </div>
      { (activityRewardsById.length < count) && (<button type="button" className={styles.showMoreButton} onClick={showMore}>{ translate('SHOW_MORE')}</button>)}
    </>
  );
};

export default RewardsActivitiesList;
