import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchSalesRepOrgsRequest } from '../../../actions/organizations/FetchSalesRepOrgs';
import { State } from '../../../models/State';
import SalesRepFilter from './SalesRepFilter';

const mapStatesToProps = ({
  organizations: {
    byHash,
    byId,
    loading,
    error,
  },
}: State) => {
  const allSalesReps = byId.flatMap(id => (byHash[id].users) || []);
  const salesReps = [...new Map(allSalesReps.map(user => [user.id, user])).values()];

  return ({
    loading,
    error,
    salesReps,
  });
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSalesReps: bindActionCreators(fetchSalesRepOrgsRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(SalesRepFilter);
