import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import EmbellishmentValueSelectionHeader from './EmbellishmentValueSelectionHeader';
import { State } from '../../../../models/State';

const mapStateToProps = (
  {
    application: {
      memberships,
      clubId: currentlyUsedOrganizationId,
    },
    clubs: {
      loadingList: loading,
      byHash: clubsByHash,
      byId: clubsById,
    },
  }: State,
) => ({
  memberships,
  currentlyUsedOrganizationId,
  clubsByHash,
  loading,
  clubsById,
});

export default withRouter(
  connect(mapStateToProps)(EmbellishmentValueSelectionHeader),
);
