import React, { FC, useEffect } from 'react';
import { Image } from 'semantic-ui-react';
import classNames from 'classnames';

import Avatar from './components/Avatar';
import styles from './ProfileAvatar.module.scss';
import { ProfileAvatarProps } from './ProfileAvatarProps';
import getInitials from '../../../utils/getInitials';

const ProfileAvatar: FC<ProfileAvatarProps> = ({
  fetchFile,
  filesByHash,
  italic = false,
  size = 'tiny',
  user: {
    firstName, lastName, profilePictureReference: imageId, userExists,
  },
  ...props
}) => {
  const imageUrl = imageId && filesByHash[imageId];
  const initials = getInitials({ firstName, lastName });

  useEffect(() => {
    if (imageId && !filesByHash[imageId]) {
      fetchFile(imageId);
    }
    // eslint-disable-next-line
  }, [fetchFile, imageId]);

  return (
    <div
      className={classNames(styles.profileImageContainer, styles[`image-${size}`], italic && styles.italic)}
      {...props}
    >
      {imageUrl ? (
        <Image src={imageUrl} circular className={styles.profileImage} />
      ) : (
        <Avatar initials={initials} />
      )}
      {userExists && (
        <div className={styles.badge} />
      )}
    </div>
  );
};

export default ProfileAvatar;
