import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import SettingsEdit, { SettingsEditProps } from './SettingsEdit';
import { State } from '../../models/State';
import { Club } from '../../models/Club';
import { uploadClubLogoRequest } from '../../actions/clubs/uploadClubLogo';
import { updateClubRequest } from '../../actions/clubs/updateClub';
import { fetchClubRequest } from '../../actions/clubs/fetchClub';
import { fetchExternalClubsRequest } from '../../actions/externalSystems/fetchExternalClubs';

const mapStatesToProps = ({
  clubs: {
    byHash,
    loadingUpload,
    loading,
    clubLogoUploaded,
  },
  externalSystems: {
    externalClubByHash,
    externalClubById,
    loading: loadingExternalClubs,
  },
}: State, { match: { params: { clubId } } }: SettingsEditProps) => ({
  club: clubId && byHash[clubId] && byHash[clubId].address
    ? byHash[clubId]
    : {
      address: {},
      socialMedia: {},
    } as Club,
  loadingUpload,
  clubLogoUploaded,
  loading,
  externalClubs: externalClubById.map(id => externalClubByHash[id]),
  loadingExternalClubs,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  uploadClubLogo: bindActionCreators(uploadClubLogoRequest, dispatch),
  updateClub: bindActionCreators(updateClubRequest, dispatch),
  fetchClub: bindActionCreators(fetchClubRequest, dispatch),
  fetchExternalClubs: bindActionCreators(fetchExternalClubsRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(SettingsEdit);
