import calculateEmbellishmentsPrice from './calculateEmbellishmentsPrice';
import calculateProductPrice from './calculateProductPrice';
import { Catalog } from '../../models/Catalog';
import { Embellishment } from '../../models/Embellishment';
import { Price, CompondPrice } from '../../models/Price';
import { Team } from '../../models/Team';
import { ProductEmbellishment } from '../../models/Product';
import { sumPrices } from '../PricingUtil';

const calculateProductPricesForTeams = (
  clubId: number,
  teams: Team[],
  embellishments: Embellishment[],
  price: Price,
  productDiscountPercent: number | null,
  catalog?: Catalog,
  productEmbellishments?: ProductEmbellishment[],
) => {
  const {
    productDiscountPercent: catalogDiscountPercent = 0,
    teamProductDiscounts = [],
  } = catalog || {};

  const enrichPrice = (id: number, name?: string): CompondPrice => {
    const discountedPriceAmount = calculateProductPrice(
      id,
      teamProductDiscounts,
      productDiscountPercent,
      catalogDiscountPercent,
      price,
    );

    const embellishmentsPrice = calculateEmbellishmentsPrice(
      clubId,
      id,
      embellishments,
      catalog,
      productEmbellishments,
    );

    return {
      id,
      name,
      price: {
        ...price,
        amount: sumPrices([discountedPriceAmount, embellishmentsPrice]),
      },
    };
  };

  const clubPrice = enrichPrice(clubId);
  const teamsPrice = teams
    .map(({ id, name }) => enrichPrice(id, name))
    .filter(team => team.price.amount !== clubPrice.price.amount);

  return {
    clubPrice,
    teamsPrice,
  };
};

export default calculateProductPricesForTeams;
