import React, { FC, useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { Price } from '../../../../models/Price';
import calculateProductPricesInHobbyCatalog from '../../../../utils/calculatePrices/calculateProductPricesInHobbyCatalog';
import { formatPrice } from '../../../../utils/PricingUtil';
import { HobbyProductPriceProps } from './HobbyProductPriceProps';

const HobbyProductPrice: FC<HobbyProductPriceProps> = ({
  catalog,
  embellishments,
  productEmbellishments,
  price = { amount: 0, currency: 'EUR' } as Price,
  productDiscountPercent = null,
  loading = false,
}) => {
  const [totalPrice, setTotalPrice] = useState<number>();

  useEffect(() => {
    const hobbyProductPrice = calculateProductPricesInHobbyCatalog(
      embellishments,
      price,
      productDiscountPercent,
      catalog,
      productEmbellishments,
    );
    setTotalPrice(hobbyProductPrice);
  }, [catalog, embellishments, price, productDiscountPercent, productEmbellishments]);

  if (loading) {
    return (
      <Loader active inline />
    );
  }

  return (
    <span>{formatPrice(totalPrice, price.currency)}</span>
  );
};

export default HobbyProductPrice;
