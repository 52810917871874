import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { SIGN_IN_ROUTE } from '../../../../constants/routes';
import RouteProps from '../../interfaces/RouteProps';

const ProtectedRoute = ({ isAuthenticated, ...props }: RouteProps) => (
  isAuthenticated
    ? <Route {...props} />
    : <Redirect to={SIGN_IN_ROUTE} />
);

export default ProtectedRoute;
