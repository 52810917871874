import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, delay, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';

import { EDIT_REWARDS_ERROR, EDIT_REWARDS_REQUEST, EDIT_REWARDS_SUCCESS } from '../../constants/actionTypes';
import { NotificationType } from '../../models/actionTypes';
import { State } from '../../models/State';
import { handleError } from '../../actions/errors/handleError';
import { addNotification } from '../../actions/notifications/addNotification';
import { EditRewardsRequestAction } from '../../models/actionTypes/RewardsActions';
import { editRewardsError, editRewardsSuccess } from '../../actions/rewards/editRewards';
import { REWARDS_EDIT_PATH } from '../../constants/paths';
import { fetchEntityRewardsRequest } from '../../actions/rewards/fetchRewards';
import { FiltersDirectionsEnum } from '../../models/Rewards';

export default (
  action$: ActionsObservable<EditRewardsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(EDIT_REWARDS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      entityId,
      entityType,
      pointsAmount,
      reason,
      tags,
      clubId,
    },
  }, { authentication: { authToken } }]) => ajax({
    url: REWARDS_EDIT_PATH,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      entityId,
      entityType,
      pointsAmount,
      reason,
      tags,
    }),
  }).pipe(
    delay(700),
    mergeMap(() => ([
      editRewardsSuccess(entityId,
        entityType,
        pointsAmount,
        clubId),
      fetchEntityRewardsRequest({
        entityId,
        entityType,
        limit: 10,
        direction: FiltersDirectionsEnum.BEFORE,
        tags: [],
      }),
      addNotification(NotificationType.SUCCESS,
        EDIT_REWARDS_SUCCESS),
    ])),
    catchError((error: AjaxError) => ([
      handleError(error, EDIT_REWARDS_ERROR),
      editRewardsError(error),
    ])),
  )),
);
