import { ApplicationActions } from '../models/actionTypes';
import {
  SET_MENU_EXPANDED,
  SET_APPLICATION_PARAMETERS,
  CHECK_EMAIL_AVAILABILITY_REQUEST,
  CHECK_EMAIL_AVAILABILITY_SUCCESS,
} from '../constants/actionTypes';
import { ApplicationReducer } from '../models/Application';

export const initialState: ApplicationReducer = {
  menuExpanded: true,
  memberships: [],
  clubId: null,
  checkingEmail: false,
  emailAvailable: true,
};

export default (state = initialState, action: ApplicationActions) => {
  switch (action.type) {
    case SET_MENU_EXPANDED: {
      const { value: menuExpanded } = action.payload;
      return {
        ...state,
        menuExpanded,
      };
    }
    case SET_APPLICATION_PARAMETERS: {
      const { params } = action.payload;
      return {
        ...state,
        ...params,
      };
    }
    case CHECK_EMAIL_AVAILABILITY_REQUEST: {
      return {
        ...state,
        checkingEmail: true,
        emailAvailable: false,
      };
    }
    case CHECK_EMAIL_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        checkingEmail: false,
        emailAvailable: action.payload.emailAvailability.emailAvailable,
      };
    }
    default:
      return state;
  }
};
