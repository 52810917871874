import { connect } from 'react-redux';

import ProductEmbellishments from './ProductEmbellishments';
import { State } from '../../../../models/State';

const mapStateToProps = ({ embellishments: { embellishmentsByHash } }: State) => ({
  embellishmentsByHash,
});

export default connect(mapStateToProps)(ProductEmbellishments);
