import React from 'react';
import { useTranslation } from 'react-i18next';

import CatalogsRow from './components/CatalogsRow';
import Header from '../../components/Header';
import TableContainer from '../../components/Layout/components/TableContainer';
import columns from './columns';
import styles from './Catalogs.module.scss';
import { Catalog } from '../../models/Catalog';
import { CatalogsProps } from './CatalogsProps';

const Catalogs = ({
  catalogs,
  count,
  fetch,
  history,
  loadingList,
}: CatalogsProps) => {
  const [translate] = useTranslation();

  const renderClubRows = (catalog: Catalog) => (
    <CatalogsRow catalog={catalog} key={catalog.id} />
  );

  return (
    <div className={styles.wrapper}>
      <Header
        border
        title={translate('CLUB_CATALOGS')}
      />

      <div className={styles.tableWrapper}>
        <TableContainer
          columns={columns}
          count={count}
          fetch={fetch}
          history={history}
          loading={loadingList}
          renderItems={catalogs.map(renderClubRows)}
          searchPlaceholder="SEARCH_FOR_CLUBS"
          invertedHeader
          emptyTableText="NO_CATALOGS_AVAILABLE"
          notFoundTableText="NO_CATALOGS_FOUND"
        />
      </div>
    </div>
  );
};

export default Catalogs;
