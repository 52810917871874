import React, {
  ChangeEvent, FC, useEffect, useState,
} from 'react';
import { Button, Input, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { SetDfbDivisionPopupProps } from './SetDfbDivisionPopupProps';
import { ExternalReferencedEntityType, ExternalSystemName } from '../../../../models/ExternalSystems';
import {
  TABLE_PAGE_URL_PARAM,
} from '../../../../constants/customizations';
import params from '../../../../utils/params';
import { history } from '../../../../configureStore';

const SetDfbDivisionPopup: FC<SetDfbDivisionPopupProps> = (
  {
    closePopup, team, clubId, saveExternalReference,
  },
) => {
  const [dfbReferenceId, setDfbReferenceId] = useState<string>();
  const [translate] = useTranslation();
  const { getParams } = params(history);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const {
    [TABLE_PAGE_URL_PARAM]: page,
  } = getParams();
  useEffect(() => {
    if (team && team.externalSystemReferences && team.externalSystemReferences.length) {
      const dFBData = team.externalSystemReferences.find(
        (ref) => ref.externalSystemName === ExternalSystemName.DFB_DIVISION,
      );
      if (dFBData) {
        setDfbReferenceId(`${dFBData.externalSystemEntityId}`);
      }
    }
  }, [team]);

  const handleIdChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setDfbReferenceId(event.target.value);
    setSubmitted(false);
  };

  const onSave = () => {
    if (team && dfbReferenceId) {
      setSubmitted(true);
      saveExternalReference({
        externalSystemName: ExternalSystemName.DFB_DIVISION,
        entityType: ExternalReferencedEntityType.TEAM,
        entityId: team.id,
        externalSystemEntityId: dfbReferenceId,
      }, clubId, page ? +page : 0);
    }
  };

  return (
    <Modal
      open={team !== undefined}
      onClose={closePopup}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header content={translate('SET_DFB_DIVISION')} />
      <Modal.Content>
        <Input
          fluid
          onChange={handleIdChanged}
          value={dfbReferenceId || ''}
          placeholder={translate('DFB_DIVISION_ID_PLACEHOLDER')}
          type="text"
        />
      </Modal.Content>
      <Modal.Actions>
        {!submitted && <Button onClick={closePopup}>{translate('CANCEL')}</Button>}
        {!submitted && <Button primary onClick={onSave}>{translate('SAVE')}</Button>}
        {submitted && <Button primary onClick={closePopup}>{translate('CLOSE')}</Button>}
      </Modal.Actions>
    </Modal>
  );
};

export default SetDfbDivisionPopup;
