import {
  PendingStaffMember,
  PendingStaffMembersByHash,
  StaffByHash,
  StaffMember,
  StaffReducer,
} from '../models/Staff';
import {
  FETCH_STAFF_ERROR,
  FETCH_STAFF_REQUEST,
  FETCH_STAFF_SUCCESS,
} from '../constants/actionTypes';
import { Actions } from '../models/actionTypes';

export const initialState: StaffReducer = {
  byHash: {},
  byId: [],
  loading: false,
  pendingStaffMembersByHash: {},
  pendingStaffMembersById: [],
  selectedMembersTeam: null,
  selectedId: [],
  selectedStaffIds: [],
};

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case FETCH_STAFF_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_STAFF_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_STAFF_SUCCESS: {
      const { staffMembers, pendingStaffMembers } = action.payload;

      return {
        ...state,
        byHash: staffMembers
          .reduce((byHash: StaffByHash, member: StaffMember) => ({
            ...byHash,
            [member.personId]: {
              ...member,
              id: member.personId,
            },
          }), state.byHash),
        byId: staffMembers.map(({ personId }: StaffMember) => personId),
        loading: false,
        pendingStaffMembersByHash: pendingStaffMembers
          .reduce((byHash: PendingStaffMembersByHash, member: PendingStaffMember) => ({
            ...byHash,
            [member.invitationToken]: member,
          }), state.pendingStaffMembersByHash),
        pendingStaffMembersById: pendingStaffMembers.map(
          ({ invitationToken }: PendingStaffMember) => invitationToken,
        ),
      };
    }
    default:
      return state;
  }
};
