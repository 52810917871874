import { ActionsObservable, ofType } from 'redux-observable';
import { delay, map } from 'rxjs/operators';

import { ADD_NOTIFICATION } from '../../constants/actionTypes';
import { AddNotificationAction } from '../../models/actionTypes';
import { ERROR_NOTIFICATION_DISPLAY_TIME } from '../../constants/customizations';
import { removeNotification } from '../../actions/notifications/removeNotification';

export default (
  action$: ActionsObservable<AddNotificationAction>,
) => action$.pipe(
  ofType(ADD_NOTIFICATION),
  delay(ERROR_NOTIFICATION_DISPLAY_TIME),
  map(({ payload: { id } }) => removeNotification(id)),
);
