import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import ReactCrop, { PercentCrop, PixelCrop } from 'react-image-crop';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  Button, Header, Image, Input, Modal,
} from 'semantic-ui-react';

import SelectFiles from './components/SelectFiles';
import convertFromBlobToUrl from './methods/convertFromBlobToUrl';
import styles from './FileUploadModal.module.scss';
import { FILES_EXTENSIONS, IMAGES_EXTENSIONS } from '../../constants/customizations';
import { FileUploadModalProps, FileUploadTypes } from './FileUploadModalProps';
import { UploadFile } from '../../models/File';
import 'react-image-crop/dist/ReactCrop.css';
import { useDebounceEffect } from '../../hooks/useDebounceEffect/useDebounceEffect';
import { canvasPreview } from './methods/canvasPreview';
import cropImage from './methods/cropImage';

const cropInitial = {
  height: 50,
  keepSelection: true,
  width: 50,
  x: 25,
  y: 25,
  unit: '%',
} as PercentCrop;

const FileUploadModal = ({
  uploadFile, triggerButton, loading, type, modalOpened, onClose, resetFileUploaded,
  filesToSelect, selectFile, aspect, renderTriggerButton = true, fileUploaded = false,
}: FileUploadModalProps) => {
  const [translate] = useTranslation();
  const [canCrop, setCanCrop] = useState(false);

  const [crop, setCrop] = useState<PercentCrop>({ ...cropInitial });
  const [fileBlob, setFileBlob] = useState<File | null>(null);
  const [fileSrc, setFileSrc] = useState<string | null>(null);
  const [imageBlobCropped, setImageBlobCropped] = useState<Blob | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<UploadFile | null>(null);

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: files => setFileBlob(files[0]),
  });

  useEffect(() => {
    if (fileBlob) {
      convertFromBlobToUrl([fileBlob], setFileSrc);
    }
  }, [fileBlob]);

  // New
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(cropInitial); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (!!reader && !!reader.result) {
          setFileSrc(reader.result.toString() || '');
        }
      });
      reader.readAsDataURL(e.target.files[0]);
      setFileBlob(e.target.files[0]);
    }
  }

  function onImageLoad() {
    setCrop(cropInitial);
  }

  useDebounceEffect(
    async () => {
      if (completedCrop
        && completedCrop.width
            && completedCrop.height
            && imgRef.current
            && previewCanvasRef.current
      ) {
        await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
        );
      }
    },
    100,
    [completedCrop],
  );
  const setInitValues = useCallback(() => {
    setCrop({ ...cropInitial });
    setFileSrc(null);
    setImageBlobCropped(null);
    setSelectedFile(null);
    setCanCrop(false);
  }, [setFileSrc, setImageBlobCropped]);

  const handleOnClose = useCallback(() => {
    setInitValues();
    setModalOpen(false);
    if (onClose) {
      onClose();
    }
  }, [setModalOpen, setInitValues, onClose]);

  useEffect(() => {
    if (!loading) {
      setInitValues();
      setModalOpen(false);
      if (resetFileUploaded) {
        resetFileUploaded();
      }
    }
    if (fileUploaded) {
      handleOnClose();
      if (resetFileUploaded) {
        resetFileUploaded();
      }
    }
  }, [loading, fileUploaded, setInitValues, handleOnClose, resetFileUploaded]);

  const onSubmit = useCallback(() => {
    const file = type === FileUploadTypes.IMAGE
      && canCrop ? imageBlobCropped : fileBlob;
    if (file && fileBlob) {
      uploadFile(new File([file], fileBlob.name));
    }
    if (selectFile && selectedFile) {
      selectFile(selectedFile.id, selectedFile.originalFilename, selectedFile.extension);
      handleOnClose();
    }
  }, [uploadFile, type, imageBlobCropped, fileBlob,
    selectFile, selectedFile, handleOnClose, canCrop]);

  const onClickButton = useCallback(() => setModalOpen(true), [setModalOpen]);
  const onToggleSelect = useCallback((file: UploadFile) => {
    setSelectedFile(selectedFile && selectedFile.id === file.id ? null : file);
  }, [setSelectedFile, selectedFile]);

  const fileNotUploaded = !fileSrc;
  const acceptExtensions = (type === FileUploadTypes.IMAGE ? IMAGES_EXTENSIONS : FILES_EXTENSIONS)
    .map(ext => `.${ext}`)
    .join(',');

  const buildTriggerButton = () => {
    if (renderTriggerButton) {
      if (triggerButton) {
        return triggerButton(onClickButton);
      }
      return (
        <Button
          content={translate('UPLOAD_IMAGE')}
          onClick={onClickButton}
          primary
          size="mini"
        />
      );
    }
    return null;
  };

  return (
    <Modal
      closeIcon
      onClose={handleOnClose}
      open={modalOpened || modalOpen}
      trigger={buildTriggerButton()}
    >
      <Modal.Header content={translate(`SELECT_${type}`)} />
      <Modal.Content>
        {canCrop && type === FileUploadTypes.IMAGE && fileSrc && (
          <div className={styles.containerResults}>
            <div className={styles.cropWrapper}>
              <ReactCrop
                crop={crop}
                className={styles.reactCrop}
                onChange={(pixelCrop, percentCrop) => {
                  setCrop(percentCrop);
                  setCompletedCrop(pixelCrop);
                }}
                onComplete={(pixelCrop, percentCrop) => {
                  if (imgRef && imgRef.current) {
                    cropImage(imgRef.current, percentCrop, (image: Blob | null) => {
                      if (image) {
                        setImageBlobCropped(image);
                      }
                    });
                  }
                }}
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={fileSrc}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
            <div>
              {completedCrop && (
              <canvas
                ref={previewCanvasRef}
                className={styles.previewCanvas}
                style={{
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
              )}
            </div>
          </div>
        )}
        {!canCrop && type === FileUploadTypes.IMAGE && fileSrc
        && (
        <div className={styles.containerResults}>
          <div className={styles.container}>
            <Image
              src={fileSrc}
              inline
              alt=""
              style={{
                objectFit: 'contain',
                height: '100%',
              }}
            />
          </div>
        </div>
        )
        }

        {type === FileUploadTypes.FILE && fileSrc && (
          'Uploaded'
        )}

        {!fileSrc && (
          <div className={styles.container}>
            <div {...getRootProps()}>
              <div className={classNames(styles.dropzone, isDragActive && styles.dropzoneActive)}>
                <Header content={translate(isDragActive ? `DROP_A_${type}_HERE` : `DRAG_DROP_ANY_${type}`)} />
              </div>
              <div className={styles.containerOr}>
                {translate('OR')}
              </div>
            </div>
            <label className="ui primary button" htmlFor="uploadFile">
              {translate(`UPLOAD_${type}`)}
            </label>
            <Input
              type="file"
              onChange={onSelectFile}
              id="uploadFile"
              className={styles.inputUploadFile}
              accept={acceptExtensions}
            />
          </div>
        )}
      </Modal.Content>

      {type === FileUploadTypes.FILE && filesToSelect && selectFile && fileNotUploaded && (
        <SelectFiles
          filesToSelect={filesToSelect}
          toggleFile={onToggleSelect}
          selectedFile={selectedFile}
        />
      )}

      <Modal.Actions className={styles.actionsContainer}>
        <Button
          basic
          circular
          content={translate('CANCEL')}
          onClick={handleOnClose}
          primary
        />
        {type === FileUploadTypes.IMAGE
            && (
            <Button
              circular
              content={translate(canCrop ? 'DISABLE_CROP' : 'CROP')}
              disabled={!fileSrc}
              onClick={() => setCanCrop(!canCrop)}
              primary
            />
            )}
        <Button
          circular
          content={translate('SAVE')}
          disabled={!fileBlob && (fileNotUploaded && !selectedFile)}
          loading={loading}
          onClick={onSubmit}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
};

export default FileUploadModal;
